import * as React from 'react';
import './ConfigureFieldsPanel.css';
import { PrimaryButton, PanelType, SearchBox, Overlay } from 'office-ui-fabric-react';
import * as Metadata from '../../../../entities/Metadata';
import FieldPanel, { IFieldActions } from '../../FieldPanel';
import { FieldsList } from '../FieldsList';
import { usePrevious } from '../../../utils/effects';
import Spinner from '../../../common/Spinner';
import ExpandablePanel from '../../../common/ExpandablePanel';
import { EntityType } from '../../../../entities/common';

export type Props = {
    fields: Metadata.Field[];
    selected: string[];
    entityType: EntityType;
    headerText?: string;
    secondaryText?: string;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    showSpinner?: boolean;
    onDismiss: () => void;
    onChange?: (fields: string[]) => void;
    mandatoryFields?: string[];
}

export const ConfigureFieldsPanel = (props: Props) => {
    const [showFieldPanel, setShowFieldPanel] = React.useState(false);
    const onAddFieldClick = React.useCallback(() => {
        setShowFieldPanel(true);
    }, []);
    const hideFieldPanel = React.useCallback(() => setShowFieldPanel(false), []);

    const [filter, setFilter] = React.useState('');
    const onFilterChange = React.useCallback((e: any, value?: string) => setFilter((value || '')), []);
    const clearFilter = React.useCallback(() => setFilter(''), []);

    const onDismiss = React.useCallback(() => !showFieldPanel && props.onDismiss(), [showFieldPanel]);

    const onRenderHeader = React.useCallback((): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{props.headerText || 'Configure Columns'}</p>
            <div className='ms-Panel-secondaryText'>{props.secondaryText || 'Select the columns to be displayed in the view. Drag and drop to reorder.'}</div>
            <SearchBox
                autoFocus={true}
                value={filter}
                placeholder='Search field'
                onChange={onFilterChange}
                onClear={clearFilter}
                onEscape={clearFilter}
            />
        </div>;
    }, [props.headerText, props.secondaryText, filter]);

    const onRenderFooter = React.useCallback(() => props.allowManageFields !== undefined
        ? <>
            <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                text="New Field"
                className="new-button"
                disabled={props.showSpinner || !props.allowManageFields}
                onClick={onAddFieldClick} />
        </>
        : null, [props.allowManageFields, props.showSpinner]);

    const [newFieldNames, setNewFieldName] = React.useState<string[]>([]);
    const fieldActions: IFieldActions = React.useMemo(
        () => ({
            ...props.fieldActions,
            saveField: props.fieldActions
                ? _ => {
                    clearFilter();
                    props.fieldActions!.saveField?.(_);
                }
                : undefined
        }), [props.fieldActions]);

    const oldFields = usePrevious(props.fields);
    React.useEffect(() => {
        const isNewFieldCreated = oldFields?.length && props.fields.length > oldFields.length;
        if (isNewFieldCreated) {
            const oldIds = oldFields!.map(_ => _.id);
            const newField = props.fields.find(_ => !oldIds.includes(_.id))!;
            props.onChange?.([...props.selected.filter(_ => props.fields.some(__ => __.name === _)), newField.name]);
            setNewFieldName(newFieldNames.concat([newField.name]));
        }
    }, [props.fields, props.selected]);

    return <>
        <ExpandablePanel
            className='configure-fields-panel'
            isOpen={true}
            isLightDismiss={!props.showSpinner}
            type={PanelType.custom}
            customWidth="600px"
            onRenderHeader={onRenderHeader}
            onRenderFooterContent={onRenderFooter}
            focusTrapZoneProps={{ disabled: true }}
            onDismiss={onDismiss}
        >
            <FieldsList
                {...props}
                newFields={newFieldNames}
                filter={filter}
                onChange={props.onChange 
                    ? _ => props.onChange!(_.map(__ => __.name)) 
                    : undefined}
                mandatoryFields={props.mandatoryFields}
            />
            {props.showSpinner && <Overlay><Spinner /></Overlay>}
        </ExpandablePanel>
        {
            showFieldPanel && props.fieldActions && <FieldPanel
                allowManageFields={props.allowManageFields}
                actions={fieldActions}
                onDismiss={hideFieldPanel}
                entityType={props.entityType}
            />
        }
    </>
}