import * as React from 'react';
import { bindActionCreators } from 'redux';
import { CheckboxVisibility, Selection, DefaultButton } from 'office-ui-fabric-react';
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import { IListProps } from '../../extensibleEntity/EntityDetailsList';
import * as ProjectsListStore from '../../../../store/ProjectsListStore';
import * as PortfoliosListStore from '../../../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../../../store/ProgramsListStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import * as Metadata from '../../../../entities/Metadata';
import {
    updateCheckboxOptions,
    Dictionary, entityLogoConfig, EntityType, IEditableExtensibleEntityWithResourcePlan, IExtensibleEntity,
    IWithManager, IWithName, IWithWarnings, SortDirection
} from "../../../../entities/common";
import { ControlSpiner } from '../../Spinner';
import { TotalUsageCell, allocationWarning, hasOutsideAllocation } from '../../../resource/usage/ResourceUsageCell';
import { SectionControlPlaceholder } from '../SectionPlaceholder';
import * as ResourcesListStore from '../../../../store/ResourcesListStore';
import { post } from '../../../../fetch-interceptor';
import { isInReadonlyMode, UserState } from '../../../../store/User';
import { CommonOperations, contains, ResourceOperations } from '../../../../store/permissions';
import { defaultCatch } from '../../../../store/utils';
import AddEntitiesDialog from './projectsControl/AddEntitiesDialog';
import EntityPickerInput, { IFindResult } from '../../inputs/EntityPickerInput';
import RemoveDialog from '../../RemoveDialog';
import { ITimelineProps } from '../../extensibleEntity/EntityTimelineList';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FilterHelper as ProjectFilterHelper } from '../../../../store/project/filters';
import { FilterHelper as ProgramFilterHelper } from '../../../../store/program/filters';
import { FilterHelper as PortfolioFilterHelper } from '../../../../store/portfolio/filters';
import * as FiltersStore from '../../../../store/filters';
import * as UsersListStore from '../../../../store/UsersListStore';
import { arraysEqual, toDictionary } from '../../../utils/common';
import { IRow } from '../../timeline/TimelineList';
import { ViewType, IMaybeTotal, totalRow, PersistResourceUsageGrid, ResourceUsageGridSettings } from '../../../resource/usage/ResourceUsageGrid';
import { IWithFilter, WithFilterProps, isFilterChanged, withFilter } from '../../withFilter';
import { IWithActiveFilter } from '../../../../store/services/viewSaver';
import { nameof } from '../../../../store/services/metadataService';
import { PPMFeatures, Subscription } from '../../../../store/Tenant';
import { ViewService } from '../../../../services/ViewService';

interface IDataContext {
    resourcePlanningLevel: EntityType;
}
interface IActions {
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;
}
interface ISettings {
    displayFields: string[];
    orderBy: string[];
    timeline: {
        displayFields: string[];
    };
}
type ControlSettings = { viewType: ViewType } & ResourceUsageGridSettings & IWithActiveFilter;
export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>;

type WrapperProps = {
    entities: IEditableExtensibleEntityWithResourcePlan[];
    isEntitiesLoading: boolean;
    onEntitiesReload: (entities: IEditableExtensibleEntityWithResourcePlan[]) => void;
}

type OwnProps = ISectionUIControlProps<IActions, ISettings, ResourcesListStore.Resource, {}, ControlSettings, IDataContext>;

type StoreProps = {
    portfolios: PortfoliosListStore.Portfolio[];
    programs: ProgramsListStore.Program[];
    isUsageLoading: boolean;
    fields: Metadata.Field[];
    isFieldsLoading: boolean;
    user: UserState;
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    activeFilter: Metadata.IFilter<Metadata.BaseFilterValue> | undefined;
    entityLayouts: Metadata.Layout[];
    canManageConfiguration: boolean;
    canConfigureSelection: boolean;
    isFiltersDisabled?: boolean;
    resourcePlanningLevel: EntityType;
    resourcePlanningLevelLabel: string;
    hasPortfolioManagement: boolean;
    hasProjectManagement: boolean;
};
type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    resourcesActions: typeof ResourcesListStore.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    usersActions: typeof UsersListStore.actionCreators
};
type Props = OwnProps & StoreProps & ActionProps & RouteComponentProps<{}> & IWithFilter<IEditableExtensibleEntityWithResourcePlan>;
interface State {
    selectedEntityIds: string[];
    isAdding?: boolean;
    isRemoving?: boolean;
    filteredEntities: IEditableExtensibleEntityWithResourcePlan[];
}

type InnerProps = Props & WrapperProps;
class UtilizationControl extends React.Component<InnerProps, State> {
    private _rowSelection: Selection;

    constructor(props: InnerProps) {
        super(props);

        this._rowSelection = new Selection({
            onSelectionChanged: () => this.setState({
                selectedEntityIds: this._rowSelection.getSelection().map(_ => (_ as IExtensibleEntity).id)
            }),
            canSelectItem: (item) => (item as IEditableExtensibleEntityWithResourcePlan).isEditable,
            getKey: (item) => (item as IExtensibleEntity).id
        });

        this.state = {
            selectedEntityIds: [],
            filteredEntities: []
        };
    }

    componentWillMount() {
        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosActions.requestPortfolios();
            this.props.programsActions.requestPrograms();
        }

        if (this.props.hasProjectManagement) {
            this.props.projectsActions.requestProjects();
        }
    }

    componentWillReceiveProps(nextProps: InnerProps) {
        if (!arraysEqual(this.props.entities, nextProps.entities) || isFilterChanged(this.props, nextProps)) {
            this.setState({ filteredEntities: nextProps.entities.filter(nextProps.filter.isItemVisible) })
        }
    }

    render() {
        const { user, entity, fields, settings, isUsageLoading, filter, controlSettings, onSaveSettings,
            entities, resourcePlanningLevel, resourcePlanningLevelLabel } = this.props;
        const { filteredEntities, selectedEntityIds, isAdding, isRemoving } = this.state;

        if (this.props.isFieldsLoading || this.props.isEntitiesLoading) {
            return <ControlSpiner isLoading={true} />;
        }

        const nameField = fields.find(_ => _.name === nameof<IWithName>('Name'),);
        const readOnly = !contains(user.permissions.common, CommonOperations.ResourceManage);

        const selectionModeCommands = this._getSelectionModeCommands(readOnly);
        const listProps: IListProps & Partial<ITimelineProps> = {
            buildTree: true,
            showTreeExpandColumn: false,
            selection: this._rowSelection,
            entities: [totalRow],
            entityType: resourcePlanningLevel,
            fields: fields.filter(_ => [nameof<IWithName>('Name'), nameof<IWithManager>('Manager')].includes(_.name)),
            displayFields: settings.timeline.displayFields,
            sorting: {
                orderBy: settings.orderBy?.length
                    ? { fieldName: settings.orderBy[0], direction: SortDirection.ASC }
                    : undefined
            },
            renderHeaderCellContent: _ => _.key === nameField?.id ? <>{resourcePlanningLevelLabel}</> : undefined,
            onItemRender: (_entity: IExtensibleEntity & IMaybeTotal, _, __, defaultRender) => {
                if (_entity.isTotal) {
                    return <></>;
                }
                return defaultRender();
            },
            expandedEntitiesIds: [totalRow.id],
            primaryOutlineLevel: 1,
            buildRow: _ => buildEntitiesGroup(_, entity, filteredEntities, resourcePlanningLevel),
            ...updateCheckboxOptions({ checkboxVisibility: readOnly ? CheckboxVisibility.hidden : undefined }, selectionModeCommands)
        };

        const entitiesIds = entities.map(_ => _.id);
        const filteredEntityIds = this.state.filteredEntities.map(__ => __.id);

        return <>
            {!entities.length
                ? <SectionControlPlaceholder
                    iconName="PPMXSectionResourcePlan"
                    title="Utilization section is empty"
                    description={`The Resource does not participate in any ${resourcePlanningLevel} yet`}>
                    {
                        !readOnly && <DefaultButton
                            text={`Add to ${resourcePlanningLevelLabel}`}
                            disabled={entity.attributes.Status === ResourcesListStore.ResourceStatus.Inactive}
                            onClick={() => this.setState({ isAdding: true })} />
                    }
                </SectionControlPlaceholder>
                : <PersistResourceUsageGrid
                    entitiesMap={toDictionary(this.props.entities)}
                    entityType={resourcePlanningLevel}
                    byResource
                    menuProps={{
                        fields: listProps.fields,
                        type: "Columns",
                        entityType: EntityType.Resource,
                        displayFields: listProps.displayFields,
                        mandatoryViewFields: ViewService.getViewMandatoryFields(EntityType.Resource),
                        canViewConfigureColumns: this.props.canConfigureSelection,
                        onDisplayFieldsChange: this.props.canConfigureSelection
                            ? this._onTimelineDisplayFieldsChange
                            : undefined,
                        commands: readOnly ? [] : [
                            {
                                key: 'addRow',
                                name: `Add to ${resourcePlanningLevelLabel}`,
                                disabled: entity.attributes.Status === ResourcesListStore.ResourceStatus.Inactive,
                                iconProps: { iconName: 'Add' },
                                onClick: () => this.setState({ isAdding: true })
                            }
                        ],
                        selectionMode: {
                            enabled: selectedEntityIds.length > 0,
                            items: selectionModeCommands,
                            onCancel: () => this._rowSelection.setAllSelected(false)
                        },
                        ...filter?.menu,
                    }}
                    isLoading={isUsageLoading}
                    listProps={listProps}
                    selectedEntityIds={selectedEntityIds}
                    entitiesIds={entitiesIds}
                    extractRowIds={(_: IExtensibleEntity & IMaybeTotal) => ({
                        entityIds: _.isTotal ? filteredEntityIds : [_.id],
                        resourceIds: [entity.id],
                        isTotalRow: _.isTotal
                    })}
                    extractCellIds={cell => ({ entityId: cell.entityId, resourceId: entity.id })}
                    viewType={this.props.controlSettings.viewType}
                    renderTotalSegmentContent={(_, segment, timeType) =>
                        <TotalUsageCell resources={[entity]} entityIds={filteredEntityIds} startDate={segment.startDate} finishDate={segment.finishDate}
                            timeType={timeType} viewType={this.props.controlSettings.viewType} entityType={resourcePlanningLevel} />
                    }
                    isEditable={(_: IEditableExtensibleEntityWithResourcePlan) => _.isEditable}
                    buildRowMenuItems={(item: IEditableExtensibleEntityWithResourcePlan & IMaybeTotal) => item.isTotal || readOnly || !item.isEditable ? [] : [{
                        key: 'deleteRow',
                        name: `Remove from ${resourcePlanningLevelLabel}`,
                        style: { color: 'red' },
                        iconProps: { iconName: "Delete", style: { color: 'red' } },
                        onClick: () => this.setState({ isRemoving: true })
                    }]}
                    controlSettings={controlSettings}
                    onSaveSettings={onSaveSettings}
                />}
            {isAdding && <AddEntitiesDialog
                dialogContentProps={{
                    title: `Add Resource to ${resourcePlanningLevelLabel}`,
                    subText: `Choose ${resourcePlanningLevel}s current resource will be added to`
                }}
                onDismiss={() => this.setState({ isAdding: false })}
                onComplete={this._addToEntities}
                renderEntitySelector={(onChanged) =>
                    <EntityPickerInput
                        searchUrl={`api/${resourcePlanningLevel}/find`}
                        filter={{ accessLevel: ResourceOperations.Update, exceptIds: entitiesIds }}
                        inputProps={{ placeholder: "Type to search", autoFocus: true }}
                        onEditComplete={(_: IFindResult[] | null) => { _ && onChanged(_.map(__ => __.id)); }} />}
            />}
            {isRemoving && <RemoveDialog
                dialogContentProps={{
                    title: `Remove from ${resourcePlanningLevelLabel}${this._rowSelection.getSelectedCount() > 1 ? 's' : ''}`,
                    subText: this._getDeletionMessage()
                }}
                onClose={() => this.setState({ isRemoving: false })}
                onComplete={this._onRemove} />}
        </>;
    }

    private _onTimelineDisplayFieldsChange = (fields: string[]) => {
        const { sectionId, id } = this.props;
        this.props.actions.updateUIControl(sectionId, id, { timeline: { displayFields: fields } });
    }

    private _addToEntities = (entityIds: string[]) => {
        this.props.resourcesActions.addResourcesToEntities(
            this.props.datacontext.resourcePlanningLevel,
            entityIds.map(_ => ({ entityId: _, resourceId: this.props.entity.id })),
            this.props.onEntitiesReload);

        this.setState({ isAdding: false });
    }

    private _getDeletionMessage = () => {
        const selection = this._rowSelection.getSelection();
        if (selection.length === 1) {
            return `Are you sure you want to remove resource
            from the ${this.props.resourcePlanningLevel} "${(selection[0] as IEditableExtensibleEntityWithResourcePlan).attributes.Name}"?`;
        }

        return `Are you sure you want to remove resource from ${selection.length} ${this.props.resourcePlanningLevel}s?`;
    }

    private _onRemove = () => {
        const entityIds = this._rowSelection.getSelection().map(_ => (_ as IEditableExtensibleEntityWithResourcePlan).id);
        if (!entityIds.length) {
            return;
        }

        this.props.resourcesActions.removeResourcesFromEntities(
            this.props.resourcePlanningLevel,
            entityIds.map(_ => ({ entityId: _, resourceId: this.props.entity.id })),
            this.props.onEntitiesReload);
    }

    private _getSelectionModeCommands(readOnly: boolean) {
        const { resourcePlanningLevelLabel } = this.props;
        const { selectedEntityIds } = this.state;
        return readOnly ? [] : [
            {
                key: 'removeRow',
                name: `Remove from ${resourcePlanningLevelLabel}`,
                disabled: selectedEntityIds.length === 0,
                iconProps: { iconName: 'delete' },
                onClick: () => this.setState({ isRemoving: true })
            },
        ];
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const { resourcePlanningLevel } = ownProps.datacontext;
    const fields = state.fields[resourcePlanningLevel];
    const layouts = state.layouts[resourcePlanningLevel];
    const filters = FiltersStore.getFilter(state.filters, resourcePlanningLevel, FiltersStore.FilterKeys.UtilizationControl);

    const isFiltersDisabled = !contains(resourcePlanningLevel === EntityType.Portfolio
        ? state.user.permissions.portfolio.global
        : resourcePlanningLevel === EntityType.Program
            ? state.user.permissions.program.global
            : state.user.permissions.project.global, ResourceOperations.Read);

    const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant);
    return {
        isUsageLoading: state.resources.isLoadingUsage,
        resourcePlanningLevel,
        resourcePlanningLevelLabel: entityLogoConfig[resourcePlanningLevel]?.label!,
        fields: fields.allIds.map(_ => fields.byId[_]),
        isFieldsLoading: fields?.isLoading ?? true,
        user: state.user,
        filters: filters.all,
        activeFilter: filters.active.filter,
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        entityLayouts: layouts.allIds.map(_ => layouts.byId[_]),
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        hasProjectManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.ProjectManagement),
        canManageConfiguration: contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
        isFiltersDisabled,
        canConfigureSelection: contains(state.user.permissions.common, CommonOperations.ResourceView)
            && (ownProps.isViewSelected
                ? contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
                : isReadOnlyMode || contains(state.user.permissions.common, CommonOperations.ResourceManage)),
    };
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps): ActionProps {
    const { resourcePlanningLevel } = ownProps.datacontext;
    return {
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(resourcePlanningLevel, FiltersStore.FilterKeys.UtilizationControl), dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        resourcesActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
        usersActions: bindActionCreators(UsersListStore.actionCreators, dispatch),
    }
}

const useFilterProps = ({ portfolios, entities, programs, fields, entityLayouts, isFiltersDisabled,
    filters, filtersActions, canManageConfiguration, history, location, controlSettings, onSaveSettings, resourcePlanningLevel, resourcePlanningLevelLabel }: InnerProps)
    : WithFilterProps<IEditableExtensibleEntityWithResourcePlan> => {
    const filterHelper = React.useMemo(
        () => resourcePlanningLevel === EntityType.Project
            ? new ProjectFilterHelper({ projectFields: fields, layouts: entityLayouts, portfolios, programs, projects: entities as unknown as ProjectsListStore.ProjectInfo[] })
            : resourcePlanningLevel === EntityType.Program
                ? new ProgramFilterHelper({ fields, layouts: entityLayouts, portfolios, programs: entities as unknown as ProgramsListStore.Program[] })
                : new PortfolioFilterHelper({ fields, layouts: entityLayouts }),
        [entities, portfolios, programs, fields, entityLayouts]);

    return {
        filterHelper: filterHelper,
        entityType: resourcePlanningLevel,
        disabled: isFiltersDisabled ? `${resourcePlanningLevelLabel} filter is available for users with "View all" ${resourcePlanningLevelLabel}s permission.` : undefined,
        filters,
        filtersActions,
        canManageConfiguration,
        entities: entities,
        fields: fields,
        history,
        location,
        controlSettings,
        onSaveSettings
    }
}

const WithFilter = withFilter(UtilizationControl, useFilterProps, FiltersStore.FilterKeys.UtilizationControl);

const UtilizationControlWrapper = (props: Props) => {
    const [entities, setEntities] = React.useState<IEditableExtensibleEntityWithResourcePlan[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        post<IEditableExtensibleEntityWithResourcePlan[]>(`api/resourcePlan/find`, { entityType: props.resourcePlanningLevel, resourceIds: [props.entity.id] })
            .then(data => {
                setEntities(data);
                setIsLoading(false);
            })
            .catch(defaultCatch());
    }, [props.entity.id]);

    const onEntitiesReload = React.useCallback((reloaded: IEditableExtensibleEntityWithResourcePlan[]) => {
        setEntities(reloaded.filter(_ => !!~_.resourceIds.indexOf(props.entity.id)));
    }, [props.entity.id]);

    return <WithFilter
        {...props}
        entities={entities}
        isEntitiesLoading={isLoading}
        onEntitiesReload={onEntitiesReload}
    />;
}

export default withRouter(connect(mapStateToProps, mergeActionCreators)(UtilizationControlWrapper));

export const buildEntitiesGroup = (groupingEntity: IExtensibleEntity, resource: ResourcesListStore.Resource, entities: IExtensibleEntity[], entityType: EntityType,
    subitemBuilder?: (resource: ResourcesListStore.Resource, entity: IExtensibleEntity) => IRow): IRow => ({
        key: groupingEntity.id,
        entity: groupingEntity,
        subItemType: entityType,
        subItems: entities.map<IRow>(entity => {
            const warnings: IWithWarnings | undefined = hasOutsideAllocation(resource.usage, entity.attributes.StartDate, entity.attributes.FinishDate, entity.id)
                ? { warnings: [allocationWarning(entityType)] }
                : undefined;

            entity = warnings ? { ...entity, ...warnings } : entity;

            return subitemBuilder?.(resource, entity) ?? {
                key: entity.id,
                entity: entity,
                markers: [],
                segments: []
            }
        }),
        markers: [],
        segments: []
    });