import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import * as FieldsStore from "../../../../store/fields";
import { isInReadonlyMode, UserState } from "../../../../store/User";
import { contains, CommonOperations } from "../../../../store/permissions";
import { StatusCalculationTypes } from "../../../../store/Tenant";
import { IControlConfiguration, ISectionUIControlProps } from '../../interfaces/ISectionUIControlProps';
import * as Metadata from '../../../../entities/Metadata';
import FieldComponent from "./fieldsArea/FieldComponent";
import { Dictionary, EntityType, IWarning } from "../../../../entities/common";
import { warningService } from "../../../../store/services/warningService";
import { ProjectAttrs } from '../../../../store/ProjectsListStore';
import { buildStatusAttributeName, buildStatusDescriptionAttributeName } from './StatusesControl';
import { Dispatch, bindActionCreators } from 'redux';
import { notUndefined } from '../../../utils/common';
import { ConfigureFieldsPanel } from '../../../field/ConfigureFields';
import { IControlWarningsTypeMap } from '../../../../store/warningsTypeMapsStore';
import { IInputProps } from '../../interfaces/IInputProps';

interface ISettings {
    fields: string[];
}

interface IDataContext {
    entityId: string;
    entityType: EntityType;
    readOnlyFields?: string[];
    warningsTypeMap?: IControlWarningsTypeMap;
    hiddenFields?: string[];
}

interface IActions {
    refreshEntity: () => void;
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;

    onEditComplete: (fieldName: string, fieldValue: any, extra: Dictionary<any>) => void;
}

interface IFieldAreaEntity {
    attributes: Dictionary<any>;
    warnings: IWarning[];
}

type ICustomElementRender = Dictionary<(props: IInputProps, state: ProjectAttrs, field: Metadata.Field) => JSX.Element>;
type StateProps = {
    user: UserState;
    fields: Metadata.Field[];
    isFieldsLoading: boolean;
    canManageBudget: boolean;
    canConfigure: boolean;
    canConfigureSelection: boolean;
};

export type IConfiguration = IControlConfiguration<IActions, {}, IDataContext>;
type OwnProps = ISectionUIControlProps<IActions, ISettings, IFieldAreaEntity, ICustomElementRender, {}, IDataContext>;
type ActionProps = {
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>;
};

type Props = OwnProps & ActionProps & StateProps;

class FieldsArea extends React.Component<Props> {
    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.isConfigureMode && !this.props.isConfigureMode) {
            this.props.fieldsActions.loadFields();
        }
    }

    render() {
        const { key, isConfigureMode, onConfigureModeComplete, datacontext, settings, canConfigure, canConfigureSelection } = this.props;

        const fieldsAll: Metadata.Field[] = this.props.fields;
        const fields: Metadata.Field[] = settings.fields
            .filter(_ => (datacontext?.hiddenFields ?? []).indexOf(_) == -1)
            .map(_ => fieldsAll.find(__ => __.name === _))
            .filter(notUndefined);

        return (
            <div className="area" key={key}>
                {fields.map((field, index) => <div key={key + "divContainer_" + field.name}>
                    {this.createFieldComponent(field, index, ["grid-item"])}
                </div>)}
                {
                    isConfigureMode && <ConfigureFieldsPanel
                        key='columns-panel'
                        headerText='Configure fields'
                        secondaryText='Select the fields to be displayed in the section. Drag and drop to reorder.'
                        entityType={this.props.datacontext.entityType}
                        onDismiss={onConfigureModeComplete}
                        fields={this.props.fields}
                        showSpinner={this.props.isFieldsLoading}
                        selected={settings.fields}
                        allowManageFields={canConfigure}
                        fieldActions={this.props.fieldsActions}
                        onChange={canConfigureSelection ? this._onFieldSelected : undefined}
                    />
                }
            </div>
        );
    }

    private createFieldComponent = (field: Metadata.Field, index: number, withClass?: string[]) => {
        const { entity, datacontext, uiControlElementsCustomRender, customFieldValidatorBuilder, isConfigureMode, disabled, actions, warnings } = this.props;

        const fieldWarnings = warningService.getWarningsByKey(field.name, warnings || [], datacontext.warningsTypeMap);

        const isFieldReadOnly = !entity.isEditable || field.isReadonly || (datacontext.readOnlyFields && !!~datacontext.readOnlyFields.indexOf(field.name));

        const inputProps = {
            key: index,
            onEditComplete: actions.onEditComplete,
            state: entity,
            className: withClass ? withClass.join(" ") : "",
            disabled,
            isConfigureMode,
            field,
            readOnly: isFieldReadOnly,
            renderInput: uiControlElementsCustomRender && uiControlElementsCustomRender[field.name],
            validator: customFieldValidatorBuilder && customFieldValidatorBuilder?.[field.name]?.(entity, field),
            warnings: fieldWarnings,
            entityType: datacontext.entityType
        };

        return <FieldComponent {...inputProps} />;
    }

    private _onFieldSelected = (fields: string[]) => {
        this.props.actions.updateUIControl(this.props.sectionId, this.props.id, { fields: fields });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[ownProps.datacontext.entityType];
    const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant, ownProps.entity);
    return {
        user: state.user,
        fields: fields.allIds.map(_ => fields.byId[_]),
        isFieldsLoading: fields.isLoading,

        canManageBudget: contains(state.user.permissions.common, CommonOperations.BudgetManage),
        canConfigure: !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
        canConfigureSelection: ownProps.isViewSelected
            ? !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
            : isReadOnlyMode || !!ownProps.entity.canConfigure
    };
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): ActionProps {
    const entityFieldsStore = FieldsStore.actionCreators.forEntity(ownProps.datacontext.entityType, ownProps.datacontext.entityId, ownProps.actions.refreshEntity);
    return {
        fieldsActions: bindActionCreators(entityFieldsStore, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FieldsArea);


export function buildReadOnlyStatusFieldsNames(
    statusCalculation: StatusCalculationTypes, statuses: string[], isEntityStatusCalculationEnabled: boolean): string[] {

    if (isStatusesEditable(statusCalculation, !isEntityStatusCalculationEnabled) || !statuses) {
        return [];
    }

    return statuses
        .map(_ => ([buildStatusAttributeName(_), buildStatusDescriptionAttributeName(_)]))
        .reduce((prev, cur) => ([...prev, ...cur]), []);
}

export function isStatusesEditable(statusCalculation: StatusCalculationTypes, statusCalculationDisabled: boolean) {
    return statusCalculation === StatusCalculationTypes.Manual || statusCalculationDisabled;
}