import { StoreHelper } from './services/storeHelper';
import { BaseFilterValue, IFilter, NEW_ID } from '../entities/Metadata';
import { AppThunkAction } from '.';
import { post, remove } from '../fetch-interceptor';
import { defaultCatch } from './utils';
import { Reducer, Action } from 'redux';
import { push, RouterAction } from 'react-router-redux';
import { SourceType } from './ExternalEpmConnectStore';
import { Dictionary, EntityType } from '../entities/common';
import { Tracker } from './PreferencesTracker';

export enum FilterKeys {
    Main = 'Main',
    ProgramsControl = 'ProgramsControl',
    ProjectsControl = 'ProjectsControl',
    UtilizationControl = 'UtilizationControl',
    UtilizationList = 'UtilizationList',
    ContributingProjectsControl = 'ContributingProjectsControl',
    ReportedTime = 'ReportedTime'
}

export const isCriteriaChange = (a: IFilter<BaseFilterValue> | undefined, b: IFilter<BaseFilterValue> | undefined) => {
    if (a == b) {
        return false;
    }
    return a?.value != b?.value || a?.attributeNames != b?.attributeNames;
}

export interface IFiltersState<TFilterValue extends BaseFilterValue = BaseFilterValue> {
    byId: Dictionary<IFilter<TFilterValue>>;
    allIds: string[];
    active: Dictionary<ActiveFilter>;
}

export function toStore<T>(filterKey: string, data: { all: IFilter<T>[], active: ActiveFilter }): IFiltersState<T> {
    return {
        ...StoreHelper.create(data.all),
        active: { [filterKey]: data.active }
    }
}

export function getFilter<T>(state: Dictionary<IFiltersState<T>>, entity: EntityType, filterKey: FilterKeys = FilterKeys.Main, sourceType?: SourceType): {
    all: IFilter<T>[],
    active: ActiveFilter & { filter?: IFilter<T> }
} {
    const filters = state[entity];
    const active = filters?.active[filterKey];
    return {
        all: filters.allIds.map(_ => filters.byId[_]).filter(_ => sourceType === undefined || _.type === sourceType),
        active: active
            ? {
                ...active,
                filter: active.filterId ? filters.byId[active.filterId] : undefined
            }
            : {}
    }
}

export type ActiveFilter = {
    filterId?: string;
    preFilterId?: string;
};

interface SaveFilterAction {
    entity: string;
    type: 'SAVE_FILTER_SUCCESS';
    id: string;
    filter: IFilter<BaseFilterValue>;
}

interface RemoveFilterAction {
    entity: string;
    type: 'REMOVE_FILTER_SUCCESS';
    id: string;
}

interface SetActiveFilterAction {
    entity: string;
    filterKey: string;
    type: 'SET_ACTIVE_FILTER';

    id?: string;
    preFilterId?: string;
    sourceType?: SourceType;
}

export interface UpdateFilterAction {
    entity: string;
    type: 'UPDATE_FILTER';
    filter: IFilter<BaseFilterValue>;
}

type KnownAction =
    | SaveFilterAction
    | RemoveFilterAction
    | SetActiveFilterAction
    | UpdateFilterAction;

export function tryGetActiveFilter(id: string | undefined, sourceType: SourceType | undefined, filterKey: string, state: IFiltersState<BaseFilterValue> | undefined) {
    if (id && state?.byId[id] && isSourceMatch(id, sourceType, state)) {
        return state.byId[id];
    }

    const currentId = state?.active[filterKey]?.filterId;
    if (currentId && state?.byId[currentId] && isSourceMatch(currentId, sourceType, state)) {
        return state.byId[currentId];
    }

    const filters = state?.allIds.map(_ => state.byId[_]).filter(_ => (sourceType === undefined || sourceType === _.type)) || [];
    return filters.find(_ => _.isDefault) ?? filters.find(_ => _.isBuiltIn);
}

function isSourceMatch(filterId: string, sourceType: SourceType | undefined, state: IFiltersState<BaseFilterValue>) {
    return sourceType === undefined || state.byId[filterId].type === sourceType;
}

export const actionCreators = {
    forEntity: (entity: EntityType, filterKey: string = FilterKeys.Main) => ({
        saveFilter: (filter: IFilter<BaseFilterValue>, redirect?: boolean | ((filter: IFilter<BaseFilterValue>) => void)):
            AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
                post<IFilter<BaseFilterValue>>(`api/metadata/${entity}/filter`, { ...filter, id: filter.id === NEW_ID ? undefined : filter.id })
                    .then(data => {
                        dispatch({ entity, type: 'SAVE_FILTER_SUCCESS', id: filter.id, filter: data });
                        if (redirect || filter.id === NEW_ID) {
                            if (redirect instanceof Function) {
                                redirect?.(data);
                            } else {
                                // dirty hack to save other query params
                                // react-router-redux push doesn't support param replace in this version
                                // todo: change this to push({ query: { filter: data.id } }) on library update
                                const query = new URLSearchParams((getState() as any).routing.location.search as string);
                                query.set("filter", data.id);
                                dispatch(push({ search: query.toString() }));
                            }
                        }

                        if (filterKey === FilterKeys.Main) {
                            Tracker.setActiveFilter(dispatch, getState, entity, filter.id, getState().filters[entity].active[FilterKeys.Main].preFilterId);
                        }
                    })
                    .catch(defaultCatch(dispatch));
            },
        removeFilter: (id: string, redirect?: (id: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            if (id === NEW_ID) {
                dispatch({ entity, type: 'REMOVE_FILTER_SUCCESS', id });
                redirect?.(id);
                return;
            }

            remove<{ id: string; }>(`api/metadata/${entity}/filter/${id}`)
                .then(data => {
                    dispatch({ entity, type: 'REMOVE_FILTER_SUCCESS', id });
                    redirect?.(id);
                })
                .catch(defaultCatch(dispatch));
        },
        setActiveFilter: (id?: string, preFilterId?: string, sourceType?: SourceType): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ entity, type: 'SET_ACTIVE_FILTER', id, preFilterId, sourceType, filterKey });

            if (filterKey == FilterKeys.Main) {
                Tracker.setActiveFilter(dispatch, getState, entity, id, preFilterId);
            }
        },
        updateFilter: (filter: IFilter<BaseFilterValue>): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ entity, type: 'UPDATE_FILTER', filter });
        }
    })
};

export const reducer: Reducer<IFiltersState<BaseFilterValue>> = (state: IFiltersState<BaseFilterValue>, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SAVE_FILTER_SUCCESS':
            {
                return state.byId[action.id]
                    ? StoreHelper.applyHandler(state, action.id, _ => action.filter)
                    : StoreHelper.addOrUpdate(state, action.filter);
            }
        case 'REMOVE_FILTER_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.remove(state, action.id)
                };
            }
        case 'SET_ACTIVE_FILTER':
            {
                const active = tryGetActiveFilter(action.id, action.sourceType, action.filterKey, state);
                return {
                    ...state,
                    active: {
                        ...state.active,
                        [action.filterKey]: {
                            filter: active,
                            filterId: active?.id,
                            preFilterId: action.preFilterId
                        }
                    }
                };
            }
        case 'UPDATE_FILTER':
            {
                return state.byId[action.filter.id]
                    ? StoreHelper.applyHandler(state, action.filter.id, _ => ({ ...action.filter, isNotSaved: !action.filter.isBuiltIn }))
                    : StoreHelper.addOrUpdate(state, action.filter);
            }
        default:
            return state;
    }
};