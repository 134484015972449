import React, { useState, useEffect } from 'react';
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxProps } from 'office-ui-fabric-react';
import { IFindProps, IFindResult } from "./EntityPickerInput";
import { post } from '../../../fetch-interceptor';

export type ComboboxPickerProps = IFindProps &
{
    onEditComplete?: (find?: IFindResult) => void;
    selectedKey?: string | number;
    inputProps?: Partial<IComboBoxProps>;
};

export default function ComboboxPicker(props: ComboboxPickerProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedKey, setSelectedKey] = useState(props.selectedKey);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);

    useEffect(() => {
        setIsLoaded(false);
        setIsLoading(false);
        setOptions([]);
        setSelectedKey(props.selectedKey);
    }, [props.searchUrl]);

    return <ComboBox
        {...props.inputProps}
        selectedKey={selectedKey}
        allowFreeform
        className='input'
        options={options}
        useComboBoxAsMenuWidth={true}
        onResolveOptions={(opts: IComboBoxOption[]): IComboBoxOption[] | PromiseLike<IComboBoxOption[]> => {
            if (!isLoading && !isLoaded) {
                setIsLoading(true);
                post<IFindResult[]>(props.searchUrl, { ...props.filter })
                    .then(_ => {
                        setOptions(_.map(__ => ({ key: __.id, text: __.name, data: __ } as IComboBoxOption)));
                        setIsLoading(false);
                        setIsLoaded(true);
                    });
            }
            return opts;
        }}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
            setSelectedKey(option?.key)
            props.onEditComplete?.(option?.data as IFindResult);
        }}
    />;
}