import * as React from 'react';
import * as Metadata from '../../entities/Metadata';
import { ItemCreation } from '../common/ItemCreation';
import { Validator } from "../../validation";
import Spinner from "../common/Spinner";
import { Overlay, Toggle, TextField, IDropdownOption } from 'office-ui-fabric-react';
import SelectSettingsEdit from './SelectSettingsEdit';
import { SliderSettingsEdit } from './SliderSettingsEdit';
import { IdentifierSettingsEdit } from './IdentifierSettingsEdit';
import { NumberSettingsEdit } from './NumberSettingsEdit';
import { Dictionary, EntityType, Impact } from '../../entities/common';
import { TextSettingsEdit } from './TextSettingsEdit';
import { ResourceSettingsEdit } from './ResourceSettingsEdit';
import { EnumSliderSettingsEdit, impactEnumSliderDefaultSettings } from './EnumSliderSettingsEdit';
import { FieldsService } from '../common/FieldsService';
import { FieldTypeDrowdown } from './FieldTypeDrowdown';
import StatusSelectSettingsEdit from './ConfigureFields/StatusSelectSettingsEdit';

const validators = {
    label: Validator.new().required().build()
}

interface IFieldSettings extends Dictionary<any> {
    required: boolean;
}

export interface IBaseFieldInfo {
    label: string;
    description?: string;
    settings: IFieldSettings;
}

export interface IFieldActions {
    removeField?: (fieldId: string) => void;
    saveField?: (fieldInfo: Metadata.ICreateFieldInfo) => void;
    updateField?: (fieldId: string, fieldInfo: Metadata.IUpdateFieldInfo) => void;
}

export interface IFieldPanelProps {
    field?: Metadata.Field;
    customWidth?: string;
    showSpinner?: boolean;
    allowManageFields?: boolean;
    entityType: EntityType;
    actions: IFieldActions;
    onDismiss: () => void;
}

enum FieldDisplayType {
    Text,
    Date,
    Integer,
    Decimal,
    User,
    Resource,
    Slider,
    Select,
    Flag,
    Impact,
    Portfolio,
    Project,
    Challenge,
    Idea,
    Group,
    Task,
    Hyperlink,
    Predecessor
}

export enum FieldActionTypes {
    Create = 'CREATE',
    Edit = 'EDIT'
}

export interface IFieldPanelState {
    isDirty?: boolean;
    fieldInfo: IBaseFieldInfo;
    displayType: FieldDisplayType;
    displayTypes: IDropdownOption[];
    isSettingsValid: boolean;
    actionType: FieldActionTypes;
}

function toFieldInfo(field: Metadata.Field): IBaseFieldInfo {
    return {
        label: Metadata.getLabel(field),
        description: field.description,
        settings: {
            required: field.settings?.required
        }
    };
}

export interface ISettingsEditComponent {
    save: (fieldInfo: Metadata.ICreateFieldInfo) => void;
    update: (fieldInfo: IBaseFieldInfo) => Metadata.IUpdateFieldInfo | void;
}

export default class FieldPanel extends React.Component<IFieldPanelProps, IFieldPanelState> {
    private _settingComponent = React.createRef<ISettingsEditComponent>();

    constructor(props: IFieldPanelProps) {
        super(props);
        const denyCreationTypes: FieldDisplayType[] = [
            FieldDisplayType.Portfolio, FieldDisplayType.Project,
            FieldDisplayType.Challenge, FieldDisplayType.Idea,
            FieldDisplayType.Group, FieldDisplayType.Task, FieldDisplayType.Predecessor];
        this.state = {
            fieldInfo: props.field
                ? toFieldInfo(props.field)
                : {
                    label: '',
                    description: '',
                    settings: {
                        required: false
                    }
                },
            displayType: props.field ? getDisplayType(props.field) : FieldDisplayType.Text,
            displayTypes: Object.keys(FieldDisplayType)
                .filter(k => typeof FieldDisplayType[k as any] === "number"
                    && (props.field ? true : !~denyCreationTypes.indexOf((FieldDisplayType[k as any]) as any as FieldDisplayType)))
                .map(k => {
                    const key = FieldDisplayType[k as any] as any as FieldDisplayType;
                    return { key: key, text: k, data: getDisplayTypeDescriptor(key) };
                }),
            isSettingsValid: true,
            actionType: props.field ? FieldActionTypes.Edit : FieldActionTypes.Create
        }
    }

    public render() {
        const { fieldInfo, isSettingsValid } = this.state;
        return (
            <ItemCreation
                onDismiss={this.props.onDismiss}
                header={{
                    text: `${this.props.field ? 'Configure' : 'Create'} Field`,
                    secondaryText: 'Configure field display options',
                    nameEditorLabel: "Field Name",
                    onChanged: label => this._setFieldInfo({ label: label }),
                    validator: validators.label,
                    value: fieldInfo.label
                }}
                isDirty={this.state.isDirty}
                commands={[
                    {
                        primary: true,
                        text: `${this.props.field ? 'Save' : 'Create'} Field`,
                        onClick: this._saveClick,
                        disabled: !isSettingsValid || !Validator.isValid(validators, fieldInfo) || !this.props.allowManageFields
                    }, {
                        text: 'Cancel',
                        onClick: this.props.onDismiss
                    }
                ]}
                className={`field-panel ${this.state.isDirty ? " not-saved" : ""}`}
                customWidth={this.props.customWidth}>
                {this._renderDetails()}
                {this.props.showSpinner && <Overlay><Spinner /></Overlay>}
            </ItemCreation>
        );
    }

    private _renderDetails() {
        const { field } = this.props;
        const { fieldInfo, displayType, displayTypes } = this.state;

        return <div className="field-details">
            <Toggle label='Required'
                checked={fieldInfo.settings.required || field?.isSystem}
                disabled={field && (field.isReadonly || field.isSystem)}
                onChange={(e, checked) => this._setFieldInfo({ settings: { required: !!checked } })}
                onText='Yes'
                offText='No' />
            <TextField label="Tooltip Description"
                onChange={(e, value) => this._setFieldInfo({ description: value })}
                value={fieldInfo.description || ""}
                resizable={false}
                multiline={true}
                rows={6} />
            <FieldTypeDrowdown
                disabled={!!this.props.field}
                label={'Type'}
                required={true}
                selectedKey={displayType}
                onChange={(e, option) => option && this.setState({
                    displayType: option.key as FieldDisplayType,
                    isSettingsValid: true,
                    isDirty: true
                })}
                defaultSelectedKeys={[0]}
                placeholder={'Select type'}
                options={displayTypes}
            />
            {
                this._renderSettings(displayType)
            }
        </div>
    }

    private _renderSettings = (displayType: FieldDisplayType): JSX.Element | null => {
        const { field, entityType } = this.props;
        const { actionType } = this.state;

        const settings = field?.settings ?? {};
        switch (displayType) {
            case FieldDisplayType.Text:
                if (field && FieldsService.isTag(field)) {
                    return null;
                }
                if (field && FieldsService.isIdentifier(field)) {
                    return <IdentifierSettingsEdit
                        ref={this._settingComponent as any}
                        actionType={actionType}
                        settings={settings}
                        onChange={this._onSettingsChange}
                    />;
                }
                return <TextSettingsEdit
                    ref={this._settingComponent as any}
                    isNative={field ? field.isNative : false}
                    actionType={actionType}
                    settings={settings}
                    onChange={this._onSettingsChange}
                />;
            case FieldDisplayType.Slider:
                return <SliderSettingsEdit
                    ref={this._settingComponent as any}
                    disabled={field?.isSystem}
                    isInteger={field?.type == Metadata.FieldType.Integer}
                    actionType={actionType}
                    settings={settings}
                    onChange={this._onSettingsChange}
                />;
            case FieldDisplayType.Select:
                if (field?.settings?.editControl === "ColorStatusDropdown") {
                    return <StatusSelectSettingsEdit
                        ref={this._settingComponent}
                        actionType={actionType}
                        entityType={entityType}
                        settings={settings as any}
                        onChange={this._onSettingsChange}
                    />;
                }
                return <SelectSettingsEdit
                    ref={this._settingComponent}
                    actionType={actionType}
                    settings={settings as any}
                    onChange={this._onSettingsChange}
                />;
            case FieldDisplayType.Integer:
            case FieldDisplayType.Decimal:
                if (settings.editControl === 'ColorStatusDropdown') {
                    return null;
                }
                return <NumberSettingsEdit
                    ref={this._settingComponent as any}
                    isNative={field ? field.isNative : false}
                    actionType={actionType}
                    settings={settings}
                    onChange={this._onSettingsChange}
                />;
            case FieldDisplayType.User:
            case FieldDisplayType.Resource:
                return <ResourceSettingsEdit
                    ref={this._settingComponent as any}
                    actionType={actionType}
                    settings={settings}
                    onChange={this._onSettingsChange}
                />;
            case FieldDisplayType.Impact:
                return <EnumSliderSettingsEdit
                    ref={this._settingComponent as any}
                    settingsDefaults={impactEnumSliderDefaultSettings}
                />;
        }

        return null;
    }

    private _onSettingsChange = (isValid: boolean) => {
        this.setState({ isDirty: true, isSettingsValid: isValid });
    }

    private _setFieldInfo<K extends keyof IBaseFieldInfo>(changes: Pick<IBaseFieldInfo, K>) {
        this.setState({
            isDirty: true,
            fieldInfo: {
                ...this.state.fieldInfo,
                ...changes
            }
        });
    }

    private _saveClick = () => {
        const { field, onDismiss } = this.props;
        const { fieldInfo, displayType } = this.state;

        if (field) {
            this._update(field, fieldInfo);
        } else {
            this._save(fieldInfo, displayType);
        }

        onDismiss();
    }

    private _update(field: Metadata.Field, fieldInfo: IBaseFieldInfo) {
        const { actions } = this.props;
        if (!actions.updateField) {
            return;
        }

        const update = this._settingComponent.current?.update(fieldInfo) ?? fieldInfo;
        actions.updateField(field.id, update);
    }

    private _save(fieldInfo: IBaseFieldInfo, displayType: FieldDisplayType) {
        const { actions } = this.props;
        if (!actions.saveField)
            return;

        const field: Metadata.ICreateFieldInfo = {
            ...fieldInfo,
            name: fieldInfo.label,
            type: getType(displayType)
        }

        const defaultValue = getDefaultValue(displayType);
        if (defaultValue !== undefined) {
            field.defaultValue = defaultValue;
        }

        this._settingComponent.current?.save(field);
        actions.saveField(field);
    }
}

function getDefaultValue(displayType: FieldDisplayType): number | string | undefined {
    switch (displayType) {
        case FieldDisplayType.Impact:
            return Impact.NA;
        default: return undefined;
    }
}

export function getDisplayType(field: Metadata.Field): FieldDisplayType {
    let displayType: FieldDisplayType = FieldDisplayType.Text;
    switch (field.type) {
        case Metadata.FieldType.Date:
        case Metadata.FieldType.DateTime: {
            displayType = FieldDisplayType.Date;
            break;
        }
        case Metadata.FieldType.Flag: {
            displayType = FieldDisplayType.Flag;
            break;
        }
        case Metadata.FieldType.Integer:
        case Metadata.FieldType.Decimal:
            {
                displayType = field.type === Metadata.FieldType.Decimal
                    ? FieldDisplayType.Decimal
                    : FieldDisplayType.Integer;

                if (field.settings?.editControl == "Slider") {
                    displayType = FieldDisplayType.Slider;
                }
                if (field.settings?.editControl == "EnumSlider" && field.settings.className == 'impact') {
                    displayType = FieldDisplayType.Impact;
                }
                break;
            }
        case Metadata.FieldType.Resource:
            {
                displayType = FieldDisplayType.Resource;
                break;
            }
        case Metadata.FieldType.User:
            {
                displayType = FieldDisplayType.User;
                break;
            }
        case Metadata.FieldType.Portfolio:
            {
                displayType = FieldDisplayType.Portfolio;
                break;
            }
        case Metadata.FieldType.Project:
            {
                displayType = FieldDisplayType.Project;
                break;
            }
        case Metadata.FieldType.Challenge:
            {
                displayType = FieldDisplayType.Challenge;
                break;
            }
        case Metadata.FieldType.Idea:
            {
                displayType = FieldDisplayType.Idea;
                break;
            }
        case Metadata.FieldType.Group:
            {
                displayType = FieldDisplayType.Group;
                break;
            }
        case Metadata.FieldType.Task:
            {
                displayType = FieldDisplayType.Task;
                break;
            }
        case Metadata.FieldType.Predecessor:
            {
                displayType = FieldDisplayType.Predecessor;
                break;
            }
        case Metadata.FieldType.Text:
            {
                displayType = FieldDisplayType.Text;
                if (field.settings && ["Dropdown", "ColorStatusDropdown"].includes(field.settings.editControl)) {
                    displayType = FieldDisplayType.Select;
                }
                break;
            }
        case Metadata.FieldType.Hyperlink:
            {
                displayType = FieldDisplayType.Hyperlink;
                break;
            }
    }
    return displayType;
}

function getType(displayType: FieldDisplayType): Metadata.FieldType {
    switch (displayType) {
        case FieldDisplayType.Slider: return Metadata.FieldType.Decimal;
        case FieldDisplayType.Select: return Metadata.FieldType.Text;
        case FieldDisplayType.Integer: return Metadata.FieldType.Integer;
        case FieldDisplayType.Decimal: return Metadata.FieldType.Decimal;
        case FieldDisplayType.Text: return Metadata.FieldType.Text;
        case FieldDisplayType.Date: return Metadata.FieldType.Date;
        case FieldDisplayType.Resource: return Metadata.FieldType.Resource;
        case FieldDisplayType.User: return Metadata.FieldType.User;
        case FieldDisplayType.Flag: return Metadata.FieldType.Flag;
        case FieldDisplayType.Impact: return Metadata.FieldType.Integer;
        case FieldDisplayType.Portfolio: return Metadata.FieldType.Portfolio;
        case FieldDisplayType.Project: return Metadata.FieldType.Project;
        case FieldDisplayType.Challenge: return Metadata.FieldType.Challenge;
        case FieldDisplayType.Idea: return Metadata.FieldType.Idea;
        case FieldDisplayType.Group: return Metadata.FieldType.Group;
        case FieldDisplayType.Task: return Metadata.FieldType.Task;
        case FieldDisplayType.Hyperlink: return Metadata.FieldType.Hyperlink;
        case FieldDisplayType.Predecessor: return Metadata.FieldType.Predecessor;
        default:
            const exhaustiveCheck: never = displayType;
    }

    throw new Error(`Can't convert displayType \"${displayType}\"`);
}

const displayTypeDescriptorMap = {
    [FieldDisplayType.Slider]: {
        iconName: 'PPMXFieldSlider',
        description: 'Slider field is used for numerical values within a defined range'
    },
    [FieldDisplayType.Select]: {
        iconName: 'PPMXFieldSelect',
        description: 'Select field is used to predefine a drop-down list of values to choose from'
    },
    [FieldDisplayType.Integer]: {
        iconName: 'PPMXFieldInteger',
        description: 'Integer field is used to store numerical data'
    },
    [FieldDisplayType.Decimal]: {
        iconName: 'PPMXFieldDecimal',
        description: 'Decimal field is used to store non-integer numerical data'
    },
    [FieldDisplayType.Text]: {
        iconName: 'PPMXFieldText',
        description: 'Text field is used to store texts up to 255 (single-line) characters and 8000 (multiline) characters'
    },
    [FieldDisplayType.Date]: {
        iconName: 'PPMXFieldDate',
        description: 'Date field is used to store dates, picked from a drop-down calendar'
    },
    [FieldDisplayType.Resource]: {
        iconName: 'PPMXFieldResource',
        description: 'Resource field is used to assign a PPM Express resource'
    },
    [FieldDisplayType.User]: {
        iconName: 'PPMXFieldUser',
        description: 'User field is used to assign a PPM Express user'
    },
    [FieldDisplayType.Flag]: {
        iconName: 'PPMXFieldFlag',
        description: 'Flag field is used to store data that has two distinct values'
    },
    [FieldDisplayType.Impact]: {
        iconName: 'PPMXFieldImpact',
        description: 'Impact field is used to display impact as Low, Normal, Moderate, Strong, and Extreme values'
    },
    [FieldDisplayType.Hyperlink]: {
        iconName: 'PPMXFieldHyperlink',
        description: 'Hyperlink field is used for URLs associated with this project, task, item'
    }
}

function getDisplayTypeDescriptor(type: FieldDisplayType) {
    return displayTypeDescriptorMap[type] || { iconName: undefined };
}