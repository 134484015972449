import * as React from 'react';
import { Icon, IconButton, IContextualMenuItem } from 'office-ui-fabric-react';

interface Props {
    map: {
        [i: string]: { title: string, cssClassName: string, iconName?: string }
    },
    value?: string | number;
    className?: string;
    subMenuItems?: IContextualMenuItem[];
}

const StageView = ({ map, value, className, subMenuItems }: Props) => {
    const config = value != undefined ? map[value] : undefined;
    return config || value !== undefined
        ? <ColorStageView
            className={config ? config.cssClassName : ''}
            iconName={config && config.iconName}
            rootClassName={className}
            title={config ? config.title : value?.toString()}
            subMenuItems={subMenuItems}
        />
        : null;
}

type ColorStateProps = {
    title?: string;
    className?: string;
    rootClassName?: string;
    iconName?: string;
    subMenuItems?: IContextualMenuItem[];
    style?: {
        color?: string
    }
}

const ColorStageView = React.memo((props: ColorStateProps) => <div className={`color-stage ${props.rootClassName || ''}`}>
    <div className={`status ${props.className ? props.className : ''}`}>
        <div className="align-center details">
            {props.iconName ? <Icon iconName={props.iconName} className="status-icon" /> : undefined}
            <span className="status-label" style={props.style}>{props.title}</span>
        </div>
        {props.subMenuItems && props.subMenuItems.length > 0 && <IconButton
            menuIconProps={{ iconName: 'ChevronDown' }}
            className='menu'
            menuProps={{ items: props.subMenuItems, className: 'section-menu' }} />}
    </div>
</div>);

export { StageView as default, ColorStageView }