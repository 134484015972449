import * as React from 'react';
import { SourceTypeMap } from "../../../../../store/ExternalEpmConnectStore";
import { Field } from "../../../../../entities/Metadata";
import { Program } from '../../../../../store/ProgramsListStore';
import BaseLinkedSystem from '../BaseLinkedSystem';
import { IListViewFieldColumn } from '../../../../../services/ViewService';
import { EntityType } from '../../../../../entities/common';

export default class LinkedSystem extends React.Component<IListViewFieldColumn<Program>> {
    public render() {
        return <BaseLinkedSystem
            field={this.props.field}
            sourceInfos={this.props.entity.sourceInfos}
            entityType={EntityType.Program}
        />
    }

    public static getValue(entity: Program, field: Field): string | undefined {
        return entity.sourceInfos.length
            ? SourceTypeMap[entity.sourceInfos[0].type]
            : undefined;
    }
}
