import * as Metadata from "../../entities/Metadata";
import { FormatDate, formatValue, toDate, toDateTime } from "../utils/common";
import { EntityType, ServerEntityType } from "../../entities/common";
import { NumberFilterValue } from './filters/NumberFilter';
import { DateFilterValue, convertToDate } from './filters/DateFilter';

export class FieldsService {
    public static getFieldDisplayValues(field: Metadata.Field, value: any) {
        switch (field.type) {
            case Metadata.FieldType.Portfolio:
            case Metadata.FieldType.Program:
            case Metadata.FieldType.Project:
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Idea:
            case Metadata.FieldType.Objective: {
                return Array.isArray(value)
                    ? (value as { id: string, name: string }[]).map(_ => _.name)
                    : [];
            }
            case Metadata.FieldType.Ref: {
                return Array.isArray(value)
                    ? (value as { id: string, name: string }[]).map(_ => _.name)
                    : [value.name];
            }
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.User: {
                return Array.isArray(value)
                    ? value.map((_: any) => _.fullName)
                    : [];
            }
            case Metadata.FieldType.Text:
            case Metadata.FieldType.Integer:
            case Metadata.FieldType.Decimal:
                {
                    const settings = field.settings;
                    if (settings && (FieldsService.isDropDown(field) || FieldsService.isSlider(field, true)) && Array.isArray(value)) {
                        return value.map((_: any) => Metadata.getOption(field, _?.toString())?.text);
                    }

                    if (field.type == Metadata.FieldType.Text) {
                        return value;
                    }

                    if (field.type === Metadata.FieldType.Integer || field.type === Metadata.FieldType.Decimal) {
                        const val = value as NumberFilterValue;
                        const values = [];
                        val.from && values.push(formatValue(val.from, field.settings && field.settings.format));
                        val.to && values.push(formatValue(val.to, field.settings && field.settings.format));
                        return values;
                    }
                    break;
                }
            case Metadata.FieldType.Date:
            case Metadata.FieldType.DateTime:
                {
                    const val = value as DateFilterValue;
                    const values = [];
                    val.from && values.push(FormatDate(convertToDate(val.from)));
                    val.to && values.push(FormatDate(convertToDate(val.to)));
                    return values;
                }
            case Metadata.FieldType.Flag: {
                return value ? "Yes" : "No";
            }
            case Metadata.FieldType.Hyperlink: {
                return value;
            }
            default:
        }
        return [];
    }

    public static compareFieldValues(field: Metadata.Field, value: any, attrValue: any) {
        switch (field.type) {
            case Metadata.FieldType.Portfolio:
            case Metadata.FieldType.Program:
            case Metadata.FieldType.Project:
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Idea:
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.Objective:
            case Metadata.FieldType.User: {
                if (!Array.isArray(attrValue)) {
                    return false;
                }

                if (Array.isArray(value)) {
                    const entityInfos: { id: string }[] | undefined = value;
                    return entityInfos && attrValue.some(_ => entityInfos.find(__ => __.id === _.id) !== undefined);
                }

                const entityInfo: { id: string } | undefined = value;
                return entityInfo && attrValue.find(_ => _.id === entityInfo.id) !== undefined;
            }
            case Metadata.FieldType.Ref: {
                if (!Array.isArray(attrValue)) {
                    return value && attrValue && attrValue.id === value.id &&
                        (attrValue.entityType === undefined
                            ? attrValue.entityType === EntityType[ServerEntityType[value.entityType]] && value.name.toLowerCase().indexOf(attrValue.name.toLowerCase()) !== -1
                            : attrValue.entityType === EntityType[ServerEntityType[value.entityType]] && attrValue.name === value.name);
                }

                return value && attrValue.find(_ => _.id === value.id && _.entityType === EntityType[ServerEntityType[value.entityType]] && _.name === value.name) !== undefined;
            }
            case Metadata.FieldType.Text:
            case Metadata.FieldType.Integer:
            case Metadata.FieldType.Decimal: {
                if (field.type === Metadata.FieldType.Text && FieldsService.isTag(field)) {
                    return value !== undefined && attrValue.some((_: any) => value.find((__: any) => __ == _) !== undefined);
                }
                if (Array.isArray(attrValue)) {
                    if (Array.isArray(value)) {
                        return attrValue.some(_ => value.find((__: any) => __ == _) !== undefined);
                    }

                    if (value === undefined && field.defaultValue !== undefined) {
                        value = field.defaultValue;
                    }

                    return value !== undefined && attrValue.find(_ => _ == value) !== undefined;
                }
                if (field.type === Metadata.FieldType.Text) {
                    return !attrValue || value && value.toLowerCase().indexOf(attrValue.toLowerCase()) !== -1;
                }
                if (field.type === Metadata.FieldType.Integer || field.type === Metadata.FieldType.Decimal) {
                    const aVal = attrValue as NumberFilterValue;
                    return (aVal.from === undefined || (value !== undefined && aVal.from <= value)) && (aVal.to === undefined || (value !== undefined && value <= aVal.to));
                }
                break;
            }
            case Metadata.FieldType.Date: {
                const aVal = attrValue as DateFilterValue;
                const date = value && toDate(value);
                const aValFrom = convertToDate(aVal.from);
                const aValTo = convertToDate(aVal.to);

                return (aValFrom === undefined || (date && aValFrom.getBeginOfDay() <= date))
                    && (aValTo === undefined || (date && date <= aValTo.getEndOfDay()));
            }
            case Metadata.FieldType.DateTime: {
                const aVal = attrValue as DateFilterValue;
                const date = value && toDateTime(value);
                const aValFrom = convertToDate(aVal.from);
                const aValTo = convertToDate(aVal.to);

                return (aValFrom === undefined || (date && aValFrom.getBeginOfDay() <= date))
                    && (aValTo === undefined || (date && date <= aValTo.getEndOfDay()));
            }
            case Metadata.FieldType.Flag: {
                return !!value == !!attrValue;
            }
            case Metadata.FieldType.Group: {
                return !value && !attrValue || value && attrValue !== undefined && value.name.toLowerCase().indexOf(attrValue.toLowerCase()) !== -1;
            }
            case Metadata.FieldType.Hyperlink: {
                return !attrValue || value && (value.text?.toLowerCase().includes(attrValue.toLowerCase())
                    || value.url.toLowerCase().includes(attrValue.toLowerCase()));
            }
            default:
        }

        return false;
    }

    public static isDropDown(field: Metadata.Field) {
        return (field.type === Metadata.FieldType.Integer || field.type === Metadata.FieldType.Text) &&
            field.settings !== undefined &&
            (field.settings.editControl === "Dropdown" || field.settings.editControl === "ColorStatusDropdown" || field.settings.editControl === "ToggleOption");
    }

    public static isSlider(field: Metadata.Field, withOptions?: boolean) {
        return (field.type === Metadata.FieldType.Integer || field.type === Metadata.FieldType.Decimal)
            && field.settings?.editControl === (withOptions ? "EnumSlider" : "Slider");
    }

    public static isTag(field: Metadata.Field) {
        return field.type === Metadata.FieldType.Text && field.settings?.editControl === "Tag";
    }

    public static isIdentifier(field: Metadata.Field) {
        return field.type === Metadata.FieldType.Text && field.settings?.index !== undefined;
    }

    public static getAttributeDisplayValues(fields: Metadata.Field[], value: any): string[] {
        let values: string[] = [];
        Object.keys(value).forEach(attrName => {
            const field = fields.find(_ => _.name === attrName);
            if (field) {
                values = values.concat(FieldsService.getFieldDisplayValues(field, value[attrName]));
            }
        }); 
        return values;
    }
}