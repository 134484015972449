import React from 'react';
import { IDraggableItem } from './DraggableList';

interface INonDraggableListProps {
    items: IDraggableItem[];
    onItemRender: (item: any) => JSX.Element | (JSX.Element | null)[];
    itemClassName?: string;
}

const NonDraggableList = (props: INonDraggableListProps) => {
    return <ul className="cb-list with-dragndrop dragndrop-disabled">
        {props.items.map(_ => (
            <li key={_.id} className={`field ${props.itemClassName ?? ''}`}>
                <div className="align-center">
                    {props.onItemRender(_)}
                </div>
            </li>))}
    </ul>;
};

export default NonDraggableList;
