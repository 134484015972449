import * as React from "react";
import { arraysEqual } from "office-ui-fabric-react";
import { HierarchyManager } from './HierarchyManager';
import { EntityType } from "../../entities/common";

type HierarchyContainerProps<T extends { id: string }, TContext> = {
    items: T[],
    allItems: T[],
    expandIds: string[],
    hierarchy: HierarchyManager<T, TContext>
};

export type HierarchyContainerEntity = {
    entityType?: EntityType,
    parentId?: string
};
export function isPortfolio(item: HierarchyContainerEntity): boolean { return item.entityType === EntityType.Portfolio; }
export function isProgram(item: HierarchyContainerEntity): boolean { return item.entityType === EntityType.Program; }
export function isProject(item: HierarchyContainerEntity): boolean { return item.entityType === EntityType.Project; }

export class HierarchyContainer<T extends { id: string }, TContext> extends React.Component<HierarchyContainerProps<T, TContext>, {}> {
    componentWillMount() {
        this.props.hierarchy.setItems(this.props.items, this.props.allItems, undefined, this.props.expandIds);
    }

    componentWillReceiveProps(nextProps: HierarchyContainerProps<T, TContext>) {
        if (!arraysEqual(this.props.items, nextProps.items) || !arraysEqual(this.props.allItems, nextProps.allItems)) {
            nextProps.hierarchy.setItems(nextProps.items, nextProps.allItems, undefined, !arraysEqual(this.props.expandIds, nextProps.expandIds) ? nextProps.expandIds : true);
        }
    }

    render() { return this.props.children; }
}

