import * as React from 'react';
import { MinMax, CalculationSettings, GenericCalculationThresholds, StatusCalculationTypes } from '../../store/Tenant';
import { ThresholdsRange } from "./ThresholdsRange";
import { FormatType } from '../../entities/Metadata';
import { Toggle } from 'office-ui-fabric-react';

export const OKRStatusCalculationSettings = (props: {
    data: CalculationSettings<GenericCalculationThresholds>;
    onChange?: (data: CalculationSettings<GenericCalculationThresholds>) => void;
    thresholdTooltip: string;
}) => {
    const { data, onChange, thresholdTooltip } = props;
    return <>
        <div className="status-calculation-toggle align-center">
            <span className="label">Calculate statuses automatically</span>
            <Toggle className="toggle"
                disabled={!onChange}
                checked={data.statusCalculation == StatusCalculationTypes.Auto}
                onChanged={onChange ? (checked: boolean) => {
                    onChange({ ...data, statusCalculation: checked ? StatusCalculationTypes.Auto : StatusCalculationTypes.Manual });
                } : undefined}
                onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
        </div>
        <div>Specify values below to define status calculation rules</div>
        <div className="card-list">
            <ThresholdsRange title="Progress / Status Impact"
                tooltip={thresholdTooltip}
                minLabel="Critical"
                maxLabel="At Risk"
                format={FormatType.Percent}
                range={{ min: -100, max: 100 }}
                value={data.thresholds.minMax}
                onChange={onChange
                    ? (value: MinMax<number | null>) => props.onChange!({ ...props.data, thresholds: { ...props.data.thresholds, ["minMax"]: value } })
                    : undefined} />
        </div>
    </>;
}