import React, { useEffect, useRef } from 'react';
import OptionEdit, { DraggableOption } from '../../SelectSettingsEdit/OptionEdit';
import ColorStatusOption from './ColorStatusOption';
import { CategoryStatusOption } from '../../../../entities/StatusDescriptor';

type Props = {
    index: number;
    option: DraggableOption<CategoryStatusOption>;
    categoryIconName: string;
    isInEditMode: boolean;
    isUnique: () => boolean;
    onEnterEditMode: () => void;
    onCancel: (initialOption: DraggableOption<CategoryStatusOption>) => void;
    onEdit: (option: DraggableOption<CategoryStatusOption>) => void;
    onEditComplete: () => void;
    onRemove?: (optionId: string) => void;
};

const CategoryStatusOptionEdit = (props: Props) => {
    const { index, option, isInEditMode, categoryIconName, onEnterEditMode, onEdit, onRemove } = props;
    
    const isUnique = props.isUnique();
    
    const initialOption = useRef<DraggableOption<CategoryStatusOption>>();
    useEffect(() => {
        initialOption.current = option;
    }, []);

    const onChange = (changedOption: DraggableOption<CategoryStatusOption>) => {
        onEdit(changedOption);
    };

    const onCancelWithInitialValue = () => {
        initialOption.current && props.onCancel?.(initialOption.current);
    };

    const onCancel = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onCancelWithInitialValue();
        e.stopPropagation();
        e.preventDefault();
    };
    
    const isValidOption = !!option.name.trim() && isUnique;

    const onEditComplete = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.onEditComplete?.();
        initialOption.current = option;
        e.stopPropagation();
        e.preventDefault();
    };

    const onEditCompleteIfValid = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isValidOption) {
            onEditComplete(e);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const clickedOnColorCalloutOrCancelButton = hasColorCalloutOrCancelButtonAncestor(e.relatedTarget as HTMLElement);
        if (clickedOnColorCalloutOrCancelButton) {
            // Clicked on the color callout or cancel button, don't blur
            e.preventDefault();
        }
        else if (isValidOption) {
            onEditComplete(e);
        } else {
            onCancel(e);
        }
    }

    return (
        <div className="cb-wrap">
            {!isInEditMode && (
                <ColorStatusOption
                    index={index}
                    option={option}
                    iconName={categoryIconName}
                    onEditClick={onEnterEditMode}
                    onRemove={onRemove}
                />
            )}
            {isInEditMode && (
                <OptionEdit
                    option={option}
                    index={index}
                    isUnique={isUnique}
                    hideRemoveColorButton
                    onChange={onChange}
                    onRemove={onCancelWithInitialValue}
                    onBlur={onBlur}
                    cancelButtonTitle="Cancel"
                    textFieldProps={{
                        onKeyDown: e => { if (e.key === 'Escape') { onCancel(e); } },
                        onKeyPress: e => { if (e.key === 'Enter') { onEditCompleteIfValid(e); }
                    }}}
                />
            )}
        </div>
    );
};

export default CategoryStatusOptionEdit;

const hasColorCalloutOrCancelButtonAncestor = (element: HTMLElement | null): boolean => {
    if (!element) {
        return false;
    }
    if (element.classList.contains("ppmx-color-picker-icon") 
        || element.classList.contains("select-option-color-callout")
        || element.classList.contains("cancel-option-edit-btn")
    ) {
        return true;
    }
    return hasColorCalloutOrCancelButtonAncestor(element.parentElement);
};
