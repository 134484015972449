import './PPMXColorPicker.css';
import * as React from 'react';
import { SwatchColorPicker, IColorCellProps, Callout, DefaultButton, IconButton } from 'office-ui-fabric-react';

const colors: IColorCellProps[] = [
    { id: '-1', color: '#a80000', label: 'Dark Red' },
    { id: '-2', color: '#eb0716', label: 'Red' },
    { id: '-3', color: '#e54917', label: 'Orange Red' },
    { id: '-4', color: '#ff6c3d', label: 'Pumpkin' },
    { id: '-5', color: '#ff8c00', label: 'Orange' },
    { id: '-6', color: '#ffba00', label: 'Marigold' },
    { id: '-7', color: '#ffda44', label: 'Yellow' },
    { id: '-8', color: 'var(--sidebar-options-green-color)', label: 'Light Green' },
    { id: '-9', color: '#28aa32', label: 'Green' },
    { id: '-10', color: '#128a10', label: 'Dark Green' },
    { id: '-11', color: '#008272', label: 'Ocean' },
    { id: '-12', color: '#00b394', label: 'Teal' },
    { id: '-13', color: '#00d9b1', label: 'Aquamarine' },
    { id: '-14', color: '#00bbf5', label: 'Light Blue' },
    { id: '-15', color: '#0076d7', label: 'Blue' },
    { id: '-16', color: '#4665d4', label: 'Dark Blue' },
    { id: '-17', color: '#6446cf', label: 'Indigo' },
    { id: '-18', color: '#8271eb', label: 'Violet' },
    { id: '-19', color: '#b49dff', label: 'Lavender' },
    { id: '-20', color: '#b600a0', label: 'Plum' },
    { id: '-21', color: '#e6008c', label: 'Magenta' },
    { id: '-22', color: '#ff6bb4', label: 'Pink' },
    { id: '-23', color: '#788086', label: 'DarkGrey' },
    { id: '-24', color: '#bdbdbd', label: 'Light Gray' }
];


const PPMXColorPalette = (props: Props & {
    columnCount: number;
    cellSize: number;
}) => {
    const color = props.color ? colors.find(_ => _.color === props.color) : undefined;
    return <SwatchColorPicker
        selectedId={color?.id}
        onColorChanged={(id, changed) => props.onChange(changed)}
        columnCount={props.columnCount}
        cellShape={'circle'}
        cellHeight={props.cellSize}
        cellWidth={props.cellSize}
        cellBorderWidth={0}
        colorCells={colors}
    />
}


type Props = {
    color?: string;
    onChange: (value?: string) => void;
    hideRemoveColorButton?: boolean;
}

const PPMXColorPicker = (props: Props) => {
    const ref = React.useRef<HTMLSpanElement>(null);
    const [showCallout, setShowCallout] = React.useState(false);
    return <span ref={ref}>
        <IconButton
            title='Color picker'
            className='ppmx-color-picker-icon'
            iconProps={{ iconName: props.color ? undefined : "PPMXColorPicker" }}
            onClick={() => setShowCallout(true)}
            style={{ backgroundColor: props.color }}
        />
        {
            showCallout && <Callout
                className="select-option-color-callout"
                target={ref}
                onDismiss={() => setShowCallout(false)}
                isBeakVisible={false}
                gapSpace={8}
            >
                <PPMXColorPalette
                    color={props.color}
                    columnCount={6}
                    cellSize={24}
                    onChange={color => {
                        props.onChange(color);
                        setShowCallout(false);
                    }}
                />
                {
                    !props.hideRemoveColorButton && <DefaultButton
                        text="Remove color"
                        onClick={() => {
                            props.onChange(undefined);
                            setShowCallout(false);
                        }}
                    />
                }
            </Callout>
        }
    </span>
}

export { PPMXColorPalette, PPMXColorPicker }