import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import { BaseFilterAttribute } from './BaseFilterAttribute';
import { FieldsService } from '../FieldsService';
import { DisplayFieldService } from '../DisplayFieldService';

type OwnProps =
    {
        field: Metadata.Field,
        value: unknown,
        onEditComplete: (data: unknown) => void
    }

export const FilterAttribute = (props: OwnProps) => {
    const { field, value } = props;

    const _getLabelExtraDetails = () => {
        const { field, value } = props;

        const values = (value !== undefined && value !== null) ? FieldsService.getFieldDisplayValues(field, value) : [];
        let suffix: string = values;
        let count: number = 0;
        if (field.type === Metadata.FieldType.Date) {
            suffix = (values as string[]).filter(_ => !!_).join(' - ');
        } else if (Array.isArray(values)) {
            suffix = values[0];
            count = values.length;
        }
        return { suffix, count };
    }

    const _onEditComplete = (value: any) => {
        const newValue = (value === null || value === undefined) ||
            (Array.isArray(value) && !value.length) ||
            (value.hasOwnProperty("from") && value.from === undefined && value.hasOwnProperty("to") && value.to === undefined) ? undefined : value;
        props.onEditComplete(newValue);
    }

    return <>
        <BaseFilterAttribute hasValue={value !== undefined && value !== null}
            label={Metadata.getLabel(field)}
            getLabelExtraDetails={_getLabelExtraDetails}>
            {DisplayFieldService.buildFieldMultiSelectInput(field, value, _onEditComplete, { inputProps: { autoFocus: true }, autoExpand: true })}
        </BaseFilterAttribute>
    </>;
}
