import * as React from 'react';
import { IListViewColumn } from "../../../../../services/ViewService";
import { Portfolio } from "../../../../../store/PortfoliosListStore";
import { getProjectsMetrics } from "../../../../../store/portfolio/utils";
import { toPercentString } from "../../../../utils/common";

export default class PercentCompleted extends React.Component<IListViewColumn<Portfolio>> {
    public render() {
        const entity = this.props.entity;
        return <span className="font-14">
            {toPercentString(PercentCompleted.getValue(entity))}
        </span>;
    }

    public static getValue(entity: Portfolio): number {
        const metrics = getProjectsMetrics(entity.calculation);
        return metrics.total !== 0 ? metrics.completed / metrics.total : 0;
    }
}