import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as analytics from '../../analytics';
import { ApplicationState } from '../../store';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from '../../store/layouts';
import { IContainerSectionInfo, LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import * as FieldsArea from "../common/sectionsControl/uiControls/FieldsArea";
import { DetailsSpinner } from "../common/Spinner";
import { ILinkDto } from '../../store/integration/common';
import { IOffice365UserInfo } from '../../store/integration/Office365Store';
import { IJiraUser } from '../../store/integration/JiraStore';
import { IVSTSUser } from '../../store/integration/VSTSStore';
import { ISpoResource } from '../../store/integration/SpoStore';
import { Dictionary, EntityType, IWarning, mapServerEntityType } from '../../entities/common';
import { bindActionCreators } from 'redux';
import { ViewTypeViews, buildViewTypeSelect } from '../common/ViewTypeSelect';
import * as CalendarStore from '../../store/CalendarStore';
import * as UtilizationControl from '../common/sectionsControl/uiControls/UtilizationControl';
import { IMondayComUser } from '../../store/integration/MondayComStore';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import { warningService } from "../../store/services/warningService";
import { EntitySectionWarning } from "../common/warnings/EntitySectionWarning";
import { ISmartsheetUser } from '../../store/integration/SmartsheetStore';
import { IP4WResource } from '../../store/integration/P4WStore';
import { UserState, isInReadonlyMode } from '../../store/User';
import { buildUpdateUIControl } from '../../entities/Subentities';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import ResourceHeader from './ResourceHeader';

type StateProps = {
    entity?: ResourcesListStore.Resource;
    isLoading: boolean;
    isUpdatingSections: boolean;
    layouts: LayoutsStore.LayoutsState;
    warnings: IWarning[];
    warningsTypeMap: WarningsTypeMapsStore.EntityWarningsTypeMap;
    sectionWarningsTypeMap: Dictionary<string[]>;
    user: UserState;
    isReadonlyMode: boolean;
    resourcePlanningLevel: EntityType;
}
type ActionProps = {
    resourceActions: typeof ResourcesListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    calendarActions: typeof CalendarStore.actionCreators;
    warningsTypeMapsActions: typeof WarningsTypeMapsStore.actionCreators;
}
type ResourceDetailsProps = RouteComponentProps<{ id: string }>
    & StateProps
    & ActionProps;

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: FieldsArea.IConfiguration;
    UtilizationControl: UtilizationControl.IConfiguration;
}

const entityName = EntityType.Resource;
class ResourceDetails extends React.Component<ResourceDetailsProps> {

    componentWillMount() {
        this.props.warningsTypeMapsActions.loadWarningsTypeMap(entityName);
        this.props.resourceActions.loadResource(this.props.match.params.id);
        this.props.calendarActions.load();
    }

    componentWillReceiveProps(nextProps: ResourceDetailsProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.props.resourceActions.loadResource(nextProps.match.params.id);
        }
    }

    public render() {
        const { entity, isLoading, layouts, isUpdatingSections, warnings, warningsTypeMap } = this.props;
        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ResourceHeader
                entity={entity}
                warnings={warnings}
                warningsTypeMap={warningsTypeMap?.common}
                actions={{
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    layoutActions: {
                        viewLayout: this.viewLayout,
                        applyLayout: this.applyLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    "ExternalEpmResourceConnectControl": {
                        [SourceType.O365User]: {
                            linkToUser: this.linkResourceToO365User,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.Jira]: {
                            linkToUser: this.linkToJiraUser,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.VSTS]: {
                            linkToUser: this.linkToVSTSUser,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.Spo]: {
                            linkToUser: this.linkToPoResource,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.MondayCom]: {
                            linkToUser: this.linkToMondayComResource,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.Smartsheet]: {
                            linkToUser: this.linkToSmartsheetResource,
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.MPPFile]: {
                            deleteUserLink: this.deleteResourceToUserLink
                        },
                        [SourceType.P4W]: {
                            linkToUser: this.linkToP4WResource,
                            deleteUserLink: this.deleteResourceToUserLink
                        }
                    },
                }}
            />}
            {entity && <LayoutableSectionsContainer
                key="sections"
                entity={entity}
                entityType={EntityType.Resource}
                warnings={warnings}
                onHeaderMiddleRender={this._onSectionHeaderMiddlePartRender}
                layouts={layouts}
                isUpdatingSections={isUpdatingSections}
                controlsConfig={this._buildControlsConfigurations()}
            />}
        </DetailsSpinner>
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { entity, resourceActions, layouts, isReadonlyMode, resourcePlanningLevel } = this.props;
        const updateUIControl = buildUpdateUIControl(entity!.id, isReadonlyMode, layouts, resourceActions);
        return {
            ['UtilizationControl']: {
                ...buildViewTypeSelect(ViewTypeViews.UtilizationControl),
                actions: {
                    updateUIControl: updateUIControl
                },
                datacontext: {
                    resourcePlanningLevel
                }
            },
            ['FieldsArea']: {
                datacontext: {
                    entityId: this.props.entity!.id,
                    entityType: EntityType.Resource,
                    warningsTypeMap: this.props.warningsTypeMap.targeted["Details"]?.["FieldsArea"]
                },
                actions: {
                    refreshEntity: this.refreshEntity,
                    updateUIControl: updateUIControl,

                    onEditComplete: this.onEditComplete
                }
            }
        }
    }

    private _onSectionHeaderMiddlePartRender = (section: IContainerSectionInfo): JSX.Element | null => {
        return (
            <EntitySectionWarning warningsTypes={this.props.sectionWarningsTypeMap[section.name]}
                warnings={this.props.warnings} />
        );
    }

    private refreshEntity = () => {
        this.props.resourceActions.loadResource(this.props.entity!.id);
    }

    private onEditComplete = (fieldName: string, fieldValue: any): void => {
        if (!fieldName) {
            return;
        }

        this.props.resourceActions.updateResourceAttributes(this.props.entity!.id, { [fieldName]: fieldValue });
    }

    private applyLayout = (layout: Metadata.Layout) => {
        this.props.resourceActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.resourceActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.resourceActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.resourceActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Metadata.Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: Notifications.NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Metadata.Section[], update: Metadata.IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: Notifications.NotificationType.Info
        });
    }

    private linkResourceToO365User = (linkData: ILinkDto<IOffice365UserInfo>) => {
        this.props.resourceActions.linkResourceToO365User(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to Office 365 user has been started.` });
        analytics.trackLink('Linked Resource to o365 user', this.props.user);
    }

    private linkToJiraUser = (linkData: ILinkDto<IJiraUser>) => {
        this.props.resourceActions.linkToJiraUser(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to Jira user has been started.` });
        analytics.trackLink('Linked Resource to Jira user', this.props.user);
    }

    private deleteResourceToUserLink = (connectionId: string) => {
        this.props.resourceActions.deleteResourceToUserLink(this.props.entity!.id, connectionId);
    }

    private linkToVSTSUser = (linkData: ILinkDto<IVSTSUser>) => {
        this.props.resourceActions.linkToVSTSUser(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to DevOps user has been started.` });
        analytics.trackLink('Linked Resource to DevOps user', this.props.user);
    }

    private linkToPoResource = (linkData: ILinkDto<ISpoResource>) => {
        this.props.resourceActions.linkToPoResource(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to resource in Project Online has been started.` });
        analytics.trackLink('Linked Resource to Project Online resource', this.props.user);
    }

    private linkToMondayComResource = (linkData: ILinkDto<IMondayComUser>) => {
        this.props.resourceActions.linkToMondayComResource(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to resource in monday.com has been started.` });
        analytics.trackLink('Linked Resource to monday.com resource', this.props.user);
    }

    private linkToSmartsheetResource = (linkData: ILinkDto<ISmartsheetUser>) => {
        this.props.resourceActions.linkToSmartsheetResource(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to resource in Smartsheet has been started.` });
        analytics.trackLink('Linked Resource to Smartsheet resource', this.props.user);
    }

    private linkToP4WResource = (linkData: ILinkDto<IP4WResource>) => {
        this.props.resourceActions.linkToP4WResource(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking resource '${this.props.entity!.attributes.Name}' to resource in Project for the web has been started.` });
        analytics.track('Linked Resource to Project for the web resource');
    }

    private updateImage = (image: File) => {
        this.props.resourceActions.updateImage(this.props.entity!.id, image);
    }

    private removeImage = () => {
        this.props.resourceActions.removeImage(this.props.entity!.id);
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    const warningsTypeMap = state.warningsTypeMaps.maps[entityName] || { common: [], targeted: {} }
    const entity = state.resources.activeEntity && state.resources.activeEntity.id === ownProps.match.params.id ? state.resources.activeEntity : undefined;
    let warnings: IWarning[] = [];
    let sectionWarningsTypeMap = {};
    if (entity?.sections) {
        const tuple = warningService.filterByEntityConfiguration([...entity.warnings], warningsTypeMap, entity.sections);
        warnings = tuple.warnings;
        sectionWarningsTypeMap = tuple.sectionWarningsTypeMap;
    }

    return {
        entity,
        warnings,
        warningsTypeMap,
        sectionWarningsTypeMap,
        isLoading: state.resources.isLoading || state.warningsTypeMaps.isLoading || !!state.layouts[entityName].isApplyingLayout,
        isUpdatingSections: state.resources.isUpdatingSections,
        layouts: state.layouts[entityName],
        user: state.user,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant),
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!
    };
}

export default connect(
    mapStateToProps, (dispatch): ActionProps =>
({
    resourceActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
    notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
    layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
    calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch),
    warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
}))(ResourceDetails);