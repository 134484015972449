import * as React from 'react';
import { ApplicationState } from '../../../../store';
import { IListViewFieldColumn } from "../../../../services/ViewService";
import { IExtensibleEntity, IWithLayout } from '../../../../entities/common';
import { LayoutsState } from '../../../../store/layouts';
import { connect } from 'react-redux';

type StateProps = {
    layouts: LayoutsState;
};

const LayoutName = (props: IListViewFieldColumn<IWithLayout> & StateProps) => {
    return <span>
        {props.entity.layoutId ? props.layouts.byId[props.entity.layoutId]?.name : null}
    </span>;
}

function mapStateToProps(state: ApplicationState, ownProps: IListViewFieldColumn<IWithLayout>): StateProps {
    return {
        layouts: state.layouts[ownProps.entityType!]
    };
}

export default connect(mapStateToProps, undefined)(LayoutName);

export const getValue = function (entity: IExtensibleEntity & IWithLayout) {
    //should use name instead of id for correct sorting
    return entity.layoutId;
 }