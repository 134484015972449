
import * as React from 'react';
import { connect } from 'react-redux';
import { IImportEntity } from "./common";
import { ApplicationState } from "../../../store/index";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ActionProps, ConverterProps, OwnProps, StateProps, SystemResourceImportPanel } from './SystemResourceImportPanel';
import * as VSTS from "../../../store/integration/VSTSStore";
import * as Office365 from "../../../store/integration/Office365Store";
import * as Jira from "../../../store/integration/JiraStore";
import * as Spo from "../../../store/integration/SpoStore";
import * as P4W from "../../../store/integration/P4WStore";
import * as MondayCom from "../../../store/integration/MondayComStore";
import * as Smartsheet from "../../../store/integration/SmartsheetStore";
import { toDictionary } from '../../../components/utils/common';
import { Dictionary } from '../../../entities/common';

export const ImportPanelSelector = (props: OwnProps) => {
    const { system } = props;

    if (system === SourceType.O365User) {
        return <Office365Import key="o365" {...props} />;
    }

    if (system === SourceType.Jira) {
        return <JiraImport {...props} />;
    }

    if (system === SourceType.VSTS) {
        return <VSTSImport {...props} />
    }

    if (system === SourceType.Spo) {
        return <SpoImport {...props} />;
    }

    if (system === SourceType.MondayCom) {
        return <MondayComImport {...props} />;
    }

    if (system === SourceType.Smartsheet) {
        return <SmartsheetImport {...props} />;
    }

    if (system === SourceType.P4W) {
        return <P4WImport {...props} />;
    }

    return null;
}

const withConverter = <T extends {}>(
    Component: React.ComponentType<StateProps<T> & ActionProps<T> & OwnProps & ConverterProps<T>>,
    converter: IConverter<T>
) => (props: StateProps<T> & OwnProps & ActionProps<T>) => <Component {...props} {...converter} />;

interface IConverter<T> {
    getMap: (users: T[]) => Dictionary<T>;
    toEntity: (_: T) => IImportEntity;
    getSourceId: (data: any) => string;
}

const Office365Converter: IConverter<Office365.IOffice365UserInfo> = {
    getMap: _ => toDictionary(_),
    toEntity: _ => ({
        id: _.id,
        displayName: _.displayName,
        email: _.mail
    }),
    getSourceId: _ => _.userId
}

const JiraConverter: IConverter<Jira.IJiraUser> = {
    getMap: entities => {
        const dictionary = new Dictionary<Jira.IJiraUser>();
        entities.forEach(_ => dictionary[_.accountId] = _);
        return dictionary;
    },
    toEntity: (_: Jira.IJiraUser) => ({
        id: _.accountId,
        displayName: _.displayName,
        email: _.email
    }),
    getSourceId: _ => _.accountId
}

const MondayComConverter: IConverter<MondayCom.IMondayComUser> = {
    getMap: entities => {
        const dictionary = new Dictionary<MondayCom.IMondayComUser>();
        entities.forEach(_ => dictionary[`${_.userKind}_${_.id}`] = _);
        return dictionary;
    },
    toEntity: (_: MondayCom.IMondayComUser) => ({
        id: `${_.userKind}_${_.id}`,
        displayName: _.displayName,
        email: _.email
    }),
    getSourceId: _ => `${_.userKind}_${_.userId}`
}

const SmartsheetConverter: IConverter<Smartsheet.ISmartsheetUser> = {
    getMap: _ => {
        const dictionary = new Dictionary<Smartsheet.ISmartsheetUser>();
        _.forEach(u => dictionary[u.email] = u);
        return dictionary;
    },
    toEntity: (_: Smartsheet.ISmartsheetUser) => ({
        id: _.email,
        displayName: _.displayName,
        email: _.email
    }),
    getSourceId: _ => _.userEmail
}

const VSTSConverter: IConverter<IImportEntity> = {
    getMap: _ => toDictionary(_),
    toEntity: _ => _,
    getSourceId: _ => _.userId
}

const SpoConverter: IConverter<IImportEntity> = {
    getMap: _ => toDictionary(_),
    toEntity: _ => _,
    getSourceId: _ => _.resourceId
}

const P4WConverter: IConverter<IImportEntity> = {
    getMap: _ => toDictionary(_),
    toEntity: _ => _,
    getSourceId: _ => _.resourceId
}

const Office365Import = connect((state: ApplicationState, ownProps: OwnProps): StateProps<Office365.IOffice365UserInfo> => ({
    user: state.user,
    users: state.office365.users.entities,
    isLoading: state.office365.users.isLoading,
    error: state.office365.users.error,
    isImporting: state.import.resources.isImporting,
    connections: state.office365.connections
}), Office365.actionCreators)(withConverter(SystemResourceImportPanel, Office365Converter))

const JiraImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<Jira.IJiraUser> => ({
    user: state.user,
    users: state.jira.users.entities,
    isLoading: state.jira.users.isLoading,
    error: state.jira.users.error,
    isImporting: state.import.resources.isImporting,
    connections: state.jira.connections
}), Jira.actionCreators)(withConverter(SystemResourceImportPanel, JiraConverter));

const MondayComImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<MondayCom.IMondayComUser> => ({
    user: state.user,
    users: state.mondayCom.users.entities,
    isLoading: state.mondayCom.users.isLoading,
    error: state.mondayCom.users.error,
    isImporting: state.import.resources.isImporting,
    connections: state.mondayCom.connections
}), MondayCom.actionCreators)(withConverter(SystemResourceImportPanel, MondayComConverter));

const SmartsheetImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<Smartsheet.ISmartsheetUser> => ({
    user: state.user,
    users: state.smartsheet.users.entities,
    isLoading: state.smartsheet.users.isLoading,
    error: state.smartsheet.users.error,
    isImporting: state.import.resources.isImporting,
    connections: state.smartsheet.connections
}), Smartsheet.actionCreators)(withConverter(SystemResourceImportPanel, SmartsheetConverter));

const VSTSImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<VSTS.IVSTSUser> => ({
    user: state.user,
    users: state.vsts.users.entities,
    isLoading: state.vsts.users.isLoading,
    error: state.vsts.users.error,
    isImporting: state.import.resources.isImporting,
    connections: state.vsts.connections
}), VSTS.actionCreators)(withConverter(SystemResourceImportPanel, VSTSConverter))

const SpoImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<Spo.ISpoResource> => ({
    user: state.user,
    users: state.spo.resources.entities,
    isLoading: state.spo.resources.isLoading,
    error: state.spo.resources.error,
    isImporting: state.import.resources.isImporting,
    connections: state.spo.connections
}), {
    importResources: Spo.actionCreators.importResources,
    loadUsers: Spo.actionCreators.loadResources,
    verifyConnection: Spo.actionCreators.verifyConnection,
})(withConverter(SystemResourceImportPanel, SpoConverter))

const P4WImport = connect((state: ApplicationState, ownProps: OwnProps): StateProps<P4W.IP4WResource> => ({
    users: state.p4w.resources.entities,
    isLoading: state.p4w.resources.isLoading,
    error: state.p4w.resources.error,
    isImporting: state.import.resources.isImporting,
    user: state.user,
    connections: state.p4w.connections
}), {
    importResources: P4W.actionCreators.importResources,
    loadUsers: P4W.actionCreators.loadResources,
    verifyConnection: P4W.actionCreators.verifyConnection,
})(withConverter(SystemResourceImportPanel, P4WConverter));

