import "./GroupedPlansList.css";
import * as React from 'react';
import { get } from '../../../../fetch-interceptor';
import { DefaultButton, Overlay } from 'office-ui-fabric-react';
import Spinner from '../../../common/Spinner';
import { SubscriptionInfo, BillingPlan } from '../common';
import { PlansList } from '../PlansList';
import Checkout from '../Checkout';
import { catchApiError } from '../../../../store/utils';
import { PaymentPeriod, PaymentType, ProductType } from '../../../../store/Tenant';
import { groupByKey } from "../../../utils/common";

type Props = {
    subscription?: SubscriptionInfo;
    chargebee: any;
    canManage: boolean;
    onSwitchTrialConfirmed: (planId: string) => void;
    onSuccessfulCheckout: () => void;
}

type PlansListState = {
    plans: BillingPlan[];
    isPlansLoading: boolean;
    isPlansError: boolean;
}

const map = {
    [PaymentPeriod.Day]: "Day",
    [PaymentPeriod.Month]: 'Monthly',
    [PaymentPeriod.Week]: 'Week',
    [PaymentPeriod.Year]: 'Annual SAVINGS 18%',
    [PaymentPeriod.Custom]: 'Custom',
    [PaymentPeriod.Unknown]: "Unknown"
}

export const GroupedPlansList = (props: Props) => {
    const [plansState, setPlansState] = React.useState<PlansListState>({ plans: [], isPlansLoading: true, isPlansError: false });
    const [paymentPeriod, setPaymentPeriod] = React.useState(props.subscription?.paymentPeriod ?? PaymentPeriod.Month);

    const paymentPeriods = React.useMemo(
        () => Array.from(new Set(plansState.plans.filter(_ => _.paymentType !== PaymentType.Direct).map(_ => _.paymentPeriod))).sort((a, b) => b - a),
        [plansState.plans]);

    React.useEffect(() => {
        if (paymentPeriods.length && !paymentPeriods.includes(paymentPeriod)) {
            setPaymentPeriod(paymentPeriods[paymentPeriods.length - 1]);
        }
    }, [paymentPeriods]);

    React.useEffect(() => {
        get<BillingPlan[]>("api/billing/plans")
            .then(_ => setPlansState({
                plans: _,
                isPlansLoading: false,
                isPlansError: false
            }))
            .catch(catchApiError(_ => setPlansState({ plans: [], isPlansLoading: false, isPlansError: true })));
    }, []);

    if (plansState.isPlansLoading) {
        return <Overlay><Spinner /></Overlay>;
    }

    const { subscription } = props;
    if (!subscription || plansState.isPlansError || !Object.keys(plansState.plans).length) {
        return <div className='error-wrapper'>
            <div className="error">
                <img src="https://www.chargebee.com/static/resources/brand/chargebee-logo-black.svg" alt="Chargebee" />
                <div className="error-title">Unfortunately PPM Express Plans are temporary unavailable.</div>
                <div className="error-description">Payment API is not available at the moment. Sorry for the inconvenience. Please try again later.</div>
            </div>
        </div>
    }

    return <div className="grouped-plans-list">
        <div className="payment-period-selector">
            {
                paymentPeriods.map(_ => <DefaultButton
                    key={_}
                    className={_ === paymentPeriod ? 'selected' : ''}
                    text={map[_]}
                    onClick={() => setPaymentPeriod(_)}
                />)
            }
        </div>
        <PlansList
            {...props}
            paymentPeriod={paymentPeriod}
            subscription={subscription}
            plans={plansState.plans}
        />
    </div>
}
