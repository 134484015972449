import * as React from "react";
import "./Collapsible.css";
import { Icon } from "office-ui-fabric-react";

type Props = {
    title: string;
    iconName: string;
    children: React.ReactNode;
    expanded?: boolean;
    disabled?: boolean;
    classNames?: {
        head?: string;
        body?: string;
    };
};

export default function Collapsible(props: Props) {
    const [expanded, setExpanded] = React.useState(props.expanded ?? false);
    React.useEffect(() => {
        setExpanded(props.expanded ?? false);
    }, [props.expanded]);

    const { iconName, title, disabled, classNames, children } = props;

    return (
        <div className={`accordion ${disabled ? "readonly" : ""} ${expanded ? "open" : ""}`}>
            <div
                className={`accordion-head ${classNames?.head ? classNames.head : ""}`}
                onClick={() => setExpanded(!expanded)}
            >
                <span className="chevron"></span>
                <Icon iconName={iconName} />
                {title}
            </div>
            {expanded && (
                <div className={`accordion-body ${classNames?.body ? classNames.body : ""}`}>
                    {children}
                </div>
            )}
        </div>
    );
}
