import * as React from 'react';
import { post } from '../../../fetch-interceptor';
import { getId, IPickerItemProps, IBasePickerSuggestionsProps, IBasePicker, IconButton, Autofill } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps';
import { IFindResult } from '../../common/inputs/EntityPickerInput';
import Link from '../../common/Link';
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import PPMXPicker from "./PPMXPicker";
import { Dictionary } from '../../../entities/common';
import InputErrorMessage from './inputErrorMessage';
import { Validator } from '../../../validation';

export type EntityInfo = {
    id: string;
    name: string;
    url?: string;
    groupHeader?: boolean | null;
    attributes?: any | null;
}

type Props = IFormInputProps<IFindResult | IFindResult[], Autofill>
    & {
        renderRemovalConfirmation?: (onSave: () => void, onClose: () => void) => JSX.Element,
        multichoice?: boolean,
        entityViewPath?: string;
        searchPath?: string;
        filter?: Dictionary<any>;
        onResolveSuggestions?: (filter: string, selectedItems?: EntityInfo[]) => EntityInfo[] | PromiseLike<EntityInfo[]>;
        autoExpand?: boolean;
    };

type State = {
    entityInfos?: EntityInfo | EntityInfo[];
    showRemovalConfirmation?: boolean;
    tmpEntityInfos?: EntityInfo | EntityInfo[];
}

export default class EntityPicker extends React.Component<Props, State> implements IFormInputComponent {
    private _picker = React.createRef<IBasePicker<EntityInfo>>();

    constructor(props: Props) {
        super(props);
        this.state = {
            entityInfos: this.toEntityInfo(this.props.value, this.props.multichoice)
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value != this.props.value) {
            this.setState({
                entityInfos: this.toEntityInfo(nextProps.value, nextProps.multichoice)
            });
        }
    }

    public render() {
        const { entityInfos } = this.state;
        return <><div className="entity-picker">
            <ItemPicker
                {...this.props}
                componentRef={this._picker}
                onChange={this.onChange}
                onResolveSuggestions={this.props.onResolveSuggestions || this._onResolveSuggestions}
                selectedItems={entityInfos ? Array.isArray(entityInfos) ? entityInfos : [entityInfos] : []}
            />
        </div>
            <InputErrorMessage text={this._getErrorMessage(this.state.entityInfos)} />
            {this.state.showRemovalConfirmation && this.props.renderRemovalConfirmation?.(
                () => this._saveChanges(this.state.tmpEntityInfos),
                () => this.setState({ showRemovalConfirmation: false, tmpEntityInfos: undefined }))}
        </>;
    }

    private _getEntityInfo(data: IFindResult): EntityInfo {
        return {
            ...data,
            url: this.props.entityViewPath ? `${this.props.entityViewPath}/${data.id}` : undefined
        };
    }

    private toEntityInfo(value: IFindResult | IFindResult[] | undefined, multichoice?: boolean): EntityInfo[] {
        let entityInfos: EntityInfo[] = [];
        if (value) {
            if (multichoice) {
                if (Array.isArray(value)) {
                    entityInfos = value.map(_ => this._getEntityInfo(_));
                }
            } else {
                let entityInfo = this._getEntityInfo(value as IFindResult);
                if (entityInfo) {
                    entityInfos.push(entityInfo);
                }
            }
        }
        return entityInfos;
    }

    private _onResolveSuggestions = (filter: string, selectedItems?: EntityInfo[]) => {
        let array = post<IFindResult[]>(this.props.searchPath!, { ...this.props.filter, name: filter });
        if (selectedItems && selectedItems.length) {
            array = array.then(_ => _.filter(__ => !selectedItems.some(selected => selected.id == __.id)));
        }

        return array.then(findResults => findResults.map<EntityInfo>(_ => this._getEntityInfo(_)!));
    }

    private onChange = (items?: EntityInfo[]): void => {
        let entityInfos = items && (this.props.multichoice ? items : items[items.length - 1]);
        if (Array.isArray(entityInfos) && Array.isArray(this.state.entityInfos) && entityInfos?.length < this.state.entityInfos?.length && this.props.renderRemovalConfirmation) {
            this.setState({ showRemovalConfirmation: true, tmpEntityInfos: entityInfos });
        } else {
            this._saveChanges(entityInfos);
        }
    }

    private _saveChanges = (entityInfos?: EntityInfo | EntityInfo[]): void => {
        this.setState({ entityInfos });

        this.props.onChanged && this.props.onChanged(entityInfos);
        this.props.onEditComplete && this._validate(entityInfos) && this.props.onEditComplete(entityInfos || null);
    }

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    focus(): void {
        this._picker.current!.focusInput();
    }

    private _validate = (value?: IFindResult | IFindResult[] | null) => {
        return this.props.validator == undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value?: IFindResult | IFindResult[] | null) =>
        this.props.inputProps?.required && Validator.new().required().build().getErrorMessage(value)
        || this.props.validator && this.props.validator.getErrorMessage(value);
}

class ItemPicker extends PPMXPicker<EntityInfo> {
    public static defaultProps = {
        onRenderItem: (props: IPickerItemProps<EntityInfo>) => <SelectedItem {...props} />,
        onRenderSuggestionsItem: (props: EntityInfo, itemProps?: IBasePickerSuggestionsProps) =>
            <div className={props.groupHeader == null ? "picker-suggestion-item" : props.groupHeader ? "picker-suggestion-item-header" : "picker-suggestion-item-with-ident"} >{props.name}</div>,
        pickerSuggestionsProps: { noResultsFoundText: "No items found", loadingText: 'Loading', resultsMaximumNumber: 10 },
        getTextFromItem: (item: EntityInfo) => item.name,
    };
}

class SelectedItem extends React.Component<IPickerItemProps<EntityInfo>> {
    render() {
        const { item, disabled, onRemoveItem } = this.props;
        const itemId = getId();

        return (
            <div className="picker-item"
                data-is-focusable
                data-is-sub-focuszone
                role={'listitem'}
                aria-labelledby={'selectedItemPortfolio-' + itemId}
                style={{ cursor: "pointer" }}>
                {
                    item.url
                        ? <Link href={item.url} className="label link" title="Navigate">{item.name}</Link>
                        : <span title={item.name}>{item.name}</span>
                }
                {
                    !disabled &&
                    <IconButton
                        onClick={onRemoveItem}
                        iconProps={{ iconName: 'Cancel', style: { fontSize: '12px' } }} />
                }
            </div>
        );
    }
}