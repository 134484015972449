import React, { useCallback, useMemo, useState } from "react";
import { SearchBox } from "office-ui-fabric-react";
import { Field } from "../../entities/Metadata";
import { laneFakeFields } from "./RoadmapLaneTooltipContent";
import { TooltipFieldSettings, PivotDefaultSettings } from "./ConfigureRoadmapTooltipPanel";
import { barFakeFields, keyDateFakeFields } from "./RoadmapItemTooltipContent";
import { FieldsList } from "../field/ConfigureFields/FieldsList";
import * as Metadata from '../../entities/Metadata';
import { EntityType } from "../../entities/common";

export type PivotKeys =
    | 'lane'
    | 'bar'
    | 'keydate';

export const defaultSettingsByPivots: Record<PivotKeys, PivotDefaultSettings> = {
    lane: {
        defaultViewFields: [
            'Progress',
            'StoryPoints',
            'EstimantedEffort',
            'EstimatedCost',
            'EstimatedBenefit',
            'RoadmapItems',
        ],
        fakeFields: laneFakeFields,
    },
    bar: {
        defaultViewFields: [
            'Period',
            'Duration',
            'Progress',
            'StoryPoints',
            'EstimatedEffort',
            'EstimatedCost',
            'EstimatedBenefit',
            'AssignedTo',
            'Tags',
        ],
        fakeFields: barFakeFields,
        excludedFields: [
            'Name',
            'Status',
        ],
    },
    keydate: {
        defaultViewFields: [
            'Date',
            'Progress',
            'AssignedTo',
            'Tags',
        ],
        fakeFields: keyDateFakeFields,
        excludedFields: [
            'Name',
            'Status',
        ],
    },
};

type Props = {
    pivotKey: string;
    fields: Field[];
    settings?: TooltipFieldSettings;
    onChanged?: (pivotKey: string, fieldNames: string[]) => void;
    filter?: string;
    onFilter?: (search: string) => void;
};

const RoadmapTooltipPivotContent = (props: Props) => {
    const { pivotKey, fields, settings, filter, onFilter, onChanged } = props;
    
    const defaultSettings = defaultSettingsByPivots[pivotKey] as PivotDefaultSettings;
    
    const selectedFieldsInit = !!settings?.fieldNames.length
        ? settings.fieldNames
        : defaultSettings.defaultViewFields;

    const [selectedFieldNames, setSelectedFieldNames] = useState(selectedFieldsInit);

    const buildFakeField = useCallback((fieldName: string): Field => {
        const fakeField = defaultSettings.fakeFields!.find(_ => _.name === fieldName)!;
        return ({
            id: fakeField.id,
            name: fieldName,
            label: fakeField.label,
            type: fakeField.type,
            isFake: true,
            isSystem: false,
            isNative: false,
            isCustom: false,
            isReadonly: false,
            settings: fakeField.settings,
        });
    }, [defaultSettings]);

    const filteredFields = fields.filter(_ => !defaultSettings.excludedFields?.includes(_.name));

    const fakeFields = useMemo(() => defaultSettings.fakeFields?.map(_ => buildFakeField(_.name)) ?? [],
    [defaultSettings.fakeFields, buildFakeField]);

    const allFields = [...filteredFields, ...fakeFields];
    
    const renderSearch = () => (
        <>
            <div className="header-text">Select the fields to be displayed in the tooltip.</div>
            <SearchBox
                autoFocus={true}
                className="search"
                placeholder="Search field"
                value={filter}
                onChange={(_, v) => onFilter?.(v ?? '')}
                onClear={() => onFilter?.('')}
                onEscape={() => onFilter?.('')}
            />
        </>
    );

    const onChange = useCallback((roadmapItemFields: Metadata.Field[]) => {
        const fieldNames = roadmapItemFields.map(_ => _.name);
        setSelectedFieldNames(fieldNames);
        onChanged?.(pivotKey, fieldNames);
    }, [selectedFieldNames, setSelectedFieldNames]);

    return (
        <div className="configure-tooltip">
            {renderSearch()}
            <FieldsList
                entityType={EntityType.RoadmapItem}
                fields={allFields}
                selected={selectedFieldNames}
                onChange={onChange}
                filter={filter}
            />
        </div>
    );
}

export default RoadmapTooltipPivotContent;
