import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IEntityStore } from './integration/common'
import { defaultCatch } from './utils';
import { addOrUpdateOrRemove } from './services/storeHelper';
import { post, remove } from '../fetch-interceptor';
import { Dictionary } from '../entities/common';

export type ExtensionsState = IEntityStore<ExtensionInfo[]>;

type UpdatedExtensionAction = { type: 'UPDATE_EXTENSION', addOrUpdate?: ExtensionInfo[], remove?: string[];}
type DeletedExtensionAction = { type: 'DELETED_EXTENSION', extensions: ExtensionInfo[] }
type ProcessingExtensionAction = { type: 'PROCESSING_EXTENSIONS' }

type KnownAction = UpdatedExtensionAction | DeletedExtensionAction | ProcessingExtensionAction;

const initialState: ExtensionsState = {
    isLoading: false,
    isProcessing: false,
    entities: [],
    error: null
};

type BaseExtensionInfo = { name: string, url: string, type: ExtensionType };
export type ExtensionInfo = BaseExtensionInfo & { id: string};
export type SaveExtensionInfo = BaseExtensionInfo & { id?: string};

export enum ExtensionType {
    Task,
    QuickStart
}

export const actionCreators = {
    saveExtension: (extension: SaveExtensionInfo): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        post<ExtensionInfo[]>(`api/metadata/extensions`, extension)
            .then(data => dispatch({ type: 'UPDATE_EXTENSION', addOrUpdate: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'PROCESSING_EXTENSIONS'}));
    },
    removeExtension: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        remove<never>(`api/metadata/extensions`, { ids })
            .then(_ => dispatch({ type: 'UPDATE_EXTENSION', remove: ids }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'PROCESSING_EXTENSIONS'}));
    },
};

export const reducer: Reducer<ExtensionsState> = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "UPDATE_EXTENSION":
            return {
                ...state,
                ...initialState,
                entities: addOrUpdateOrRemove(state.entities, action.addOrUpdate, action.remove)
            };         
        case "PROCESSING_EXTENSIONS":
            return {
                ...state,
                isProcessing: true
            };
        default:
            return state;
    }
};