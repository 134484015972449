import { createStore, applyMiddleware, compose, combineReducers, StoreEnhancer, StoreEnhancerStoreCreator, ReducersMapObject, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { History } from 'history';
import * as StoreModule from './store';
import themeMiddleware from './store/middlewares/themeMiddleware';

export default function configureStore(history: History, initialState?: StoreModule.ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer<StoreModule.ApplicationState>;

    const middlewares = [thunk, routerMiddleware(history), themeMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer, devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next];
    const composedEnhancers = compose<StoreEnhancerStoreCreator>(...enhancers);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer((StoreModule.reducers) as any);
    return createStore(allReducers, initialState, composedEnhancers);
}

function buildRootReducer(allReducers: ReducersMapObject<StoreModule.ApplicationState, AnyAction>) {
    const appReducer = combineReducers<StoreModule.ApplicationState>(Object.assign({}, allReducers, { routing: routerReducer }));
    return (state: StoreModule.ApplicationState, action: AnyAction) => appReducer(StoreModule.rootReducer(state, action), action);
}
