import React from 'react';
import { IconButton } from 'office-ui-fabric-react';

type Props = {
    categoryName: string;
    categoryColor: string;
    onAddStatus: () => void;
};

const CategoryHeader = (props: Props) => {
    const { categoryName, categoryColor, onAddStatus } = props;
    return (
        <div className="align-center flex-space-between category-header">
            <div className="align-center">
                <div className="category-color" style={{ backgroundColor: categoryColor }}></div>
                <div className="category-name">{categoryName}</div>
            </div>
            <IconButton
                title="Add Status"
                menuIconProps={{ iconName: 'CircleAddition' }}
                onClick={onAddStatus} />
        </div>
    );
};

export default CategoryHeader;
