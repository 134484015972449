import * as React from "react";
import { Icon } from "office-ui-fabric-react";

interface IProps {
    disabled: boolean;
    votesCount: number;
    isVoted: boolean;
    onChange: (isVoted: boolean) => void;
}
export default class VotesToggle extends React.PureComponent<IProps> {
    public render() {
        const { disabled, votesCount, isVoted, onChange } = this.props;
        return <div
            title={disabled ? 'Voting is currently disabled. You can vote for the idea once it is moved to Active stage' : (isVoted ? "Click to un-like" : "Click to like")}
            className={`votes-toggle ${disabled ? 'disabled' : ''}`}
            onClick={disabled ? undefined : _ => onChange(!isVoted)}>
            <Icon iconName={isVoted ? 'LikeSolid' : 'Like'} className={disabled ? 'disabled' : ''} />
            <div className="title">{isVoted ? "Liked" : "Like"}</div>
            <div className="progress-tip">{votesCount}</div>
        </div>;
    }
}
