import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { Field } from "../../../../../entities/Metadata";
import CalculationFinancialsTotals, { CalculationFinancialsTotals as CalculationFinancialsTotalsHelper} from "../CalculationFinancialsTotals";

export default class BudgetTotals extends React.Component<IListViewFieldColumn<ProjectInfo>> {
    public render() {
        return <CalculationFinancialsTotals {...this.props} entity={this.props.entity.calculation.costs} />;
    }

    public static getValue(entity: ProjectInfo, field: Field): number|undefined {
        return CalculationFinancialsTotalsHelper.getValue(entity.calculation.costs, field);
    }
}