import "./SharePanel.css"
import * as React from "react";
import { Persona, PersonaSize, Checkbox, IconButton, Icon } from "office-ui-fabric-react";
import { LicenseType, LicenseTypeMap } from "../../../store/permissions";
import { getPersonInfoImageUrl } from "../../utils/common";
import { EntityType, IUserInfo } from "../../../entities/common";
import { IUser } from "../../../store/UsersListStore";
import { LayoutService } from "../../utils/LayoutService";
import { LayoutsState } from "../../../store/layouts";
import { Layout } from "../../../entities/Metadata";
import { EntityViewSelector } from "../EntityViewSelector";

type Props = {
    hideView?: boolean;
    hideCollaborate?: boolean;
    layouts?: LayoutsState;
    isPrivate?: boolean;
    entityType: EntityType;
    entityTypeLabel: string;
    filteredItems: SharedWith[];
    manager: IUserInfo[];
    initialUsers: string[];
    onChange: (item: SharedWith, delta: Partial<SharedWith>) => void;
    onDelete: (item: SharedWith) => void;
}

export type SharedWith = {
    user: IUser;
    edit: Operation;
    collaborate: Operation;
    view: Operation;
    profileLayoutId?: string;
    layoutId?: string;
}
type Operation = { isGlobal?: boolean; isAvailable?: boolean; isInherited?: boolean; };

const ShareUserList = (props: Props) => {
    const { hideView, hideCollaborate, filteredItems, layouts, manager, initialUsers, entityTypeLabel, entityType, onChange, onDelete, isPrivate } = props;

    const getCbViewTitle = (isManager: boolean, _: SharedWith, entityTypeLabel: string, entityType: EntityType, isPrivate?: boolean): string | undefined => {
        return isManager
            ? undefined
            : _.view.isGlobal && !_.view.isInherited
                ? isPrivate
                    ? `The user has Administrate app and View All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                    : `The user has View All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                : _.view.isInherited
                    ? entityType === EntityType.Project
                        ? `The project is included to the Program / Portfolio this user has permissions to view. Inherited permissions cannot be changed.`
                        : `Inherited permissions cannot be changed.`
                    : undefined;
    }

    const getCbCollaborateTitle = (isManager: boolean, _: SharedWith, entityTypeLabel: string, entityType: EntityType, isPrivate?: boolean): string | undefined => {
        return isManager
            ? undefined
            : _.collaborate.isGlobal && !_.collaborate.isInherited
                ? isPrivate
                    ? `The user has Administrate app and Collaborate on All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                    : `The user has Collaborate on All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                : _.view.isInherited
                    ? entityType === EntityType.Project
                        ? `The project is included to the  Program / Portfolio this user has permissions to collaborate on. Inherited permissions cannot be changed.`
                        : `Inherited permissions cannot be changed.`
                    : undefined;
    }

    const getCbEditTitle = (isViewer: boolean, _: SharedWith, entityTypeLabel: string, isManager: boolean, entityType: EntityType, isPrivate?: boolean): string | undefined => {
        return isViewer
            ? `This user has '${LicenseTypeMap[_.user.license].label}' license type and cannot edit ${entityTypeLabel}s.`
            : isManager
                ? undefined
                : _.edit.isGlobal && !_.edit.isInherited
                    ? isPrivate
                        ? `The user has Administrate app and Edit All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                        : `The user has Edit All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                    : _.edit.isInherited
                        ? entityType === EntityType.Project
                            ? `The project is included to the  Program / Portfolio this user has permissions to view and edit. Inherited permissions cannot be changed.`
                            : `Inherited permissions cannot be changed.`
                        : undefined;
    }


    return <div className="share-with-list with-top-margin"><table>
        <thead>
            <tr>
                <th></th>
                {!hideView && <th>View</th>}
                {!hideCollaborate && <th className="pl-1">Collaborate</th>}
                <th className="pl-1">Edit</th>
                {layouts && <th>Default View</th>}
                <th></th>
            </tr>
        </thead>
        <tbody>
            {filteredItems
                .map(_ => {
                    const key = _.user.id || _.user.email;
                    const isViewer = _.user.license == LicenseType.Viewer;
                    const isManager = !!manager.find(m => m.id == _.user.id);
                    const text = _.user.id ? _.user.fullName : 'Invite ' + _.user.fullName;
                    const viewDisabled = isManager || _.view.isInherited || _.view.isGlobal || _.collaborate.isAvailable || _.edit.isAvailable;
                    const collaborateDisabled = isManager || _.collaborate.isInherited || _.collaborate.isGlobal || _.edit.isAvailable;
                    const editDisabled = isManager || _.edit.isInherited || _.edit.isGlobal || isViewer;
                    const isNew = !_.user.id || initialUsers.every(id => id !== _.user.id);
                    return <tr key={key} title={isManager
                        ? isViewer
                            ? `The user is a Manager of this ${entityTypeLabel}. Managers can view their ${entityTypeLabel}s by default. `
                            : `The user is a Manager of this ${entityTypeLabel}. ${entityTypeLabel} Managers can view and edit their ${entityTypeLabel}s by default.`
                        : undefined}>
                        <td>
                            <div className="user-info-content">
                                <Persona
                                    size={PersonaSize.size28}
                                    text={text}
                                    className="ellipsis"
                                    title={_.user.fullName}
                                    imageUrl={_.user.id ? getPersonInfoImageUrl(_.user) : undefined}
                                    onRenderInitials={_.user.id ? undefined : () => { return <Icon iconName="PPMXPeopleInvite" />; }}
                                />
                                {isNew && <span className='green-label'>New</span>}
                            </div>
                        </td>
                        {!hideView && <td>
                            <Checkbox
                                title={viewDisabled ? getCbViewTitle(isManager, _, entityTypeLabel, entityType, isPrivate) : undefined}
                                disabled={viewDisabled}
                                checked={!!_.view.isAvailable || !!_.collaborate.isAvailable || !!_.edit.isAvailable}
                                onChange={(e, c) => onChange(_, { view: { ..._.view, isAvailable: c } })}
                            />
                        </td>}
                        {!hideCollaborate && <td className="pl-1">
                            <Checkbox
                                title={collaborateDisabled ? getCbCollaborateTitle(isManager, _, entityTypeLabel, entityType, isPrivate) : undefined}
                                disabled={collaborateDisabled}
                                checked={!!_.collaborate.isAvailable || !!_.edit.isAvailable}
                                onChange={(e, c) => onChange(_, { collaborate: { ..._.collaborate, isAvailable: c } })}
                            />
                        </td>}
                        <td className="pl-1">
                            <Checkbox
                                title={editDisabled ? getCbEditTitle(isViewer, _, entityTypeLabel, isManager, entityType, isPrivate) : undefined}
                                disabled={editDisabled}
                                checked={!!_.edit.isAvailable}
                                onChange={(e, c) => onChange(_, { edit: { ..._.edit, isAvailable: c } })}
                            />
                        </td>
                        {layouts && <td className="default-view pl-1">
                            <EntityViewSelector
                                readOnly={isManager}
                                layoutsState={layouts}
                                onChange={(layout?: Layout) => onChange(_, { layoutId: layout?.id })}
                                selectedKey={_.layoutId}
                                hideLabel
                                entityLayout={{
                                    key: LayoutService.DefaultLayoutId,
                                    title: 'Overwrite profile view with default configuration',
                                    text: 'Default'
                                }}
                                profileLayoutId={_.profileLayoutId} />
                        </td>}
                        <td>
                            <IconButton
                                iconProps={{ iconName: "Delete" }}
                                className="delete-btn"
                                disabled={!!_.user.id && (isManager || _.view.isInherited || _.edit.isInherited || _.view.isGlobal || _.edit.isGlobal)}
                                onClick={() => onDelete(_)} />
                        </td>
                    </tr>
                })}
        </tbody>
    </table></div>
}

export default ShareUserList;

