import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Field, FieldType, FormatType } from '../../../../../entities/Metadata';
import { DisplayFieldService } from '../../../../common/DisplayFieldService';
import { ITask } from '../../../../../entities/Subentities';

const componentPath = "task/Baseline";
export const BaselineFakeFields: Field[] = [{
    id: "23bf6fec-27f1-4714-8454-9981eb6c4db0",
    label: "Baseline Start Date",
    name: "startDate",
    type: FieldType.Date,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } } }
}, {
    id: "d500725a-cdad-4f30-a313-79a1a3e2fd23",
    label: "Baseline Due Date",
    name: "dueDate",
    type: FieldType.Date,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } } }
}, {
    id: "829a2360-7e61-485b-bd48-3cfe9fb17ac9",
    label: "Baseline Duration",
    name: "duration",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Days }
}, {
    id: "d196ae2b-3241-4039-8405-48d668d83688",
    label: "Baseline Effort",
    name: "effort",
    type: FieldType.Decimal,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Duration }
}, {
    id: "11fdca60-a55a-42af-9ef3-b07640d1b28e",
    label: "Baseline Original Estimate",
    name: "originalEstimate",
    type: FieldType.Decimal,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Duration }
}, {
    id: "df94c687-cd3b-4a69-b82e-cd7b02d5fd43",
    label: "Start Date Variance",
    name: "startDateVariance",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Days }
}, {
    id: "e86f259a-fd5a-4e85-8f6b-01249866c273",
    label: "Due Date Variance",
    name: "dueDateVariance",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Days }
}, {
    id: "2bc7e1a7-09d8-4f93-9a53-f4b42025805e",
    label: "Duration Variance",
    name: "durationVariance",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Days }
}, {
    id: "af3053f5-ffa0-4757-8013-8d2fd5b2a8dc",
    label: "Original Estimate Variance",
    name: "originalEstimateVariance",
    type: FieldType.Decimal,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Duration }
}, {
    id: "332d2d1f-6267-41fd-8e5d-12ca5124af1b",
    label: "Effort Variance",
    name: "effortVariance",
    type: FieldType.Decimal,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Duration }
}];

export default class Baseline extends React.Component<IListViewFieldColumn<ITask>> {
    render() {
        const { field, entity } = this.props;
        const value = entity.baseline?.[field.name];
        const Formatter = DisplayFieldService.getFieldFormatter(field, false);
        if (Formatter) {
            return <Formatter value={value} />;
        }
        return <>{value !== undefined && value !== null ? `${value}` : ''}</>;
    }
}
export function getValue(entity: ITask, field: Field): string {
    return entity.baseline?.[field.name];
}