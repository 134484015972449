import { DefaultButton, IContextualMenuItem } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { IWithFilter, WithFilterProps, withFilter } from '../common/withFilter';
import { Dictionary, EntityType, IExtensibleEntity, ITimeframe, Quantization, SortDirection, UserPreferencesSettingsUpdate } from '../../entities/common';
import { FilterHelper as ProjectFilterHelper } from '../../store/project/filters';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as Metadata from '../../entities/Metadata';
import * as FiltersStore from '../../store/filters';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import { defaultCatch, mergeDefault } from '../../store/utils';
import { useColumnResize, useScale, useSorting  } from '../../store/services/viewSaver';
import { nameof } from '../../store/services/metadataService';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import * as ReportedTimeStore from '../../store/ReportedTimeStore';
import { CommonOperations, ResourceOperations, contains } from "../../store/permissions";
import { bindActionCreators } from 'redux';
import EntityTimelineList from '../common/extensibleEntity/EntityTimelineList';
import Spinner from '../common/Spinner';
import { ScaleRenderMode } from '../common/timeline/TimelineBody';
import { cancellablePost, post } from '../../fetch-interceptor';
import { distinct, distinctBy, formatValue, notUndefined, toDate, toDateTime, toNameDictionary } from '../utils/common';
import { IRow } from '../common/timeline/TimelineList';
import { ViewService } from '../../services/ViewService';
import ReportedTimeGridMenu from './ReportedTime/ReportedTimeGridMenu';
import ReportedTimeCell from './ReportedTime/ReportedTimeCell';
import "../../components/timeTracking/timeTrackingGrid.css";
import TimeTrackingAdministrativeCategoryEntityName from '../timeTracking/TimeTrackingAdministrativeCategoryEntityName';
import AddEntitiesDialog from '../common/sectionsControl/uiControls/projectsControl/AddEntitiesDialog';
import PersonPickerInput, { PersonInfo } from '../common/inputs/PersonPickerInput';
import { getWorkingHoursBetweenDates } from '../common/timeline/utils';
import { CalendarDataSet } from '../../store/CalendarStore';
import { TimeTrackingAdministrativeCategory } from '../../store/Tenant';

type OwnProps = {
    resourceIds: string[];
};

type StoreProps = {
    resourceFields: Metadata.Field[];
    projectFields: Metadata.Field[];
    entityLayouts: Metadata.Layout[];
    isFiltersDisabled: boolean;
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    canManageConfiguration: boolean;
    portfolios: PortfoliosListStore.Portfolio[];
    programs: ProgramsListStore.Program[];

    controlSetting: any;
    isLoading: boolean;
    resourcesMap: Dictionary<ResourcesListStore.Resource>;
    globalCalendar: CalendarDataSet;
    administrativeCategories: TimeTrackingAdministrativeCategory[];
};

type ActionProps = {
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    reportedTimeActions: typeof ReportedTimeStore.actionCreators;
};

type InnerProps = OwnProps & StoreProps & ActionProps & RouteComponentProps<{}> & {
    projects: ReportedTimeProjectOrAdministrativeCategory[];
    reportedTime: ReportedTimeStore.ReportedResourceProjectTime[];
    isEntitiesLoading: boolean;
    controlSettings: ControlSettings;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate) => void;
    loadEntities: (resourceIds: string[]) => void;
} & IWithFilter<ReportedTimeProjectOrAdministrativeCategory>;

type WithEntityType = {
    entityType: EntityType;
}

type WithReportedTime = {
    reportedTime: ReportedTimeStore.ReportedResourceProjectTime[];
}

const _initialTimeFrame: ITimeframe = new Date().getThisMonth();

const _totalReportedFieldName = "TotalReported";

type ControlSettings = ReportedTimeStore.ReportedTimeSettings;

const defaultControlSettings: ControlSettings = {
    displayFields: [nameof<ResourcesListStore.ResourceAttrs>("Name"), _totalReportedFieldName],
    grouping: 'Resource',
    quantization: Quantization.days,
    sortBy: {
        fieldName: "Name", direction: SortDirection.ASC
    },
    timeframe: _initialTimeFrame
}

function ReportedTimeList(props: InnerProps) {

    const { projects, controlSettings, onSaveSettings, filter, resourceFields, projectFields, globalCalendar, isEntitiesLoading,
        resourcesMap, resourceIds, isLoading, reportedTime, history, loadEntities } = props;

    const [isAddingResource, setIsAddingResource] = useState(false);

    const filteredProjects = projects.filter(filter.isItemVisible);

    const resources = resourceIds.map(_ => resourcesMap[_]);

    const nameField = resourceFields.find(_ => _.name === "Name");

    const saverProps = {
        ...useColumnResize(controlSettings, onSaveSettings),
        ...useScale(controlSettings, onSaveSettings),
        sorting: useSorting(controlSettings, onSaveSettings),
    }
    
    let entities: IExtensibleEntity[];

    if (controlSettings.grouping === 'Project') {
        entities = filteredProjects;
    }
    else {
        entities = resources;
    }

    return (
        <div className="resource-reported-time">
            <div className="page-navigation">
                <DefaultButton text="Back to Resources" iconProps={{ iconName: "Back" }} href="/resources" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.replace("/resources");
                }} />
            </div>
            <h2>Resource Reported Time</h2>
            <div className="entities-list">
                {
                    !resources.length || isLoading || isEntitiesLoading
                        ? <Spinner />
                        :
                        <div>
                            <ReportedTimeGridMenu
                                entityType={EntityType.Resource}
                                mandatoryViewFields={ViewService.getViewMandatoryFields(EntityType.Resource)}
                                fields={[...resourceFields, _totalReportedField]}
                                type={"Columns"}
                                displayFields={controlSettings.displayFields}
                                canViewConfigureColumns
                                onDisplayFieldsChange={_ => onSaveSettings?.({
                                    parentSettingsPathKeys: [],
                                    valueBySettingNameMap: {
                                        [nameof<ControlSettings>('displayFields')]: _
                                    }
                                })}
                                commands={[{
                                    key: 'addRow',
                                    name: 'Add Resource',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => setIsAddingResource(true)
                                }]}
                                farCommands={[
                                    {
                                        key: 'grouping',
                                        name: `Group by: ${controlSettings.grouping}`,
                                        iconProps: { iconName: 'ViewListGroup' },
                                        subMenuProps: {
                                            items: ['Resource' as ReportedTimeStore.Grouping, 'Project' as ReportedTimeStore.Grouping].map<IContextualMenuItem>(_ => ({
                                                key: _,
                                                name: _,
                                                canCheck: true,
                                                checked: controlSettings.grouping === _,
                                                onClick: () => onSaveSettings?.({
                                                    parentSettingsPathKeys: [],
                                                    valueBySettingNameMap: { [nameof<ControlSettings>('grouping')]: _ }
                                                })
                                            }))
                                        }
                                    }
                                ]}
                                {...filter.menu} />
                        
                            <div data-is-scrollable="true" className='entities-list-body'>
                                <EntityTimelineList
                                    key="timeline"
                                    fields={[...resourceFields, _totalReportedField]}
                                    displayFields={controlSettings.displayFields}
                                    entities={entities}
                                    buildTree
                                    entityType={EntityType.Resource}
                                    scaleMultiplier={2.5} //cell width,                              
                                    isFieldFake={_ => !!_.isFake}
                                    renderHeaderCellContent={_ => _.key === nameField?.id ? <>Resource / Project</> : undefined}
                                
                                    buildRow={(groupingEntity: IExtensibleEntity) => {

                                        if (controlSettings.grouping === 'Project') {
                                            return _buildProjectGroup(groupingEntity, resourcesMap, reportedTime);
                                        }

                                        return _buildResourceGroups(groupingEntity, reportedTime, filteredProjects);
                                    }}
                                    scaleRenderMode={ScaleRenderMode.Cell}
                                    renderSegmentContent={(row, segment) => {

                                        const entity = row.entity as any as (WithEntityType & WithReportedTime);
                                        const reportedTime = entity.reportedTime as ReportedTimeStore.ReportedResourceProjectTime[];

                                        const isResourceTotalRow = controlSettings.grouping === 'Resource' && entity.entityType === EntityType.Resource;

                                        const isTotal = isResourceTotalRow
                                            || controlSettings.grouping === 'Project' && entity.entityType === EntityType.Project;
                                    
                                    
                                        let expected: number | undefined;
                                    
                                        if (isResourceTotalRow) {
                                            const resource = row.entity as ResourcesListStore.Resource;
                                            const resourceCalendarDataSet = ResourcesListStore.createResourceCalendar(resource, globalCalendar);
                                            expected = getWorkingHoursBetweenDates(
                                                segment.startDate,
                                                segment.finishDate,
                                                resourceCalendarDataSet
                                            );
                                        }

                                        return <ReportedTimeCell reportedTime={reportedTime} segment={segment} isTotal={isTotal} expected={expected} />
                                    }}
                                    {...saverProps}
                                    onItemRender={(entity: WithEntityType & ReportedTimeProjectOrAdministrativeCategory, _, field, defaultRender) => {
                                  
                                        if (field.id === _totalReportedField.id) {
                                            const totalReported = (field as Metadata.CalculatedField).calculate(entity);
                                            return (<div className="total-reported">{formatValue(totalReported, Metadata.FormatType.Duration)}</div>);
                                        }

                                        if (entity.entityType === EntityType.Project) {
                                        
                                            if (entity.IsAdministrativeCategory && field.name === nameField?.name) {
                                        
                                                return <TimeTrackingAdministrativeCategoryEntityName categoryId={entity.id} />
                                            }
                                        

                                            const entityColumn = ViewService.buildColumn(field.name, toNameDictionary(projectFields), undefined, true, EntityType.Project);
                                            return entityColumn ? entityColumn.onRender?.(entity, undefined, entityColumn) : null;
                                        }
                                    
                                        const resourceColumn = ViewService.buildColumn(field.name, toNameDictionary(resourceFields), undefined, true, EntityType.Resource);
                                        return resourceColumn ? resourceColumn.onRender?.(entity, undefined, resourceColumn) : null;
                                    }}
                                />
                            </div>
                        </div>
                    
                }

                {isAddingResource && <AddEntitiesDialog
                    confirmButtonText="Add Resources"
                    dialogContentProps={{
                        title: 'Add Resources',
                        subText: 'Select resources to view reported time'
                    }}
                    onDismiss={() => setIsAddingResource(false)}
                    onComplete={(newResourceIds) => {
                        loadEntities(newResourceIds);
                        setIsAddingResource(false);
                    }}
                    renderEntitySelector={(onChanged) => (
                        <PersonPickerInput
                            multichoice={true}
                            onChanged={(value) => value && onChanged((value as PersonInfo[]).map(_ => _.id))}
                            searchUrl="api/resource/find"
                            filter={{ exceptIds: resourceIds }}
                            inputProps={{ placeholder: "Type to search", autoFocus: true }}
                        />
                    )}
                />}
            </div>
        </div>
    );
}

const _totalReportedField: Metadata.CalculatedField = {
    id: "totalReported",
    name: _totalReportedFieldName,
    type: Metadata.FieldType.Text,
    isNative: false,
    isSystem: false,
    isCustom: false,
    label: "Total Reported",
    isReadonly: true,
    isFake: true,
    calculate: (item: WithReportedTime) => {
        return item.reportedTime.reduce((sum, current) => sum + current.duration, 0);
    }
};

const _buildResourceGroups = (
    groupingEntity: IExtensibleEntity,
    reportedTime: ReportedTimeStore.ReportedResourceProjectTime[],
    filteredProjects: ReportedTimeProjectOrAdministrativeCategory[]) => {

    const resourceReportedTime = reportedTime.filter(_ => _.resource.id === groupingEntity.id);
    const resourceProjectsIds = resourceReportedTime.map(_ => _.project?.id || _.administrativeCategory).filter(distinct);

    const subItems: IRow[] = resourceProjectsIds
        .map(_ => filteredProjects.find(p => p.id === _))
        .filter(notUndefined)
        .map(project => {

            return {
                key: `${groupingEntity.id}_${project.id}`,
                entity: {
                    ...project,
                    entityType: EntityType.Project,
                    reportedTime: resourceReportedTime.filter(__ => __.administrativeCategory === project.id || __.project?.id === project.id),
                },
                segments: [],
                markers: []
            };
        });
       
    return {
        key: groupingEntity.id,
        entity: {
            ...groupingEntity,
            entityType: EntityType.Resource,
            reportedTime: resourceReportedTime,
        },
        markers: [],
        segments: [],
        subItemType: EntityType.Project,
        subItems: subItems
    };
}

const _buildProjectGroup = (
    groupingEntity: IExtensibleEntity,
    resourceMaps: Dictionary<ResourcesListStore.Resource>,
    reportedTime: ReportedTimeStore.ReportedResourceProjectTime[]) => {

    const projectReportedTime = reportedTime.filter(_ => _.project?.id === groupingEntity.id || _.administrativeCategory === groupingEntity.id);
    const projectResourceIds = distinctBy(projectReportedTime.map(_ => _.resource.id), _ => _);

    const projectResources = projectResourceIds.map(_ => resourceMaps[_]);

    const subItems: IRow[] = projectResources.map(resource => {
        return {
            key: `${groupingEntity.id}_${resource.id}`,
            entity: {
                ...resource,
                entityType: EntityType.Resource,
                reportedTime: projectReportedTime.filter(_ => _.resource.id === resource.id),
            },
            segments: [],
            markers: []
        };
    });
       
    return {
        key: groupingEntity.id,
        entity: {
            ...groupingEntity,
            entityType: EntityType.Project,
            reportedTime: projectReportedTime,
        },
        markers: [],
        segments: [],
        subItemType: EntityType.Resource,
        subItems: subItems
    };
}

type ReportedTimeProjectOrAdministrativeCategory = ProjectsListStore.ProjectInfo & {
    IsAdministrativeCategory?: boolean;
}

const useFilterProps = ({ projects, projectFields, portfolios, programs, entityLayouts, isFiltersDisabled,
    filters, filtersActions, canManageConfiguration, history, location, controlSettings, onSaveSettings }: InnerProps)
    : WithFilterProps<ProjectsListStore.ProjectInfo> => {
    
    const projectsInfo = projects.map(_ => _ as unknown as ProjectsListStore.ProjectInfo);
    const filterHelper = new ProjectFilterHelper({ projectFields, layouts: entityLayouts, portfolios, programs, projects: projectsInfo });

    return {
        filterHelper,
        entityType: EntityType.Project,
        disabled: isFiltersDisabled ? `Project filter is available for users with "View all" Projects permission.` : undefined,
        filters,
        filtersActions,
        canManageConfiguration,
        entities: projectsInfo,
        fields: projectFields,
        history,
        location,
        controlSettings,
        onSaveSettings
    }
}

const WithFilter = withFilter(ReportedTimeList, useFilterProps, FiltersStore.FilterKeys.ReportedTime)

type Props = OwnProps & StoreProps & ActionProps & RouteComponentProps<{}>;

const ReportedTimeListWrapper = (props: Props) => {

    const { administrativeCategories } = props;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [entities, setEntities] = React.useState<ReportedTimeStore.ReportedResourceProjectTime[]>([]);
    const [resourceIds, setResourceIds] = useState(props.resourceIds);

    const [projects, setProjects] = useState<ProjectsListStore.ProjectInfo[]>([]);

    useEffect(() => {
        props.reportedTimeActions.loadSettings();
    }, []);

    const controlSettings = mergeDefault<ControlSettings>(defaultControlSettings, props.controlSetting);
    controlSettings.timeframe!.start ??= _initialTimeFrame.start;
    controlSettings.timeframe!.end ??= _initialTimeFrame.end;

    const start = controlSettings.timeframe!.start;
    const end = controlSettings.timeframe!.end;

    useEffect(() => {

        if (props.isLoading) {
            return;
        }

        setLoading(true);

        const param = {
            start: toDateTime(start)!.toDateOnlyString(),
            end: toDateTime(end)!.toDateOnlyString(),
            resourceIds: resourceIds
        };

        const request = cancellablePost<ReportedTimeStore.ReportedResourceProjectTime[]>(`api/reported-time`, param);

        request.promise.then(data => {
            data.forEach(_ => {
                _.date = toDate(_.date)!
            });
            setEntities(data);

            setLoading(false);
        })
            .catch(defaultCatch());
        
        return () => request.cancelTokenSource.cancel();

    }, [
        props.isLoading,
        resourceIds.join(""),
        toDateTime(start)!.toDateOnlyString(),
        toDateTime(end)!.toDateOnlyString()]);
    
    useEffect(() => {

        let manualProjects: ReportedTimeProjectOrAdministrativeCategory[] = entities.map(_ => {
            const manualProject = _.project
                ? {
                    id: _.project.id,
                    attributes: {
                        Name: _.project.name
                    }
                }
                : {
                    id: _.administrativeCategory,
                    attributes: {
                        Name: administrativeCategories.find(ac => ac.id === _.administrativeCategory)?.title ?? `Administrative category ${_.administrativeCategory}`
                    },
                    IsAdministrativeCategory: true
                };
            
            return manualProject as any;
        });

        manualProjects = distinctBy(manualProjects, _ => _.id);

        const missedManualProjects = manualProjects.filter(_ => !projects.some(p => p.id === _.id));

        if (missedManualProjects.length) {

            let newProjects = [...projects, ...missedManualProjects];

            setProjects(newProjects);

            const projectIdsToLoad = missedManualProjects.filter(_ => !_.IsAdministrativeCategory).map(_ => _.id);

            if (projectIdsToLoad.length) {

                post<ProjectsListStore.ProjectInfo[]>(`api/reported-time/projects`, {
                    ids: projectIdsToLoad
                })
                    .then(data => {
                
                        if (data.length) {
                            newProjects = newProjects.map(_ => data.find(p => p.id === _.id) || _);
                            setProjects(newProjects);
                        }
                    })
                    .catch(defaultCatch());
            }
        }

    }, [entities]);

    const listProps = {
        ...props,
        reportedTime: entities,
        projects: projects,
        isEntitiesLoading: loading,
        controlSettings: controlSettings,
        onSaveSettings: props.isLoading
            ? undefined
            : props.reportedTimeActions.saveSettings,
        loadEntities: (_: string[]) => { setResourceIds([...resourceIds, ..._]) }
    }

    return <WithFilter {...listProps} resourceIds={resourceIds} />;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {

    const layouts = state.layouts[EntityType.Project];
    const resourceFields = state.fields[EntityType.Resource];
    const projectFields = state.fields[EntityType.Project];

    const filters = FiltersStore.getFilter(state.filters, EntityType.Project);

    const isFiltersDisabled = !contains(state.user.permissions.project.global, ResourceOperations.Read);

    return {
        controlSetting: state.reportedTime.settings,
        isLoading: state.reportedTime.isLoading,
        entityLayouts: layouts.allIds.map(_ => layouts.byId[_]),
        resourceFields: resourceFields.allIds.map(_ => resourceFields.byId[_]),
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
        filters: filters.all,
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        isFiltersDisabled: isFiltersDisabled,
        canManageConfiguration: contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
        resourcesMap: { ...state.resources.byId },
        globalCalendar: state.calendar,
        administrativeCategories: state.tenant.timeTracking.administrativeCategories
    };
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps): ActionProps {
    return {
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Project, FiltersStore.FilterKeys.ReportedTime), dispatch),
        reportedTimeActions: bindActionCreators(ReportedTimeStore.actionCreators, dispatch),
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(ReportedTimeListWrapper));