import * as React from 'react';
import { IPersonaProps, IBasePicker, Autofill, TooltipHost, TooltipDelay } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import InputErrorMessage from "./inputErrorMessage";
import { getPersonInfoImageUrl, notUndefined } from '../../../components/utils/common';
import { Validator } from "../../../validation";
import PPMXPeoplePicker from "./PPMXPeoplePicker";

type Props = IFormInputProps<PersonInfo | PersonInfo[], Autofill> & {
    multichoice?: boolean;
    onBlur?: () => void;
    buildSecondaryText?: (persona: PersonInfo) => string | null | undefined;
    autoExpand?: boolean;
    users: PersonInfo[];
};

type State = {
    value?: PersonInfo | PersonInfo[];
    personas: IPersonaProps[];
}

const validator = Validator.new().person().build();

export const PersonFromListPickerInput = (props: Props) => {

    const { readOnly, multichoice, inputProps, className, autoExpand } = props;

    //props.inputRef = React.useRef<HTMLInputElement>(null);
    const picker = React.createRef<IBasePicker<IPersonaProps>>();

    const _getPersona = (info: PersonInfo): IPersonaPropsWithOrigin => {
        const formatArray = (arr: string[] | undefined | null) => arr && !!arr.length ? `${arr[0]}${(arr.length > 1 ? (` (+${arr.length - 1})`) : "")}` : undefined;

        return {
            id: info.id ?? info.email,
            text: info.fullName,
            imageUrl: getPersonInfoImageUrl(info),
            onRenderSecondaryText: props.buildSecondaryText
                ? undefined
                : (_: IPersonaPropsWithOrigin) => <TooltipHost
                    tooltipProps={{
                        maxWidth: "454px",
                        onRenderContent: () => <div>
                            {_.origin.role && <div><b>Role:</b> {_.origin.role}</div>}
                            {_.origin.skills && <div><b>Skills:</b> {_.origin.skills.join(', ')}</div>}
                            {_.origin.tags && <div>{_.origin.tags.join(' ')}</div>}
                        </div>
                    }}
                    closeDelay={10}
                    delay={TooltipDelay.long}>
                    {_.secondaryText}
                </TooltipHost>,
            secondaryText: (props.buildSecondaryText
                ? props.buildSecondaryText(info)
                : [info.role || undefined, formatArray(info.skills), formatArray(info.tags)].filter(notUndefined).join(' | ')) || undefined,
            origin: info
        };
    }

    const _toPersonasList = (value: PersonInfo | PersonInfo[] | undefined | null, multichoice?: boolean): IPersonaProps[] => {
        let personas: IPersonaProps[] = [];
        if (value) {
            if (multichoice) {
                if (Array.isArray(value)) {
                    personas = value.map(_ => _getPersona(_));
                }
            } else {
                let persona = validator.isValid(value) ? _getPersona(value as PersonInfo) : undefined;
                if (persona) {
                    personas.push(persona);
                }
            }
        }
        return personas;
    }

    const [state, setState] = React.useState<State>({
        value: props.value,
        personas: _toPersonasList(props.value, props.multichoice)
    })

    const _areEqual = (item1?: PersonInfo, item2?: PersonInfo): boolean => {
        return item1?.id == item2?.id && item1?.email == item2?.email;
    }

    const _onFilterChanged = (filterText: string, currentPersonas?: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
        filterText = filterText?.toLocaleLowerCase();
        const list = props.users.filter(_ => (Array.isArray(state.value) ? state.value.every(__ => !_areEqual(__, _)) : !_areEqual(state.value, _)) &&
            (!filterText ||
            _.fullName?.toLocaleLowerCase().indexOf(filterText) !== -1 ||
            _.email?.toLocaleLowerCase().indexOf(filterText) !== -1))
            .map(_getPersona);
        return Promise.resolve(list);
    }

    const _getPersonInfo = (persona?: IPersonaPropsWithOrigin): PersonInfo | null => {
        if (!persona) {
            return null;
        }
        return persona.origin;
    }

    const _focus = () => {
        picker.current!.focusInput();
    }
    const _getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    }

    const _onChange = (items?: IPersonaPropsWithOrigin[]): void => {
        let value: PersonInfo | PersonInfo[] | undefined = undefined;
        if (props.multichoice) {
            if (items) {
                value = items.map(_ => _getPersonInfo(_)!);
            }
        } else {
            value = _getPersonInfo(items && items.length ? items[items.length - 1] : undefined) ?? undefined;
        }
        setState({
            value: value,
            personas: _toPersonasList(value, props.multichoice)
        });

        if (props.onChanged) {
            props.onChanged(value);
        }

        if (props.onEditComplete && _validate(value)) {
            props.onEditComplete(value || null);
        }

        _focus();
    }

    const _validate = (value?: PersonInfo | PersonInfo[] | null) => {
        return props.validator == undefined || props.validator.isValid(value);
    }

    const _getErrorMessage = (value?: PersonInfo | PersonInfo[] | null) => {
        return props.validator && props.validator.getErrorMessage(value);
    }

    return <>
        <div className={`person-picker ${multichoice ? 'multichoice' : ''}`} onClick={_focus}>
            {<PPMXPeoplePicker
                disabled={props.disabled}
                inputProps={inputProps}
                onBlur={props.onBlur}
                readOnly={readOnly}
                selectedItems={state.personas}
                onResolveSuggestions={_onFilterChanged}
                getTextFromItem={_getTextFromItem}
                className={`ms-PeoplePicker ${className || ""}`}
                componentRef={picker}
                onChange={readOnly ? undefined : _onChange}
                autoExpand={autoExpand} />}
        </div>
        <InputErrorMessage text={_getErrorMessage(state.value)} />
    </>
}

interface IPersonaPropsWithOrigin extends IPersonaProps { origin: PersonInfo; }

export type PersonInfo = {
    id: string;
    fullName: string;
    imageId?: string;
    role?: string | null;
    tags?: string[] | null;
    skills?: string[] | null;
    email?: string | null;
    logonAccount?: string | null;
}