import { ContextualMenuItemType, DirectionalHint, IconButton, IContextualMenuItem, IContextualMenuListProps } from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { TenantState } from '../../store/Tenant';
import ReportsPlaceholder from './ReportsPlaceholder';
import { ReportNav } from '../utils/reporting';

type OwnProps = {
    reports: ReportNav[];
    onClick: (report: ReportNav) => void;
    items: IContextualMenuItem[];
}

type StateProps = { tenant: TenantState }
type Props = OwnProps & StateProps;
class MenuWithReport extends React.Component<Props, { reportItem: IContextualMenuItem }> {
    constructor(props: Props) {
        super(props);
        const reportItem: IContextualMenuItem = {
            key: 'reports',
            name: 'Reports',
            iconProps: { iconName: "FabricReportLibrary" },
            ...(props.reports.length > 0
                ? {
                    title: props.tenant.reporting.error,
                    disabled: !!props.tenant.reporting.error,
                    subMenuProps: {
                        items: props.reports.map(_ => (
                            {
                                key: _.id,
                                name: _.title,
                                iconProps: { iconName: "FileSymlink" },
                                onClick: () => this.props.onClick(_)
                            }))
                    }
                }
                : {
                    subMenuProps: {
                        items: [{ key: "" }],
                        onRenderMenuList: () => <ReportsPlaceholder />
                    }
                })
        };

        this.state = { reportItem }
    }
    
    public render() {
        const { items } = this.props;
        const { reportItem } = this.state;
        return <div className="menu">
            <IconButton
                menuIconProps={{ iconName: 'PPMXMore' }}
                menuProps={{
                    directionalHint: DirectionalHint.bottomRightEdge,
                    items: items.length
                        ? [reportItem, { key: 'divider_1', itemType: ContextualMenuItemType.Divider }].concat(items)
                        : [reportItem]
                }}
            />
        </div>;
    }
}

function mapStateToProps(state: ApplicationState) {
    return { tenant: state.tenant }
}

export default connect(mapStateToProps, {})(MenuWithReport);