import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdown, Icon } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { StatusCategoryConfigsList, StatusCategory } from '../../../entities/common';
import ColorStatusDropdownOption, { DropdownOptionData } from './ColorStatusDropdownOption';
import { CategoryStatusOption } from '../../../entities/StatusDescriptor';
import * as StatusDescriptorFactory from '../../../entities/StatusDescriptorFactory';
import { Field } from '../../../entities/Metadata';

type ColorStatusDropdownInputProps = IFormInputProps<string | number> & {
    statusField?: Field;
    options?: CategoryStatusOption[];
};

export default class ColorStatusDropdownInput extends React.Component<ColorStatusDropdownInputProps> implements IFormInputComponent {
    private _dropdown = React.createRef<IDropdown>();

    componentDidMount() {
        this.props.inputRef?.(this);
    }

    public render(): JSX.Element {
        const { inputProps, disabled, readOnly } = this.props;
        const value = this.props.value ?? this._getDefaultValue();
        
        const isReadOnly = readOnly ?? inputProps?.readOnly;
        const isDisabled = disabled ?? inputProps?.disabled;
        const matchedValue = inputProps?.options?.find((_: any) => _.key === value);

        const options = this.props.options
            ? this._mapDropdownOptions(this.props.options, value)
            : this._mapDefaultDropdownOptions(value);

        return <Dropdown
            {...inputProps}
            disabled={isDisabled}
            onKeyDown={e => { if (isDisabled || isReadOnly) { e.preventDefault(); } }}
            componentRef={this._dropdown}
            selectedKey={matchedValue !== undefined && matchedValue !== null ? matchedValue.key : value}
            onChange={isDisabled || isReadOnly ? undefined : this._onChange}
            onRenderOption={this.onRenderOption}
            options={options}
            onRenderTitle={this.onRenderTitle}
            className="color-status-dropdown-input"
            calloutProps={{ className: "color-status-dropdown-input-callout" }} />
    }

    private onRenderOption = (props?: IDropdownOption, defaultRender?: (props?: IDropdownOption) => JSX.Element | null): JSX.Element | null => {
        if (!props) {
            return <span />;
        }
        
        const { selected, text } = props;
        const data = props.data as DropdownOptionData;
        return <ColorStatusDropdownOption data={data} text={text} selected={selected} />;
    }

    private onRenderTitle = (props?: IDropdownOption[], defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null): JSX.Element | null => {
        if (!props || !Array.isArray(props)) {
            return <span />;
        }

        const data = props[0].data as DropdownOptionData;
        return <ColorStatusDropdownOption data={data} text={props[0].text} />
    }

    private _onChange = (event: React.FormEvent<HTMLDivElement>, opt: IDropdownOption) => {
        this.props.onChanged?.(opt.key);
        this.props.onEditComplete?.(opt.key);
    }

    private _mapDropdownOptions = (options: CategoryStatusOption[], value: string | number): IDropdownOption[] => {
        return options.map<IDropdownOption>(_ => ({
            key: _.name,
            text: _.name,
            data: { backgroundColor: _.color } as DropdownOptionData,
            selected: _.name === value,
        }));
    }

    private _mapDefaultDropdownOptions = (value: string | number): IDropdownOption[] => {
        return StatusCategoryConfigsList.map<IDropdownOption>(_ => ({
            key: _.status,
            text: _.title,
            data: { cssClassName: _.cssClassName } as DropdownOptionData,
            selected: _.status === value,
        }));
    }

    private _getDefaultValue = (): string | number => {
        const { statusField } = this.props;
        if (statusField) {
            const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField)
            return statusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA);
        }

        return StatusCategory.NA;
    }

    focus(): void {
        const { readOnly, disabled, inputProps } = this.props;
        const isDisabled = readOnly ?? inputProps?.readOnly ?? disabled ?? inputProps?.disabled;
        this._dropdown.current!.focus(!isDisabled);
    }
}
