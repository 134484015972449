import { Icon } from "office-ui-fabric-react";
import React from "react";

export type DropdownOptionData = {
    cssClassName?: string;
    backgroundColor?: string;
}

type Props = {
    selected?: boolean;
    text: string;
    data: DropdownOptionData;
}

const ColorStatusDropdownOption = (props: Props) => {
    const { data, text, selected } = props;
    return (
        <span className={`color-status overflow-text ${data.cssClassName ?? ""}`}>
            {
                selected !== undefined && <span className={`is-selected ${selected ? "selected" : ""}`}>
                    <Icon iconName="CheckMark" />
                </span>
            }
            <span
                className='status'
                style={data.backgroundColor ? { backgroundColor: data.backgroundColor } : undefined}
            ></span>
            <span className="value">{text}</span>
        </span>
    )
}

export default ColorStatusDropdownOption;