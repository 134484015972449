import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo, ProjectInfo as IProjectInfo } from "../../../../../store/ProjectsListStore";
import { SourceTypeMap } from "../../../../../store/ExternalEpmConnectStore";
import { SourceInfo } from "../../../../../entities/common";
import { Field } from "../../../../../entities/Metadata";

export default class SyncDetails extends React.Component<IListViewFieldColumn<ProjectInfo>> {
    public render() {
        const field = this.props.field;

        if (!field) {
            return <span>Invalid column configuration</span>;
        }

        const failedSources = SourceInfo.getSyncableConnections(this.props.entity.sourceInfos)
            .filter(_ => !!_.syncStatus.lastException);
        return <span>
            {failedSources.map((source, index) => <div key={index} className="selectable-text">{SourceTypeMap[source.type]}: {source.syncStatus.lastException}</div>)}
        </span>;
    }

    public static getValue(entity: IProjectInfo, field: Field): string | undefined {
        const failedSources = SourceInfo.getSyncableConnections(entity.sourceInfos)
            .filter(_ => !!_.syncStatus.lastException);
        return failedSources.length ? failedSources[0].syncStatus.lastException : undefined;
    }
}