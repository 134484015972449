import * as React from "react";
import * as analytics from '../../../analytics';
import { connect } from "react-redux";
import { ISpoProject, actionCreators, ISpoConnectionState } from "../../../store/integration/SpoStore";
import { IImportProjectMap } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import { useDataLoadingAfterConnectionValidationEffect, IImportEntity, IImportEntityMap, getValidationError } from "./common";
import { toDictionary } from "../../utils/common";
import { ProjectsImportProps } from "./ProjectsImportPanel";
import { UserState } from "../../../store/User";

type OwnProps = {
    connectionId: string;
    onRender: (props: ProjectsImportProps) => JSX.Element;
}

type StateProps = {
    user: UserState;
    projects: ISpoProject[];
    isLoading: boolean;
    error: string | null;
    isImporting: boolean;
    connections: ISpoConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;
const SpoProjectImport = (props: Props) => {
    const { connectionId } = props;
    const entities = React.useMemo(() => props.projects.map<IImportEntity>(_ => ({
        id: _.id,
        name: _.name,
        fullName: `${_.name} (${_.id})`,
        isAlreadyLinked: _.isAlreadyLinked
    })), [props.projects]);
    const map = React.useMemo(() => toDictionary(props.projects), [props.projects]);
    const _onImport = React.useCallback((connectionId: string, maps: IImportEntityMap[]) => {
        props.importProjects(connectionId, maps.map<IImportProjectMap<ISpoProject>>(_ => ({
            linkingData: map[_.entityId],
            projectId: _.projectId,
            projectName: _.projectName
        })));
        analytics.trackImport('Import Projects from Project Online', props.user, { count: maps.length });
    }, [map]);

    const connectionValidation = useDataLoadingAfterConnectionValidationEffect(
        connectionId, 
        props.connections, 
        props.isImporting, 
        props.verifyConnection,
        props.loadProjects
    );

    return props.onRender({
        label: "Projects",
        connectionId: props.connectionId,
        isLoading: connectionValidation?.isValidating || props.isLoading,
        error:getValidationError(connectionValidation) ?? props.error,
        onImport: _onImport,

        oldImportGridProps: {
            entities: entities,
            getSourcesInfo: __ => __.sourceInfos.map(_ => ({
                sourceId: _.sourceData.projectId,
                connectionId: _.connectionId,
                data: _
            }))
        }
    });
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        projects: state.spo.projects.entities,
        isLoading: state.spo.projects.isLoading,
        error: state.spo.projects.error,
        isImporting: state.import.projects.isImporting,
        connections: state.spo.connections
    }
}

export default connect(mapStateToProps, actionCreators)(SpoProjectImport);