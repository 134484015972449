import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import * as H from 'history';
import { match } from "react-router-dom";
import { ApplicationState } from "../../store";
import { CommandBar, IContextualMenuItem } from "office-ui-fabric-react";
import { Integrations, Subscription, TenantState, RoutesAvailability } from "../../store/Tenant";
import NewEntityHeaderButton from "./NewEntityHeaderButton";
import { notUndefined } from "../utils/common";
import { EntityType, IEditableExtensibleEntity } from "../../entities/common";
import { UserState } from "../../store/User";
import * as Metadata from '../../entities/Metadata';
import { PngExportConfig } from './PngExporter';
import { ICardSortOption, ICardSortState } from '../../store/views';
import EntitiesFilterTags from './EntitiesFilterTags/EntitiesFilterTags';
import { bindActionCreators } from 'redux';
import { actionCreators as PngExportActionCreators } from '../../store/PngExporterStore';
import { buildActionsMenuItem, IReportsProps, buildReportsMenuItem, buildSortByMenuItem, buildPlanningAndUtilizationMenuItem, buildReportedTimeMenuItem } from './headerMenuItemBuilders';
import ImportProjects, { supported as ImportProjectsIntegrations } from '../import/project/ImportPanel';
import ImportResources, { supported as ImportResourcesIntegrations } from '../import/resource/ImportPanel';
import ListMenuItemSelectorBuilder from './ListMenuItemSelector';

export interface IHeaderProps {
    entityType: EntityType;
    createEntityTypes: EntityType[];
    importEntityTypes: EntityType[];
    reportProps?: IReportsProps;
    allowImportFromFile?: boolean;
    allowExportToFile?: boolean;
};

export type EntitiesScreenRouter = {
    history: H.History;
    match: match<{
        type?: string;
        subViewId?: string;
    }>;
    location: H.Location;
};

export interface IEntitiesScreenView<T> {
    icon: string;
    subTypes?: { title: string, iconName: string, onClick: () => void, isActive: () => boolean }[];
    url: string;
    subViews: Metadata.ISubView[];
    render: (key: string, view: Metadata.ISubView, entities: T[]) => JSX.Element | JSX.Element[];
    onAddSubViewClick?: () => void;
    onEditSubViewClick?: (id: string) => void;
    onCopySubViewClick?: (view: Metadata.ISubView) => void;
    onRemoveSubViewClick?: (id: string) => void;
    sortBy?: ICardSortState;
    onSortChange?: (sortBy: ICardSortOption) => void;
    activeSubViewId?: string;
    onSubViewChange: (subViewId: string) => void;
    headerSideItems?: IContextualMenuItem[];
}

export type ActiveView<T> = {
    view: IEntitiesScreenView<T>;
    subView: Metadata.ISubView;
};

export type EntitiesScreenFilter = {
    activeFilter?: Metadata.IFilter<Metadata.BaseFilterValue>;
    autoFilterId: string;
    onFilterRender: (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => JSX.Element | null;
    getAttributeValue: (attrType: string, value: any) => string[];
};

const ListMenuItemSelector = ListMenuItemSelectorBuilder<Metadata.ISubView>();

type OwnProps = {
    headerProps: IHeaderProps;
    router: EntitiesScreenRouter;
    active: ActiveView<IEditableExtensibleEntity>;
    filter: EntitiesScreenFilter;
    entities: IEditableExtensibleEntity[];
    canEdit: boolean;
    canManageConfiguration: boolean;
    baseUrl: string;
    getPngExportConfig?: () => PngExportConfig;
    importFromFile?: (file: File) => void;
};

type StateProps = {
    subscription: Subscription;
    user: UserState;
    tenant: TenantState;
    integrations: Integrations;
};

type ActionProps = {
    pngExporterActions: typeof PngExportActionCreators;
};

type Props = OwnProps & StateProps & ActionProps;

const pngExportedViewTypes: string[] = [Metadata.ViewTypes.timeline as string, Metadata.ViewTypes.list as string];
const exportImportFileViewTypes: string[] = [Metadata.ViewTypes.timeline as string, Metadata.ViewTypes.list as string, Metadata.ViewTypes.card as string];

const EntitiesScreenHeader = (props: Props) => {
    const { headerProps, router, subscription, integrations, user, tenant, active, filter, pngExporterActions, canEdit } = props;
    
    const fileUpload = useRef<HTMLInputElement>(null);
    const [entityTypeToCreate, setEntityTypeToCreate] = useState<EntityType>();
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

    const projectIntegrations = integrations.getAvailable(ImportProjectsIntegrations);
    const resourceIntegrations = integrations.getAvailable(ImportResourcesIntegrations);

    const getGetPngExportConfig = () => {
        return !!active.view.url && pngExportedViewTypes.includes(active.view.url.toLowerCase())
            ? props.getPngExportConfig
            : undefined;
    }

    const renderActionsHeaderMenu = exportImportFileViewTypes.includes(active.view.url.toLowerCase())
        && (!!getGetPngExportConfig() || headerProps.allowExportToFile || headerProps.allowImportFromFile);

    const primaryItems: IContextualMenuItem[] = [
        {
            key: 'new-entity',
            onRender: () => <NewEntityHeaderButton
                entityTypes={Subscription.getCreateEntityTypes(subscription, tenant.defaultProjectSettings, headerProps.createEntityTypes)}
                currentEntityType={headerProps.entityType} />
        },
        buildPlanningAndUtilizationMenuItem({ user, subscription, headerProps, router }),
        buildReportedTimeMenuItem({ user, subscription, headerProps, router, tenant }),
        buildReportsMenuItem(headerProps.reportProps, tenant),
        renderActionsHeaderMenu
            ? buildActionsMenuItem({
                entityIds: props.entities.map(e => e.id),
                readonly: !canEdit,
                importEntityTypes: headerProps.importEntityTypes,
                currentEntityType: headerProps.entityType,
                user,
                projectIntegrations,
                resourceIntegrations,
                pngExporterActions: pngExporterActions,
                getPngExportConfig: getGetPngExportConfig(),
                onCreate: setEntityTypeToCreate,
                onCsvImport: headerProps.allowImportFromFile ? () => fileUpload.current?.click() : undefined,
            }) : undefined
    ].filter(notUndefined);

    const farItems: IContextualMenuItem[] = [
        {
            key: 'filter-tags',
            onRender: () => <EntitiesFilterTags
                getAttributeValue={filter.getAttributeValue}
                filter={filter.activeFilter}
                showRightDivider
                onTagClick={filter.activeFilter && (!filter.activeFilter.isPublic || props.canManageConfiguration)
                    ? () => setIsFilterPanelOpen(!isFilterPanelOpen)
                    : undefined}
            />
        },
        ...(active.view.headerSideItems ?? []),
        buildSortByMenuItem(active),
        {
            key: 'views',
            onRender: () => <ListMenuItemSelector
                canManageConfiguration={props.canManageConfiguration}
                itemType='View'
                activeItem={active.subView}
                items={active.view.subViews}
                onActiveItemChanged={id => props.router.history.push(`${props.baseUrl}/${active.view.url}/${id}`)}
                onItemAddClick={active.view.onAddSubViewClick}
                onItemEditClick={active.view.onEditSubViewClick}
                onItemRemoveClick={active.view.onRemoveSubViewClick}
                onItemCopyClick={active.view.onCopySubViewClick}
            />
        }
    ].filter(notUndefined);

    return (
        <>
            <CommandBar
                className="header-command-bar"
                style={{ width: '100%' }}
                overflowButtonProps={{ menuProps: { items: [], isBeakVisible: false } }}
                items={primaryItems}
                farItems={farItems}
            />
            {filter.onFilterRender(isFilterPanelOpen, () => setIsFilterPanelOpen(!isFilterPanelOpen))}
            {entityTypeToCreate === EntityType.Project && <ImportProjects integrations={projectIntegrations} onDismiss={() => setEntityTypeToCreate(undefined)} />}
            {entityTypeToCreate === EntityType.Resource && <ImportResources integrations={resourceIntegrations} onDismiss={() => setEntityTypeToCreate(undefined)} />}
            <input ref={fileUpload} style={{ display: "none" }} type="file" accept=".csv"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files[0]) {
                        props.importFromFile?.(e.target.files[0]);
                    }
                    e.target.value = '';
                }}
            />
        </>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        tenant: state.tenant,
        integrations: new Integrations(state.tenant.subscription.integrations),
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        pngExporterActions: bindActionCreators(PngExportActionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(EntitiesScreenHeader);
