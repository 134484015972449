import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import { TimeTrackingGlobalSettings, actionCreators as tenantActionCreators } from "../../../store/Tenant";
import DropdownInput from "../../common/inputs/DropdownInput";
import LabellableComponent from "../../common/LabellableComponent";
import FlagInput from "../../common/inputs/FlagInput";
import DatePickerInput from "../../common/inputs/DatePickerInput";
import { toDate, toDateTime } from "../../utils/common";
import { UserState } from "../../../store/User";
import { CommonOperations, contains } from "../../../store/permissions";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";

type StateProps = {
    settings: TimeTrackingGlobalSettings;
    user: UserState;
};

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
};

type Props = StateProps & ActionProps;

const TimeTrackingSettingsGlobal = (props: Props) => {
    const { tenantActions, user } = props;

    const canManage = contains(user.permissions.common, CommonOperations.TimeTrackingManage);

    const [state, setState] = useState(props.settings);
    const [showCompletedWorkConfirmation, setShowCompletedWorkConfirmation] = useState(false);

    const saveSettings = React.useCallback((settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => {
        
        const newState = {
            ...state,
            ...settings
        };

        setState(newState);
        
        tenantActions.updateTimeTrackingSettings(settings, callback);
    }, [state]);

    return (
        <>
            <div className="settings-fields-holder time-tracking-settings">

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Cut off date for the time reporting lock"
                        description={`Please select a cut off date for the time reporting lock. All data in users’ timesheets will be locked for changes until this date.`}>
                    
                        <DatePickerInput value={toDateTime(state.timeReportingLock)?.toDateOnlyString()}
                            onChanged={_ => {
                                saveSettings({
                                    timeReportingLock: toDate(_) || null
                                });
                            }}
                            readOnly={!canManage}
                        />
                    </LabellableComponent>
                </div>
            
                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Record Reported Time to Completed Work"
                        description={`Please define if user-reported time should be added to the tasks' Completed Work. This setting is global for the tenant, but it can be disabled at the Project level.`}>
                    
                        <FlagInput value={state.calculateCompletedWorkBasedOnReportedTime}
                            onChanged={_ => {

                                if (_) {
                                    setShowCompletedWorkConfirmation(true);
                                }
                                else {
                                    saveSettings({
                                        calculateCompletedWorkBasedOnReportedTime: _
                                    });
                                }
                            }}
                            readOnly={!canManage}
                        />
                    </LabellableComponent>
                </div>

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Time Entry Minimum Duration"
                        description={`Select the minimum duration for a time entry for all users in your PPM Express tenant. If a user enters a time less than this specified minimum, the value will be automatically rounded up.`}>
               
                        <DropdownInput
                            value={state.minReportingDurationMinutes}
                            readOnly={!canManage}
                            inputProps={{
                                options: [
                                    {
                                        key: 15,
                                        text: "15 minutes"
                                    },
                                    {
                                        key: 30,
                                        text: "30 minutes"
                                    },
                                    {
                                        key: 60,
                                        text: "60 minutes"
                                    }
                                ]
                            }}
                            onChanged={(_) => {
                                saveSettings({
                                    minReportingDurationMinutes: _
                                });
                            }}
                        />
                    </LabellableComponent>
                </div>

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Time Reporting Increment"
                        description={`This setting specifies the unit that all time entries must be in multiples of. For instance, if the increment is set to 15 minutes, all time entries must be in multiples of 15 minutes. This setting ensures consistency and simplifies the aggregation and reporting of time data.`}>
                        <DropdownInput
                            value={state.reportingIncrementMinutes}
                            readOnly={!canManage}
                            inputProps={{
                                options: [
                                    {
                                        key: 1,
                                        text: "1 minute"
                                    },
                                    {
                                        key: 5,
                                        text: "5 minutes"
                                    },
                                    {
                                        key: 10,
                                        text: "10 minutes"
                                    },
                                    {
                                        key: 15,
                                        text: "15 minutes"
                                    }
                                ]
                            }}
                            onChanged={(_) => {
                                saveSettings({
                                    reportingIncrementMinutes: _
                                });
                            }}
                        />
                    </LabellableComponent>
               
                </div>
            </div>

            {showCompletedWorkConfirmation &&
                <ConfirmationDialog
                dialogContentProps={{
                    isMultiline: true,
                    title: "Confirm this action",
                    subText: `Are you sure you want to enable recording of the Reported Time to Tasks Completed Work in all Projects of your Tenant?
                             Tasks Completed Work will be recalculated immediately using the time reported in Users Timesheets.`    
                    
                }}
                onNo={() => { }}
                    onDismiss={() => setShowCompletedWorkConfirmation(false)}
                    
                    onYes={() => {
                        saveSettings({
                            calculateCompletedWorkBasedOnReportedTime: true
                        }, () => {
                            props.tenantActions.runTaskCompletedWorkRecalculation();
                        });
                    }}
                />}
        </>
    );
};

const mapStateToProps = (_: ApplicationState): StateProps => ({
    settings: _.tenant.timeTracking.globalSettings,
    user: _.user
});

function mapDispatchToProps(dispatch: Dispatch): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingSettingsGlobal);
