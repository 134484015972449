import * as React from 'react';

export interface IDropdownFormatterOption {
    key: any;
    text: string;
    color?: string;
}

export const DropdownFormatter = (props: { value?: string | number, options: IDropdownFormatterOption[] }) => {
    const selectedOpt = props.options.find(opt => opt.key.toString() === props.value?.toString());
    return <div>{selectedOpt ? selectedOpt.text : props.value}</div>;
}

export const ColorStatusDropdownFormatter = (props: { value?: string | number, options: IDropdownFormatterOption[] }) =>
    <OptionFormatter {...props} wrapClassName="color-status-dropdown-input" innerClassName="color-status" />;

export const ToggleOptionFormatter = (props: { value?: string | number, options: IDropdownFormatterOption[] }) =>
    <OptionFormatter {...props} wrapClassName="toggle-option-input" innerClassName="toggle-option" />;

const OptionFormatter = (props: { value?: string | number, options: IDropdownFormatterOption[], wrapClassName: string, innerClassName: string }) => {
    const selectedOpt = props.options.find(opt => opt.key.toString() === props.value?.toString());
    return selectedOpt
        ? <span className={props.wrapClassName}>
            <span className={`${props.innerClassName} ${selectedOpt.text}`}>
                <span className="value">
                    {selectedOpt.text}
                </span>
            </span>
        </span>
        : <span></span>;
}