import React from "react";
import { nameof } from "../../store/services/metadataService";
import * as Metadata from '../../entities/Metadata';
import { DisplayFieldService } from "../common/DisplayFieldService";
import { Dictionary, EntityType } from '../../entities/common';
import { IObjectiveAttrs, ObjectiveCalculationType, OKRDirection, OKRValueType } from "../../store/ObjectivesListStore";
import { formatsMap, OkrProgressFormatter, ProgressFormatter, propsOKRFlagLabels } from "../common/formatters/OkrFormatters";
import NumberInput from '../common/inputs/NumberInput';
import { NumberFlagInput } from '../common/inputs/NumberFlagInput';
import { IInputProps } from "../common/interfaces/IInputProps";

export const rendersBuilder = () => ({
    [nameof<IObjectiveAttrs>("ValueType")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        DisplayFieldService.buildFieldInputElement(field, {
            ...props,
            onChanged: props.onChanged ? (v) => {
                let extra: Dictionary<any> | undefined;

                if (v === OKRValueType.Percent) {
                    if (!state.attributes.StartValue && !state.attributes.TargetValue && !state.attributes.CurrentValue) {
                        extra = {
                            [nameof<IObjectiveAttrs>("StartValue")]: 0,
                            [nameof<IObjectiveAttrs>("CurrentValue")]: 0,
                            [nameof<IObjectiveAttrs>("TargetValue")]: 100
                        };
                    }
                } else if (v === OKRValueType.Flag) {
                    extra = {
                        [nameof<IObjectiveAttrs>("StartValue")]: 0,
                        [nameof<IObjectiveAttrs>("CurrentValue")]: 0,
                        [nameof<IObjectiveAttrs>("TargetValue")]: 1,
                        [nameof<IObjectiveAttrs>("Direction")]: OKRDirection.Growth
                    };
                }

                props.onChanged!(v, extra);
            } : undefined,
            onEditComplete: (v, extra) => {
                if (v === OKRValueType.Percent) {
                    if (!state.attributes.StartValue && !state.attributes.TargetValue && !state.attributes.CurrentValue) {
                        extra = extra ?? {};
                        extra[nameof<IObjectiveAttrs>("StartValue")] = 0;
                        extra[nameof<IObjectiveAttrs>("CurrentValue")] = 0;
                        extra[nameof<IObjectiveAttrs>("TargetValue")] = 100;
                    }
                } else if (v === OKRValueType.Flag) {
                    extra = extra ?? {};
                    extra[nameof<IObjectiveAttrs>("StartValue")] = 0;
                    extra[nameof<IObjectiveAttrs>("CurrentValue")] = 0;
                    extra[nameof<IObjectiveAttrs>("TargetValue")] = 1;
                    extra[nameof<IObjectiveAttrs>("Direction")] = OKRDirection.Growth;
                }

                props.onEditComplete(v, extra);
            }
        }, EntityType.Objective),
    [nameof<IObjectiveAttrs>("Direction")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        (state.attributes.CalculationType === ObjectiveCalculationType.Calculated && state.attributes.ValueType !== OKRValueType.Flag)
            ? DisplayFieldService.buildFieldInputElement(field, props, EntityType.Objective)
            : null,
    [nameof<IObjectiveAttrs>("StartValue")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        state.attributes.ValueType !== OKRValueType.Flag
            ? <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} format={formatsMap[state.attributes.ValueType]} validator={DisplayFieldService.buildValidatorFromField(field)} />
            : null,
    [nameof<IObjectiveAttrs>("TargetValue")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        state.attributes.ValueType !== OKRValueType.Flag
            ? <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} format={formatsMap[state.attributes.ValueType]} validator={DisplayFieldService.buildValidatorFromField(field)} />
            : null,
    [nameof<IObjectiveAttrs>("CurrentValue")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        state.attributes.ValueType !== OKRValueType.Flag
            ? <NumberInput {...props} inputProps={{ readOnly: field.isReadonly }} format={formatsMap[state.attributes.ValueType]} validator={DisplayFieldService.buildValidatorFromField(field)} />
            : <NumberFlagInput {...props} {...propsOKRFlagLabels} inputProps={{ readOnly: field.isReadonly }} value={state.attributes.CurrentValue} />,
    [nameof<IObjectiveAttrs>("Progress")]: (props: IInputProps, state: { attributes: IObjectiveAttrs }, field: Metadata.Field): JSX.Element | null =>
        state.attributes.CalculationType === ObjectiveCalculationType.Calculated
            ? <OkrProgressFormatter direction={state.attributes.Direction} startValue={state.attributes.StartValue}
                targetValue={state.attributes.TargetValue} currentValue={state.attributes.CurrentValue} />
            : state.attributes.CalculationType === ObjectiveCalculationType.KR_Rollup
                ? <ProgressFormatter value={state.attributes.Progress} />
                : DisplayFieldService.buildFieldInputElement(field, props, EntityType.Objective)
})