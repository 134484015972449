import * as React from 'react';
import { connect } from 'react-redux';
import { PersonaSize, Persona, IPersonaProps } from "office-ui-fabric-react";
import { getPersonInfoImageUrl } from "../../utils/common";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { ApplicationState } from "../../../store/index";
import { IUserInfo } from "../../../entities/common";
import Link from "../Link";
import { Validator } from "../../../validation";

type OwnProps = { resource?: IUserInfo | IUserInfo[], withNavigation?: boolean, onClick?: (e: any, resource: IUserInfo) => void; };
type StateProps = { user: UserState };
type Props = OwnProps & StateProps;
type State = {
    showAllResources: boolean;
}

const personValidator = Validator.new().person().build();
const shortListLimit = 5;

class ResourceFormatter extends React.PureComponent<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = { showAllResources: false };
    }
    render() {
        const { resource, user, withNavigation, onClick } = this.props;
        if (!resource) return null;
        const size = PersonaSize.size28;
        const renderLink = contains(user.permissions.common, CommonOperations.ResourceView) && withNavigation;
        if (!Array.isArray(resource) || resource.length == 1) {
            let person = !Array.isArray(resource) ? resource : resource[0];
            if (!personValidator.isValid(person)) return null;
            const imageUrl = getPersonInfoImageUrl(person);

            return onClick
                ? <a onClick={(e) => onClick(e, person)} className="with-persona">
                    <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />
                </a>
                : renderLink
                    ? <Link href={`/resource/${person.id}`} className="with-persona">
                        <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />
                    </Link>
                    : <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />;

        }

        return <>
            {(this.state.showAllResources || resource.length <= shortListLimit ? resource : resource.slice(0, shortListLimit)).map((r, index) =>
                onClick
                    ? <a key={index} title={r.fullName} onClick={(e) => onClick(e, r)} className="with-persona">
                        <Persona className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} />
                    </a>
                    : renderLink
                        ? <Link key={index} href={`/resource/${r.id}`} title={r.fullName} className="with-persona">
                            <Persona className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} />
                        </Link>
                        : <Persona key={index} className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} title={r.fullName} />
            )}
            {!this.state.showAllResources && resource.length > shortListLimit
                && <span className="res-cut" onClick={(e) => { this.setState({ showAllResources: true }); e.stopPropagation(); }} title="Show More">+{resource.length - shortListLimit}</span>}
        </>;
    }
    //need for not rendering default tooltip
    private onRenderPrimaryText = (props?: IPersonaProps): JSX.Element => <span>{props?.text}</span>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { user: state.user }
}

export default connect(mapStateToProps)(ResourceFormatter);