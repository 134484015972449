import * as React from 'react';
import { IListViewFieldColumn } from "../../../../services/ViewService";
import { Dictionary, Impact as ImpactEnum, ImpactConfigMap } from "../../../../entities/common";

export default class Impact extends React.Component<IListViewFieldColumn<{ attributes: Dictionary<any> }>> {
    public render() {
        const field = this.props.field;
        if (!field) {
            return <span className="overflow-text">
                Invalid column configuration
            </span>;
        }

        const value = this.props.entity.attributes[field.name];
        if (!field.settings) {
            return <span className={"bold-font overflow-text" + (this.props.className ? ` ${this.props.className}` : "")}>{value}</span>;
        }
        return <ImpactView value={value} />;
    }
}

export class ImpactView extends React.Component<{ value: ImpactEnum }> {
    render() {
        const { value } = this.props;
        const map = ImpactConfigMap[value != undefined ? value : ImpactEnum.NA];

        return (
            <div className={`align-center impact label impact-${value}`}>
                {map ? map.title : value}
            </div>
        );
    }
}