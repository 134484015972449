import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from "../../utils/common";

export interface IDraggableItem {
    id: string;
}

interface IDraggableListProps {
    items: IDraggableItem[];
    onChanged?: (items: any[], changedPositionItem: any) => void;
    onItemRender: (item: any, index: number) => JSX.Element | (JSX.Element | null)[];
    isItemDraggable?: (item: any, index: number) => boolean;
    itemClassName?: string;
    getKey?: (item: IDraggableItem, index: number) => string | number;
}

const idKey = (item: IDraggableItem) => item.id;

const DraggableList = (props: IDraggableListProps) => {
    const getKey = props.getKey || idKey;
    const onDragEnd = useCallback((result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const item = props.items[result.source.index];
        const reorderedItems = reorder(
            props.items,
            result.source.index,
            result.destination.index
        );

        props.onChanged!(reorderedItems, item);
    }, [props.onChanged, props.items]);

    const items = useMemo(() => props.items.map((_, index) => {
        const isDragDisabled = !props.onChanged || props.isItemDraggable?.(_, index) === false;
        return <Draggable
            key={getKey(_, index)}
            draggableId={_.id}
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided, snapshot) => (
                <li
                    className={"field" + (snapshot.isDragging ? ' dragging' : '') + (isDragDisabled ? ' drag-disabled' : '')}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                >
                    <div className="align-center">
                        {props.onItemRender(_, index)}
                    </div>
                </li>
            )}
        </Draggable>
    }), [props.items, props.onChanged, props.isItemDraggable, props.onItemRender]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <ul
                        className={`cb-list ${props.itemClassName ?? "with-dragndrop"}`
                            + (!props.onChanged ? ' dragndrop-disabled' : '')
                            + (snapshot.isDraggingOver ? ' drag-over' : '')}
                        ref={provided.innerRef}
                    >
                        {items}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;
