import { get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { push, RouterAction } from 'react-router-redux';
import { MetadataService, UpdateUIControlInfo, ActionsBuilder, namesof } from './services/metadataService';
import * as Metadata from "../entities/Metadata";
import { IEntityStore, StoreHelper, IDeletionResult, partialUpdate } from './services/storeHelper';
import { Dictionary, IUserInfo, ISourceInfo, EntityType, IEditable, IBaseEntity, IWithLayout, IWithImage } from "../entities/common";
import { defaultCatch } from "./utils";
import { CreateIdeaSuccessAction, ReceivedDeleteIdeasResultAction, Idea } from './IdeasListStore';
import { ILinkDto } from './integration/common';
import { TeamsChannelLink } from '../components/integration/TeamsChannelConnectControl';
import * as ExternalEpmConnectStore from "./ExternalEpmConnectStore";
import { ApplyLayout, LayoutApplied } from './layouts';
import { ImportExportFactory } from './importExport';

export type ChallengeAttrs = {
    Name: string;
    Manager: IUserInfo[];
    Stage: ChallengeStage;
    CreatedDate: string;
    Description?: string;
    Area?: string;
}

export enum ChallengeStage {
    Draft = 0,
    Active = 1,
    Closed = 2
}

export interface ChallengeStageConfig { title: string, cssClassName: string }
export const challengeStagesMap: { [i: number]: ChallengeStageConfig } =
{
    [ChallengeStage.Draft]: {
        title: "Draft",
        cssClassName: "Draft",
    },
    [ChallengeStage.Active]: {
        title: "Active",
        cssClassName: "Active"
    },
    [ChallengeStage.Closed]: {
        title: "Closed",
        cssClassName: "Closed"
    }
}

export interface Challenge extends IBaseEntity, IWithLayout, IWithImage, IEditable, Metadata.IWithSections, Metadata.IWithPinnedViews {
    ideaIds: string[];
    attributes: ChallengeAttrs & Dictionary<any>;
    calculation: {
        activeIdeasCount: number
    };
    sourceInfos: ISourceInfo[];
    canConfigure: boolean;
    canCollaborate: boolean;
    defaultIdeaLayoutId?: string;
}

export interface IChallengeInfo {
    id: string;
    name: string;
}

export interface ChallengesState extends IEntityStore<Challenge> {
    isLoading: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];
}

const unloadedState: ChallengesState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isUpdatingSections: false
};

export const DEFAULT_BULK_EDIT_COLUMNS = namesof<ChallengeAttrs>(['Name']);

interface LoadChallengesAction {
    type: 'LOAD_CHALLENGES';
}
interface ChallengesLoadedAction {
    type: 'CHALLENGES_LOADED';
    challenges: Challenge[];
}
interface ReceivedDeleteChallengesResultAction {
    type: 'RECEIVED_REMOVE_CHALLENGES_RESULT';
    deletionResult?: IDeletionResult[];
}

export interface CreateChallengeSuccessAction {
    type: 'CREATE_CHALLENGE_SUCCESS';
    challenge: Challenge;
}
interface LoadChallengeAction {
    type: 'LOAD_CHALLENGE';
    id: string;
}
export interface UpdateChallengeIdeasAction {
    type: 'UPDATE_CHALLENGE_IDEAS';
    id: string;
    ideaIds: string[];
    isAdd?: boolean;
}
interface ChallengeLoadedAction {
    type: 'CHALLENGE_LOADED';
    challenge: Challenge;
}

interface UpdatingSectionsAction {
    type: 'UPDATING_CHALLENGE_SECTIONS';
    challengeId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_CHALLENGE_SECTION_SUCCESS';
    challengeId: string;
    sections: Metadata.Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_CHALLENGE_PINNED_VIEWS_SUCCESS';
    challengeId: string;
    pinnedViews: string[];
}

export interface CreateChallengeAction {
    type: 'CREATE_CHALLENGE';
}

interface UpdateChallengeUIControlAction {
    type: 'UPDATE_CHALLENGE_UICONTROL_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

export interface BultUpdateChallengesSuccessAction {
    type: 'BULK_UPDATE_CHALLENGES_SUCCESS';
    challenges: Challenge[];
}

interface UpdateImageAction {
    type: 'UPDATE_CHALLENGE_IMAGE';
    challengeId: string;
    imageId?: string;
}

type KnownAction = LoadChallengesAction
    | ChallengesLoadedAction
    | ReceivedDeleteChallengesResultAction
    | CreateChallengeSuccessAction
    | LoadChallengeAction
    | UpdateChallengeIdeasAction
    | ChallengeLoadedAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | CreateChallengeAction
    | UpdateChallengeUIControlAction
    | BultUpdateChallengesSuccessAction
    | UpdateImageAction;


export const defaultActionCreators = {
    loadChallenges: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Challenge[]>(`api/challenge`)
            .then(data => dispatch({ type: 'CHALLENGES_LOADED', challenges: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_CHALLENGES' });
    },
    loadChallenge: (challengeId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Challenge>(`api/challenge/${challengeId}`)
            .then(data => dispatch(({ type: 'CHALLENGE_LOADED', challenge: data }) as any))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'LOAD_CHALLENGE', id: challengeId }));
    },
    removeChallenges: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/challenge/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_CHALLENGES_RESULT", deletionResult: [data] });
                    if (redirectBack) {
                        dispatch(push('/challenges'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/challenge/bulkDelete`, { ids: ids })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_CHALLENGES_RESULT", deletionResult: data });
                    if (redirectBack) {
                        dispatch(push('/challenges'));
                    }
                })
                .catch(defaultCatch(dispatch));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_CHALLENGES_RESULT" });
    },
    createChallenge: (name: string, layoutId: string, openOnComplete: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch,
        getState) => {
        post<Challenge>(`api/challenge`, { name: name, layoutId })
            .then(data => {
                dispatch(<CreateChallengeSuccessAction>{ type: "CREATE_CHALLENGE_SUCCESS", challenge: data });
                if (openOnComplete) {
                    dispatch(push(`/challenge/${data.id}`));
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch(<CreateChallengeAction>{ type: "CREATE_CHALLENGE" });
    },
    updateChallengeAttributes: (challengeId: string, newAttributeValues: Dictionary<any>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Challenge>(`api/challenge/${challengeId}/attributes`, newAttributeValues)
                .then(data => dispatch({ type: 'CHALLENGE_LOADED', challenge: data }))
                .catch(defaultCatch(dispatch));
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/challenge`,
        (challengeId, sections, dispatch) => dispatch({
            type: 'UPDATE_CHALLENGE_SECTION_SUCCESS',
            challengeId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((challengeId, sections, dispatch) => dispatch({
        type: 'UPDATE_CHALLENGE_SECTION_SUCCESS',
        challengeId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/challenge`,
        (challengeId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_CHALLENGE_PINNED_VIEWS_SUCCESS',
            challengeId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/challenge`,
        (uiControlInfo, dispatch) => dispatch(<UpdateChallengeUIControlAction>{
            type: 'UPDATE_CHALLENGE_UICONTROL_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateChallengeUIControlAction>{
        type: 'UPDATE_CHALLENGE_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Challenge),
    bulkUpdate: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Challenge[]>(`api/challenge/BulkUpdate`, updates)
            .then(data => dispatch({ type: 'BULK_UPDATE_CHALLENGES_SUCCESS', challenges: data }));
    },
    applyLayout: (challengeId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Challenge>(`api/challenge/${challengeId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'CHALLENGE_LOADED', challenge: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Challenge });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Challenge });
    },
    applyLayoutMany: (challengeIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Challenge[]>(`api/challenge/applyLayout/${layoutId}`, { ids: challengeIds })
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_CHALLENGES_SUCCESS', challenges: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Challenge });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Challenge });
    },
    setDefaultIdeaLayout: (challengeId: string, layoutId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Challenge>(`api/challenge/${challengeId}/defaultIdeaLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'CHALLENGE_LOADED', challenge: data });
            })
            .catch(defaultCatch(dispatch));
    },
    updateImage: (challengeId: string, image: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', image);
        post<{ imageId: string }>(`api/challenge/${challengeId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_CHALLENGE_IMAGE', imageId: _.imageId, challengeId: challengeId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (challengeId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/challenge/${challengeId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_CHALLENGE_IMAGE', imageId: undefined, challengeId: challengeId }))
            .catch(defaultCatch(dispatch));
    },
    createIdeaAndAddToChallenge: (challengeId: string, attributes: Dictionary<any>):
        AppThunkAction<KnownAction | CreateIdeaSuccessAction | ReceivedDeleteIdeasResultAction | RouterAction> => (dispatch, getState) => {
            post<{ idea: Idea, challenge: Challenge }>(`api/challenge/${challengeId}/idea/new`, attributes)
                .then(data => {
                    dispatch(<CreateIdeaSuccessAction>{
                        type: 'CREATE_IDEA_SUCCESS',
                        idea: data.idea,
                        isNotSetActiveEntity: true
                    });
                    dispatch({
                        type: 'UPDATE_CHALLENGE_IDEAS',
                        id: challengeId,
                        ideaIds: [data.idea.id],
                        isAdd: true
                    });
                    dispatch(push(`/idea/${data.idea.id}`));
                })
                .catch(defaultCatch(dispatch));
        },
    removeIdea: (challengeId: string, ideaIds: string[], redirectBack?: boolean):
        AppThunkAction<KnownAction | ReceivedDeleteIdeasResultAction | RouterAction> => (dispatch, getState) => {
            remove<IDeletionResult[]>(`api/challenge/${challengeId}/idea`, { ids: ideaIds })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_IDEAS_RESULT", deletionResult: data });
                    dispatch({
                        type: 'UPDATE_CHALLENGE_IDEAS',
                        id: challengeId,
                        ideaIds: data.filter(_ => _.isDeleted).map(_ => _.id),
                        isAdd: false
                    });
                    if (redirectBack) {
                        dispatch(push(`/challenge/${challengeId}`));
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    linkToTeamsChannel: (challengeId: string, linkData: ILinkDto<TeamsChannelLink>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Challenge>(`api/challenge/${challengeId}/link/teamsChannel`, linkData)
            .then(data => dispatch({ type: 'CHALLENGE_LOADED', challenge: data }))
            .catch(defaultCatch(dispatch));
    },
    deleteChallengeToExternalSystemLink: (challengeId: string, connectionId: string, sourceType: ExternalEpmConnectStore.SourceType):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<Challenge>(`api/challenge/${challengeId}/link`, { connectionId, sourceType })
                .then(data => dispatch({ type: 'CHALLENGE_LOADED', challenge: data }))
                .catch(defaultCatch(dispatch));
        }
};

const defaultReducer: Reducer<ChallengesState> = (state: ChallengesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'LOAD_CHALLENGES':
            return {
                ...state,
                isLoading: true
            };
        case 'CHALLENGES_LOADED':
            return {
                ...state,
                ...StoreHelper.create(action.challenges),
                isLoading: false
            };
        case 'LOAD_CHALLENGE':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: undefined,
                isLoading: true
            };

        case 'UPDATE_CHALLENGE_IDEAS':
            {
                return StoreHelper.applyHandler(state,
                    action.id,
                    (challenge: Challenge) => partialUpdate(
                        challenge,
                        {
                            ideaIds: action.isAdd ? Array.from(new Set([...challenge.ideaIds, ...action.ideaIds])) : challenge.ideaIds.filter(_ => !~action.ideaIds.indexOf(_))
                        }));
            }
        case 'RECEIVED_REMOVE_CHALLENGES_RESULT':
            let newState = state;
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'CREATE_CHALLENGE':
            return {
                ...state,
                isLoading: true
            };
        case 'CREATE_CHALLENGE_SUCCESS':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.challenge),
                activeEntityId: action.challenge.id,
                activeEntity: action.challenge,
                isLoading: false
            };
        case 'CHALLENGE_LOADED':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.challenge),
                activeEntity: state.activeEntityId === action.challenge.id ? action.challenge : state.activeEntity,
                isLoading: false
            };
        case 'UPDATING_CHALLENGE_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_CHALLENGE_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.challengeId,
                        (challenge: Challenge) => partialUpdate(challenge, { sections: action.sections })),
                    isUpdatingSections: false
                };
            }
        case 'UPDATE_CHALLENGE_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.challengeId,
                        (challenge: Challenge) => Object.assign({}, challenge, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_CHALLENGE_UICONTROL_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId, (challenge: Challenge) =>
                    MetadataService.UpdateUIControlSettings(challenge, action.uiControlInfo));
            }
        case 'BULK_UPDATE_CHALLENGES_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.union(state, action.challenges),
                    isLoading: false
                };
            }
        case 'UPDATE_CHALLENGE_IMAGE':
            {
                return StoreHelper.applyHandler(state, action.challengeId, (challenge: Challenge) =>
                    partialUpdate(challenge, { imageId: action.imageId }));
            }
        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

const namespace = 'PORTFOLIO';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Challenge, ChallengesState>(namespace, EntityType.Challenge);

export const reducer: Reducer<ChallengesState> = (state: ChallengesState = unloadedState, incomingAction: Action) => {
    return defaultReducer(importExportReducer(state, incomingAction), incomingAction)
}

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators
}