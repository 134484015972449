import * as React from 'react';
import { IPanelProps, Panel } from "office-ui-fabric-react";
import { ApplicationState } from '../../store';
import { connect } from "react-redux";

type StateProps = {
    isFullScreen: boolean;
}

type Props = IPanelProps & StateProps;

class ExpandablePanel extends React.Component<Props> {
    public render() {
        return <Panel 
            {...this.props} 
            focusTrapZoneProps={{forceFocusInsideTrap: true}}
            className={`${this.props.className || ''} ${this.props.isFullScreen ? 'side-panel-maximized' : ''}`}>
        </Panel>
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: IPanelProps): StateProps {
    return {
        isFullScreen: state.userInterface.isFullScreen
    }
}

export default connect(mapStateToProps)(ExpandablePanel);