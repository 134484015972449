import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Icon } from 'office-ui-fabric-react';
import { Dictionary, IExtensibleEntity } from '../../../../../entities/common';
import { Field } from '../../../../../entities/Metadata';

type Entity = { fakeFieldsData: Dictionary<any> } & IExtensibleEntity;
export default class Name extends React.Component<IListViewFieldColumn<Entity>> {
    public render() {
        const { entity, field } = this.props;
        return <span className="align-center insights-late" title={entity.fakeFieldsData[field.name]}>
            <Icon iconName={field.settings?.views.list.itemIconName} />
            <span>{entity.fakeFieldsData[field.name]}</span>
        </span>
    }
    
    public static getValue(entity: Entity, field: Field): number | undefined {
        return entity.fakeFieldsData[field.name];
    }
}