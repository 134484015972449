import React, { useState } from 'react';
import "./StatusCategorySettingsEdit.css";
import { StatusCategory, EntityType, statusCategoryMap, entityTypeLabelMap } from '../../../../entities/common';
import { DraggableList } from '../../../common/DraggableList';
import { DraggableOption } from '../../SelectSettingsEdit/OptionEdit';
import RemoveDialog from '../../../common/RemoveDialog';
import CategoryStatusOptionEdit from './CategoryStatusOptionEdit';
import CategoryHeader from "./CategoryHeader";
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { CategoryStatusOption } from '../../../../entities/StatusDescriptor';

type Props = {
    category: StatusCategory;
    entityType: EntityType;
    options: DraggableOption<CategoryStatusOption>[];
    isUnique: () => boolean;
    onChange: (categoryOptions: DraggableOption<CategoryStatusOption>[]) => void;
};

const StatusCategorySettingsEdit = (props: Props) => {
    const { category, entityType, options, isUnique, onChange } = props;
    const [editedStatus, setEditedStatus] = useState<DraggableOption<CategoryStatusOption> | undefined>(undefined);
    const [statusToDelete, setStatusToDelete] = useState<DraggableOption<CategoryStatusOption> | undefined>(undefined);
    const [draggableOptions, setDraggableOptions] = useState(options);

    const categoryConfig = statusCategoryMap[category];
    
    const isNewStatus = (status: DraggableOption<CategoryStatusOption>) => status.id.startsWith('new_');

    const onAddStatus = () => {
        const newStatusOption: DraggableOption<CategoryStatusOption> = {
            id: 'new_' + Date.now(),
            name: "",
            color: categoryConfig.color,
            category,
        };
        
        const newOpts = [...draggableOptions.filter(_ => !!_.name.trim()), newStatusOption];

        setDraggableOptions(newOpts);
        setEditedStatus(newStatusOption);
        onChange(newOpts);
    };

    const onOptionEdit = (option: DraggableOption<CategoryStatusOption>) => {
        const newOpts = draggableOptions.map(_ => _.id === option.id ? option : _);
        setDraggableOptions(newOpts);
        onChange(newOpts);
    };

    const onCancel = (initialOption: DraggableOption<CategoryStatusOption>) => {
        setEditedStatus(undefined);
        if (isNewStatus(initialOption) && !initialOption.name) {
            deleteStatus(initialOption);
        } else {
            onOptionEdit(initialOption);
        }
    };

    const deleteStatus = (optionToDelete: DraggableOption<CategoryStatusOption> | undefined) => {
        if (!optionToDelete) {
            return;
        }
        const newOpts = draggableOptions.filter(_ => _.id !== optionToDelete.id);
        setDraggableOptions(newOpts);
        onChange(newOpts);
    };

    return (
        <div className='status-category-settings-edit'>
            <CategoryHeader
                categoryName={categoryConfig.title}
                categoryColor={categoryConfig.color}
                onAddStatus={onAddStatus}
            />
            <div className="options">
                <DraggableList
                    items={draggableOptions}
                    onItemRender={(item: DraggableOption<CategoryStatusOption>, index) => (
                        <CategoryStatusOptionEdit
                            option={item}
                            index={index}
                            categoryIconName={categoryConfig.iconName}
                            isUnique={isUnique}
                            isInEditMode={editedStatus?.id === item.id}
                            onEnterEditMode={() => setEditedStatus(item)}
                            onEdit={onOptionEdit}
                            onEditComplete={() => setEditedStatus(undefined)}
                            onCancel={onCancel}
                            onRemove={draggableOptions.filter(_ => !!_.name.trim()).length > 1
                                ? () => setStatusToDelete(item)
                                : undefined}
                        />
                    )}
                    isItemDraggable={() => draggableOptions.length !== 1}
                    onChanged={opts => {
                        setDraggableOptions(opts);
                        onChange(opts);
                    }}
                />
            </div>
            <StatusDeletionDialog
                entityType={entityType}
                status={statusToDelete}
                onConfirm={() => deleteStatus(statusToDelete)}
                onClose={() => setStatusToDelete(undefined)}
            />
        </div>
    );
}

export default StatusCategorySettingsEdit;

const entityTypesWithOverrideAutoCalculationFeature = [EntityType.Project, EntityType.Program, EntityType.Portfolio];
export const entityTypesWithAutoCalculationFeature = [...entityTypesWithOverrideAutoCalculationFeature, EntityType.KeyDate, EntityType.Task];

type StatusDeletionDialogProps = {
    entityType: EntityType;
    status: DraggableOption<CategoryStatusOption> | undefined;
    onConfirm: () => void;
    onClose: () => void;
};

const StatusDeletionDialog = (props: StatusDeletionDialogProps) => {
    const { entityType, status, onConfirm, onClose } = props;

    if (!status) {
        return null;
    }

    const entityTypeNames = entityTypeLabelMap[entityType];
    const entityTypeLabel = entityTypeNames.singular;
    const getMessage = () => {
        const NATitle = statusCategoryMap[StatusCategory.NA].title;

        if (entityTypesWithAutoCalculationFeature.includes(entityType)) {
            return <>
                If the auto-calculation for {entityTypeLabel} status is turned off,
                the default status from the '{NATitle}' category will be applied to current items.
                <br />If the auto-calculation for {entityTypeLabel} status is turned on,
                the default status from the '{statusCategoryMap[status.category].title}' category will be applied to current items.
                {entityTypesWithOverrideAutoCalculationFeature.includes(entityType) && <>
                    <br />If the auto-calculation for {entityTypeLabel} status is turned on, and the status was previously overridden,
                    the status will be reset to the calculated one.
                </>}
            </>;
        }

        return <>The default status from the '{NATitle}' category will apply to current items.</>;
    }
    
    const message = getMessage();

    return (
        <RemoveDialog
            dialogContentProps={{
                title: `Delete Status`,
                subText: `Are you sure you want to delete the status '${status.name}'?`,
            }}
            confirmButtonProps={{
                text: "Confirm",
            }}
            onComplete={onConfirm}
            onClose={onClose}
        >
            <MessageBar messageBarType={MessageBarType.warning}>
                {entityTypeNames.plural} will no longer be able to transition to this status.
                <br />
                {message}
            </MessageBar>
        </RemoveDialog>
    );
}
