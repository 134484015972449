import { get, post, remove } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IEntityStore, StoreHelper, partialUpdate, addOrUpdateOrRemove } from './services/storeHelper';
import { RouterAction } from 'react-router-redux';
import { IBaseEntity, Dictionary, IConnected, IPatch, EntityType } from "../entities/common";
import { ITask, ITaskAttrs, IWithTasks, sortTasksByRank } from "../entities/Subentities";
import { catchApiError, defaultCatch } from "./utils";
import { KnownAction as GroupsKnownAction } from "./groups";
import { BaseFilterValue, Group, IFilter, IGroupInfo, PreFilterOption, TaskFilters } from '../entities/Metadata';
import { UpdatedTasksCalculation, ScheduleData, TeamMemberData, TaskProgressData, defaultActionCreators as projectActionCreators } from './ProjectsListStore';
import { SourceType } from './ExternalEpmConnectStore';
import { KnownAction as NotificationsKnownAction, actionCreators as NotificationsActionCreators, NotificationType } from "./NotificationsStore";
import { ICreationData } from './Subentity';
import { namesof } from './services/metadataService';

export type ITasksState = {
    isLoading: boolean;
    isListLoading: boolean;
};

export type SaveTaskResult = {
    tasks: ITask[];
    scheduleData: IConnected<ScheduleData>;
    teamMembersData: IConnected<TeamMemberData>;
    taskProgressesData: IConnected<TaskProgressData>
}

export type ScheduleTasksCreationData = {
    id: string,
    duration: number,
    name: string;
    parentId?: string;
}

export function TasksOperationsFactory<TNamespace, TTypeInfo extends IBaseEntity & IWithTasks, TListState extends IEntityStore<TTypeInfo> & { tasks: ITasksState }>
    (namespace: TNamespace, entityType: EntityType) {

    function onTasksSaved(dispatch: any, projectId: string, connectionId: string, data: SaveTaskResult) {
        dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data.tasks, sortByRank: true, connectionId });
        projectActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);
    }

    interface RequestedTasksAction {
        namespace: TNamespace,
        type: 'REQUESTED_TASKS';
        projectId: string;
    }

    interface ReceivedTasksAction {
        namespace: TNamespace,
        type: 'UPDATED_TASKS';
        projectId: string;
        connectionId: string;
        addOrUpdate?: ITask[] | ITask;
        remove?: string[];
        sortByRank?: boolean;
    }
    interface LoadingStartedAction {
        namespace: TNamespace,
        type: 'LOADING_STARTED';
        isLoading: boolean;
    }

    type KnownAction = RequestedTasksAction
        | ReceivedTasksAction
        | LoadingStartedAction;

    const actionCreators = {
        loadTasks: (projectId: string, sourceType: SourceType, connectionId: string, isRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
            get<ITask[]>(`api/${entityType}/${projectId}/task?connectionId=${connectionId}`)
                .then(data => {
                    dispatch({
                        namespace, type: 'UPDATED_TASKS',
                        projectId,
                        addOrUpdate: data,
                        sortByRank: !!~[SourceType.O365Planner, SourceType.Ppmx, SourceType.Spo, SourceType.VSTS, SourceType.Jira, SourceType.P4W].indexOf(sourceType),
                        connectionId
                    });
                })
                .catch(defaultCatch(dispatch));

            dispatch(isRefresh
                ? { namespace, type: 'LOADING_STARTED', isLoading: true }
                : { namespace, type: 'REQUESTED_TASKS', projectId });
        },
        createTask: (projectId: string, connectionId: string, data: ICreationData):
            AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation | NotificationsKnownAction> => (dispatch) => {
                post<SaveTaskResult>(`api/${entityType}/${projectId}/task`, data)
                    .then(dto => onTasksSaved(dispatch, projectId, connectionId, dto))
                    .catch(catchApiError(_ => {
                        dispatch({ namespace, type: "UPDATED_TASKS", projectId, remove: [], connectionId });
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                    }));

                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        updateTasks: (projectId: string, connectionId: string, tasks: IPatch<ITask>[]): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> => (dispatch) => {
            post<SaveTaskResult>(`api/${entityType}/${projectId}/task/bulk/update`, tasks)
                .then(data => onTasksSaved(dispatch, projectId, connectionId, data))
                .catch(defaultCatch(dispatch));

            dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
        },
        createTasks: (projectId: string, connectionId: string, tasks: ICreationData[]):
            AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation | NotificationsKnownAction> => (dispatch) => {
                post<SaveTaskResult>(`api/${entityType}/${projectId}/task/bulk/create`, tasks)
                    .then(data => onTasksSaved(dispatch, projectId, connectionId, data))
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                    }));

                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        createSchedule: (projectId: string, connectionId: string, startDate: string, groupId: string, tasks: ScheduleTasksCreationData[]):
            AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation | NotificationsKnownAction> => (dispatch) => {
                post<SaveTaskResult>(`api/${entityType}/${projectId}/schedule`, { projectId, startDate, groupId, tasks })
                    .then(data => onTasksSaved(dispatch, projectId, connectionId, data))
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                    }));

                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        dragTasksToGroup: (projectId: string, connectionId: string, ids: string[], groupId: string, insertBeforeId?: string):
            AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation | NotificationsKnownAction> => (dispatch, getState) => {
                post<ITask[]>(`api/${entityType}/${projectId}/tasks/dragToGroup/${groupId}`, { ids, insertBeforeId })
                    .then(data => dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data, sortByRank: true, connectionId }))
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch({ namespace, type: "UPDATED_TASKS", projectId, sortByRank: true, connectionId });
                    }));
                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        removeTasks: (projectId: string, connectionId: string, ids: string[]): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> => (dispatch, getState) => {
            remove<SaveTaskResult & { removedIds: string[] | null }>(`api/project/${projectId}/task`, { ids })
                .then(data => {
                    dispatch({ namespace, type: "UPDATED_TASKS", projectId, remove: data.removedIds || undefined, addOrUpdate: data.tasks || undefined, connectionId });
                    projectActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);
                })
                .catch(defaultCatch(dispatch));
        },
        indentTasks: (projectId: string, connectionId: string, ids: string[]):
            AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation | NotificationsKnownAction> => (dispatch) => {
                post<SaveTaskResult>(`api/project/${projectId}/task/indent`, { ids })
                    .then(data => {
                        if (data.tasks) {
                            dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data.tasks, connectionId });
                            projectActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);
                        } else {
                            dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                        }
                    })
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                    }));
                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        outdentTask: (projectId: string, connectionId: string, ids: string[]): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> => (dispatch) => {
            post<SaveTaskResult>(`api/project/${projectId}/task/outdent`, { ids })
                .then(data => {
                    if (data.tasks) {
                        dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data.tasks, connectionId });
                        projectActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);
                    } else {
                        dispatch({ namespace, type: 'LOADING_STARTED', isLoading: false });
                    }
                })
                .catch(defaultCatch(dispatch));
            dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
        },
        rollupTasks: (projectId: string, connectionId: string, ids: string[]): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> => (dispatch) => {
            post<SaveTaskResult>(`api/project/${projectId}/task/rollup`, { ids })
                .then(data => onTasksSaved(dispatch, projectId, connectionId, data))
                .catch(defaultCatch(dispatch));

            dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
        },
        changeCalculationMode: (projectId: string, connectionId: string, ids: string[], isAutoMode: boolean): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> =>
            (dispatch) => {
                post<SaveTaskResult>(`api/project/${projectId}/task/mode`, { isAutoMode, ids })
                    .then(data => onTasksSaved(dispatch, projectId, connectionId, data))
                    .catch(defaultCatch(dispatch));

                dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
            },
        reportTime: (projectId: string, taskId: string, seconds: number, date: Date, connectionId: string):
            AppThunkAction<KnownAction | NotificationsKnownAction> => (dispatch) => {
                post<{ errorMessage: string, task: ITask }>(`api/${entityType}/${projectId}/time`, { taskId, seconds, date })
                    .then(data => {
                        if (!data.errorMessage) {
                            dispatch(NotificationsActionCreators.pushNotification({ message: "Reported time successfully saved.", type: NotificationType.Success }));
                            dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data.task, connectionId });
                        } else {
                            dispatch(NotificationsActionCreators.pushNotification({ message: data.errorMessage, type: NotificationType.Error }));
                        }
                    })
                    .catch(defaultCatch(dispatch));
            },
        setBaseline: (projectId: string, connectionId: string, ids: string[]): AppThunkAction<KnownAction | RouterAction | UpdatedTasksCalculation> => (dispatch) => {
            post<ITask[]>(`api/${entityType}/${projectId}/task/baseline`, { ids })
                .then(data => dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data, connectionId }))
                .catch(defaultCatch(dispatch));

            dispatch({ namespace, type: 'LOADING_STARTED', isLoading: true });
        },
        refreshTask:
            (projectId: string, taskId: string, connectionId: string): AppThunkAction<KnownAction | RouterAction > =>
                dispatch => {
                    get<ITask>(`api/${entityType}/${projectId}/task/${taskId}`)
                        .then(data => dispatch({ namespace, type: "UPDATED_TASKS", projectId, addOrUpdate: data, connectionId }))
                        .catch(defaultCatch(dispatch));

                    dispatch({ namespace, type: "LOADING_STARTED", isLoading: true });
                }
    };

    function updateTaskGroups(tasks: ITask[] | undefined, groups: Group[] | null): ITask[] | undefined {
        const groupsMap: Dictionary<IGroupInfo> = {};
        groups?.forEach(_ => groupsMap[_.id] = { id: _.id, name: _.name, color: _.color });
        return tasks?.map<ITask>(_ => ({ ..._, attributes: { ..._.attributes, Group: _.attributes.Group ? groupsMap[_.attributes.Group.id] : undefined } }))
    }

    const reducer: Reducer<TListState> = (state: TListState, incomingAction: Action) => {
        const maybeGroupAction = incomingAction as GroupsKnownAction;
        if (maybeGroupAction.type === "RECEIVED_GROUPS"
            && maybeGroupAction.namespace === "externaltask/group"
            && maybeGroupAction.entityId === state.activeEntity?.id) {
            return {
                ...state,
                ...StoreHelper.applyHandler(state, maybeGroupAction.entityId, (entity: TTypeInfo) => partialUpdate(entity, {
                    tasks: maybeGroupAction.items
                        ? maybeGroupAction.items
                        : updateTaskGroups(entity.tasks, maybeGroupAction.groupsItems)
                } as Partial<TTypeInfo>))
            };
        }

        const action = incomingAction as KnownAction;
        if (action.namespace !== namespace)
            return state;

        switch (action.type) {
            case 'REQUESTED_TASKS':
                return {
                    ...state,
                    ...StoreHelper.applyHandler(state, action.projectId, (entity: TTypeInfo) => partialUpdate(entity, { tasks: [] as ITask[] } as Partial<TTypeInfo>)),
                    tasks: {
                        ...state.tasks,
                        isListLoading: true
                    }
                };
            case 'UPDATED_TASKS':
                return {
                    ...state,
                    ...(state.activeEntity?.id == action.projectId
                        ? StoreHelper.applyHandler(state, action.projectId,
                            (entity: TTypeInfo) => {
                                const entityTasks = entity.tasksConnectionId == action.connectionId ? entity.tasks : [];
                                let tasks = addOrUpdateOrRemove(entityTasks, action.addOrUpdate, action.remove);
                                if (action.sortByRank) {
                                    tasks.sort((a: ITask, b: ITask) => sortTasksByRank(a, b));
                                }
                                return partialUpdate(entity, { tasks, tasksConnectionId: action.connectionId } as Partial<TTypeInfo>);
                            })
                        : {}),
                    tasks: {
                        ...state.tasks,
                        isListLoading: false,
                        isLoading: false
                    }
                };
            case 'LOADING_STARTED':
                return {
                    ...state,
                    tasks: {
                        ...state.tasks,
                        isLoading: action.isLoading
                    }
                };
            default:
                return state;
        }
    };

    return {
        actionCreators,
        reducer
    };
}

export const TYPE_FIELD_ID = "30eb2884-a704-4d5a-908e-f000cf7d5d58";
export const TITLE_FIELD_ID = "8feaf883-7088-4aea-a35a-4ed9f3469241";

export namespace Types {
    export enum Spo {
        Summary = "Summary Task",
        Task = "Task"
    }
    export enum Smartsheet {
        Summary = "Summary Task",
        Task = "Task"
    }
    export enum P4W {
        Summary = "Summary Task",
        Task = "Task"
    }
    export enum VSTS {
        UserStory = "User Story"
    }
}

export function getTypeFieldSettings(sourceType: SourceType) {
    if (sourceType == SourceType.Spo) {
        return { options: [Types.Spo.Summary, Types.Spo.Task] };
    }
    if (sourceType == SourceType.P4W) {
        return { options: [Types.P4W.Summary, Types.P4W.Task] };
    }
    return { options: ["Task"] };
}

export namespace TaskHierarchy {
    const DefaulLevels: { [k: number]: number } = {
        [SourceType.Spo]: 2,
        [SourceType.VSTS]: 0,
        [SourceType.Jira]: 0,
        [SourceType.MondayCom]: 0,
        [SourceType.Smartsheet]: 2,
        [SourceType.MPPFile]: 2,
        [SourceType.P4W]: 2,
        [SourceType.Ppmx]: 0
    }

    export function allowDrag(sourceType: SourceType, filter?: IFilter<BaseFilterValue>, preFilter?: PreFilterOption<ITask>) {
        if (sourceType == SourceType.Ppmx) {
            return isHierarchyView(sourceType, filter, preFilter);
        }

        return false;
    }

    export function filterRootHierarchyItems(sourceType: SourceType, tasks: ITask[]) {
        if (sourceType === SourceType.Spo) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0);
        }

        if (sourceType === SourceType.VSTS) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0 || !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.Jira) {
            return tasks.filter(_ => !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.MondayCom) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0 || !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.Smartsheet) {
            return tasks.filter(_ => !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.MPPFile) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0 || !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.P4W) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0 || !_.hierarchy.parentExternalId);
        }

        if (sourceType === SourceType.Ppmx) {
            return tasks.filter(_ => _.hierarchy.outlineLevel === 0 || !_.hierarchy.parentId);
        }
        return tasks;
    }

    export function isHierarchicalSort(sourceType: SourceType) {
        return !!~[SourceType.Spo, SourceType.VSTS, SourceType.Jira, SourceType.Smartsheet, SourceType.P4W,
        SourceType.MPPFile, SourceType.Ppmx, SourceType.MondayCom].indexOf(sourceType);
    }

    export function isSupportOutline(sourceType: SourceType) {
        return !!~[SourceType.Spo, SourceType.VSTS, SourceType.Jira, SourceType.Smartsheet, SourceType.P4W,
        SourceType.MPPFile, SourceType.Ppmx, SourceType.MondayCom].indexOf(sourceType);
    }

    export function getDefaultLevel(sourceType: SourceType | null) {
        return sourceType != null ? DefaulLevels[sourceType] || 0 : 0;
    }

    export function isHierarchyView(sourceType: SourceType, filter: IFilter<BaseFilterValue> | undefined, preFilter?: PreFilterOption<ITask>) {
        if (!filter)
            return false;

        if (sourceType === SourceType.Spo) {
            return filter.id === TaskFilters.Spo.outlineFilterId && !preFilter;
        }

        if (sourceType === SourceType.VSTS) {
            return filter.id === TaskFilters.VSTS.topLevelItemsFilterId && !preFilter;
        }

        if (sourceType === SourceType.Jira) {
            return filter.id === TaskFilters.Jira.allFilterId && !preFilter;
        }

        if (sourceType === SourceType.MondayCom) {
            return filter.id === TaskFilters.MondayCom.topLevelItemsFilterId && !preFilter;
        }

        if (sourceType === SourceType.Smartsheet) {
            return filter.id === TaskFilters.Smartsheet.outlineFilterId && !preFilter;
        }

        if (sourceType === SourceType.MPPFile) {
            return filter.id === TaskFilters.MPPFile.outlineFilterId && !preFilter;
        }

        if (sourceType === SourceType.P4W) {
            return filter.id === TaskFilters.P4W.outlineFilterId && !preFilter;
        }

        if (sourceType === SourceType.Ppmx) {
            return filter.id === TaskFilters.Ppmx.allTaskFilterId && !preFilter;
        }

        return false;
    }
}

export const allowedReadonlyProgressFields = namesof<ITaskAttrs>(["Progress", "RemainingWork", "CompletedWork"]);

export const allowEditReadOnlyProgress = (task?: ITask, resourceId?: string): boolean =>
    !!task && task.sourceType === SourceType.Ppmx && !!task.attributes.AssignedTo?.find(a => a.id === resourceId)
    && (!task.hierarchy.isParent || (task.hierarchy.isParent && !task.isAutoMode));

export const allowEditTaskProgress = (readonly: boolean | undefined, task: ITask, resourceId?: string): boolean =>
    task.sourceType === SourceType.Ppmx
    && (readonly && allowEditReadOnlyProgress(task, resourceId)
        || !readonly && (!task.hierarchy.isParent || (task.hierarchy.isParent && !task.isAutoMode)));

export const allowEditTasksProgress = (readonly: boolean | undefined, tasks: ITask[], resourceId?: string): boolean =>
    tasks?.some(task => allowEditTaskProgress(readonly, task, resourceId));
