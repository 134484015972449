import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Idea } from "../../../../../store/IdeasListStore";
import { Icon } from 'office-ui-fabric-react';

export default class Votes extends React.Component<IListViewFieldColumn<Idea>> {
    public render() {
        return <span className="votes-view like-disabled">
            <Icon iconName="Like" />
            <span className="progress-tip">{this.props.entity.votes}</span>
        </span>;
    }

    public static getValue(entity: Idea): number {
        return entity.votes;
    }
}