import React from "react";
import { CommonOperations } from "../../../store/permissions";
import SettingsPage, { Section } from "../SettingsPage";
import TimeTrackingSettingsGlobal from "./TimeTrackingSettingsGlobal";
import TimeTrackingAdministrative from "./Administrative";
import Link from "../../common/Link";


export default function TimeTrackingSettings() {

    const sections: Section[] = [
        {
            key: "globalSettings",
            name: "Time Reporting Settings",
            icon: "",
            onRenderBody: () => <TimeTrackingSettingsGlobal />,
            tooltip: <span>
                Define time reporting settings for your PPM Express tenant.
                Please refer to the <Link target="_blank" href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-0">article</Link> for more details.
            </span>
        },
        {
            key: "administrative",
            name: "Administrative Categories",
            icon: "",
            tooltip: <span>
                Create and manage administrative categories that users in your tenant will report time on.
                Please refer to the <Link target="_blank" href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-1">article</Link> for more details.
            </span>,
            onRenderBody: () => <TimeTrackingAdministrative />,
        }
    ];

    return (
        <SettingsPage
            title="Time Tracking Settings"
            requiredPermission={CommonOperations.TimeTrackingView }
            sections={sections}></SettingsPage>
    );
};
