import { getOffset, shiftDate, IScale } from "./TimelineScale";
import * as React from 'react';
import { TooltipHost, TooltipDelay, Icon } from "office-ui-fabric-react";
import { FormatDate } from "../../utils/common";
import { Rnd } from "react-rnd";
import { ICanvasInnerComponentProps, RowShift } from "./TimelineBody";
import { WITH_BLUR } from "../inputs/TextInput";
import { IExtensibleEntity } from "../../../entities/common";
import { TimelineElementCommandsPanel } from "./TimelineElementCommandsPanel";

export interface IBaseTimelineElement {
    key: string;
    entity?: IExtensibleEntity;
    className?: string;
    style?: React.CSSProperties;
    addContent?: boolean;
    datesVisibility?: Visibility;
    availableSpace?: number;
}

export interface ITimelineSegment extends IBaseTimelineElement {
    startDate: Date;
    finishDate: Date;
}
export interface IScaleTimelineSegment extends ITimelineSegment {
    prevStartDate: Date | undefined;
}

export enum Visibility {
    OnHover,
    Always
}

interface ITimelineSegmentState {
    left: number;
    width: string | number;
    startDate: Date;
    finishDate: Date;
    datesVisibility?: Visibility;
    resizeWidth?: string | number;
    trimmedStart?: boolean;
    trimmedEnd?: boolean;
    isOutOfScale: boolean;
    rowShift?: RowShift;
    lockDateOnDnd?: boolean;
}

const minWidthToShowFullDates: number = 200;
const minWidthToShowOneDate: number = 100;

export class TimelineSegment extends React.Component<ICanvasInnerComponentProps<ITimelineSegment>, ITimelineSegmentState> {
    private _suppressOnClick: boolean = false;
    public static minSegmentWidth = 24;

    constructor(props: ICanvasInnerComponentProps<ITimelineSegment>) {
        super(props);
        this.state = this._buildState(props);
    }

    public render() {
        const { scale, timelineWidth, data, onClick, onChange, dragAxis, enableResizing, resetSelection, isSelected } = this.props;
        const { trimmedStart, trimmedEnd, isOutOfScale, lockDateOnDnd } = this.state;

        if (isOutOfScale) {
            return null;
        }

        const trimmed = trimmedStart || trimmedEnd;

        let dragAxisValue = dragAxis?.(data) || 'x';
        dragAxisValue = lockDateOnDnd && dragAxisValue === 'both' ? 'y' : dragAxisValue;
        const canDrag = !trimmed && dragAxisValue !== 'none';
        const isShort = this._isShorterThan(minWidthToShowFullDates);
        const className = `timeline segment ${data.className || ''} ${this._datesVisibility() === Visibility.OnHover ? 'dates-on-hover' : ''}`
            + ` ${onClick ? 'clickable' : ''} ${trimmedStart ? 'trim-left' : ''} ${trimmedEnd ? 'trim-right' : ''}`
            + ` ${this._datesVisibility() === undefined ? 'date-hidden' : ''} ${isShort ? 'short' : ''} ${lockDateOnDnd ? 'locked-date' : ''}`
            + (isSelected ? ' selected' : '');
        const resizing = enableResizing?.(data) || { left: true, right: true };
        return <>{onChange
            ? <Rnd className={className}
                default={{ width: this.state.width, height: 24, x: this.state.left, y: 0 }}
                size={{ width: this.state.width, height: 24 }}
                position={{ x: this.state.left, y: 0 }}
                style={{ ...data.style }}
                enableResizing={{ left: !!resizing.left && !trimmedStart, right: !!resizing.right && !trimmedEnd }}
                bounds='.drag-table'
                dragAxis={dragAxisValue}
                disableDragging={!canDrag}
                onResizeStart={(e) => {
                    this.setState({ datesVisibility: Visibility.Always });
                    //prevent event bubble that initiates dradndrop of parent tr
                    e.stopPropagation();
                }}
                onResize={(e, direction, ref, delta, position) => {
                    resetSelection?.();
                    const startShift = position.x - this.state.left;
                    if (direction == "left") {
                        let startDate = shiftDate(scale, data.startDate, startShift, timelineWidth);
                        if (startDate >= data.finishDate) { startDate = data.finishDate.getBeginOfDay(); }
                        const rowShift: RowShift | undefined = this.props.resolvePosition?.(data, { startDate }, 0);
                        const state = {
                            startDate,
                            rowShift,
                            resizeWidth: ref.clientWidth
                        };
                        this.props.onSideShift?.(parseInt(this.state.width as string) - state.resizeWidth, 'left');
                        this.setState(state);
                    }
                    else {
                        let finishDate = shiftDate(scale, data.finishDate, delta.width, timelineWidth);
                        if (finishDate <= data.startDate) { finishDate = data.startDate.getEndOfDay(); }
                        const rowShift: RowShift | undefined = this.props.resolvePosition?.(data, { finishDate }, 0);
                        const state = {
                            resizeWidth: ref.clientWidth,
                            rowShift,
                            finishDate
                        }
                        this.props.onSideShift?.(state.resizeWidth - parseInt(this.state.width as string), 'right');
                        this.setState(state);
                    }
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    this.setState(prev => {
                        if (direction == "left") {
                            const { startDate, rowShift } = prev;
                            if (data.startDate.getTime() !== startDate.getTime()
                                || !!rowShift) {
                                onChange(data, { startDate }, rowShift);
                            }
                            const offset = getOffset(scale, startDate, timelineWidth);
                            const state = {
                                width: getOffset(scale, data.finishDate, timelineWidth).left - offset.left,
                                left: offset.left,
                                datesVisibility: undefined,
                                resizeWidth: undefined,
                                rowShift: undefined
                            };
                            this.props.onSideShift?.(parseInt(prev.width as string) - state.width, 'left');
                            return state;
                        }
                        else {
                            const { finishDate, rowShift } = prev;
                            const pxEnd = getOffset(scale, finishDate, timelineWidth);
                            if (data.finishDate.getTime() !== finishDate.getTime()
                                || !!rowShift) {
                                onChange(data, { finishDate }, rowShift);
                            }
                            const state = {
                                width: pxEnd.left - prev.left,
                                left: prev.left,
                                datesVisibility: undefined,
                                resizeWidth: undefined,
                                rowShift: undefined
                            };
                            this.props.onSideShift?.(state.width - parseInt(prev.width as string), 'right');
                            return state;
                        }
                    });

                }}
                onDragStart={(e) => {
                    if (dragAxisValue === 'both' || lockDateOnDnd) {
                        this.setState({ lockDateOnDnd: e.shiftKey });
                    }
                    //prevent event bubble that initiates dradndrop of parent tr
                    e.stopPropagation();
                }}
                onDrag={canDrag && !trimmed ? (e, d) => {
                    resetSelection?.();
                    if (this.state.datesVisibility === undefined) {
                        this.setState({ datesVisibility: Visibility.Always });
                    }
                    const shiftX = (dragAxisValue === 'x' || dragAxisValue === 'both') ? d.x - this.state.left : 0;
                    const shiftY = (dragAxisValue === 'y' || dragAxisValue === 'both') ? d.y : undefined;
                    const startDate = shiftDate(scale, data.startDate, shiftX, timelineWidth);
                    const finishDate = shiftDate(scale, data.finishDate, shiftX, timelineWidth);
                    const rowShift: RowShift | undefined = this.props.resolvePosition?.(data, { startDate, finishDate }, shiftY);

                    this.setState({
                        startDate,
                        finishDate,
                        rowShift
                    });
                    this._suppressOnClick = true;
                    this.props.onDrag?.(shiftX, shiftY);
                } : undefined}
                onDragStop={canDrag && !trimmed ? (e, d) => {
                    const shift = d.x - this.state.left;

                    if (!shift && !this.state.rowShift) {
                        this._suppressOnClick = false;
                        this.setState({ datesVisibility: undefined });
                        this.props.onDrag?.(0);
                    } else {
                        this.setState(prev => {
                            const { startDate, finishDate, rowShift } = prev;

                            if (data.startDate.getTime() !== startDate.getTime()
                                || data.finishDate.getTime() !== finishDate.getTime()
                                || !!rowShift) {
                                onChange(data, { startDate, finishDate }, rowShift);
                            }

                            const state = {
                                left: getOffset(scale, startDate, timelineWidth).left,
                                datesVisibility: undefined,
                                rowShift: undefined
                            };
                            this.props.onDrag?.(state.left - prev.left);
                            return state;
                        });
                    }
                } : undefined}
            >
                {this._renderBody()}
            </Rnd>
            : <div className={className}
                onBlurCapture={e => {
                    //nail: allows interactive contents inside the tooltip
                    if (!~e.target.className?.indexOf(WITH_BLUR)) {
                        e.stopPropagation();
                    }
                }}
                style={{ ...data.style, ...this.state }}>
                {this._renderBody()}
            </div>}
            {isSelected && !this.props.hideCommandPanel && 
                <TimelineElementCommandsPanel className='segment'
                    x={this.state.left}
                    y={-27}
                    parentWidth={this.state.width}
                    timelineWidth={this.props.timelineWidth}
                    commands={this.props.buildCommandsMenu?.(data)} />}
            </>;
    }

    componentWillReceiveProps(props: ICanvasInnerComponentProps<ITimelineSegment>) {
        if (props.scale.totalWidthUnits !== this.props.scale.totalWidthUnits
            || props.scale.dates[0].dateFormatted !== this.props.scale.dates[0].dateFormatted
            || props.scale.dates.slice(-1)[0].dateFormatted !== this.props.scale.dates.slice(-1)[0].dateFormatted
            || props.timelineWidth !== this.props.timelineWidth
            || props.data.startDate.getTime() !== this.props.data.startDate.getTime()
            || props.data.finishDate.getTime() !== this.props.data.finishDate.getTime()) {
            this.setState(this._buildState(props));
        }
    }

    _renderBody(): JSX.Element {
        const { data, renderTooltipContent, renderContent, onClick, isSelected, hideCommandPanel } = this.props;

        return <div className="segment-body overflow-text"
            style={{ width: '100%', height: '100%' }}
            onDoubleClick={this.props.onDoubleClick ? (ev: React.MouseEvent<HTMLElement>) => this.props.onDoubleClick?.(data, ev) : undefined}
            onClick={(ev: React.MouseEvent<HTMLElement>) => {
                ev.stopPropagation();
                if (this._suppressOnClick) {
                    this._suppressOnClick = false;
                    return;
                }
                onClick?.(data, ev);
            }}>
            <TooltipHost
                styles={{ root: { width: '100%', height: '100%' } }}
                tooltipProps={{
                    onRenderContent: renderTooltipContent && !this._suppressOnClick ? () => renderTooltipContent(data) || null : undefined,
                    maxWidth: "464px"
                }}
                calloutProps={{ hidden: isSelected && !hideCommandPanel }}
                closeDelay={100}
                delay={TooltipDelay.long}>
                {this._datesVisibility() !== undefined && this._renderStartFinishDates()}
                {renderContent && renderContent(data)}
            </TooltipHost>
        </div>;
    }

    private _datesVisibility = () =>
        this.props.isSelected && !this.props.hideCommandPanel ? undefined : (this.state.datesVisibility ?? this.props.data.datesVisibility);

    private _isShorterThan = (width: number) => {
        return parseInt((this.state.resizeWidth !== undefined ? this.state.resizeWidth : this.state.width) as string) < width;
    }

    private _renderStartFinishDates = (): JSX.Element => {
        const start = FormatDate(this.state.startDate);
        const finish = FormatDate(this.state.finishDate);
        const { availableSpace } = this.props.data;
        if (!this.props.largeMode && this.state.datesVisibility === undefined && availableSpace !== undefined) {
            if (availableSpace < minWidthToShowOneDate) {
                return <div className="date-marker start"><Icon iconName="PPMXStartIcon" /></div>;
            }
            if (availableSpace < minWidthToShowFullDates) {
                return <div className="date-marker start"><Icon iconName="PPMXStartIcon" />{start}...</div>;
            }
        }

        return this._isShorterThan(minWidthToShowFullDates)
            ? <div className="date-marker start overflow-text"><Icon iconName="PPMXStartIcon" />{start} - {finish}<Icon iconName="PPMXFinishIcon" /></div>
            : <>
                <div className="date-marker start"><Icon iconName="PPMXStartIcon" />{start}</div>
                <div className="date-marker finish">{finish}<Icon iconName="PPMXFinishIcon" /></div>
            </>;
    }

    private _buildState(props: ICanvasInnerComponentProps<ITimelineSegment>): ITimelineSegmentState {
        let segment = TimelineSegment.calculateSegment(props.scale, props.data, props.timelineWidth);
        return {
            ...segment,
            startDate: props.data.startDate,
            finishDate: props.data.finishDate
        }
    }

    public static calculateSegment(scale: IScale, segment: ITimelineSegment, timelineWidth: number) {
        const start = getOffset(scale, segment.startDate, timelineWidth);
        const end = getOffset(scale, segment.finishDate, timelineWidth);

        return {
            left: start.left,
            width: Math.max(TimelineSegment.minSegmentWidth, end.left - start.left),
            trimmedStart: start.isOutOfScale,
            trimmedEnd: end.isOutOfScale,
            isOutOfScale: start.isOutOfScale && end.isOutOfScale && start.left == end.left
        }
    }
}