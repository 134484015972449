import * as React from 'react';
import { Overlay, PanelType } from 'office-ui-fabric-react';
import * as Metadata from '../../../../../entities/Metadata';
import { notUndefined } from "../../../../utils/common";
import { IFieldActions } from '../../../../field/FieldPanel';
import { FieldsList } from '../../../../field/ConfigureFields/FieldsList';
import ExpandablePanel from '../../../ExpandablePanel';
import { EntityType } from '../../../../../entities/common';
import Spinner from '../../../Spinner';

interface IStatusSelectionPanelProps {
    statuses: string[];
    selected: string[];
    entityType: EntityType;
    onChange?: (fields: string[]) => void;
    onDismiss: () => void;
    getStatusField: (status: string) => Metadata.Field | undefined;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    showSpinner?: boolean;
}

interface IStatusField extends Metadata.Field {
    key: string;
}

export const StatusSelectionPanel = (props: IStatusSelectionPanelProps) => {
    const fields: IStatusField[] = React.useMemo(
        () => props.statuses
            .map(_ => ({ key: _, field: props.getStatusField(_) }))
            .filter(_ => !!_.field)
            .map(_ => ({ ..._.field!, key: _.key })),
        [props.statuses, props.getStatusField]);
    const selected = React.useMemo(
        () => props.selected.map(_ => props.getStatusField(_)?.name).filter(notUndefined),
        [props.selected, props.getStatusField]);
    const onRenderHeader = React.useCallback(() => <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">Configure View</p>
        <div className='ms-Panel-secondaryText'>Select the statuses to be displayed in the view. Drag and drop to reorder.</div>
    </div>, []);
    
    return (
        <ExpandablePanel
            key='selector_panel'
            isOpen={true}
            isLightDismiss={!props.showSpinner}
            type={PanelType.custom}
            customWidth="400px"
            focusTrapZoneProps={{ disableFirstFocus: true }}
            onRenderHeader={onRenderHeader}
            onDismiss={props.onDismiss}
        >
            <FieldsList
                entityType={props.entityType}
                fields={fields}
                selected={selected}
                allowManageFields={props.allowManageFields}
                fieldActions={props.fieldActions}
                onChange={props?.onChange 
                    ? (_: IStatusField[]) => props.onChange!(_.map(__ => __.key)) 
                    : undefined}
            />
            {props.showSpinner && <Overlay><Spinner /></Overlay>}
        </ExpandablePanel>
    );
}