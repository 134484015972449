import { Action, Reducer } from 'redux';
import { remove, post } from "../fetch-interceptor";
import { AppThunkAction } from "./";
import { IEntityStore, StoreHelper, partialUpdate } from "./services/storeHelper";
import { defaultCatch } from "./utils";
import { IUpdateLayoutInfo, Layout, Section, Field, FieldType } from '../entities/Metadata';
import { Dictionary } from '../entities/common';

export const _layoutFakeFieldName = '_layout';
export function buildLayoutFakeField(id: string): Field {
    return {
        id,
        type: FieldType.Integer,
        name: _layoutFakeFieldName,
        label: 'Layout',
        isNative: false,
        isCustom: false,
        isFake: true,
        isReadonly: true,
        isSystem: true,
        settings: {
            views: {
                list: {
                    componentPath: "LayoutName",
                    maxWidth: 150,
                    minWidth: 150
                }
            }
        }
    };
};

export interface LayoutsState extends IEntityStore<Layout> {
    isApplyingLayout?: boolean;
}

export type UIControlSettingsUpdatedAction = {
    type: "UICONTROL_SETTINGS_UPDATED";
    entity: string;
    layoutId: string;
    sectionId: string;
    uiControlId: string;
    settings: Dictionary<any>;
}

export type LayoutSectionsUpdatedAction = {
    type: "LAYOUT_SECTIONS_UPDATED";
    entity: string;
    layoutId: string;
    sections: Section[];
}

export type ApplyLayout = {
    type: 'APPLY_LAYOUT';
    entity: string;
}

export type LayoutApplied = {
    type: 'LAYOUT_APPLIED';
    entity: string;
}

type RemoveLayoutAction = {
    entity: string;
    type: 'REMOVE_LAYOUT_SUCCESS';
    id: string;
}

type LayoutsLoadedAction = {
    type: 'LAYOUTS_LOADED';
    entity: string;
    layouts: Layout[];
}

type LayoutsUpdatedAction = {
    type: 'LAYOUTS_UPDATED';
    entity: string;
    layouts: Layout[];
}

type LayoutUpdatedAction = {
    type: "LAYOUT_UPDATED";
    entity: string;
    layout: Layout;
}

interface ViewLayout {
    type: 'VIEW_LAYOUT';
    entity: string;
    id?: string;
}

type KnownAction = 
    | LayoutsLoadedAction
    | LayoutsUpdatedAction
    | UIControlSettingsUpdatedAction
    | LayoutSectionsUpdatedAction
    | LayoutUpdatedAction
    | RemoveLayoutAction
    | ApplyLayout
    | LayoutApplied
    | ViewLayout;

export const actionCreators = {
    forEntity: (entity: string) => ({
        saveLayout: (layoutId: string | undefined, sections: Section[], update: IUpdateLayoutInfo, callback?: (layoutId: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Layout[]>(`api/metadata/${entity}/layout`, { layoutId, update, sections })
                .then(_ => {
                    dispatch({ entity, type: 'LAYOUTS_UPDATED', layouts: _ });
                    callback?.(layoutId ?? _[_.length - 1].id);
                })
                .catch(defaultCatch(dispatch));
        },
        removeLayout: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<{ id: string; }>(`api/metadata/${entity}/layout/${id}`)
                .then(_ => { dispatch({ entity, type: "REMOVE_LAYOUT_SUCCESS", id }); })
                .catch(defaultCatch(dispatch));
        },
        viewLayout: (id?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ entity, type: 'VIEW_LAYOUT', id });
        }
    })
};

export const reducer: Reducer<LayoutsState> = (state: LayoutsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LAYOUTS_LOADED': return {
            ...StoreHelper.create(action.layouts),
            isLoading: false,
            isApplyingLayout: false
        }
        case 'LAYOUTS_UPDATED': return {
            ...state,
            ...StoreHelper.union(state, action.layouts),
            isLoading: false,
            isApplyingLayout: false
        }
        case 'LAYOUT_UPDATED': return {
            ...state,
            ...StoreHelper.addOrUpdate(state, action.layout),
            isLoading: false
        }
        case 'REMOVE_LAYOUT_SUCCESS': return {
            ...state,
            ...StoreHelper.remove(state, action.id)
        }
        case 'UICONTROL_SETTINGS_UPDATED': return {
            ...state,
            ...StoreHelper.applyHandler(state, action.layoutId,
                (_: Layout) => partialUpdate(_, {
                    sections: _.sections?.map(section =>
                        action.sectionId === section.id
                            ? {
                                ...section, uiControls: section.uiControls?.map(uiControl =>
                                    action.uiControlId === uiControl.id
                                        ? { ...uiControl, settings: action.settings }
                                        : uiControl)
                            }
                            : section)
                })),
            isLoading: false
        }
        case 'LAYOUT_SECTIONS_UPDATED': return {
            ...state,
            ...StoreHelper.applyHandler(state, action.layoutId,
                (_: Layout) => partialUpdate(_, {
                    sections: action.sections
                })),
            isLoading: false
        }
        case 'APPLY_LAYOUT': return {
            ...state,
            isApplyingLayout: true
        }
        case 'LAYOUT_APPLIED': return {
            ...state,
            isApplyingLayout: false
        }
        case 'VIEW_LAYOUT': {
            const activeEntity = (action.id && state.byId[action.id] ? state.byId[action.id] : undefined);
            return {
                ...state,
                activeEntity,
                activeEntityId: activeEntity?.id
            };
        }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}