import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import { IEntityListProps } from './interfaces/IEntity';
import * as Metadata from '../../entities/Metadata';
import { IExtensibleEntity, Dictionary, EntityType } from '../../entities/common';
import { DisplayFieldService } from "../common/DisplayFieldService";
import { Column, RowsChangeData, EditorProps, FillEvent, PasteEvent, FormatterProps } from "react-data-grid";
import ColumnsPanel from "../field/ColumnsPanel";
import { IFormInputComponent } from "./interfaces/IFormInputComponent";
import { IValidator } from "../../validation";
import { toDictionary, notUndefined, toNameDictionary, isLockedField } from '../utils/common';
import { Prompt } from "react-router-dom";
import { DefaultButton } from 'office-ui-fabric-react';
import { ConfirmationDialog } from './ConfirmationDialog';
import DataGrid from './DataGrid/DataGrid';
import { IInputProps } from './interfaces/IInputProps';
import { TenantState } from '../../store/Tenant';

type OwnProps<T extends IExtensibleEntity> = IEntityListProps<T> & {
    entityType: EntityType;
    fields: Metadata.Field[];
    selectedFieldIds: string[];
    readOnlyFieldNames?: string[];
    attributesCustomRender: Dictionary<IBulkEditInput>;
    save: (updates: Dictionary<unknown>) => void;
    close: () => void;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
}

type Props<T extends IExtensibleEntity> = OwnProps<T> & StateProps;


type BulkEntity = IExtensibleEntity & { isEditable: boolean };

const hasFieldAccess = (field: Metadata.Field, user: UserState, tenant: TenantState, entityType: EntityType): boolean => {
    const isLocked = isLockedField(field.settings?.format, field.name, user, tenant, entityType)
    return !isLocked;
}

const rowKeyGetter = <T extends BulkEntity>(row: T) => row.id;
const onPaste = <T extends BulkEntity>(event: PasteEvent<T>) => ({
    ...event.targetRow,
    attributes: {
        ...event.targetRow.attributes,
        [event.targetColumnKey]: event.sourceRow.attributes[event.sourceColumnKey]
    }
})
const onFill = <T extends BulkEntity>(event: FillEvent<T>) => ({
    ...event.targetRow,
    attributes: {
        ...event.targetRow.attributes,
        [event.columnKey]: event.sourceRow.attributes[event.columnKey]
    }
})

const EntitiesBulkEdit = <T extends BulkEntity>(props: Props<T>) => {
    const [isColumnsPanelOpen, setColumnsPanelOpen] = React.useState(false);
    const [isPrompting, setPromting] = React.useState(false);
    const [isSaved, setIsSaved] = React.useState(true);
    const [updates, setUpdates] = React.useState<Dictionary<Dictionary<unknown>>>();
    const [selectedFieldNames, setSelectedFieldNames] = React.useState(() => {
        const fieldsMap = toDictionary(props.fields);
        return props.selectedFieldIds.map(_ => fieldsMap[_]).filter(notUndefined).map(_ => _.name);
    });

    const onSave = (close?: boolean) => {
        if (isSaved || !updates || !Object.keys(updates).length) {
            return;
        }

        props.save(updates);

        setIsSaved(true);
        setUpdates(undefined);
        close && props.close();
    }

    const onClose = (force?: boolean) => {
        if (isSaved) {
            props.close();
        } else if (force) {
            setIsSaved(true);
            setUpdates(undefined);
            setTimeout(() => props.close(), 0);
        } else {
            setPromting(true);
        }
    }

    const accessibleFields = React.useMemo(
        () => props.fields.filter(_ => hasFieldAccess(_, props.user, props.tenant, props.entityType)),
        [props.user, props.tenant, props.fields, props.entityType]);

    const selectedFields = React.useMemo(() => {
        const map = toNameDictionary(accessibleFields);
        return selectedFieldNames
            .map(_ => map[_])
            .filter(notUndefined);
    }, [selectedFieldNames, accessibleFields]);

    const columns = React.useMemo(() => selectedFields.map(field => {
        const input = props.attributesCustomRender[field.name];
        const readonly = field.isReadonly || (props.readOnlyFieldNames && props.readOnlyFieldNames.includes(field.name));
        const column: Column<T> = {
            key: field.name,
            name: Metadata.getLabel(field),
            editor: readonly ? null : (props => <CellEditor {...props} field={field} input={input} />),
            editable: row => row.isEditable && (input?.editable?.(row) ?? true) && !readonly,
            formatter: formatterProps => <CellFormatter {...formatterProps} field={field} entityType={props.entityType} />,
            resizable: true,
        }
        return column;
    }), [selectedFields, props.readOnlyFieldNames, props.attributesCustomRender]);

    const onFieldSelected = (field: Metadata.Field, checked: boolean, selectedIndex?: number) => {
        const selected = columns.map(_ => _.key).filter(_ => _ !== field.name);
        if (checked) {
            selected.splice(selectedIndex!, 0, field.name);
        }

        setSelectedFieldNames(selected);
    }

    const [entities, setEntities] = React.useState(props.entities);
    const onRowsChange = (rows: T[], data: RowsChangeData<T>) => {
        setEntities(rows);
        setUpdates(state => {
            state ??= {};
            data.indexes.forEach(index => {
                const row = rows[index];
                state![row.id] = {
                    ...state![row.id],
                    [data.column.key]: row.attributes[data.column.key]
                }
            })

            return { ...state };
        });
        setIsSaved(false);
    }

    return <>
        <div className="header" key="bulk-edit-header">
            <div className="align-center">
                <div className="primaryItems">
                    <DefaultButton
                        className="dropdown-button"
                        text="Save"
                        title="Save"
                        iconProps={{ iconName: "Save" }}
                        onClick={_ => onSave()} />
                    <DefaultButton
                        className="dropdown-button"
                        text="Columns"
                        title="Select columns"
                        iconProps={{ iconName: "Settings" }}
                        onClick={() => setColumnsPanelOpen(true)}
                    />
                </div>
                <div className="sideItems">
                    <DefaultButton
                        className="dropdown-button"
                        text="Close"
                        title="Close"
                        iconProps={{ iconName: "Cancel" }}
                        onClick={() => onClose()} />
                </div>
            </div>
        </div>
        <div className="entities-bulkedit-grid" key="bulk-edit">
            <DataGrid<T>
                defaultColumnOptions={{ minWidth: 150 }}
                columns={columns}
                rows={entities}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={onRowsChange}
                onFill={onFill}
                onPaste={onPaste}
            />
        </div>
        {
            isColumnsPanelOpen && <ColumnsPanel
                key='columns-panel'
                entityType={props.entityType}
                onDismiss={() => setColumnsPanelOpen(false)}
                fields={accessibleFields}
                selected={selectedFieldNames}
                onChange={onFieldSelected}
                onSelectedOrderChanged={setSelectedFieldNames}
                secondaryText='Select the columns to be displayed on the Bulk Edit page. Drag and drop to reorder.'
            />
        }
        {
            isPrompting && <ConfirmationDialog
                onDismiss={() => setPromting(false)}
                onYes={() => onSave(true)}
                onNo={() => onClose(true)}
                yesButtonProps={{ text: "Save" }}
                noButtonProps={{ text: "Discard" }}
                dialogContentProps={{
                    title: 'Confirm',
                    subText: 'You have unsaved changes. Please click Save to save the changes or Discard to discard them.'
                }} />
        }
        <Prompt
            key="prompt"
            when={!isSaved}
            message={() => {
                setPromting(true);
                return false;
            }}
        />
    </>;
}

export interface IBulkEditInput {
    render?: (props: IInputProps, field: Metadata.Field, entity: any) => JSX.Element | null;
    validator?: (attributes: any) => IValidator;
    editable?: (entity: any) => boolean;
}

const CellFormatter = <T extends BulkEntity>(props: FormatterProps<T> & { field: Metadata.Field, entityType: EntityType }) => {
    const Formatter = DisplayFieldService.getFieldFormatter(props.field, false, props.entityType);
    const value = props.row.attributes[props.column.key];
    if (Formatter) {
        return <Formatter {...props} value={value} />;
    }
    return <>{value !== undefined && value !== null ? `${value}` : ''}</>;
}

const CellEditor = <T extends BulkEntity>(props: EditorProps<T> & { field: Metadata.Field, input?: IBulkEditInput }) => {
    const inputProps: IInputProps = {
        onEditComplete: () => { },
        onChanged: (fieldValue: any | null) => {
            const validator = props.input?.validator?.(props.row);
            if (validator && !validator.isValid(fieldValue)) {
                return;
            }

            props.onRowChange({
                ...props.row,
                attributes: {
                    ...props.row.attributes,
                    [props.column.key]: fieldValue
                }
            });
        },
        isConfigureMode: true,
        hideLabel: true,
        value: props.row.attributes[props.column.key],
        inputRef: (_: IFormInputComponent) => _ && _.focus()
    };

    return props.input?.render ? props.input.render(inputProps, props.field, props.row) : DisplayFieldService.buildCompactFieldInputElement(props.field, inputProps);
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
    user: state.user,
    tenant: state.tenant
});

export default function <T extends BulkEntity>() {
    return connect(mapStateToProps, {})(EntitiesBulkEdit);
}