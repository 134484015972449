import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IFormInputProps } from '../../interfaces/IFormInputProps';
import { FormatType } from "../../../../entities/Metadata";
import { formatValue } from "../../../utils/common";
import { IFormInputComponent } from "../../interfaces/IFormInputComponent";
import { ITextField, ITextFieldProps } from "office-ui-fabric-react/lib/components/TextField/TextField.types";
import { Validator, ValidatorType } from '../../../../validation';
import ShowMoreComponent from '../../ShowMoreComponent';
import { getId } from 'office-ui-fabric-react';
import './TextInput.css';

//similar consts are set on the backend on in Validator.Text class
export const MULTILINE_MAX_LENGTH = 8000;
export const REGULAR_MAX_LENGTH = 255;

export const WITH_BLUR = "with-blur";
type TextInputProps = IFormInputProps<string, HTMLTextAreaElement> & {
    format?: FormatType;
    isNumber?: boolean;
    maxLength?: number;
    errorMessage?: string | JSX.Element;
    showMoreMode?: boolean;
    allowForceUpdate?: boolean;
}

type StringComponentState = {
    value: string;
    isFocused: boolean;
}

export default class TextInput extends React.Component<TextInputProps, StringComponentState> implements IFormInputComponent {
    private _textField?: ITextField;
    private readonly _key = getId();

    componentWillMount() {
        let newValue = this.props.value ?? "";

        if (this._isSupportFormattedInput()) {
            newValue = formatValue(newValue, this.props.format);
        }

        this.setState({ ...this.state, ...{ value: newValue } });
    }

    componentDidMount() {
        this.props.inputRef?.(this);
    }

    componentWillReceiveProps(nextProps: TextInputProps) {
        const isDirty = (this.props.value ?? "") !== this._getParsedValue(this.state.value);

        if (this.props.value !== nextProps.value && (!isDirty || nextProps.allowForceUpdate)) {
            let newValue = nextProps.value ?? "";

            if (this._isSupportFormattedInput()) {

                //do not re-format untill typing
                if (!this.state.isFocused) {
                    newValue = formatValue(newValue, this.props.format);
                    this.setState({ value: newValue });
                }
            }
            else {
                this.setState({ value: newValue });
            }
        }
    }

    public render() {
        const value = this.state.value ?? "";
        const parsedValue = this._getParsedValue(value);
        
        const { id, disabled, readOnly, inputProps, errorMessage, selectOnFocus, showMoreMode, onFocus, onBlur } = this.props;
        const { isFocused } = this.state;

        const isMultiline = inputProps?.multiline;
        let placeholder = inputProps?.placeholder;

        if (!placeholder && isFocused && this._isSupportFormattedInput()) {
            placeholder = this._getFormattedInputPlaceholder();
        }

        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;

        const formattedValue = !value && placeholder
            ? undefined
            : this.props.format !== undefined || this.props.isNumber
                ? formatValue(parsedValue, this.props.format)
                : value;
        const isValid = this._validate(parsedValue);

        const textFieldProps: ITextFieldProps = {
            id,
            componentRef: (_: ITextField | null) => { _ && (this._textField = _) },
            multiline: isMultiline,
            maxLength: this._getMaxLength(),
            placeholder,
            autoAdjustHeight: showMoreMode || inputProps?.autoAdjustHeight,
            borderless: (showMoreMode && isValid) || inputProps?.borderless,
            readOnly: isReadOnly,
            value: isFocused ? value : formattedValue,
            tabIndex: isReadOnly ? -1 : undefined,
            onKeyPress: !!isMultiline || isReadOnly || isDisabled ? undefined : (e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    this._textField?.blur();
                }
            }),
            onKeyDown: !!isMultiline || isReadOnly || isDisabled ? undefined : (e => {
                if (e.key === 'Escape') {
                    this.setState({ value: this.props.value ?? "" }, () => {
                        this._textField?.blur();
                    });
                }
            }),
            onChange: this._changeValue,
            disabled: isDisabled,
            onFocus: e => {
                selectOnFocus && this._textField?.select();
                this.setState({ isFocused: true });
                onFocus?.(e);
            },
            onBlur: e => {
                this.setState({ isFocused: false });
                !isReadOnly && !disabled && this._saveChanges();
                onBlur?.(e);
            },
            errorMessage,
            onGetErrorMessage: _ => this._getErrorMessage(parsedValue),
            inputClassName: WITH_BLUR,
        };
        
        const textFieldKey = `${this.props.id || this._key}_${this.props.inputProps?.required || this.props.validator?.has(ValidatorType.required) ? "required" : ""}`;
        return this.props.showMoreMode
            ? <ShowMoreComponent containerClassName={`show-more-field ${isValid ? 'valid' : 'invalid'}`} containerMaxHeight={inputProps?.containerMaxHeight}>
                    <TextField key={textFieldKey} {...textFieldProps}/>
                </ShowMoreComponent>
            : <TextField key={textFieldKey} {...textFieldProps} />;
    }

    private _saveChanges = () => {
        let value = this.state.value.trim();

        const parsed = this._getParsedValue(value);

        let isValid = this._validate(parsed);

        if (!isValid && this.props.resetInvalidOnBlur) {
            value = (this.props.value ?? "").trim();
            
            isValid = this._validate(value);
        }
        else if (this._isSupportFormattedInput()) {
            value = formatValue(parsed, this.props.format);
        }

        this.setState({ value });

        isValid && this.props.onEditComplete?.(parsed);
    }

    private _changeValue = (e: any, value: string) => {
        this.setState({ value });
        
        const parsed = this._getParsedValue(value);

        this.props.onChanged?.(parsed);
    }

    private _validate = (value: string) => {
        return this.props.validator === undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value: string): string | undefined => {
        const maxLength = this._getMaxLength();
        return this.props.inputProps?.required && Validator.new().required().build().getErrorMessage(value)
            || Validator.new().maxLength(maxLength).build().getErrorMessage(value)
            || this.props.validator?.getErrorMessage(value);
    }

    private _getMaxLength = () => {
        const { maxLength, inputProps } = this.props;
        return maxLength ?? (inputProps?.multiline ? MULTILINE_MAX_LENGTH : REGULAR_MAX_LENGTH);
    }

    public focus() {
        this._textField?.focus();
    }

    
    private _getParsedValue = (value: string): any => {
        if (this.props.format) {
            const [isValid, parsed] = this._tryParseValue(value);

            if (isValid) {
                return parsed.toString();
            }
        }

        return value;
    }

    private _isSupportFormattedInput = (): boolean => {

        return this.props.format === FormatType.Duration
            || this.props.format === FormatType.Days;
    }

    private _getFormattedInputPlaceholder = (): string | undefined => {

        switch (this.props.format) {
            case FormatType.Duration:
                if (this.props.validator?.has(ValidatorType.int32)) {
                    return "0h";
                }
                
                return "0h 00m";
            case FormatType.Days:
                return "0d";
            
            default:
                return undefined;
        }
    }

    private _tryParseValue = (value: string): [isValid: boolean, parsed: any] => {
        switch (this.props.format) {
            case FormatType.Duration:
                return _tryParseDuration(value);
            case FormatType.Days:
                return _tryParseDays(value);
            default:
                return [false, undefined];
        }
    }
}

const _durationRegex = new RegExp("^((\\d*\\.?\\d*)h)?\\s?(\\d*\\.?\\d*)m?$");

const _tryParseDuration = (value: string): [isValid: boolean, parsedHours: number] => {

    let isValid = false;
    let parsedHours = NaN;

    if (!value) {
        return [isValid, parsedHours];
    }
     
    [isValid, parsedHours] = _tryParseNumber(value);

    if (isValid) {
        return [isValid, parsedHours];
    }

    if (_durationRegex.test(value)) {
        
        const executed = _durationRegex.exec(value);
        const hoursGroup = executed?.[2];
        const minutesGroup = executed?.[3];

        const hours = hoursGroup && Number.parseFloat(hoursGroup) || 0;
        const minutes = minutesGroup && Number.parseFloat(minutesGroup) || 0;

        parsedHours = hours +  minutes / 60;
        isValid = true;
    }

    
    return [isValid, parsedHours];
}

const _daysRegex = new RegExp("^(\\d*\\.?\\d*)d?$");

const _tryParseDays = (value: string): [isValid: boolean, parsedDays: number] => {

    let isValid = false;
    let parsedDays = NaN;

    if (!value) {
        return [isValid, parsedDays];
    }
     
    [isValid, parsedDays] = _tryParseNumber(value);

    if (isValid) {
        return [isValid, parsedDays];
    }

    if (_daysRegex.test(value)) {
        
        const executed = _daysRegex.exec(value);
        const daysGroup = executed?.[1];

        parsedDays = daysGroup && Number.parseFloat(daysGroup) || 0;

        isValid = true;
    }

    
    return [isValid, parsedDays];
}


const _tryParseNumber = (value: string): [ isValid: boolean, parsed: number ] => {
    const parsed = Number(value);
    const isValid = !isNaN(parsed);

    
    return [ isValid, parsed ];
 }