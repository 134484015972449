import * as React from 'react';
import * as TimelineList from '../common/timeline/TimelineList';
import { IRoadmapItem, IRoadmapItemDependency, RoadmapItemType } from '../../entities/Subentities';
import { EntityGroup } from "../common/extensibleEntity/EntityGroupHeader";
import { StatusCategory, Dictionary, EntityType, IExtensibleEntity, PROGRESS_COMPLETED, IStyleSettingValues } from "../../entities/common";
import * as utils from "../common/timeline/utils";
import { toDate } from '../utils/common';
import { Visibility, ITimelineSegment } from '../common/timeline/TimelineSegment';
import { calculateShadeColor } from '../task/TaskIcon';
import { ungroupedGroup } from '../common/inputs/GroupDropdown';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import { TimelineProgress } from '../task/timeline';
import { Field, IGroupInfo } from '../../entities/Metadata';
import RoadmapLaneTooltipContent, { GroupSummary } from './RoadmapLaneTooltipContent';
import { isPlanned, PlanStateField } from '../../store/RoadmapsListStore';
import { ITimelineRelation, RelationItemMarker, RelationItemSegment, TimelineEntityType, TimelineRelationType } from '../common/timeline/TimelineRelation';
import { Icon } from 'office-ui-fabric-react';
import { CalendarDataSet } from '../../store/CalendarStore';
import { TooltipFieldSettings } from './ConfigureRoadmapTooltipPanel/ConfigureRoadmapTooltipPanel';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

const colorLightnessPercent: number = .5;

export const getColor = (item: IRoadmapItem, roadmapItemFields: Field[], useStatus: boolean): string => {
    if (useStatus) {
        const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.RoadmapItem, roadmapItemFields)!;
        return item.attributes.Status
            ? statusDescriptor.getColorOrDefault(item.attributes.Status, StatusCategory.NA)
            : statusDescriptor.getCategoryDefaultOption(StatusCategory.NA).color
    }
    return item.attributes.Label?.color ?? item.attributes.Lane?.color ?? ungroupedGroup.color;
};

export const isMilestone = (item: IRoadmapItem): boolean => item.attributes.Type === RoadmapItemType.KeyDate;

export interface IRoadmapStyleSettingValues extends IStyleSettingValues {
    showDates: boolean;
    showProgress: boolean;
    showStatus: boolean;
    showWarnings: boolean;
    showLink: boolean;
    showTooltip: boolean;
    largeBars: boolean;
}

interface IMapSegment extends ITimelineSegment {
    hasWarnings?: boolean;
    hasLink?: boolean;
    color?: string;
    progress?: number;
}

export function buildMapSegment(
    item: IRoadmapItem,
    roadmapItemFields: Field[],
    styleSettings: IRoadmapStyleSettingValues
): IMapSegment | undefined {
    const startDate = toDate(item.attributes.StartDate),
        finishDate = toDate(item.attributes.FinishDate);

    if (!isMilestone(item) && startDate && finishDate) {
        const color = getColor(item, roadmapItemFields, styleSettings.showStatus);
        const progress = styleSettings.showProgress ? item.attributes.Progress : PROGRESS_COMPLETED;
        const hasWarnings = styleSettings.showWarnings && item.warnings && item.warnings.length > 0;
        const hasLink = styleSettings.showLink && item.externalData && !!item.externalData["ImportedFromId"] && !item.externalData["OriginIsDeleted"];
        return {
            key: item.id,
            startDate: startDate.getBeginOfDay(),
            finishDate: finishDate.getEndOfDay(),
            className: 'start-to-finish',
            datesVisibility: styleSettings.showDates ? Visibility.Always : undefined,
            style: { backgroundColor: calculateShadeColor(color, colorLightnessPercent, true), borderColor: color },
            entity: item,
            hasWarnings,
            color,
            progress,
            hasLink
        };
    }
}

export function buildMapMarker(
    item: IRoadmapItem,
    roadmapItemFields: Field[],
    styleSettings: IRoadmapStyleSettingValues
): ITimelineMarker | undefined {
    const finishDate = toDate(item.attributes.FinishDate);

    if (isMilestone(item) && finishDate) {
        const color = getColor(item, roadmapItemFields, styleSettings.showStatus);
        const backgroundColor = styleSettings.showProgress && item.attributes.Progress !== PROGRESS_COMPLETED
            ? calculateShadeColor(color, colorLightnessPercent, true)
            : color;

        const warningsCount = styleSettings.showWarnings ? item.warnings.length : 0;
        const hasLink = styleSettings.showLink && item.externalData && !!item.externalData["ImportedFromId"] && !item.externalData["OriginIsDeleted"];

        const largeFont = styleSettings.largeBars && !styleSettings.showDates;
        const textWidth = item.attributes.Name.getRenderWidth(largeFont ? "400 16px Segoe UI" : "400 14px Segoe UI");
        const textMinWidth = 12;
        const markerTextPaddingsInCss = 10;
        const linkWidth = 20;

        const contentWidth = textWidth + markerTextPaddingsInCss + (hasLink ? linkWidth : 0);
        const minWidthToRenderContent = textMinWidth + markerTextPaddingsInCss + (hasLink ? linkWidth : 0);
        return {
            date: finishDate.getBeginOfDay(),
            key: item.id,
            label: item.attributes.Name,
            style: { backgroundColor: color },
            datesVisibility: styleSettings.showDates ? Visibility.Always : undefined,
            entity: item,
            addContent: true,
            contentWidth,
            warningsCount,
            backgroundColor,
            hasLink,
            minWidthToRenderContent
        }
    }
}

function buildRelationItem(
    itemKey: string,
    itemsMap: Dictionary<IRoadmapItem>,
    roadmapItemFields: Field[],
    styleSettings: IRoadmapStyleSettingValues
): RelationItemSegment | RelationItemMarker | undefined {
    if (!itemKey) {
        return undefined;
    }
    const item = itemsMap[itemKey];
    if (!item) {
        return undefined;
    }
    const segment = buildMapSegment(item, roadmapItemFields, styleSettings);
    if (segment) {
        return { ...segment, type: TimelineEntityType.Segment };
    }
    const marker = buildMapMarker(item, roadmapItemFields, styleSettings);
    if (marker) {
        return { ...marker, type: TimelineEntityType.Marker };
    }
}

export function buildMapRelation(
    item: IRoadmapItem,
    dependency: IRoadmapItemDependency,
    itemsMap: Dictionary<IRoadmapItem>,
    roadmapItemFields: Field[],
    styleSettings: IRoadmapStyleSettingValues
): ITimelineRelation | undefined {
    if (!itemsMap) {
        return undefined;
    }

    const source = buildRelationItem(dependency.sourceId, itemsMap, roadmapItemFields, styleSettings);
    const target = buildRelationItem(dependency.targetId, itemsMap, roadmapItemFields, styleSettings);
    const directLink = item.id === dependency.sourceId;
    if (source && target) {
        return {
            key: dependency.id,
            entity: dependency,
            isReverse: !directLink,
            type: TimelineRelationType.RightToLeft,
            parent: directLink ? source : target,
            child: directLink ? target : source
        };
    }
}

export type GroupRowEntity = IExtensibleEntity & {
    attributes: { Lane: IGroupInfo } | {},
    externalData: Dictionary<any>
}

export const groupRowType = "group";

export function getTimelineMapGroupRow(group: EntityGroup): TimelineList.IRow {
    const entity: GroupRowEntity = {
        id: '',
        attributes: {
            Lane: { id: group.key, name: group.name, color: group.color }
        },
        externalData: {
        }
    };

    return {
        key: group.key,
        rowType: groupRowType,
        entity,
        segments: [],
        markers: []
    }
}

export function getListViewGroupRow(group: EntityGroup): TimelineList.IRow {
    const entity: GroupRowEntity = {
        id: '',
        attributes: {},
        externalData: {
            [PlanStateField]: isPlanned(group.key)
        }
    };

    return {
        key: group.key,
        rowType: groupRowType,
        entity,
        segments: [],
        markers: []
    }
}

export function renderLaneTooltipContent(lane: EntityGroup, settings: TooltipFieldSettings | undefined): JSX.Element {
    return <RoadmapLaneTooltipContent
        lane={{ name: lane.name, color: lane.color }}
        summary={lane.data}
        settings={settings}
    />;
}

export function renderSegmentContent(_: TimelineList.IRow, segment: IMapSegment): JSX.Element | undefined {
    const entity = segment.entity as IRoadmapItem;
    const { color, progress, hasWarnings, hasLink } = segment;
    return <>
        <TimelineProgress progress={progress} color={color} />
        {hasWarnings ? <div className="warning">
            <Icon iconName="WarningSolid" />
            {entity.warnings.length > 1 ? <div className="counter noselect">{entity.warnings.length}</div> : undefined}
        </div> : undefined}
        <div className={`segment-name overflow-text ${hasWarnings ? 'with-warnings' : ''}`}>{entity.attributes.Name}</div>
        {hasLink ? <div className="link"><Icon iconName="PPMXLinkWhite" /></div> : undefined}
    </>;
}

export function renderMarkerContent(_: TimelineList.IRow, marker: ITimelineMarker): JSX.Element | undefined {
    const entity = marker.entity as IRoadmapItem;
    const { hasLink } = marker;
    return <>
        <div className="marker-name overflow-text">{entity.attributes.Name}</div>
        {hasLink ? <div className="link"><Icon iconName="PPMXLinkWhite" /></div> : undefined}
    </>;
}


export function calculateRoadmapGroupSummary(items: IRoadmapItem[]): GroupSummary {
    const sum = items
        .reduce<{
            effort: number, cost: number, benefit: number, storyPoints: number,
            actual: number, new: number, inProgress: number, complete: number
        }>((prev, item, index, arr) => {
            const effort = item.attributes.EstimatedEffort || 0;
            const progress = item.attributes.Progress || 0;
            const actual = progress / PROGRESS_COMPLETED * effort;

            return {
                effort: prev.effort + effort,
                actual: prev.actual + actual,
                new: progress === 0 ? (prev.new + 1) : prev.new,
                inProgress: progress !== 0 && progress !== PROGRESS_COMPLETED ? (prev.inProgress + 1) : prev.inProgress,
                complete: progress === PROGRESS_COMPLETED ? (prev.complete + 1) : prev.complete,
                cost: prev.cost + (item.attributes.EstimatedCost || 0),
                storyPoints: prev.storyPoints + (item.attributes.StoryPoints || 0),
                benefit: prev.benefit + (item.attributes.EstimatedBenefit || 0)
            };
        }, { effort: 0, cost: 0, storyPoints: 0, benefit: 0, actual: 0, new: 0, inProgress: 0, complete: 0 });

    return {
        counts: {
            new: sum.new,
            inProgress: sum.inProgress,
            complete: sum.complete,
            total: items.length
        },
        progress: !sum.effort ? 0 : (PROGRESS_COMPLETED * sum.actual / sum.effort),
        effort: sum.effort,
        cost: sum.cost,
        storyPoints: sum.storyPoints,
        benefit: sum.benefit
    };
}

export function estimateRoadmapItemDuration(item: IRoadmapItem): number | undefined {
    const { minDate, maxDate } = utils.minMax([item.attributes.StartDate, item.attributes.FinishDate]);
    if (minDate && maxDate) {
        return utils.getDuration(minDate.getBeginOfDay(), maxDate.getEndOfDay());
    }
}

export function estimateRoadmapItemWorkDuration(item: IRoadmapItem, calendar: CalendarDataSet): number | undefined {
    const { minDate, maxDate } = utils.minMax([item.attributes.StartDate, item.attributes.FinishDate]);
    if (minDate && maxDate) {
        return utils.getWorkingDaysBetweenDates(minDate.getBeginOfDay(), maxDate.getEndOfDay(), calendar);
    }
}