import * as React from 'react';
import { IEntityListProps } from "../../common/interfaces/IEntity";
import * as Metadata from "../../../entities/Metadata";
import { EntityType, IExtensibleEntity, ISortState, updateCheckboxOptions } from "../../../entities/common";
import EntityDetailsList, { IListProps, IDetailsProps } from "../../common/extensibleEntity/EntityDetailsList";
import EntityTimelineList, { ITimelineProps } from "../../common/extensibleEntity/EntityTimelineList";
import {
    Selection, IDetailsHeaderProps, IRenderFunction, Sticky, ITooltipHostProps, TooltipHost,
    StickyPositionType, ScrollablePane, ScrollbarVisibility, IScrollablePane, ConstrainMode, Overlay, CheckboxVisibility, IContextualMenuItem
} from "office-ui-fabric-react";
import Spinner from "../../common/Spinner";
import { debounce, debounceDelay, notEmpty } from '../../utils/common';
import { HierarchyManager, IHierarchyItem, Sorter } from '../../utils/HierarchyManager';
import { SortService } from '../../../services/SortService';
import { PngExportConfig } from '../../common/PngExporter';
import SelectionModeCommandBar from '../../../components/common/SelectionModeCommandBar';

type ListViewProps<T extends IExtensibleEntity> = IEntityListProps<T> & {
    fields: Metadata.Field[];
    entityType: EntityType;
    selection: Selection;
    isFieldFake?: (field: Metadata.Field) => boolean;
    sort: ISortState;
    sorter?: Sorter<T>;
    onSortChange: (sort: ISortState) => void;
    view: Metadata.IListSubView;
    listProps: Partial<IListProps> & (IDetailsProps | ITimelineProps);
    selectionModeItems?: IContextualMenuItem[];
    showSpinner?: boolean;
    checkboxVisibility?: CheckboxVisibility;
    hierarchy?: HierarchyManager<T, void>;
    onColumnResized?: (fieldId: string, width: number) => void;
    scaleMultiplier?: number;
    type: 'Details' | 'Timeline';
};

type State = {
    displayFields: string[];
};

export default class ListSubView<T extends IExtensibleEntity> extends React.Component<ListViewProps<T>, State>{
    private _scrollablePane = React.createRef<IScrollablePane>();
    private _debounced = debounce<{ fieldId: string, width: number }>(debounceDelay);

    constructor(props: ListViewProps<T>) {
        super(props);
        this.state = this._buildState(props);
        
        const defaultSorter: Sorter<T> = (orderBy) => {
            const { fields, isFieldFake } = this.props;
            const fieldsMap = Metadata.toMap(fields, isFieldFake);
            return SortService.getComparer(fieldsMap, orderBy, isFieldFake);
        };

        props.hierarchy?.setup(
            this.props.sorter ?? defaultSorter,
            () => {
                const orderBy = props.hierarchy?.getOrderBy();
                if (!SortService.areSortsEqual(this.props.sort, orderBy)) {
                    orderBy && this.props.onSortChange(Array.isArray(orderBy) ? orderBy[0] : orderBy);
                    return;
                }
                
                this.forceUpdate();
            },
            props.sort);

        props.selection?.setAllSelected(false);;
    }

    componentWillReceiveProps(nextProps: ListViewProps<T>) {
        if (this.props.view != nextProps.view || this.props.fields != nextProps.fields) {
            this.setState(this._buildState(nextProps));
        }
    }

    public render() {
        const selectedCount = this.props.selection?.getSelectedCount();

        const listProps: IListProps = {
            entities: this.props.hierarchy
                ? this.props.hierarchy.getFlattened()
                : this.props.sorter
                    ? this.props.entities.sort(this.props.sorter(this.props.sort))
                    : this.props.entities,
            entityType: this.props.entityType,
            selection: this.props.selection,
            fields: this.props.fields,
            isFieldFake: this.props.isFieldFake,
            displayFields: this.state.displayFields,
            sorting: this.props.hierarchy?.getSortingProps() ?? {
                external: !!this.props.sorter,
                orderBy: this.props.sort,
                onChange: this.props.onSortChange
            },
            columns: this.props.view.columns,
            onColumnResize: this._onColumnResize,
            getKey: this.props.hierarchy?.getKey,
            ...updateCheckboxOptions({ checkboxVisibility: this.props.checkboxVisibility }, this.props.selectionModeItems, true)
        };

        const mergedItemRender: Partial<IListProps> = this.props.hierarchy && this.props.listProps.onItemRender
            ? {
                onItemRender: (entity, index, field, defaultRender) => {
                    return this.props.hierarchy?.renderItem(entity as T & IHierarchyItem, index, field,
                        () => this.props.listProps.onItemRender!(entity, index, field, defaultRender)
                    )!
                }
            }
            : {};

        return <div style={{ position: "relative" }}>
            {selectedCount! > 0 && <div className='entities-list-header' key="header">
                <SelectionModeCommandBar
                    selectedCount={selectedCount}
                    items={this.props.selectionModeItems}
                    onCancel={() => this.props.selection?.setAllSelected(false)}
                />
            </div>}
            <div data-is-scrollable className="list-container">
                {
                    this.props.type === "Details"
                        ? <ScrollablePane key="details" componentRef={this._scrollablePane} scrollbarVisibility={ScrollbarVisibility.auto} className={this.props.showSpinner ? "overlayed" : ""}>
                            <EntityDetailsList
                                key={this.props.view.id}
                                {...listProps}
                                {...this.props.listProps as IDetailsProps}
                                {...mergedItemRender}
                                fabricListProps={{
                                    constrainMode: ConstrainMode.unconstrained,
                                    onRenderDetailsHeader: this._onRenderDetailsHeader,
                                    ...(this.props.listProps as IDetailsProps).fabricListProps,
                                }}
                            />
                        </ScrollablePane>
                        : <EntityTimelineList
                            key={this.props.view.id}
                            {...listProps}
                            {...this.props.listProps as ITimelineProps}
                            {...mergedItemRender}
                            scaleMultiplier={this.props.scaleMultiplier}
                        />
                }
            </div>
            {this.props.showSpinner && <Overlay><Spinner /></Overlay>}
        </div>;
    }

    private _onColumnResize = (fieldId: string, width: number) => {
        this.props.onColumnResized && this._debounced({ fieldId, width }, this._onColumnResized);
    }

    private _onColumnResized = (data: { fieldId: string, width: number }) => {
        this.props.onColumnResized?.(data.fieldId, data.width);
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private _buildState(props: ListViewProps<T>): State {
        if (!props.view || !props.fields.length) {
            return { displayFields: [] };
        }

        const displayFields = props.view.columns
            .map(_ => {
                const field = props.fields.find(__ => __.id === _.id);
                return field ? (field.name || field.id) : undefined;
            })
            .filter(notEmpty);
        return { displayFields };
    }
}

export const getPngExportConfigSelectors = (activeView?: Metadata.ViewTypes): Partial<PngExportConfig> => ({
    elementSelector: activeView === Metadata.ViewTypes.list ? ".ms-ScrollablePane" : undefined,
    scrollableElementSelector: activeView === Metadata.ViewTypes.list ? ".ms-ScrollablePane--contentContainer .ms-Viewport" : undefined
});