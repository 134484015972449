import { IWithPinnedViews, IWithSections } from './../entities/Metadata';
import { get, post, remove } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IEntityStore, StoreHelper, partialUpdate, IDeletionResult, addOrUpdateOrRemove } from './services/storeHelper';
import { push, RouterAction } from 'react-router-redux';
import { Section, IFilter, BaseFilterValue } from '../entities/Metadata';
import { MetadataService, UpdateUIControlInfo, ActionsBuilder, namesof } from './services/metadataService';
import * as ExternalEpmConnectStore from "./ExternalEpmConnectStore";
import { O365GroupLinkInfo, ProjectInfo, CreateProjectSuccessAction, IPortfolioInfo } from "./ProjectsListStore";
import {
    Dictionary, IBlinePlanActual, ICalculation, EntityType, StatusCategory, Impact, IBaseEntity,
    IWithWarnings, IWithChangeHistory, IWithInsights, UpdateContext, IInsightsData, IWarning, IWithImage, IWithSourceInfos,
    IPatch, IWithLayout, IWithResourcePlan, IEditable, IWithName, IWithManager, IWithStartFinishDates, IWithBenefits
} from "../entities/common";
import { KeyDate, KeyDateWithWarnings, ActionItem, KeyDecision, Risk } from "../entities/Subentities";
import { defaultCatch } from "./utils";
import { ILinkDto } from './integration/common';
import { IWithPrioritiesAlignment } from './StrategicPrioritiesListStore';
import { TeamsChannelLink } from '../components/integration/TeamsChannelConnectControl';
import { ImportExportFactory } from './importExport';
import { ApplyLayout, LayoutApplied } from './layouts';
import * as NotificationsStore from "./NotificationsStore";
import { UpdateFilterAction } from './filters';
import { HierarchyContainerEntity } from '../components/utils/HierarchyContainer';
import { ChangeHistoryOperationsFactory, HISTORY_DEFAULT_STATE, IChangeHistoryState } from './HistoryListStore';
import { IPlanInfo } from './integration/Office365Store';
import { IJiraLinkInfo } from './integration/JiraStore';
import { ISpoProject } from './integration/SpoStore';
import { IMondayComBaseSourceData } from './integration/MondayComStore';
import { ISmartsheetProgramSourceData } from './integration/SmartsheetStore';
import { actionsForBuilder, BaseSubentitiesUpdateAction, ICreationData } from './Subentity';
import { IP4WProject } from './integration/P4WStore';
import { IVSTSLinkData } from '../components/integration/Vsts/VSTSConnectControl';
import { ResourcePlanOperationsFactory } from './ResourcePlanListStore';

const namespace = 'PROGRAM';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Program, ProgramsState>(namespace, EntityType.Program);
const historyStore = ChangeHistoryOperationsFactory<Program, ProgramsState>(EntityType.Program);
const resourcePlanStore = ResourcePlanOperationsFactory<Program, ProgramsState>(EntityType.Program);

export const statuses = ["Overall", "Cost", "Schedule"];
export const StatusNames = ["OverallStatus", "CostStatus", "ScheduleStatus"];

export interface ProgramAttrs extends IWithName, IWithManager, IWithStartFinishDates, IWithBenefits {
    Identifier: string;
    Stage: ProgramStage;
    Budget: number;
    CostStatus: string;
    OverallStatus: string;
    CreatedDate: string;
    Portfolio: IPortfolioInfo[];
    Tags?: string[];
}

type ProgramAttributesModel = IBaseEntity & IWithWarnings & IWithChangeHistory & IWithInsights & {
    attributes: ProgramAttrs;
    keyDates: KeyDate[];
}

export interface Program extends IBaseEntity, IWithPrioritiesAlignment, IWithInsights, IWithLayout, IWithResourcePlan,
    IWithImage, IWithWarnings, IWithChangeHistory, IWithSourceInfos, IWithSections, IWithPinnedViews, IEditable {
    projectIds: string[];
    calculation: ProgramCalculation;
    attributes: ProgramAttrs & Dictionary<any>;
    keyDates: KeyDate[];
    actionItems: ActionItem[];
    keyDecisions: KeyDecision[];
    isEditable: boolean;
    canConfigure: boolean;
    risks: Risk[];
    resourceIds: string[];
}

export interface ProgramCalculation extends ICalculation {
    projectStatuses: Dictionary<StatusCategory>;
    projectProgresses: any;
    costs: IBlinePlanActual;
    work: IBlinePlanActual;
}

export enum ProgramStage {
    Active = 0,
    Closed = 1,
    Inactive = 2,
    Future = 3
}

export interface ProgramStageConfig { title: string, cssClassName: string }
export const programStagesMap: { [i: number]: ProgramStageConfig } =
{
    [ProgramStage.Active]: {
        title: "Active",
        cssClassName: "Active"
    },
    [ProgramStage.Closed]: {
        title: "Closed",
        cssClassName: "Closed"
    },
    [ProgramStage.Inactive]: {
        title: "Inactive",
        cssClassName: "Inactive",
    },
    [ProgramStage.Future]: {
        title: "Future",
        cssClassName: "Future",
    }
}

export interface ProgramsState extends IEntityStore<Program> {
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];

    changeHistory: IChangeHistoryState;
}

export type ProgramOrProject = (ProjectInfo | Program) & HierarchyContainerEntity;

export const DEFAULT_BULK_EDIT_COLUMNS = namesof<ProgramAttrs>(["Name", "OverallStatus"]);

interface CreateProgramAction {
    type: 'CREATE_PROGRAM';
}

interface LoadProgramAction {
    type: 'LOAD_PROGRAM';
    id?: string;
}

interface ReceivedDeleteProgramsResultAction {
    type: 'RECEIVED_REMOVE_PROGRAMS_RESULT';
    deletionResult?: IDeletionResult[];
}

export interface CreatedProgramAction {
    type: 'CREATED_PROGRAM';
    program: Program;
    isNotSetActiveEntity?: boolean;
}

interface ReceivedProgramAction {
    type: 'RECEIVED_PROGRAM';
    program: Program;
}

interface ReceivedProgramAttributesAction {
    type: "RECEIVED_PROGRAM_ATTRIBUTES";
    data: ProgramAttributesModel;
}

interface UpdatingSectionsAction {
    type: 'UPDATING_PROGRAM_SECTIONS';
    programId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_PROGRAM_SECTION_SUCCESS';
    programId: string;
    sections: Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_PROGRAM_PINNED_VIEWS_SUCCESS';
    programId: string;
    pinnedViews: string[];
}

export interface RequestProgramsAction {
    type: 'REQUEST_PROGRAMS';
}

interface ReceiveProgramsAction {
    type: 'RECEIVED_PROGRAMS';
    programs: Program[];
}

export interface ReceivedProgramsPartAction {
    type: 'RECEIVED_PROGRAMS_PART';
    programs: Program[];
}

interface UpdateUIControlAction {
    type: 'UPDATE_UICONTROL_IN_PROGRAM_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

export interface UpdateProjectsAction {
    type: 'UPDATE_PROGRAM_PROJECTS';
    programId: string;
    projectIds: string[];
    calculation: ProgramCalculation;
    isAdd?: boolean;
}

interface BulkUpdateProgramsSuccessAction {
    type: 'BULK_UPDATE_PROGRAMS_SUCCESS';
    programs: Program[];
}

interface UpdateKeyDatesAction {
    type: 'UPDATE_PROGRAM_KEYDATES';
    programId: string;
    set?: KeyDate[];
    addOrUpdate?: KeyDate | KeyDate[];
    remove?: string[];
    warnings: IWarning[];
}

interface UpdateImageAction {
    type: 'UPDATE_PROGRAM_IMAGE';
    programId: string;
    imageId?: string;
}

interface UpdateCalculationAction {
    type: 'UPDATE_PROGRAM_CALCULATION';
    programId: string;
    calculation: ProgramCalculation;
    updates: Dictionary<any>;
}

interface UpdateProgramActionItems extends BaseSubentitiesUpdateAction<ActionItem> {
    type: "UPDATE_PROGRAM_ACTIONITEMS";
}

interface UpdaeProgramKeyDecisions extends BaseSubentitiesUpdateAction<KeyDecision> {
    type: "UPDATE_PROGRAM_KEYDECISIONS";
}

export interface RemovedProgramsSourceInfosAction {
    type: "REMOVED_PROGRAMS_SOURCE_INFOS";
    connectionId: string;
}

interface UpdateProgramRisks extends BaseSubentitiesUpdateAction<Risk> {
    type: "UPDATE_PROGRAM_RISKS";
}

type KnownAction = RequestProgramsAction
    | ReceiveProgramsAction
    | ReceivedProgramsPartAction
    | CreateProgramAction
    | ReceivedProgramAction
    | ReceivedProgramAttributesAction
    | CreatedProgramAction
    | ReceivedDeleteProgramsResultAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | LoadProgramAction
    | UpdateProjectsAction
    | BulkUpdateProgramsSuccessAction
    | UpdateKeyDatesAction
    | UpdateImageAction
    | UpdateCalculationAction
    | UpdateUIControlAction
    | UpdateProgramActionItems
    | UpdaeProgramKeyDecisions
    | RemovedProgramsSourceInfosAction
    | UpdateProgramRisks;

const actionsFor = actionsForBuilder<KnownAction>(EntityType.Program);
const subentitiesActionCreators = {
    removeKeyDates: (programId: string, keyDateIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<KeyDateWithWarnings>(`api/program/${programId}/keyDate`, { ids: keyDateIds })
            .then(data => dispatch({ type: "UPDATE_PROGRAM_KEYDATES", programId, remove: keyDateIds, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    createKeyDate: (programId: string, data: ICreationData): AppThunkAction<KnownAction> => (dispatch) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/program/${programId}/keyDate`, data)
            .then(dto => dispatch({ type: "UPDATE_PROGRAM_KEYDATES", programId, addOrUpdate: dto.keyDates, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    updateKeyDates: (programId: string, keyDates: IPatch<KeyDate>[]): AppThunkAction<KnownAction> => (dispatch) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/program/${programId}/keyDate/bulk`, keyDates)
            .then(data => dispatch({ type: "UPDATE_PROGRAM_KEYDATES", programId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    linkKeyDates: (programId: string, subentityToParentMap: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/program/${programId}/keyDate/link`, subentityToParentMap)
            .then(_ => dispatch({ type: 'UPDATE_PROGRAM_KEYDATES', programId, addOrUpdate: _.keyDates, warnings: _.warnings }))
            .catch(defaultCatch(dispatch));
    },
    setKeyDatesBaseline: (programId: string, ids: string[]): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/program/${programId}/keyDate/baseline`, { ids })
            .then(data => dispatch({ type: 'UPDATE_PROGRAM_KEYDATES', programId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<Risk>().create('Risk', _ => ({ type: 'UPDATE_PROGRAM_RISKS', ..._ })),
    linkRisk: (programId: string, data: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ risks: Risk[] }>(`api/program/${programId}/risk/link`, data)
            .then(_ => dispatch({ type: 'UPDATE_PROGRAM_RISKS', entityId: programId, addOrUpdate: _.risks }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<ActionItem>().create('ActionItem', _ => ({ type: 'UPDATE_PROGRAM_ACTIONITEMS', ..._ })),
    ...actionsFor<KeyDecision>().create('KeyDecision', _ => ({ type: 'UPDATE_PROGRAM_KEYDECISIONS', ..._ }))
}

const defaultActionCreators = {
    requestPrograms: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Program[]>(`api/program`)
            .then(data => { dispatch({ type: 'RECEIVED_PROGRAMS', programs: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_PROGRAMS' }); // Ensure server-side prerendering waits for this to complete
    },
    createProgram: (name: string, layoutId: string, createFilter: boolean, openOnComplete: boolean)
        : AppThunkAction<KnownAction | RouterAction | UpdateFilterAction> => (dispatch) => {
            post<{ program: Program; filter: IFilter<BaseFilterValue> | null }>(`api/program`, { name, layoutId, createFilter })
                .then(data => {
                    dispatch({ type: "CREATED_PROGRAM", program: data.program });
                    data.filter && dispatch({ entity: EntityType.Project, type: 'UPDATE_FILTER', filter: data.filter });

                    if (openOnComplete) {
                        dispatch(push(`/program/${data.program.id}`));
                    }
                })
                .catch(defaultCatch(dispatch));

            dispatch(<CreateProgramAction>{ type: "CREATE_PROGRAM" });
        },
    removePrograms: (ids: string[], redirectBack: boolean = false): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/program/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PROGRAMS_RESULT", deletionResult: [data] });
                    if (redirectBack) {
                        dispatch(push('/programs'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/program/bulkDelete`, { ids })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PROGRAMS_RESULT", deletionResult: data });
                    if (redirectBack) {
                        dispatch(push('/programs'));
                    }
                })
                .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PROGRAM" });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_PROGRAMS_RESULT" });
    },
    getProgramsByIds: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program[]>(`api/program/get`, { ids: ids })
            .then(data => {
                dispatch({ type: 'RECEIVED_PROGRAMS_PART', programs: data });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "REQUEST_PROGRAMS" });
    },
    loadProgram: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Program>(`api/program/${id}`)
            .then(data => dispatch({ type: "RECEIVED_PROGRAM", program: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PROGRAM", id: id });
    },
    updateAttributes: (programId: string, updates: Dictionary<any>, context?: Dictionary<UpdateContext>): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            post<ProgramAttributesModel>(`api/program/${programId}/attributes`, { updates, context })
                .then(data => dispatch({ type: 'RECEIVED_PROGRAM_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    resetProgramStatus: (programId: string, statusAttributeName: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            post<ProgramAttributesModel>(`api/program/${programId}/resetStatus/${statusAttributeName}`, {})
                .then(data => dispatch({ type: 'RECEIVED_PROGRAM_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    updateInsights: (programId: string, data: Partial<IInsightsData>):
        AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
            post<Program>(`api/program/${programId}/insights`, data)
                .then(program => dispatch({ type: 'RECEIVED_PROGRAM', program }))
                .catch(defaultCatch(dispatch));
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/program`,
        (programId, sections, dispatch) => dispatch({
            type: 'UPDATE_PROGRAM_SECTION_SUCCESS',
            programId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((programId, sections, dispatch) => dispatch({
        type: 'UPDATE_PROGRAM_SECTION_SUCCESS',
        programId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/program`,
        (programId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_PROGRAM_PINNED_VIEWS_SUCCESS',
            programId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/program`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_IN_PROGRAM_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_IN_PROGRAM_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Program),
    removeProjects: (programId: string, projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Program>(`api/program/${programId}/project`, { ids: projectIds })
            .then(data => {
                dispatch({ type: "UPDATE_PROGRAM_PROJECTS", programId, projectIds: data.projectIds, calculation: data.calculation });
                dispatch({ type: "UPDATE_PROGRAM_KEYDATES", programId, set: data.keyDates, warnings: data.warnings });
                dispatch({ type: "UPDATE_PROGRAM_RISKS", entityId: programId, set: data.risks });
            })
            .catch(defaultCatch(dispatch));
    },
    addProjects: (programId: string, projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program>(`api/program/${programId}/project`, { ids: projectIds })
            .then(data => dispatch({ type: "UPDATE_PROGRAM_PROJECTS", programId, projectIds: data.projectIds, calculation: data.calculation }))
            .catch(defaultCatch(dispatch));
    },
    createProjectAndAddToProgram: (programId: string, name: string, layoutId: string):
        AppThunkAction<KnownAction | CreateProjectSuccessAction> => (dispatch, getState) => {
            post<{ project: ProjectInfo, program: Program }>(`api/program/${programId}/project/new`, { name, layoutId })
                .then(data => {
                    dispatch(<CreateProjectSuccessAction>{
                        type: 'CREATE_PROJECT_SUCCESS',
                        project: data.project,
                        isNotSetActiveEntity: true
                    });
                    dispatch({
                        type: 'UPDATE_PROGRAM_PROJECTS',
                        programId,
                        projectIds: [data.project.id],
                        calculation: data.program.calculation,
                        isAdd: true
                    })
                })
                .catch(defaultCatch(dispatch));
        },
    bulkUpdate: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program[]>(`api/program/BulkUpdate`, updates)
            .then(data => dispatch({ type: "BULK_UPDATE_PROGRAMS_SUCCESS", programs: data }))
            .catch(defaultCatch(dispatch));
    },
    updateImage: (programId: string, logo: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', logo);

        post<{ imageId: string }>(`api/program/${programId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_PROGRAM_IMAGE', imageId: _.imageId, programId: programId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (programId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/program/${programId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_PROGRAM_IMAGE', imageId: undefined, programId: programId }))
            .catch(defaultCatch(dispatch));
    },
    applyLayout: (programId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Program>(`api/program/${programId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'RECEIVED_PROGRAM', program: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Program });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Program });
    },
    applyLayoutMany: (programIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Program[]>(`api/program/applyLayout/${layoutId}`, { ids: programIds })
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_PROGRAMS_SUCCESS', programs: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Program });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Program });
    },
    updateCalculation: (programId: string, changes: Partial<ProgramCalculation>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{calculation: ProgramCalculation, updates: Dictionary<any>}>(`api/program/${programId}/calculation`, changes)
            .then(_ => dispatch({ type: 'UPDATE_PROGRAM_CALCULATION', programId, ..._ }))
            .catch(defaultCatch(dispatch));
    },
    updatePriorityAlignment: (programId: string, strategicPriorityId: string, impact: Impact):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Program>(`api/program/${programId}/priorityAlignments`, { strategicPriority: { id: strategicPriorityId }, impact: impact })
                .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
                .catch(defaultCatch(dispatch));
        },
    recalculateAlignmentScore: (programId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Program>(`api/program/${programId}/recalculateAlignmentScore`, {})
                .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
                .catch(defaultCatch(dispatch));
        },
    linkToO365Group: (programId: string, linkData: ILinkDto<O365GroupLinkInfo>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program>(`api/program/${programId}/link/o365Group`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToTeamsChannel: (programId: string, linkData: ILinkDto<TeamsChannelLink>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program>(`api/program/${programId}/link/teamsChannel`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToPlannerPlan: (programId: string, linkData: ILinkDto<IPlanInfo>):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: Program }>(`api/program/${programId}/link/plannerPlan`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    }
                    dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToJiraProject: (programId: string, linkData: ILinkDto<IJiraLinkInfo>): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ error: string, entity: Program }>(`api/program/${programId}/link/jira/project`, linkData)
            .then(data => {
                if (data.error) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                }
                dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
            })
            .catch(defaultCatch(dispatch));
    },
    linkToFile: (programId: string, fileUrl: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Program>(`api/program/${programId}/link/file`, { fileUrl: fileUrl })
            .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToVSTSProject: (programId: string, linkData: ILinkDto<IVSTSLinkData>): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ error: string, entity: Program }>(`api/program/${programId}/link/vsts/project`, linkData)
            .then(data => {
                if (data.error) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                }
                dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
            })
            .catch(defaultCatch(dispatch));
    },
    linkToPoProject: (programId: string, linkData: ILinkDto<ISpoProject>): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ error: string, entity: Program }>(`api/program/${programId}/link/spo/project`, linkData)
            .then(data => {
                if (data.error) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                }
                dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
            })
            .catch(defaultCatch(dispatch));
    },
    linkToMondayComBoard: (programId: string, linkData: ILinkDto<IMondayComBaseSourceData>):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: Program }>(`api/program/${programId}/link/mondaycom/board`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    }
                    dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToSmartsheetWorkspace: (programId: string, linkData: ILinkDto<ISmartsheetProgramSourceData>):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: Program }>(`api/program/${programId}/link/smartsheet/workspace`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    }
                    dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToP4WProject: (programId: string, linkData: ILinkDto<IP4WProject>): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ error: string, entity: Program }>(`api/program/${programId}/link/p4w/project`, linkData)
            .then(data => {
                if (data.error) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                }
                dispatch({ type: 'RECEIVED_PROGRAM', program: data.entity });
            })
            .catch(defaultCatch(dispatch));
    },
    deleteProgramToExternalSystemLink: (programId: string, connectionId: string, sourceType: ExternalEpmConnectStore.SourceType)
        : AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<Program>(`api/program/${programId}/link`, { connectionId, sourceType })
                .then(data => dispatch({ type: 'RECEIVED_PROGRAM', program: data }))
                .catch(defaultCatch(dispatch));
        },
    requestAccess: (programId: string, message?: string): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post(`api/program/${programId}/requestAccess`, { message })
            .then(_ => dispatch(NotificationsStore.actionCreators.pushNotification({ message: `Thank you for your request! Access will be provided as soon as possible.` })))
            .catch(defaultCatch(dispatch));
    },
    ...subentitiesActionCreators
};

const unloadedState: ProgramsState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
    isListUpdating: false,
    isUpdatingSections: false,

    changeHistory: HISTORY_DEFAULT_STATE
};

const defaultReducer: Reducer<ProgramsState> = (state: ProgramsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PROGRAMS':
            return {
                ...state,
                isLoading: true,
                isListLoading: true
            };
        case 'RECEIVED_PROGRAMS':
            return {
                ...state,
                ...StoreHelper.create(action.programs),
                isLoading: false,
                isListLoading: false
            };
        case 'RECEIVED_PROGRAMS_PART':
            return {
                ...state,
                ...StoreHelper.union(state, action.programs),
                isLoading: false,
                isListLoading: false
            };
        case 'RECEIVED_PROGRAM':
            if (state.activeEntity?.id === action.program?.id) {
                action.program.changeHistory = state.activeEntity.changeHistory;
            }

            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.program),
                activeEntity: state.activeEntityId === action.program.id ? action.program : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_PROGRAM_ATTRIBUTES':
            return {
                ...state,
                activeEntity: state.activeEntityId === action.data.id && state.activeEntity
                    ? {
                        ...state.activeEntity,
                        attributes: action.data.attributes,
                        warnings: action.data.warnings,
                        insights: action.data.insights,
                        keyDates: action.data.keyDates
                    }
                    : state.activeEntity
            };
        case 'CREATED_PROGRAM':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.program),
                activeEntityId: action.isNotSetActiveEntity ? undefined : action.program.id,
                activeEntity: action.isNotSetActiveEntity ? undefined : action.program,
                isLoading: false
            };
        case 'CREATE_PROGRAM':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVED_REMOVE_PROGRAMS_RESULT':
            let newState = { ...state };
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted && newState.byId[result.id]) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'LOAD_PROGRAM':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            }
        case 'UPDATE_PROGRAM_PROJECTS':
            {
                return StoreHelper.applyHandler(state,
                    action.programId,
                    (program: Program) => Object.assign({},
                        program,
                        {
                            projectIds: action.isAdd ? [...program.projectIds, ...action.projectIds] : action.projectIds,
                            calculation: action.calculation
                        }));
            }
        case 'UPDATE_PROGRAM_KEYDATES':
            {
                return StoreHelper.applyHandler(state, action.programId,
                    (program: Program) => partialUpdate(program, {
                        keyDates: action.set ? action.set : addOrUpdateOrRemove(program.keyDates, action.addOrUpdate, action.remove),
                        warnings: action.warnings
                    }));

            }
        case 'UPDATING_PROGRAM_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_PROGRAM_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.programId,
                        (program: Program) => Object.assign({}, program, { sections: action.sections })),
                    isUpdatingSections: false
                };
            }
        case 'UPDATE_PROGRAM_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.programId,
                        (program: Program) => Object.assign({}, program, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_UICONTROL_IN_PROGRAM_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId, (program: Program) => MetadataService.UpdateUIControlSettings(program, action.uiControlInfo));
            }
        case 'BULK_UPDATE_PROGRAMS_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.union(state, action.programs),
                    isLoading: false
                };
            }
        case 'UPDATE_PROGRAM_IMAGE':
            {
                return StoreHelper.applyHandler(state, action.programId, (program: Program) => partialUpdate(program, { imageId: action.imageId }));
            }
        case 'UPDATE_PROGRAM_CALCULATION':
            return StoreHelper.applyHandler(state, action.programId, 
                (program: Program) => partialUpdate(program, { calculation: action.calculation, attributes: { ...program.attributes, ...action.updates } }));
        case 'UPDATE_PROGRAM_ACTIONITEMS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (program: Program) => partialUpdate(program, {
                    actionItems: addOrUpdateOrRemove(program.actionItems, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROGRAM_KEYDECISIONS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (program: Program) => partialUpdate(program, {
                    keyDecisions: addOrUpdateOrRemove(program.keyDecisions, action.addOrUpdate, action.remove)
                }));
            }
        case "REMOVED_PROGRAMS_SOURCE_INFOS":
            {
                return {
                    ...state,
                    ...StoreHelper.applyHandler(state, state.allIds, (porfolio: Program) => partialUpdate(porfolio, {
                        sourceInfos: porfolio.sourceInfos.filter(_ => _.connectionId !== action.connectionId)
                    })),
                    activeEntity: state.activeEntity
                        ? { ...state.activeEntity, sourceInfos: state.activeEntity.sourceInfos.filter(_ => _.connectionId !== action.connectionId) }
                        : state.activeEntity
                };
            }
        case "UPDATE_PROGRAM_RISKS":
            {
                return StoreHelper.applyHandler(state, action.entityId, (program: Program) => partialUpdate(program, {
                    risks: action.set ? action.set : addOrUpdateOrRemove(program.risks, action.addOrUpdate, action.remove)
                }));
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};

export const reducer: Reducer<ProgramsState> = (state: ProgramsState = unloadedState, incomingAction: Action) => {
    return defaultReducer(
        resourcePlanStore.reducer(
            historyStore.reducer(
                importExportReducer(state, incomingAction),
                incomingAction),
            incomingAction),
        incomingAction);
}

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators,
    ...historyStore.actionCreators,
    ...resourcePlanStore.actionCreators
}