import * as React from 'react';
import { getId, IPickerItemProps, IconButton, IBasePickerSuggestionsProps } from 'office-ui-fabric-react';
import { IFormInputProps } from "../interfaces/IFormInputProps";
import { post } from "../../../fetch-interceptor";
import { toDictionary } from "../../utils/common";
import PPMXPicker from "./PPMXPicker";
import { Dictionary } from '../../../entities/common';

export class EntityPicker extends PPMXPicker<IFindResult> {
    public static defaultProps = {
        onRenderSuggestionsItem: (props: IFindResult, itemProps?: IBasePickerSuggestionsProps) => <div className="picker-suggestion-item">{props.name}</div>,
        pickerSuggestionsProps: { noResultsFoundText: "Nothing found", loadingText: 'Loading', resultsMaximumNumber: 10 },
        getTextFromItem: (item: IFindResult) => item.name
    };
}

export interface IFindResult {
    id: string;
    name: string;
    disabled?: boolean;
    title?: string;
    attributes?: Dictionary<any>;
}

export interface IFindProps {
    searchUrl: string;
    filter?: Dictionary<any>;
}

type EntityPickerInputProps = IFormInputProps<IFindResult[]> & IFindProps;

export default class EntityPickerInput extends React.Component<EntityPickerInputProps> {

    public render() {
        const { readOnly } = this.props;
        return <EntityPicker
            inputProps={this.props.inputProps}
            readOnly={readOnly}
            onRenderItem={this._onRenderItem}
            selectedItems={this.props.value}
            onResolveSuggestions={this._onResolveSuggestions}
            onChange={this.onChange}
        />;
    }

    private onChange = (value: IFindResult[]): void => {
        this.props.onChanged && this.props.onChanged(value);
        this.props.onEditComplete && this.props.onEditComplete(value);
    }

    private _onResolveSuggestions = (filter: string, selectedItems?: IFindResult[]) => {
        let promise = post<IFindResult[]>(this.props.searchUrl, { ...this.props.filter, name: filter });
        
        if (selectedItems && selectedItems.length) {
            const selectedItemsMap = toDictionary(selectedItems);
            promise = promise.then(_ => _.filter(__ => !selectedItemsMap[__.id]));
        }

        return promise;
    }

    private _onRenderItem = (itemProps: IPickerItemProps<IFindResult>) => {
        let {
            item,
            onRemoveItem
        } = itemProps;
        const { readOnly } = this.props;

        const itemId = getId();
        return (
            <div key={item.id} className={`picker-item ${readOnly ? "readonly" : ""} ${item.disabled ? "disabled" : ""}`}
                title={item.title}
                data-is-focusable={true}
                data-is-sub-focuszone={true}
                role={'listitem'}
                aria-labelledby={'selectedItemProject-' + itemId}>
                <span>{item.name}</span>
                {
                    !readOnly && !item.disabled &&
                    <IconButton
                        onClick={onRemoveItem}
                        iconProps={{ iconName: 'Cancel', style: { fontSize: '12px' } }} />
                }
            </div>
        );
    }
}