import './FieldsList.css';
import React, { createRef, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as Metadata from '../../../../entities/Metadata';
import { DraggableList, NonDraggableList } from '../../../common/DraggableList';
import { arraysEqual, notEmpty, orderNotSelected, toDictionary, toNameDictionary } from '../../../utils/common';
import FieldPanel, { IFieldActions } from '../../FieldPanel';
import ManageFieldCard from '../ManageFieldCard';
import ReactDOM from 'react-dom';
import { Dictionary, EntityType } from '../../../../entities/common';
import NotFound from '../NotFound';
import { ViewService } from '../../../../services/ViewService';

type Props = {
    fields: Metadata.Field[];
    entityType: EntityType;
    selected: string[];
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    onChange?: (fields: Metadata.Field[]) => void;
    filter?: string;
    newFields?: string[];
    mandatoryFields?: string[];
}

export const FieldsList = (props: Props) => {
    const selected = useMemo(() =>
        (props.mandatoryFields?.length
            ? [...props.mandatoryFields, ...props.selected.filter(_ => !props.mandatoryFields?.includes(_))]
            : props.selected)
            .filter(_ => props.fields.some(__ => __.name === _))
        , [props.selected, props.mandatoryFields]);
    const [orderedFields, setOrderedFields] = useState(getOrdered(props.fields, selected));
    const mandatoryFields = useMemo(() => props.fields.filter(_ => ViewService.isMandatory(_, props.mandatoryFields)), [props.fields, props.mandatoryFields]);

    const getSelectedIndex = useCallback((f: Metadata.Field, fields: Metadata.Field[]) => {
        let index = 0;
        for (const field of fields) {
            if (field.id === f.id) {
                break;
            }
            if (selected && ~selected.indexOf(field.name)) {
                index++;
            }
        }
        return index;
    }, [selected]);

    const onChange = useCallback((field: Metadata.Field, checked: boolean, selectedIndex?: number) => {
        const fieldNames = selected.filter(_ => _ !== field.name);
        if (checked) {
            fieldNames.splice(selectedIndex!, 0, field.name);
        }

        const fieldsMap = toNameDictionary(props.fields);
        const fields = fieldNames.map(_ => fieldsMap[_]).filter(notEmpty);
        props.onChange!(fields);
    }, [props.onChange, selected]);

    const onOrderChanged = useCallback((changedOrder: Metadata.Field[], field: Metadata.Field) => {
        const reordered = mandatoryFields.length ? [...mandatoryFields, ...changedOrder] : changedOrder;
        // for smooth fields reorder
        setOrderedFields(reordered);
        const index = getSelectedIndex(field, reordered)
        // automatically turn on only fields which dragged in selected area
        if (index < props.selected.length) {
            onChange(field, true, index);
        }
    }, [selected, onChange]);

    const refs = useRef<Dictionary<RefObject<HTMLDivElement>>>({});
    const onItemRender = useCallback((field: Metadata.Field) =>
        <ManageFieldCard
            ref={refs.current[field.name] || (refs.current[field.name] = createRef())}
            selected={selected.includes(field.name)}
            field={field}
            isNew={!!props.newFields?.includes(field.name)}
            allowManage={props.allowManageFields}
            index={getSelectedIndex(field, orderedFields)}
            onChange={ViewService.isMandatory(field, props.mandatoryFields) || !props.onChange ? undefined : onChange}
            hideToggle={ViewService.isMandatory(field, props.mandatoryFields)}
            actions={props.fieldActions}
            onEditClick={onEditClick}
        />,
        [props.allowManageFields, orderedFields, selected, props.newFields, props.mandatoryFields, props.onChange]);

    useEffect(() => {
        const newOrder = getOrdered(props.fields, selected);
        if (!arraysEqual(orderedFields, newOrder)) {
            setOrderedFields(newOrder);
        }
    }, [props.fields, selected]);

    useEffect(() => {
        // scroll last new field into view
        if (props.newFields) {
            const lastNewField = props.newFields[props.newFields.length - 1];
            props.selected.includes(lastNewField) && setScrollInfoView(lastNewField);
        }
    }, [props.selected.length, props.newFields?.length])

    const [scrollIntoView, setScrollInfoView] = useState('');
    useLayoutEffect(() => {
        if (scrollIntoView && refs.current[scrollIntoView]?.current) {
            const node = ReactDOM.findDOMNode(refs.current[scrollIntoView].current) as HTMLElement;
            node?.scrollIntoView(false);
        }
    }, [scrollIntoView]);

    const isItemDraggable = useCallback(() => !props.filter, [!!props.filter]);
    const lowerCaseFilter = props.filter?.toLowerCase();
    const filtered = useMemo(() => lowerCaseFilter
        ? orderedFields.filter(_ => Metadata.getLabel(_).toLowerCase().includes(lowerCaseFilter))
        : orderedFields,
        [orderedFields, lowerCaseFilter]);

    const [editField, setEditField] = useState<Metadata.Field | null>(null);
    const onEditClick = useCallback((field) => setEditField(field), []);
    const resetEditing = useCallback(() => setEditField(null), []);

    const filteredMandatories = filtered.filter(_ => ViewService.isMandatory(_, props.mandatoryFields));
    return (
        <div className='configure-fields'>
            {!!filteredMandatories.length && <NonDraggableList
                items={filteredMandatories}
                onItemRender={onItemRender} />}
            <DraggableList
                items={filtered.filter(_ => !ViewService.isMandatory(_, props.mandatoryFields))}
                onItemRender={onItemRender}
                isItemDraggable={isItemDraggable}
                onChanged={props.onChange ? onOrderChanged : undefined}
                getKey={getKey}
            />
            {
                !filtered.length &&
                <>
                    {lowerCaseFilter?.length
                        ? <NotFound />
                        : <span>No fields have been created yet</span>
                    }
                </> 
            }
            {
                // editing can't be made in the field card because the drag and drop issue
                // react dnd (used for drag and drop) don't allow to drag and drop component inside other draggable
                // that means select field option reorder won't work if panel declared inside the card which is draggable
                editField && props.fieldActions && (
                    <FieldPanel
                        entityType={props.entityType}
                        field={editField}
                        allowManageFields={props.allowManageFields}
                        actions={props.fieldActions}
                        onDismiss={resetEditing}
                    />
                )
            }
        </div>
    );
}

const getKey = (f: Metadata.Field, index: number) => f.id;

const getOrdered = (fields: Metadata.Field[], selected: string[]) => {
    return orderNotSelected(fields,
        (selected ? f => selected.indexOf(f.name) : f => 0),
        a => Metadata.getLabel(a),
        true);
}