import { getOffset, shiftDate, IScale } from "./TimelineScale";
import * as React from 'react';
import { TooltipHost, TooltipDelay, Icon } from "office-ui-fabric-react";
import { Rnd } from "react-rnd";
import { ICanvasInnerComponentProps, RowShift } from "./TimelineBody";
import { IBaseTimelineElement, Visibility } from "./TimelineSegment";
import { FormatDate } from "../../utils/common";
import { TimelineElementCommandsPanel } from "./TimelineElementCommandsPanel";

export enum MarkerType {
    KeyDate = 0,
    Milestone = 1,
    Release = 2,
    Deliverable = 3
}

const MarkerTypeClassMap: { [i: number]: string } = {
    [MarkerType.KeyDate]: "keydate",
    [MarkerType.Milestone]: "milestone",
    [MarkerType.Release]: "release",
    [MarkerType.Deliverable]: "deliverable",
}

export interface ITimelineMarker extends IBaseTimelineElement {
    date: Date;
    label?: string;
    line?: boolean;
    contentWidth?: number;
    warningsCount?: number;
    backgroundColor?: string;
    hasLink?: boolean;
    minWidthToRenderContent?: number;
    markerType?: MarkerType;
}

interface ITimelineMarkerState {
    left: number;
    width: number;
    renderBody: boolean;
    date: Date;
    datesVisibility?: Visibility;
    isOutOfScale: boolean;
    rowShift?: RowShift;
    lockDateOnDnd?: boolean;
}

const minWidthToShowDate: number = 100;
const maxAllowedLabelWidth: number = 300;
const rhombMarkerShift = 22;
const otherMarkerShift = 18;
export const rhombWidth = 17;
export const warningWidth = 24;
export const markerBodyLeft = 9;
export const largeModeRhombTopShift = 11;

export class TimelineMarker extends React.Component<ICanvasInnerComponentProps<ITimelineMarker>, ITimelineMarkerState> {
    private _suppressOnClick: boolean = false;

    constructor(props: ICanvasInnerComponentProps<ITimelineMarker>) {
        super(props);
        this.state = this._buildState(props);
    }

    public componentWillReceiveProps(nextProps: ICanvasInnerComponentProps<ITimelineMarker>) {
        if (nextProps.scale.totalWidthUnits !== this.props.scale.totalWidthUnits
            || nextProps.scale.dates[0].dateFormatted !== this.props.scale.dates[0].dateFormatted
            || nextProps.scale.dates.slice(-1)[0].dateFormatted !== this.props.scale.dates.slice(-1)[0].dateFormatted
            || nextProps.data.date.getTime() !== this.props.data.date.getTime()
            || nextProps.data.datesVisibility !== this.props.data.datesVisibility
            || nextProps.data.availableSpace !== this.props.data.availableSpace
            || nextProps.data.contentWidth !== this.props.data.contentWidth
            || nextProps.data.label !== this.props.data.label
            || nextProps.largeMode !== this.props.largeMode
            || nextProps.timelineWidth !== this.props.timelineWidth) {
            this.setState(this._buildState(nextProps));
        }
    }

    public render() {
        const { scale, timelineWidth, data, renderContent, onClick, onChange, dragAxis, resetSelection, isSelected, hideCommandPanel } = this.props;
        const { isOutOfScale, lockDateOnDnd } = this.state;
        if (isOutOfScale) {
            return null;
        }

        if (data.line) {
            return <div className={`vertical-line ${data.className || ''}`}
                style={{ ...data.style, left: this.state.left }} title={data.label}>
                <div className={`line ${data.className || ''}`} style={data.style}>
                    {renderContent?.(data)}
                </div>
            </div>;
        }

        const datesVisibility = this.state.datesVisibility !== undefined ? this.state.datesVisibility : data.datesVisibility;
        let dragAxisValue = dragAxis?.(data) ?? 'x';
        dragAxisValue = lockDateOnDnd && dragAxisValue === 'both' ? 'y' : dragAxisValue;
        const canDrag = dragAxisValue !== 'none';
        const markerTopOffset = data.markerType === undefined
            ? rhombMarkerShift + (this.props.largeMode && data.datesVisibility !== undefined ? largeModeRhombTopShift : 0)
            : otherMarkerShift;
        const className = `timeline ${data.className || ''} `
            + (data.markerType === undefined ? 'with-rhomb' : MarkerTypeClassMap[data.markerType!])
            + (onClick ? ' clickable' : '')
            + (datesVisibility === undefined ? ' date-hidden' : datesVisibility === Visibility.OnHover ? ' dates-on-hover' : '')
            + (lockDateOnDnd ? ' locked-date' : '')
            + (!!data.warningsCount ? ' with-warnings' : '')
            + (isSelected ? ' selected' : '');
        const commandPanelY = this.props.largeMode && datesVisibility !== undefined ? 3 : -8;
        return <>
            {onChange
                ? <Rnd
                    className={className}
                    size={{ width: this.state.width, height: 13 }}
                    position={{ x: this.state.left, y: markerTopOffset }}
                    enableResizing={{}}
                    dragAxis={dragAxisValue}
                    disableDragging={!canDrag}
                    bounds='.drag-table'
                    onDragStart={(e) => {
                        if (dragAxisValue === 'both' || lockDateOnDnd) {
                            this.setState({ lockDateOnDnd: e.shiftKey });
                        }
                        //prevent event bubble that initiates dradndrop of parent tr
                        e.stopPropagation();
                    }}
                    onDrag={canDrag ? (e, d) => {
                        resetSelection?.();
                        if (this.state.datesVisibility === undefined) {
                            this.setState({ datesVisibility: Visibility.Always });
                        }
                        const shiftX = (dragAxisValue === 'x' || dragAxisValue === 'both') ? d.x - this.state.left : 0;
                        const date = shiftDate(scale, data.date, shiftX, timelineWidth);
                        const verticalShift = d.y - markerTopOffset;
                        const rowShift: RowShift | undefined = this.props.resolvePosition?.(data, { date }, verticalShift);

                        this.setState({
                            date,
                            rowShift
                        });
                        this._suppressOnClick = true;
                        const shiftY = (dragAxisValue === 'y' || dragAxisValue === 'both') ? verticalShift : undefined;
                        this.props.onDrag?.(shiftX, shiftY);
                    } : undefined}
                    onDragStop={canDrag ? (e, d) => {
                        const shift = d.x - this.state.left;
                        const verticalShift = d.y - markerTopOffset;
                        if (!shift && !verticalShift) {
                            this._suppressOnClick = false;
                            this.setState({ datesVisibility: undefined });
                            this.props.onDrag?.(0);
                        } else {
                            this.setState(prev => {
                                const { date, rowShift } = prev;

                                if (data.date.getTime() !== date.getTime() || !!rowShift) {
                                    onChange(this.props.data, { date }, rowShift);
                                }
                                const offset = getOffset(scale, date, timelineWidth);
                                const state = {
                                    left: offset.left,
                                    isOutOfScale: offset.isOutOfScale,
                                    datesVisibility: undefined
                                };
                                this.props.onDrag?.(state.left - prev.left);
                                return state;
                            });

                        }
                    } : undefined}
                >
                    {this._renderBody()}
                </Rnd>
                : <div className={className} style={{ left: this.state.left, top: markerTopOffset }}>
                    {this._renderBody()}
                </div>}
            {isSelected && !hideCommandPanel &&
                <TimelineElementCommandsPanel className='marker'
                    x={this.state.left}
                    y={commandPanelY}
                    parentWidth={this.state.width}
                    timelineWidth={this.props.timelineWidth}
                    commands={this.props.buildCommandsMenu?.(data)} />}
        </>;
    }

    _renderBody(): JSX.Element {
        const { data, renderTooltipContent, renderContent, onClick, isSelected, hideCommandPanel } = this.props;
        const datesVisibility = isSelected && !hideCommandPanel
            ? undefined
            : (this.state.datesVisibility ?? data.datesVisibility);
        return (
            <div style={{ width: '100%', height: '100%' }}
                onDoubleClick={this.props.onDoubleClick ? (ev: React.MouseEvent<HTMLElement>) => this.props.onDoubleClick?.(data, ev) : undefined}
                onClick={(ev: React.MouseEvent<HTMLElement>) => {
                    ev.stopPropagation();
                    if (this._suppressOnClick) {
                        this._suppressOnClick = false;
                        return;
                    }
                    onClick?.(data, ev);
                }}>
                <TooltipHost
                    styles={{ root: { width: '100%', height: '100%' } }}
                    tooltipProps={{
                        onRenderContent: renderTooltipContent && !this._suppressOnClick ? () => renderTooltipContent(data) || null : undefined,
                        maxWidth: "454px"
                    }}
                    calloutProps={{ hidden: isSelected && !hideCommandPanel }}
                    closeDelay={100}
                    delay={TooltipDelay.long}>
                    {datesVisibility !== undefined && this._renderDate()}
                    <div style={{ width: '100%', height: '100%' }}>
                        {data.markerType === undefined
                            ? <>
                                <div className="rhomb" style={{ ...data.style }}>
                                    <div className="inner-rhomb">
                                        {data.warningsCount ? <div className="warning">
                                            <Icon iconName="WarningSolid" />
                                            {data.warningsCount > 1 ? <div className="counter noselect">{data.warningsCount}</div> : undefined}
                                        </div> : undefined}
                                    </div>
                                </div>
                                {this.state.renderBody && <div className="marker-body"
                                    style={{
                                        backgroundColor: data.backgroundColor,
                                        width: this.state.width - markerBodyLeft,
                                        paddingLeft: (!!data.warningsCount ? warningWidth : rhombWidth) - markerBodyLeft
                                    }}>
                                    {renderContent?.(data)}
                                </div>}
                            </>
                            : <div className="image"></div>
                        }
                    </div>
                </TooltipHost>
            </div>);
    }

    private _renderDate = (): JSX.Element => {
        const date = FormatDate(this.state.date);
        const { availableSpace } = this.props.data;
        if (this.state.datesVisibility === undefined && availableSpace !== undefined && availableSpace < minWidthToShowDate) {
            return <div className="date-marker start"><Icon iconName="PPMXFinishIcon" /></div>;
        }

        return <div className="date-marker start"><Icon iconName="PPMXFinishIcon" />{date}</div>;
    }

    private _buildState(props: ICanvasInnerComponentProps<ITimelineMarker>): ITimelineMarkerState {
        const marker = TimelineMarker.calculateMarker(props.scale, props.data, props.timelineWidth);
        return {
            ...marker,
            date: props.data.date
        };
    }

    public static calculateMarker(scale: IScale, marker: ITimelineMarker, timelineWidth: number) {
        let offset = getOffset(scale, marker.date, timelineWidth);

        const rhombWarningWidth = !!marker.warningsCount ? warningWidth : rhombWidth;
        const availableBodySpace = marker.availableSpace ? marker.availableSpace - rhombWarningWidth : Number.MAX_VALUE;

        const bodyWidth = Math.min(
            availableBodySpace,
            marker.contentWidth ?? Number.MAX_VALUE,
            maxAllowedLabelWidth);

        const renderBody = !!marker.addContent && (marker.minWidthToRenderContent === undefined || availableBodySpace > marker.minWidthToRenderContent);
        const width = rhombWarningWidth + (renderBody ? bodyWidth : 0);

        return {
            left: offset.left,
            isOutOfScale: offset.isOutOfScale,
            width,
            renderBody
        };
    }
}