import * as React from 'react';

type Props = { text?: string }

export default class InputErrorMessage extends React.Component<Props, {}> {
    public render() {
        if (!this.props.text) {
            return (null);
        }

        return <div className="error-message">{this.props.text}</div>;
    }
}