import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Resource } from "../../../../../store/ResourcesListStore";
import { SourceTypeMap, SourceType_ } from "../../../../../store/ExternalEpmConnectStore";
import { Field } from "../../../../../entities/Metadata";
import { Icon } from 'office-ui-fabric-react';

export default class LinkedSystem extends React.Component<IListViewFieldColumn<Resource>> {
    public render() {
        const field = this.props.field;

        if (!field) {
            return <span>Invalid column configuration</span>;
        }

        return this.props.entity.sourceInfos.length
            ? <div className="entityName overflow-text font-14">
                {
                    this.props.entity.sourceInfos.map((sourceInfo, index) => {
                        const iconName = SourceType_.getIconName(sourceInfo.type);
                        const title = `Linked to ${SourceTypeMap[sourceInfo.type]} user \"${sourceInfo.sourceData.displayName}\"`;
                        return <span key={`icon-${index}`} title={title}>
                            <Icon className={"logo " + iconName} iconName={iconName} />
                        </span>;
                    })
                }
            </div>
            : null;
    }

    public static getValue(entity: Resource, field: Field): string | undefined {
        return entity.sourceInfos.length
            ? SourceTypeMap[entity.sourceInfos[0].type]
            : undefined;
    }
}