import * as React from 'react';
import { CheckboxVisibility, IColumn, IContextualMenuItem, SelectionMode } from 'office-ui-fabric-react';
import { IExtensibleEntity, Dictionary, Quantization, ITimeframe, OriginScale } from '../../../entities/common';
import { IListProps } from './EntityDetailsList';
import TimelineList, { Grouping, IRow, RowPositionMap, Styling } from '../timeline/TimelineList';
import { ResizeEnable } from 'react-rnd';
import { ITimelineSegment } from '../timeline/TimelineSegment';
import { ITimelineMarker } from '../timeline/TimelineMarker';
import { RowShift, ScaleRenderMode } from '../timeline/TimelineBody';
import { IScale, ITimelineInfo } from '../timeline/TimelineScale';
import { arraysEqual, notUndefined, sortNumber, toDictionary } from '../../utils/common';
import { groupRowType } from '../../roadmap/map';
import { ITimelineRelation } from '../timeline/TimelineRelation';
import { HierarchyProps, THierarchyEntity, chainMerger, useHierarchy } from '../../utils/SectionHierarchyManager';
import { ListUserSettings, useTimelineMapSaver, useTimelineSaver } from '../../../store/services/viewSaver';

export interface ITimelineMapProps {
    type: 'TimelineMap';
    initialTimeframe?: Partial<ITimeframe>;
    userTimeframe?: Partial<ITimeframe>;
    defaultQuantization?: Quantization;
    userQuantization?: Quantization;
    scaleRenderMode?: ScaleRenderMode;
    onScaleChange?: (scale: IScale, quantization: Quantization, timeline: ITimelineInfo, origin?: OriginScale) => void;
    buildSegment: (entity: IExtensibleEntity, styling?: Styling) => ITimelineSegment | undefined;
    buildRelations: (entity: IExtensibleEntity, entitiesMap: Dictionary<IExtensibleEntity>, styling?: Styling) => ITimelineRelation[] | undefined;
    buildMarker: (entity: IExtensibleEntity, styling?: Styling) => ITimelineMarker | undefined;
    renderSegmentContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderSegmentTooltipContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerContent?: (row: IRow, segment: ITimelineMarker) => JSX.Element | undefined;
    renderMarkerTooltipContent?: (row: IRow, marker: ITimelineMarker) => JSX.Element | undefined;
    renderHeaderCellContent?: (column: IColumn) => JSX.Element | undefined;
    onSegmentClick?: (row: IRow, segment: ITimelineSegment) => void;
    onMarkerClick?: (row: IRow, marker: ITimelineMarker) => void;
    onSegmentDoubleClick?: (row: IRow, segment: ITimelineSegment) => void;
    onMarkerDoubleClick?: (row: IRow, marker: ITimelineMarker) => void;
    onSegmentChange?: (item: ITimelineMapSegment, delta: Partial<ITimelineMapSegment>) => void
    onMarkerChange?: (item: ITimelineMapMarker, delta: Partial<ITimelineMapMarker>) => void
    segmentDragAxis?: (row: IRow, segment: ITimelineSegment) => "x" | "y" | "both" | "none" | undefined;
    segmentEnableResizing?: (row: IRow, segment: ITimelineSegment) => ResizeEnable | undefined;
    markerDragAxis?: (row: IRow, marker: ITimelineMarker) => "x" | "y" | "both" | "none" | undefined;
    buildSegmentCommands?: (data: ITimelineSegment) => IContextualMenuItem[] | undefined;
    buildMarkerCommands?: (data: ITimelineMarker) => IContextualMenuItem[] | undefined;
    buildRelationCommands?: (data: ITimelineRelation) => IContextualMenuItem[] | undefined;
    grouping: Grouping;
    ranking: Ranking;
    styling?: Styling;
}

export interface Ranking {
    getRank: (entity: IExtensibleEntity) => number;
    getRankAfter: (entity: IExtensibleEntity) => number;
    getOverlappedEntities: (groupId: string, rank: number, startDate: Date, finishDate: Date, key: string) => IExtensibleEntity[];
}

interface IMapItem {
    groupKey: string;
    rank: number;
}

export type ITimelineMapSegment = ITimelineSegment & IMapItem
export type ITimelineMapMarker = ITimelineMarker & IMapItem

type Props = IListProps & ITimelineMapProps;

interface State {
    items: IRow[];

    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
}

export default class EntityTimelineMap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { items: this._buildItems(props) };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!arraysEqual(this.props.entities, nextProps.entities)
            || this.props.groups !== nextProps.groups
            || this.props.styling?.values !== nextProps.styling?.values) {
            this.setState({ items: this._buildItems(nextProps) });
        }
    }

    public render() {
        const { items } = this.state;
        return <TimelineList
            items={items}
            columns={[]}
            initialTimeframe={this.props.initialTimeframe}
            userQuantization={this._getQuantization()}
            userTimeframe={this._getTimeframe()}
            onScaleChange={this._onScaleChange}
            scaleRenderMode={this.props.scaleRenderMode}
            renderSegmentContent={this.props.renderSegmentContent}
            renderSegmentTooltipContent={this.props.renderSegmentTooltipContent}
            renderMarkerContent={this.props.renderMarkerContent}
            renderMarkerTooltipContent={this.props.renderMarkerTooltipContent}
            onSegmentClick={this.props.onSegmentClick}
            onMarkerClick={this.props.onMarkerClick}
            onSegmentDoubleClick={this.props.onSegmentDoubleClick}
            onMarkerDoubleClick={this.props.onMarkerDoubleClick}
            onSegmentChange={!this.props.onSegmentChange
                ? undefined
                : (row: IRow, segment: ITimelineMapSegment, data: Partial<ITimelineMapSegment>, rowShift?: RowShift) =>
                    this._onChange(segment, data, rowShift, this.props.onSegmentChange)}
            onMarkerChange={!this.props.onMarkerChange
                ? undefined
                : (row: IRow, marker: ITimelineMapMarker, data: Partial<ITimelineMapMarker>, rowShift?: RowShift) =>
                    this._onChange(marker, data, rowShift, this.props.onMarkerChange)}
            resolveSegmentPosition={this._resolveSegmentPosition}
            resolveMarkerPosition={this._resolveMarkerPosition}
            segmentDragAxis={this.props.segmentDragAxis}
            segmentEnableResizing={this.props.segmentEnableResizing}
            markerDragAxis={this.props.markerDragAxis}
            renderHeaderCellContent={this.props.renderHeaderCellContent}
            checkboxVisibility={CheckboxVisibility.hidden}
            groups={this.props.groups}
            grouping={this.props.grouping}
            isVirtualizationDisabled={this.props.isVirtualizationDisabled}
            styling={this.props.styling}
            buildSegmentCommands={this.props.buildSegmentCommands}
            buildMarkerCommands={this.props.buildMarkerCommands}
            buildRelationCommands={this.props.buildRelationCommands}
            selection={this.props.selection}
            timelineElementSelectionMode={SelectionMode.multiple}
        />;
    }

    private _getQuantization = () => {
        return this.props.userQuantization || this.state.quantization;
    }

    private _getTimeframe = (): Partial<ITimeframe> | undefined => {
        const { userTimeframe } = this.props;
        return userTimeframe?.start || userTimeframe?.end
            ? userTimeframe
            : this.state.timeframe;
    }

    private _onScaleChange = (scale: IScale, quantization: Quantization, timeline: ITimelineInfo, origin?: OriginScale) => {
        origin && this.setState({ quantization: origin.quantization, timeframe: origin.timeframe });
        this.props.onScaleChange?.(scale, quantization, timeline, origin);
    }

    private _buildItems(props: Props): IRow[] {
        const { entities, buildSegment, buildMarker, buildRelations, ranking, groups, grouping, styling } = props;

        const groupMap: Dictionary<IExtensibleEntity[]> = entities.reduce((map, _) => ({ ...map, [grouping.getGroupKey(_)]: [...(map[grouping.getGroupKey(_)] || []), _] }), {});
        const itemsMap = toDictionary(entities);
        const result: IRow[] = [];
        groups!.forEach(_ => {
            const itemsInGroup = groupMap[_.key] || [];

            const lines: number[] = [];
            const lineRows: Dictionary<IRow> = {};
            itemsInGroup.forEach(entity => {
                const line = ranking.getRank(entity);
                if (!lineRows[line]) {
                    lines.push(line);
                    lineRows[line] = {
                        key: `${_.key}_${line}`,
                        entity: entity,
                        segments: [],
                        markers: [],
                        relations: []
                    };
                }
                const segment = buildSegment(entity, styling);
                const marker = buildMarker(entity, styling);
                const relations = buildRelations(entity, itemsMap, styling);
                if (segment) {
                    lineRows[line].segments = [...lineRows[line].segments, segment];
                }
                if (relations) {
                    lineRows[line].relations = [...lineRows[line].relations!, ...relations];
                }
                if (marker) {
                    lineRows[line].markers = [...lineRows[line].markers, marker];
                }
            });

            for (const line of [...lines].sort(sortNumber)) {
                result.push(lineRows[line]);
            }
        });

        return result;
    }

    private _resolveSegmentPosition = (data: ITimelineSegment, change: Partial<ITimelineSegment>, verticalOffset: number,
        rowPositionMap: RowPositionMap, currentRowPosition: number, rowHeight: number): RowShift | undefined => {
        return this._resolvePosition(data.key, change.startDate ?? data.startDate, change.finishDate ?? data.finishDate,
            verticalOffset, rowPositionMap, currentRowPosition, rowHeight);
    }

    private _resolveMarkerPosition = (data: ITimelineMarker, change: Partial<ITimelineMarker>, verticalOffset: number,
        rowPositionMap: RowPositionMap, currentRowPosition: number, rowHeight: number): RowShift | undefined => {
        return this._resolvePosition(data.key, change.date ?? data.date, change.date ?? data.date,
            verticalOffset, rowPositionMap, currentRowPosition, rowHeight);
    }

    private _resolvePosition = (key: string, startDate: Date, finishDate: Date, verticalOffset: number, rowPositionMap: RowPositionMap, currentRowPosition: number,
        rowHeight: number): RowShift | undefined => {
        if (currentRowPosition === undefined || rowPositionMap === undefined) {
            return undefined;
        }

        const lineOffset = verticalOffset / rowHeight;
        const dropPosition = Math.abs(lineOffset) % 1;
        const PERCENT_25 = .25;
        const PERCENT_75 = .75;
        const addNewLine = dropPosition >= PERCENT_25 && dropPosition <= PERCENT_75;
        //if new line requested, find upper row, otherwise find nearest to drop point.
        const linesShift = addNewLine ? Math.floor(lineOffset) : Math.round(lineOffset);
        const targetLine = currentRowPosition + linesShift;
        const targetRow = rowPositionMap.rowByLineMap[targetLine];

        if (!targetRow) {
            return undefined;
        }

        if (!addNewLine) {
            const overlappedItems = this.props.ranking.getOverlappedEntities(this.props.grouping.getGroupKey(targetRow.entity),
                this.props.ranking.getRank(targetRow.entity), startDate, finishDate, key);
            const overlappedSegments = overlappedItems.map(_ => this.props.buildSegment(_, this.props.styling)).filter(notUndefined);
            const overlappedMarkers = overlappedItems.map(_ => this.props.buildMarker(_, this.props.styling)).filter(notUndefined);

            return {
                targetRow,
                addNewLine,
                overlappedSegments,
                overlappedMarkers
            }
        }

        return {
            targetRow,
            addNewLine
        }
    }

    private _onChange = (item: ITimelineMapMarker | ITimelineMapSegment, delta: Partial<ITimelineMapMarker | ITimelineMapSegment>, rowShift?: RowShift,
        onChange?: (item: ITimelineMapMarker | ITimelineMapSegment, delta: Partial<ITimelineMapMarker | ITimelineMapSegment>) => void) => {
        const result = { ...delta };
        if (rowShift) {
            const { targetRow, addNewLine } = rowShift;
            result.groupKey = this.props.grouping.getGroupKey(targetRow.entity);
            result.rank = addNewLine || targetRow.rowType === groupRowType
                ? this.props.ranking.getRankAfter(targetRow.entity)
                : this.props.ranking.getRank(targetRow.entity);
        }

        onChange?.(item, result)
    }
}


export type HierarchTimelineProps<T extends THierarchyEntity> = Omit<ITimelineMapProps & IListProps<T>, "entities"> & ListUserSettings & HierarchyProps<T>;

export const HierarchyTimelineMap = <T extends THierarchyEntity>(props: HierarchTimelineProps<T>) => {
    const timelineProps = chainMerger(props,
        _ => useTimelineMapSaver(_),
        _ => useHierarchy(_.hierarchy, _.sorting!, _.comparerBuilder, _.onItemRender));

    return <EntityTimelineMap {...timelineProps} />;
}

export const PersistTimelineMap = (props: Props & ListUserSettings) => {
    const saverProps = useTimelineSaver(props);
    return <EntityTimelineMap {...props} {...saverProps} />;
}