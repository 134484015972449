import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Selection, DefaultButton, arraysEqual, IContextualMenuItem } from 'office-ui-fabric-react';
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { UserState } from "../../store/User";
import { contains, CommonOperations, ResourceOperations } from "../../store/permissions";
import * as ResourcesListStore from '../../store/ResourcesListStore';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as FiltersStore from '../../store/filters';
import * as CalendarStore from '../../store/CalendarStore';
import * as UtilizationStore from '../../store/UtilizationStore';
import { ViewTypeSelect, ViewsTypes } from '../common/ViewTypeSelect';
import { bookingTypeField, PersistResourceUsageGrid, ResourceUsageGridSettings, totalPlannedField, ViewType } from './usage/ResourceUsageGrid';
import { TotalUsageCell } from './usage/ResourceUsageCell';
import {
    EntityType, IExtensibleEntity, Dictionary, UserPreferencesSettingsUpdate, Quantization, IEditableExtensibleEntityWithResourcePlan, entityLogoConfig,
    getCheckboxOptionsBasedOnCommands
} from '../../entities/common';
import { nameof } from '../../store/services/metadataService';
import { ITimelineProps, EntityRowMenuColumnKey } from '../common/extensibleEntity/EntityTimelineList';
import { IListProps } from '../common/extensibleEntity/EntityDetailsList';
import { ViewService } from '../../services/ViewService';
import { toNameDictionary, notUndefined, distinct } from '../utils/common';
import { post } from '../../fetch-interceptor';
import Spinner from '../common/Spinner';
import { PPMFeatures, Subscription } from '../../store/Tenant';
import { defaultCatch, mergeDefault } from '../../store/utils';
import AddEntitiesDialog from '../common/sectionsControl/uiControls/projectsControl/AddEntitiesDialog';
import EntityPickerInput, { IFindResult } from '../common/inputs/EntityPickerInput';
import RemoveDialog from '../common/RemoveDialog';
import PersonPickerInput, { PersonInfo } from '../common/inputs/PersonPickerInput';
import { FilterHelper as ProjectFilterHelper } from '../../store/project/filters';
import { FilterHelper as ProgramFilterHelper } from '../../store/program/filters';
import { FilterHelper as PortfolioFilterHelper } from '../../store/portfolio/filters';
import { buildEntitiesGroup } from '../common/sectionsControl/uiControls/UtilizationControl';
import { buildResourcesGroup } from '../common/sectionsControl/uiControls/ResourcePlanControl';
import { WithFilterProps, isFilterChanged, withFilter } from '../common/withFilter';
import { IWithActiveFilter } from '../../store/services/viewSaver';
import { IWithFilter } from '../common/withFilter';
import ReplaceResourceDialog from './ReplaceResourceDialog';
import * as analytics from '../../analytics';

type ResourceExt = ResourcesListStore.Resource & { type: EntityType.Resource, entityIds: string[] };
type EntityExt = IEditableExtensibleEntityWithResourcePlan & { type: EntityType };
type SubLine = IExtensibleEntity & { resource: ResourcesListStore.Resource, entity: IEditableExtensibleEntityWithResourcePlan; type?: undefined };
type ResourceOrEntity = ResourceExt | EntityExt | SubLine;

type MapState = {
    filteredEntityIds: string[];
    entitiesMap: Dictionary<IEditableExtensibleEntityWithResourcePlan>;
    entitiesIdsMap: Dictionary<{ resourceId: string; entityId: string; }>;
    resources: ResourceExt[];
}
type State = MapState & {
    resourceIds: string[];
    selectedEntityIds: string[];
    expandedEntitiesIds?: string[];
    isAdding?: boolean;
    addTo?: { entityId?: string, resourceId?: string };
    isRemoving?: boolean;
    isReplacing?: boolean;
    canReplace?: boolean;
}
type Grouping = 'Resource' | 'Entity';

type OwnProps = {
    resourceIds: string[];
    resourcePlanningLevel: EntityType;
};

type StoreProps = {
    resourcesMap: Dictionary<ResourcesListStore.Resource>;
    isUsageLoading: boolean;
    isResourcesLoading: boolean;
    resourceFields: Metadata.Field[];
    fields: Metadata.Field[];
    isFieldsLoading: boolean;
    calendar: CalendarStore.CalendarDataSet;
    subscription: Subscription;
    user: UserState;
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    entityLayouts: Metadata.Layout[];
    portfolios: PortfoliosListStore.Portfolio[];
    isPortfoliosLoading: boolean;
    programs: ProgramsListStore.Program[];
    isProgramsLoading: boolean;
    canManageConfiguration: boolean;
    isFiltersDisabled: boolean;
    utilization: UtilizationStore.UtilizationState;
    resourcePlanningLevelLabel: string;
    hasPortfolioManagement: boolean;
}

type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    resourcesActions: typeof ResourcesListStore.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    utilizationActions: typeof UtilizationStore.actionCreators;
}

type ControlSettings = IWithActiveFilter & ResourceUsageGridSettings & {
    viewType: ViewType;
    displayFields: string[];
    grouping: Grouping;
}

type InnerProps = StoreProps & ActionProps & RouteComponentProps<{}> & OwnProps & {
    entities: IEditableExtensibleEntityWithResourcePlan[];
    isEntitiesLoading: boolean;
    controlSettings: ControlSettings;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate) => void;
    loadEntities: (resourceIds: string[]) => void;
} & IWithFilter<IEditableExtensibleEntityWithResourcePlan>;

const defaultControlSettings: ControlSettings = {
    viewType: ViewType.Hours,
    displayFields: [nameof<ResourcesListStore.ResourceAttrs>("Name"), bookingTypeField.name, totalPlannedField.name],
    grouping: 'Resource',
    quantization: Quantization.months
}

class UtilizationList extends React.Component<InnerProps, State> {
    private _rowSelection: Selection;

    constructor(props: InnerProps) {
        super(props);
        this._rowSelection = new Selection({
            onSelectionChanged: () => {
                const selectedRowsCount = this._rowSelection.getSelectedCount();
                this.setState({
                    selectedEntityIds: this._rowSelection.getSelection().map(_ => (_ as ResourceOrEntity).id),
                    canReplace: selectedRowsCount === 1,
                })
            },
            canSelectItem: (item) => {
                const data = (item as ResourceOrEntity);
                return data.type === undefined && data.entity.isEditable;
            },
            getKey: (item) => (item as ResourceOrEntity).id
        });
        this.state = {
            selectedEntityIds: [],
            entitiesMap: {},
            entitiesIdsMap: {},
            filteredEntityIds: [],
            resourceIds: props.resourceIds,
            resources: props.resourceIds.map(_ => props.resourcesMap[_]).filter(notUndefined).map(_ => ({ ..._, type: EntityType.Resource, entityIds: [] })),
            canReplace: false,
            isReplacing: false,
        };
    }

    componentWillReceiveProps(nextProps: InnerProps) {
        const newResources = this.state.resourceIds.map(_ => nextProps.resourcesMap[_]);
        const oldResources = this.state.resourceIds.map(_ => this.props.resourcesMap[_]);
        if (!arraysEqual(oldResources, newResources)) {
            this.setState({
                resources: newResources.filter(notUndefined).map(_ => ({
                    ..._, type: EntityType.Resource, entityIds: this.state.resources.find(__ => __.id === _.id)?.entityIds || []
                }))
            });
        }

        const isEntitiesChanged = !arraysEqual(this.props.entities, nextProps.entities);
        if (isEntitiesChanged || isFilterChanged(this.props, nextProps)) {
            const filtered = nextProps.filter
                ? nextProps.entities.filter(nextProps.filter.isItemVisible)
                : nextProps.entities;
            this.setState({ filteredEntityIds: filtered.map(_ => _.id) });
        }

        if (isEntitiesChanged) {
            this.setState({ ...this._buildMapState(nextProps.entities) });
        }
    }

    render() {
        if (!Subscription.contains(this.props.subscription, PPMFeatures.ResourceManagement)) {
            return <Redirect to="/" />;
        }
        const { user, resourceFields, isResourcesLoading, isUsageLoading, filter, controlSettings, onSaveSettings, resourcePlanningLevel, resourcePlanningLevelLabel } = this.props;
        const { resources, selectedEntityIds, expandedEntitiesIds, isAdding, isRemoving, isReplacing, addTo } = this.state;

        const nameField = resourceFields.find(_ => _.name === "Name");
        const readOnly = !contains(user.permissions.common, CommonOperations.ResourceManage);
        const resourceFieldsMap = toNameDictionary(this.props.resourceFields);
        const entityFieldsMap = toNameDictionary(this.props.fields);
        const filteredEntities: EntityExt[] = this.state.filteredEntityIds.map(_ => this.state.entitiesMap[_]).filter(notUndefined)
            .map(_ => ({ ..._, type: resourcePlanningLevel }));
        const selectionModeCommands = this._getSelectionModeCommands(readOnly);
        
        const listProps: IListProps & Partial<ITimelineProps> = {
            buildTree: true,
            expandedEntitiesIds: expandedEntitiesIds,
            primaryOutlineLevel: 1,
            buildRow: controlSettings.grouping === 'Resource'
                ? (_: ResourceExt) => buildEntitiesGroup(
                    _, _,
                    _.entityIds.filter(id => ~this.state.filteredEntityIds.indexOf(id)).map(entityId => this.state.entitiesMap[entityId]).filter(notUndefined),
                    resourcePlanningLevel,
                    this._buildSubItems)
                : (_: EntityExt) => buildResourcesGroup(
                    _, _,
                    _.resourceIds.map(id => this.props.resourcesMap[id]).filter(notUndefined),
                    resourcePlanningLevel,
                    this._buildSubItems),
            selection: this._rowSelection,
            entities: controlSettings.grouping === 'Resource' ? resources : filteredEntities,
            entityType: EntityType.Resource,
            fields: resourceFields,
            displayFields: controlSettings.displayFields,
            sorting: {
                orderBy: { fieldName: "Name", direction: Metadata.SortDirection.ASC }
            },
            renderHeaderCellContent: _ => _.key === nameField?.id ? <>Resource / {resourcePlanningLevelLabel}</> : undefined,
            onItemRender: (resourceOrEntity: ResourceOrEntity, _, field, defaultRender) => {
                if (field.id === EntityRowMenuColumnKey) {
                    return defaultRender();
                }

                const { controlSettings } = this.props;

                const resource = resourceOrEntity.type === EntityType.Resource
                    ? resourceOrEntity as ResourceExt
                    : resourceOrEntity.type === undefined && controlSettings.grouping === 'Entity'
                        ? resourceOrEntity.resource
                        : undefined;
                if (resource) {
                    const resourceColumn = ViewService.buildColumn(field.name, resourceFieldsMap, undefined, true, EntityType.Resource);
                    return resourceColumn ? resourceColumn.onRender?.(resource, undefined, resourceColumn) : null;
                }

                const entity = resourceOrEntity.type !== undefined
                    ? resourceOrEntity as EntityExt
                    : resourceOrEntity.type === undefined && controlSettings.grouping === 'Resource'
                        ? resourceOrEntity.entity
                        : undefined;
                if (entity) {
                    const entityColumn = ViewService.buildColumn(field.name, entityFieldsMap, undefined, true, resourcePlanningLevel);
                    return entityColumn ? entityColumn.onRender?.(entity, undefined, entityColumn) : null;
                }

                return defaultRender();
            },
            ...getCheckboxOptionsBasedOnCommands(selectionModeCommands)
        };

        return <div className="resource-utilization">
            <div className="page-navigation">
                <DefaultButton text="Back to Resources" iconProps={{ iconName: "Back" }} href="/resources" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.replace("/resources");
                }} />
                <div className="align-right">
                    <ViewTypeSelect
                        selected={controlSettings.viewType}
                        views={ViewsTypes.ResourceUsage}
                        onItemSelected={_ => onSaveSettings?.({
                            parentSettingsPathKeys: [],
                            valueBySettingNameMap: {
                                [nameof<ControlSettings>('viewType')]: _
                            }
                        })}
                    />
                </div>
            </div>
            <h2>Resource Planning and Utilization</h2>
            <div className="entities-list">
                {this.props.isFieldsLoading || this.props.isEntitiesLoading || this.props.isPortfoliosLoading || this.props.isProgramsLoading
                    ? <Spinner />
                    : <PersistResourceUsageGrid
                        entitiesMap={this.state.entitiesMap}
                        menuProps={{
                            entityType: EntityType.Project,
                            fields: listProps.fields,
                            type: "Columns",
                            displayFields: controlSettings.displayFields,
                            canViewConfigureColumns: true,
                            mandatoryViewFields: ViewService.getViewMandatoryFields(EntityType.Resource),
                            onDisplayFieldsChange: _ => onSaveSettings?.({
                                parentSettingsPathKeys: [],
                                valueBySettingNameMap: {
                                    [nameof<ControlSettings>('displayFields')]: _
                                }
                            }),
                            commands: readOnly ? [] : [{
                                    key: 'addRow',
                                    name: 'Add Resource',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => this.setState({ isAdding: true })
                                }],
                            selectionMode: {
                                enabled: selectedEntityIds.length > 0,
                                items: selectionModeCommands,
                                onCancel: () => this._rowSelection.setAllSelected(false)
                            },
                            farCommands: [
                                {
                                    key: 'grouping',
                                    name: `Group by: ${controlSettings.grouping === 'Entity' ? resourcePlanningLevelLabel : controlSettings.grouping}`,
                                    iconProps: { iconName: 'ViewListGroup' },
                                    subMenuProps: {
                                        items: ['Resource' as Grouping, 'Entity' as Grouping].map<IContextualMenuItem>(_ => ({
                                            key: _,
                                            name: _ === 'Entity' ? resourcePlanningLevelLabel : _,
                                            canCheck: true,
                                            checked: controlSettings.grouping === _,
                                            onClick: () => onSaveSettings?.({
                                                parentSettingsPathKeys: [],
                                                valueBySettingNameMap: { [nameof<ControlSettings>('grouping')]: _ }
                                            })
                                        }))
                                    }
                                }
                            ],
                            ...filter.menu
                        }}
                        scrollable
                        isLoading={isResourcesLoading || isUsageLoading}
                        entitiesIds={Object.keys(this.state.entitiesIdsMap)}
                        entityType={resourcePlanningLevel}
                        selectedEntityIds={selectedEntityIds}
                        listProps={listProps}
                        extractRowIds={(resourceOrEntity: ResourceOrEntity) => ({
                            entityIds: resourceOrEntity.type === undefined
                                ? [resourceOrEntity.entity.id]
                                : resourceOrEntity.type === EntityType.Resource
                                    ? this.state.filteredEntityIds
                                    : [resourceOrEntity.id],
                            resourceIds: resourceOrEntity.type === undefined
                                ? [resourceOrEntity.resource.id]
                                : resourceOrEntity.type === EntityType.Resource
                                    ? [resourceOrEntity.id]
                                    : (resourceOrEntity as EntityExt).resourceIds,
                            isTotalRow: resourceOrEntity.type !== undefined
                        })}
                        extractCellIds={cell => this.state.entitiesIdsMap[cell.entityId]}
                        viewType={controlSettings.viewType}
                        renderTotalSegmentContent={(row, segment, timeType) =>
                            <TotalUsageCell resources={controlSettings.grouping === 'Entity'
                                ? (row.entity as EntityExt).resourceIds.map(_ => this.props.resourcesMap[_]).filter(notUndefined)
                                : [row.entity as ResourceExt]}
                                entityIds={controlSettings.grouping === 'Entity'
                                    ? [(row.entity as EntityExt).id]
                                    : this.state.filteredEntityIds}
                                entityType={resourcePlanningLevel}
                                startDate={segment.startDate}
                                finishDate={segment.finishDate}
                                timeType={timeType}
                                viewType={controlSettings.viewType} />
                        }
                        isEditable={(resourceOrEntity: ResourceOrEntity) => resourceOrEntity.type === undefined && resourceOrEntity.entity.isEditable}
                        buildRowMenuItems={(resourceOrEntity: ResourceOrEntity) => {
                            if (resourceOrEntity.type === undefined) {
                                if (!resourceOrEntity.entity.isEditable) {
                                    return [];
                                }
                                const result: IContextualMenuItem[] = controlSettings.grouping === 'Resource' ? [] :
                                    [{
                                        key: 'replace',
                                        name: 'Replace Resource',
                                        disabled: !this.state.canReplace,
                                        iconProps: { iconName: 'PPMXSwitchUser' },
                                        onClick: () => this.setState({ isReplacing: true })
                                    }];
                                result.push({
                                    key: 'deleteRow',
                                    name: `Remove from ${resourcePlanningLevelLabel}`,
                                    style: { color: 'red' },
                                    iconProps: { iconName: "Delete", style: { color: 'red' } },
                                    onClick: () => this.setState({ isRemoving: true })
                                });
                                return result;
                            }
                            if (resourceOrEntity.type === EntityType.Resource) {
                                return [{
                                    key: 'addRow',
                                    name: `Add to ${resourcePlanningLevelLabel}`,
                                    disabled: resourceOrEntity.attributes.Status === ResourcesListStore.ResourceStatus.Inactive,
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => this.setState({ addTo: { resourceId: resourceOrEntity.id } })
                                }];
                            }
                            return [{
                                key: 'addRow',
                                name: 'Add Resource',
                                disabled: !resourceOrEntity.isEditable,
                                iconProps: { iconName: 'Add' },
                                onClick: () => this.setState({ addTo: { entityId: resourceOrEntity.id } })
                            }];
                            return [];
                        }}
                        controlSettings={controlSettings}
                        onSaveSettings={onSaveSettings}
                    />}
            </div>
            {isAdding && <AddEntitiesDialog
                confirmButtonText="Add Resources"
                dialogContentProps={{
                    title: 'Add Resources',
                    subText: 'Select resources to be added for planning'
                }}
                onDismiss={() => this.setState({ isAdding: false })}
                onComplete={this._addResources}
                renderEntitySelector={(onChanged) => <>
                    <PersonPickerInput
                        multichoice={true}
                        onChanged={(value) => value && onChanged((value as PersonInfo[]).map(_ => _.id))}
                        searchUrl="api/resource/find"
                        filter={{ exceptIds: resources.map(_ => _.id) }}
                        inputProps={{ placeholder: "Type to search", autoFocus: true }}
                    />
                </>}
            />}
            {addTo && <AddEntitiesDialog
                dialogContentProps={{
                    title: `Add Resource to ${resourcePlanningLevelLabel}${addTo.resourceId ? "s" : ""}`,
                    subText: addTo.resourceId
                        ? `Choose ${resourcePlanningLevel}s the resource will be added to`
                        : `Choose resources that will be added to the ${resourcePlanningLevel}`
                }}
                onDismiss={() => this.setState({ addTo: undefined })}
                onComplete={_ => this._addToEntities(addTo.resourceId ? [addTo.resourceId] : _, addTo.entityId ? [addTo.entityId] : _)}
                renderEntitySelector={(onChanged) =>
                    addTo.resourceId
                        ? <EntityPickerInput
                            searchUrl={`api/${resourcePlanningLevel}/find`}
                            filter={{ accessLevel: ResourceOperations.Update, exceptIds: this.state.resources.find(_ => _.id === addTo.resourceId)!.entityIds }}
                            inputProps={{ placeholder: "Type to search", autoFocus: true }}
                            onEditComplete={(_: IFindResult[] | null) => { _ && onChanged(_.map(__ => __.id)); }} />
                        : <PersonPickerInput
                            multichoice
                            onChanged={(value) => value && onChanged((value as PersonInfo[]).map(_ => _.id))}
                            searchUrl="api/resource/find"
                            filter={{ exceptIds: this.state.entitiesMap[addTo.entityId!].resourceIds }}
                            inputProps={{ placeholder: "Type to search", autoFocus: true }}
                        />
                }
            />}
            {isRemoving && <RemoveDialog
                dialogContentProps={{
                    title: `Remove from ${resourcePlanningLevelLabel}${this._rowSelection.getSelectedCount() > 1 ? 's' : ''}`,
                    subText: this._getDeletionMessage()
                }}
                onClose={() => this.setState({ isRemoving: false })} onComplete={this._onRemove} />}
            {isReplacing && <ReplaceResourceDialog
                entityType={resourcePlanningLevelLabel}
                entityid={(this._rowSelection.getSelection()[0] as SubLine).entity.id}
                resource={(this._rowSelection.getSelection()[0] as SubLine).resource}
                resourceIds={(this._rowSelection.getSelection()[0] as SubLine).entity.resourceIds}
                onDismiss={() => this.setState({ isReplacing: false })}
                onReplaceResource={this._replaceResource} />
            }
        </div>;
    }

    private _buildSubItems = (res: ResourcesListStore.Resource, entity: IEditableExtensibleEntityWithResourcePlan)
        : { key: string; entity: SubLine; markers: never[]; segments: never[]; } => {
        const resourceOrEntity: ResourceOrEntity = {
            entity: entity,
            resource: res,
            attributes: {},
            id: toEntityId(res.id, entity.id)
        };

        return {
            key: resourceOrEntity.id,
            entity: resourceOrEntity,
            markers: [],
            segments: []
        };
    };

    private _buildMapState = (entities: IEditableExtensibleEntityWithResourcePlan[]): MapState => {
        const { filter } = this.props;
        const { resources, resourceIds } = this.state;

        const entityIdsByResourceIdsMap = resources.map(res => ({
            resourceId: res.id,
            entityIds: entities.filter(_ => _.resourceIds.includes(res.id)).map(_ => _.id)
        })).reduce((cum, cur) => ({ ...cum, [cur.resourceId]: cur.entityIds }), {} as Dictionary<string[]>);

        const entitiesMap = entities.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {} as Dictionary<IEditableExtensibleEntityWithResourcePlan>);
        return {
            entitiesMap,
            filteredEntityIds: entities
                .filter(_ => !!_.resourceIds.find(__ => resourceIds.includes(__)) && filter.isItemVisible(_))
                .map(_ => _.id),
            entitiesIdsMap: Object.keys(entitiesMap)
                .reduce((cum, cur) => ([...cum, ...entitiesMap[cur].resourceIds.map(_ => ({ resourceId: _, entityId: cur }))]), [])
                .reduce((cum, cur) => ({ ...cum, [toEntityId(cur.resourceId, cur.entityId)]: cur }), {}),
            resources: resources.map(res => ({ ...res, entityIds: entityIdsByResourceIdsMap[res.id] || [] }))
        };
    }

    private _addResources = (resourceIds: string[] | undefined) => {
        resourceIds = [...this.state.resourceIds, ...(resourceIds || [])];
        const resources = resourceIds.map(_ => this.props.resourcesMap[_]).filter(notUndefined)
            .map<ResourceExt>(_ => ({ ..._, type: EntityType.Resource, entityIds: this.state.resources.find(__ => __.id === _.id)?.entityIds || [] }));
        this.setState({
            isAdding: false,
            resourceIds,
            resources
        });
        this.props.loadEntities(resourceIds);

        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosActions.requestPortfolios();
        }
    }

    private _replaceResource = (newResourceIds: string[], keepRateOnReplace: boolean, sumUpPlanHours: boolean, sumUpActualHours: boolean) => {

        const selectedItems = this._rowSelection.getSelection().map(_ => ({ prevResourceId: (_ as SubLine).resource.id, entityId: (_ as SubLine).entity.id }));
        if (selectedItems?.[0] && newResourceIds?.[0]) {
            this.props.resourcesActions.replaceResourcesInEntities(
                this.props.resourcePlanningLevelLabel,
                selectedItems[0].entityId,
                selectedItems[0].prevResourceId,
                newResourceIds?.[0],
                keepRateOnReplace,
                sumUpPlanHours,
                sumUpActualHours,
                entities => this._updateEntities(entities)
            );
            analytics.trackEvent("Replace resource in resource plan", this.props.user, { keepRateOnReplace, sumUpPlanHours, sumUpActualHours });
        }
    }

    private _addToEntities = (resourceIds: string[], entityIds: string[]) => {
        this.props.resourcesActions.addResourcesToEntities(this.props.resourcePlanningLevel,
            resourceIds.reduce((cum, resourceId) => ([...cum, ...entityIds.map(entityId => ({ entityId, resourceId }))]), []),
            entities => {
                const entitiesMap: Dictionary<IEditableExtensibleEntityWithResourcePlan> = {
                    ...this.state.entitiesMap,
                    ...entities.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {})
                }
                const allEntities = Object.keys(entitiesMap).map(_ => entitiesMap[_]);
                this.setState({
                    ...this._buildMapState(allEntities),
                    expandedEntitiesIds: [...resourceIds, ...entityIds]
                });
            });

        this.setState({ addTo: undefined, expandedEntitiesIds: undefined });
    }

    private _getDeletionMessage = () => {
        const selection = this._rowSelection.getSelection();
        if (selection.length === 1) {
            return `Are you sure you want to remove resource "${(selection[0] as SubLine).resource.attributes.Name}"
                from the ${this.props.resourcePlanningLevel} "${(selection[0] as SubLine).entity.attributes.Name}"?`;
        }

        const sublines = selection as SubLine[];
        const entitiesCount = sublines.map(_ => _.entity.id).filter(distinct).length;
        const resourcesCount = sublines.map(_ => _.resource.id).filter(distinct).length;
        return `Are you sure you want to remove ${resourcesCount > 1
            ? 'selected resources'
            : ` resource "${sublines[0].resource.attributes.Name}"`} \nfrom ${entitiesCount > 1
                ? `${entitiesCount} ${this.props.resourcePlanningLevel}s`
                : `the ${this.props.resourcePlanningLevel} "${sublines[0].entity.attributes.Name}"`}?`;
    }

    private _onRemove = () => {
        const selectedItems = this._rowSelection.getSelection().map(_ => ({ resourceId: (_ as SubLine).resource.id, entityId: (_ as SubLine).entity.id }));
        if (selectedItems.length) {
            this.props.resourcesActions.removeResourcesFromEntities(this.props.resourcePlanningLevel, selectedItems, entities => this._updateEntities(entities));
            analytics.trackEvent("Remove resource from resource plan", this.props.user);
        }
    }

    private _getSelectionModeCommands(readOnly: boolean) {
        const { resourcePlanningLevelLabel } = this.props;
        return readOnly ? [] : [{
            key: 'removeRow',
            name: `Remove from ${resourcePlanningLevelLabel}`,
            iconProps: { iconName: 'delete' },
            onClick: () => this.setState({ isRemoving: true })
        }];
    }

    private _updateEntities(entities: any[]) {
        const entitiesMap: Dictionary<IEditableExtensibleEntityWithResourcePlan> = {
            ...this.state.entitiesMap,
            ...entities.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {})
        }
        const allEntities = Object.keys(entitiesMap).map(_ => entitiesMap[_]);
        this.setState(this._buildMapState(allEntities));
    }

}

function toEntityId(resourceId: string, entityId: string): string {
    return `${resourceId}_${entityId}`;
}

const useFilterProps = ({ portfolios, entities, programs, fields, entityLayouts, isFiltersDisabled,
    filters, filtersActions, canManageConfiguration, history, location, controlSettings, onSaveSettings, resourcePlanningLevel, resourcePlanningLevelLabel }: InnerProps)
    : WithFilterProps<IEditableExtensibleEntityWithResourcePlan> => {
    const filterHelper = React.useMemo(
        () => resourcePlanningLevel === EntityType.Project
            ? new ProjectFilterHelper({ projectFields: fields, layouts: entityLayouts, portfolios, programs, projects: entities as unknown as ProjectsListStore.ProjectInfo[] })
            : resourcePlanningLevel === EntityType.Program
                ? new ProgramFilterHelper({ fields, layouts: entityLayouts, portfolios, programs: entities as unknown as ProgramsListStore.Program[] })
                : new PortfolioFilterHelper({ fields, layouts: entityLayouts }),
        [entities, portfolios, programs, fields, entityLayouts]);

    return {
        filterHelper: filterHelper,
        entityType: resourcePlanningLevel,
        disabled: isFiltersDisabled ? `${resourcePlanningLevelLabel} filter is available for users with "View all" ${resourcePlanningLevelLabel}s permission.` : undefined,
        filters,
        filtersActions,
        canManageConfiguration,
        entities: entities,
        fields: fields,
        history,
        location,
        controlSettings,
        onSaveSettings
    }
}

const WithFilter = withFilter(UtilizationList, useFilterProps, FiltersStore.FilterKeys.UtilizationList)

type Props = OwnProps & StoreProps & ActionProps & RouteComponentProps<{}>;

const UtilizationListWrapper = (props: Props) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [entities, setEntities] = React.useState<IEditableExtensibleEntityWithResourcePlan[]>([]);

    const loadEntities = (resourceIds: string[]) => {
        setLoading(true);

        post<IEditableExtensibleEntityWithResourcePlan[]>(`api/resourcePlan/find`, { entityType: props.resourcePlanningLevel, resourceIds })
            .then(data => {
                setEntities(data);
                setLoading(false);
            })
            .catch(defaultCatch());
    }

    React.useEffect(() => {
        loadEntities(props.resourceIds);
        props.utilizationActions.loadSettings();
        if (props.hasPortfolioManagement) {
            props.portfoliosActions.requestPortfolios();
            props.programsActions.requestPrograms();
        }
        props.projectsActions.requestProjects();
    }, []);

    const controlSettings = React.useMemo(
        () => mergeDefault(defaultControlSettings, props.utilization.settings),
        [props.utilization.settings]);

    const listProps = {
        ...props,
        entities: entities,
        isEntitiesLoading: loading,
        controlSettings: controlSettings,
        onSaveSettings: props.utilization.isLoading
            ? undefined
            : props.utilizationActions.saveSettings,
        loadEntities: loadEntities
    }

    return <WithFilter {...listProps} />;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const resourceFields = state.fields[EntityType.Resource];
    const entityFields = state.fields[ownProps.resourcePlanningLevel];
    const entityLayouts = state.layouts[ownProps.resourcePlanningLevel];
    const filters = FiltersStore.getFilter(state.filters, ownProps.resourcePlanningLevel,);

    const isFiltersDisabled = !contains(ownProps.resourcePlanningLevel === EntityType.Portfolio
        ? state.user.permissions.portfolio.global
        : ownProps.resourcePlanningLevel === EntityType.Program
            ? state.user.permissions.program.global
            : state.user.permissions.project.global, ResourceOperations.Read);

    return {
        resourcePlanningLevelLabel: entityLogoConfig[ownProps.resourcePlanningLevel]?.label!,
        isUsageLoading: state.resources.isLoadingUsage,
        isResourcesLoading: state.resources.isLoading,
        resourcesMap: { ...state.resources.byId },
        resourceFields: resourceFields.allIds.map(_ => resourceFields.byId[_]),
        fields: entityFields.allIds.map(_ => entityFields.byId[_]),
        entityLayouts: entityLayouts.allIds.map(_ => entityLayouts.byId[_]),
        isFieldsLoading: resourceFields.isLoading || entityFields.isLoading,
        calendar: state.calendar,
        subscription: state.tenant.subscription,
        user: state.user,
        isPortfoliosLoading: state.portfolios.isListLoading,
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        isProgramsLoading: state.programs.isListLoading,
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        filters: filters.all,
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        canManageConfiguration: contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
        isFiltersDisabled,
        utilization: state.utilization
    };
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps): ActionProps {
    return {
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        resourcesActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(ownProps.resourcePlanningLevel, FiltersStore.FilterKeys.UtilizationList), dispatch),
        utilizationActions: bindActionCreators(UtilizationStore.actionCreators, dispatch),
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(UtilizationListWrapper));