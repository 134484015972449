
import * as Metadata from "../entities/Metadata";
import { Reducer, Action } from "redux";
import { AppThunkAction } from ".";
import { IUserInfo, statusCategoryMap, StatusCategory } from "../entities/common";
import { ISyncVersion, SourceType } from "./ExternalEpmConnectStore";
import { get, post } from "../fetch-interceptor";
import { defaultCatch } from "./utils";
import { IEntityStore, StoreHelper } from "./services/storeHelper";
import { EntityGroup } from "../components/common/extensibleEntity/EntityGroupHeader";
import { namesof, nameof } from "./services/metadataService";
import { notUndefined, toDate } from "../components/utils/common";
import { PPMFeatures, Subscription } from "./Tenant";

export enum Status {
    NA = 'N/A',
    OnTrack = "On Track",
    AtRisk = "At Risk",
    Critical = "Critical",
    Done = "Done",
    Active = "Active",
    Closed = "Closed",
    Postponed = "Postponed"
}

export namespace Status_ {
    export const statusOrder: { [key: string]: number } = {
        [Status.Critical]: 3,
        [Status.AtRisk]: 2,
        [Status.OnTrack]: 1,
        [Status.Active]: 5,
        [Status.Postponed]: 6,
        [Status.NA]: 0,
        [Status.Done]: 4,
        [Status.Closed]: 7
    }

    export function getOrder(status: string) {
        const order = statusOrder[status];
        return order != undefined ? order : Number.MAX_VALUE
    }

    export function getCssClass(status: string) {
        return statusCategoryMap[getStatusCategory(status)].cssClassName;
    }

    export function getStatusCategory(status: string) {
        switch (status) {
            case Status.NA: return StatusCategory.NA;
            case Status.OnTrack: return StatusCategory.Green;
            case Status.AtRisk: return StatusCategory.Amber;
            case Status.Critical: return StatusCategory.Red;
            case Status.Done: return StatusCategory.Done;
            case Status.Active: return StatusCategory.Green;
            case Status.Closed: return StatusCategory.Done;
            case Status.Postponed: return StatusCategory.NA;
        }

        return StatusCategory.NA;
    }
}

export type LinkInfo = {
    type: SourceType;
    url: string;
    iconUrl?: string;
    connectionId: string;
    syncVersion: ISyncVersion;
}

export type MyWorkParent = {
    id: string;
    name: string;
    imageId?: string;
    type: MyWorkType;
    isEditable: boolean;
    canView: boolean;
    canCollaborate: boolean;
    links: LinkInfo[];
    taskStatusCalculationDisabled: boolean;
    isPrivate: boolean;
}

export type MyWorkAttrs = {
    Name: string;
    Description?: string | null;
    StartDate: string;
    DueDate: string;
    Progress: number;
    Status: string;
    Parent: MyWorkParent;
    AssignedTo: IUserInfo[];
    Type: string;
}

export enum MyWorkType {
    Project = 1,
    Portfolio = 2,
    Program = 12,

    Risk = 6,
    Issue = 7,
    Task = 8,
    ActionItem = 10,
    ChangeRequest = 19
}

export type MyWork = {
    id: string;
    attributes: MyWorkAttrs;
    groupsKeys: string[];
    source: {
        id: string;
        type: MyWorkType;
        link?: LinkInfo;
        isMilestone: boolean;
        isAutoMode?: boolean;
        color?: string;
    }
}

export enum DueDateGroup {
    NoDate = 'nodates',
    Today = 'today',
    Tomorrow = 'tomorrow',
    ThisWeek = 'thisweek',
    NextWeek = 'nextweek',
    ThisMonth = 'thismonth',
    NextMonth = 'nextmonth',
    Future = 'future',
    Late = 'late'
}

export namespace DueDateGroup_ {
    const dueDateGroupOrder: { [key: string]: number } = {
        [DueDateGroup.NoDate]: 9,
        [DueDateGroup.Today]: 2,
        [DueDateGroup.Tomorrow]: 3,
        [DueDateGroup.ThisWeek]: 4,
        [DueDateGroup.NextWeek]: 5,
        [DueDateGroup.ThisMonth]: 6,
        [DueDateGroup.NextMonth]: 7,
        [DueDateGroup.Future]: 8,
        [DueDateGroup.Late]: 1
    }

    const dueDateGroupLabel: { [key: string]: string } = {
        [DueDateGroup.NoDate]: "No Date",
        [DueDateGroup.Today]: "Today",
        [DueDateGroup.Tomorrow]: "Tomorrow",
        [DueDateGroup.ThisWeek]: "This Week",
        [DueDateGroup.NextWeek]: "Next Week",
        [DueDateGroup.ThisMonth]: "This Month",
        [DueDateGroup.NextMonth]: "Next Month",
        [DueDateGroup.Future]: "Future",
        [DueDateGroup.Late]: "Late"
    }

    export const getOrder = (key: string) => dueDateGroupOrder[key];
    export const getLabel = (key: string) => dueDateGroupLabel[key];
    export const getAll = () => values(DueDateGroup).sort((a, b) => DueDateGroup_.getOrder(a) - DueDateGroup_.getOrder(b));
    export const getFilters = (): { [key: string]: (work: MyWork) => boolean } => {
        const today = {
            begin: new Date().getBeginOfDay(),
            end: new Date().getEndOfDay()
        };
        const thisWeek = new Date().getThisWeek();
        const nextweek = new Date().getNextWeek();
        const thisMonth = new Date().getThisMonth();
        const nextMonth = new Date().getNextMonth();

        const defaultFilter = (key: string) => (work: MyWork) => work.groupsKeys[DUE_DATE_GROUP_INDEX] == key;
        return [
            DueDateGroup.Today,
            DueDateGroup.Tomorrow,
            DueDateGroup.NoDate
        ].reduce((a, b) => ({ ...a, [b]: defaultFilter(b) }), {
            [DueDateGroup.ThisWeek]: _ =>
                !!_.attributes.DueDate
                && toDate(_.attributes.DueDate)! >= thisWeek.start
                && toDate(_.attributes.DueDate)! <= thisWeek.end,
            [DueDateGroup.NextWeek]: _ =>
                !!_.attributes.DueDate
                && toDate(_.attributes.DueDate)! >= nextweek.start
                && toDate(_.attributes.DueDate)! <= nextweek.end,
            [DueDateGroup.ThisMonth]: _ =>
                !!_.attributes.DueDate
                && toDate(_.attributes.DueDate)! >= thisMonth.start
                && toDate(_.attributes.DueDate)! <= thisMonth.end,
            [DueDateGroup.NextMonth]: _ =>
                !!_.attributes.DueDate
                && toDate(_.attributes.DueDate)! >= nextMonth.start
                && toDate(_.attributes.DueDate)! <= nextMonth.end,
            [DueDateGroup.Late]: _ =>
                _.attributes.Progress != 100
                && _.attributes.Status != Status.Closed
                && _.attributes.Status != Status.Done
                && !!_.attributes.DueDate && toDate(_.attributes.DueDate)! < today.begin,
            [DueDateGroup.Future]: _ =>
                !!_.attributes.DueDate && toDate(_.attributes.DueDate)! > today.end
        });
    }
}

export enum Groups {
    DueDate = "due-date",
    Status = "status",
    Parent = "parent"
}

export namespace GroupTypes {
    const _map = {
        [Groups.DueDate]: "Due date",
        [Groups.Status]: "Status",
        [Groups.Parent]: (subscription: Subscription) => Subscription.contains(subscription, PPMFeatures.PortfolioManagement)
            ? "Project / Program / Portfolio"
            : "Project"
    }

    export const getTitle = (group: { key: Groups }, subscription: Subscription) => {
        const title = _map[group.key];
        return typeof title === "string"
            ? title
            : title(subscription);
    }
}

export const groupTypes = [{
    key: Groups.DueDate,
    keygen: (task: MyWork) => {
        if (!task.attributes.DueDate) {
            return DueDateGroup.NoDate;
        }

        const dueDate = toDate(task.attributes.DueDate)!.trimHours().getTime();
        const today = new Date().trimHours();
        if (dueDate == today.getTime()) {
            return DueDateGroup.Today;
        } else if (dueDate == today.addDays(1).getTime()) {
            return DueDateGroup.Tomorrow;
        }

        const thisWeek = today.getThisWeek();
        if (thisWeek.start.getTime() <= dueDate && dueDate <= thisWeek.end.getTime()) {
            return DueDateGroup.ThisWeek;
        }

        const nextWeek = today.getNextWeek();
        if (nextWeek.start.getTime() <= dueDate && dueDate <= nextWeek.end.getTime()) {
            return DueDateGroup.NextWeek;
        }

        const thisMonth = today.getThisMonth();
        if (thisMonth.start.getTime() <= dueDate && dueDate <= thisMonth.end.getTime()) {
            return DueDateGroup.ThisMonth;
        }

        const nextMonth = today.getNextMonth();
        if (nextMonth.start.getTime() <= dueDate && dueDate <= nextMonth.end.getTime()) {
            return DueDateGroup.NextMonth;
        }

        if (dueDate > today.getTime()) {
            return DueDateGroup.Future;
        } else {
            return DueDateGroup.Late;
        }
    },
    getGroup: (key: string): EntityGroup => ({ key: key, name: DueDateGroup_.getLabel(key), count: 1, color: "" }),
    sort: (a: EntityGroup, b: EntityGroup) => DueDateGroup_.getOrder(a.key) - DueDateGroup_.getOrder(b.key),
    listColumns: namesof<MyWorkAttrs>(["Name", "Description", "Parent", "StartDate", "DueDate", "Progress", "Status", "AssignedTo"]),
    timelineColumns: namesof<MyWorkAttrs>(["Name", "Description", "Parent", "Status"])
}, {
    key: Groups.Status,
    keygen: (task: MyWork) => task.attributes.Status,
    getGroup: (key: string): EntityGroup => ({ key: key, name: key, count: 1, color: "" }),
    sort: (a: EntityGroup, b: EntityGroup) => Status_.getOrder(a.key) - Status_.getOrder(b.key),
    listColumns: namesof<MyWorkAttrs>(["Name", "Description", "Parent", "StartDate", "DueDate", "Progress", "AssignedTo"]),
    timelineColumns: namesof<MyWorkAttrs>(["Name", "Parent"])
}, {
    key: Groups.Parent,
    getGroup: (key: string, work: MyWork): EntityGroup => ({ key: key, name: work.attributes.Parent.name, count: 1, color: "", data: work.attributes.Parent }),
    keygen: (task: MyWork) => task.attributes.Parent.id,
    sort: (a: EntityGroup, b: EntityGroup) => a.name.localeCompare(b.name),
    listColumns: namesof<MyWorkAttrs>(["Name", "Description", "StartDate", "DueDate", "Progress", "Status", "AssignedTo"]),
    timelineColumns: namesof<MyWorkAttrs>(["Name", "Status"])
}];

export const DUE_DATE_GROUP_INDEX = groupTypes.findIndex(_ => _.key == Groups.DueDate);

interface LoadAction {
    type: "MYWORK_LOAD";
}

interface LoadedAction {
    type: "MYWORK_LOADED";
    data: MyWork[];
}

interface LoadedBySourceIdAction {
    type: "MYWORK_LOADED_BY_SOURCE_ID";
    data: MyWork[];
    sourceIds: string[];
}

type KnownAction = LoadAction | LoadedAction | LoadedBySourceIdAction;

export const actionCreators = {
    sync: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<MyWork[]>('/api/mywork/sync', {})
            .then(_ => dispatch({ type: 'MYWORK_LOADED', data: _ }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'MYWORK_LOAD' });
    },
    load: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<MyWork[]>('/api/mywork', {})
            .then(_ => dispatch({ type: 'MYWORK_LOADED', data: _ }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'MYWORK_LOAD' });
    },
    findBySourceId: (sourceIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<MyWork[]>('/api/mywork/search', { sourceIds })
            .then(_ => dispatch({ type: 'MYWORK_LOADED_BY_SOURCE_ID', sourceIds, data: _ }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'MYWORK_LOAD' });
    }
}

export interface MyWorkState extends IEntityStore<MyWork> {
    isLoading: boolean;
}

const unloadedState: MyWorkState = {
    byId: {},
    allIds: [],
    isLoading: false
};

export const reducer: Reducer<MyWorkState> = (state: MyWorkState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "MYWORK_LOAD": return {
            ...state,
            isLoading: true
        }
        case "MYWORK_LOADED": {
            action.data.forEach(_ => _.groupsKeys = groupTypes.map(g => g.keygen(_)));
            return {
                ...StoreHelper.create(action.data),
                isLoading: false
            }
        }
        case "MYWORK_LOADED_BY_SOURCE_ID": {
            action.data.forEach(_ => _.groupsKeys = groupTypes.map(g => g.keygen(_)));
            return {
                ...StoreHelper.union(StoreHelper.filter(state, _ => !~action.sourceIds.indexOf(_.source.id)), action.data),
                isLoading: false
            }
        }
        default: const exhaustiveCheck: never = action;
    }

    return state;
}

export function _buildFields(work: MyWork[]) {
    const statusFieldName = nameof<MyWorkAttrs>("Status");
    const typeFieldName = nameof<MyWorkAttrs>("Type");
    const statusOptions = work.map(_ => _.attributes.Status);
    const typeOptions = work.map(_ => _.attributes.Type).filter(notUndefined);

    return {
        fields: fields.map(_ => _.name === statusFieldName
            ? ({
                ..._,
                settings: {
                    ..._.settings,
                    options: Array.from(new Set((_.settings?.options as string[] || []).concat(statusOptions))).map(Metadata.ToOption)
                }
            })
            : _.name === typeFieldName
                ? ({
                    ..._,
                    settings: {
                        ..._.settings,
                        options: Array.from(new Set((_.settings?.options as string[] || []).concat(typeOptions))).map(Metadata.ToOption)
                    }
                })
                : _)
    };
}

export const fields: Metadata.Field[] = [
    {
        id: "fadaae8a-520c-4e8e-b7e0-f1a5a9d58e19",
        label: "Name",
        name: nameof<MyWorkAttrs>("Name"),
        type: Metadata.FieldType.Text,
        settings: {
            views: {
                list: {
                    componentPath: "mywork/Name",
                    minWidth: 200,
                    maxWidth: 500,
                }
            }
        },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "a5dce9cf-cc64-4244-a776-051bf5fd2873",
        label: "Description",
        name: nameof<MyWorkAttrs>("Description"),
        type: Metadata.FieldType.Text,
        settings: { multiline: true },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "cc8d421c-678c-4207-81fa-1f16a711798f",
        label: "From",
        name: nameof<MyWorkAttrs>("Parent"),
        type: Metadata.FieldType.Portfolio,
        settings: {
            views: {
                list: {
                    componentPath: "mywork/Parent",
                    minWidth: 200,
                    maxWidth: 400
                }
            }
        },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "73ce7001-83f7-4270-b7ec-59657eae07d7",
        label: "Start Date",
        name: nameof<MyWorkAttrs>("StartDate"),
        type: Metadata.FieldType.Date,
        settings: { views: { list: { minWidth: 120, maxWidth: 120 } } },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "ff3ca554-6840-46d1-9323-82a40a0c58c1",
        label: "Due Date",
        name: nameof<MyWorkAttrs>("DueDate"),
        type: Metadata.FieldType.Date,
        settings: { views: { list: { minWidth: 120, maxWidth: 120 } } },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "08e96dc7-d45a-459f-b371-a5c3fdb5edb4",
        label: "Status",
        name: nameof<MyWorkAttrs>("Status"),
        type: Metadata.FieldType.Text,
        settings: {
            editControl: "Dropdown",
            options: values(Status),
            views: { list: { componentPath: "mywork/Status", minWidth: 104, maxWidth: 104 } }
        },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "63ba3e4e-cff6-438c-bc91-7788c71b586e",
        label: "Progress",
        name: nameof<MyWorkAttrs>("Progress"),
        type: Metadata.FieldType.Integer,
        settings: { format: 2 },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "882b0697-4be5-4165-a4d2-f5c9d8977328",
        name: nameof<MyWorkAttrs>("AssignedTo"),
        label: "Assigned To",
        type: Metadata.FieldType.Resource,
        settings: { multichoice: true },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true
    },
    {
        id: "e488cc4b-61c9-4f98-8981-651e4000d225",
        name: nameof<MyWorkAttrs>("Type"),
        label: "Type",
        type: Metadata.FieldType.Integer,
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        settings: {
            editControl: "Dropdown",
            options: []
        }
    }];

function values<TEnum extends Record<string, any>>(theEnum: TEnum): string[] {
    return Object.keys(theEnum).map((key: any) => theEnum[key]).filter(_ => typeof _ == "string").sort((a, b) => a.localeCompare(b));
}