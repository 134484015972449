import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { ColorStageView } from '../StageView';
import * as Metadata from '../../../../../entities/Metadata';

const Stage = ({ entity, className, field }: IListViewFieldColumn<ProjectInfo>) => {
    const stage = entity.attributes.Stage;
    const option = Metadata.getOption(field, stage);
    return <ColorStageView
        title={stage}
        className={className}
        style={{ color: option?.color }}
    />
}

export default Stage;