import * as React from 'react';
import { MinMax, ProjectStatusCalculationThresholds, CalculationSettings, StatusCalculationTypes } from '../../store/Tenant';
import { ThresholdsRange } from "./ThresholdsRange";
import { FormatType } from '../../entities/Metadata';
import { StatusAutoCalculationSettings } from './StatusAutoCalculationSettings';
import { contains } from '../../store/permissions';

type Props = {
    hasML: boolean;
    data: CalculationSettings<ProjectStatusCalculationThresholds>;
    onChange?: (data: CalculationSettings<ProjectStatusCalculationThresholds>) => void;
}

export class ProjectStatusCalculationSettings extends React.Component<Props> {
    public render() {
        const { hasML, data, onChange } = this.props;
        const { thresholds } = data;
        const disabled = !onChange;
        const isAuto = contains(data.statusCalculation, StatusCalculationTypes.Auto);

        return <>
            <StatusAutoCalculationSettings
                hasML={hasML}
                statusCalculation={data.statusCalculation}
                onChange={disabled ? undefined : statusCalculation => onChange!({ ...data, statusCalculation })} />
            {
                isAuto &&
                <>
                    <div>Specify values below to measure the degree that will accept a certain risk or not</div>
                    <div className="card-list">
                        <ThresholdsRange title="Late Tasks Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.lateTasks}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("lateTasks", v)} />
                        <ThresholdsRange title="Late Key Dates Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.lateKeyDates}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("lateKeyDates", v)} />
                        <ThresholdsRange title="Resources with Late Tasks Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.resourcesWithLateTasks}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("resourcesWithLateTasks", v)} />
                        <ThresholdsRange title="Overdue Issues Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.overdueIssues}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("overdueIssues", v)} />
                        <ThresholdsRange title="Overdue Risks Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.overdueRisks}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("overdueRisks", v)} />
                        <ThresholdsRange title="Cost Overrun Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.costOverrun}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("costOverrun", v)} />
                    </div>
                    <div>Project Online thresholds</div>
                    <div className="card-list">
                        <ThresholdsRange title="Efforts Overrun Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.effortsOverrun}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("effortsOverrun", v)} />
                        <ThresholdsRange title="Duration Overrun Thresholds"
                            format={FormatType.Percent}
                            value={thresholds.durationOverrun}
                            onChange={disabled ? undefined : (v: MinMax<number | null>) => this._set("durationOverrun", v)} />
                    </div>
                </>
            }
        </>;
    }

    private _set = (propName: keyof ProjectStatusCalculationThresholds, value: MinMax<number | null>): void => {
        this.props.onChange!({
            ...this.props.data,
            thresholds: { ...this.props.data.thresholds, [propName]: value }
        });
    }
}