import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { groupByKey } from '../../utils/common';
import { StatusCategory, EntityType } from '../../../entities/common';
import { FieldActionTypes, ISettingsEditComponent } from '../FieldPanel';
import StatusCategorySettingsEdit, { entityTypesWithAutoCalculationFeature } from './StatusCategorySettingsEdit/StatusCategorySettingsEdit';
import { DraggableOption } from '../SelectSettingsEdit/OptionEdit';
import { buildOptionsUpdate, buildOptions, areOptionsValid, areOptionsUnique } from '../SelectSettingsEdit/SelectSettingsEdit';
import { FieldType } from '../../../entities/Metadata';
import { useDidMountEffect } from '../../utils/effects';
import { Link } from 'office-ui-fabric-react';
import { CategoryStatusOption, IStatusDropDownSettings } from '../../../entities/StatusDescriptor';

const orderedCategories = [
    StatusCategory.NA,
    StatusCategory.Green,
    StatusCategory.Amber,
    StatusCategory.Red,
    StatusCategory.Done,
];

type Props = {
    settings: IStatusDropDownSettings;
    entityType: EntityType;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
};

const StatusSelectSettingsEdit = forwardRef<ISettingsEditComponent, Props>((props, ref) => {
    const { settings, entityType } = props;

    const [draggableOptions, setDraggableOptions] = useState<DraggableOption<CategoryStatusOption>[]>(
        settings.options.map((_, index) => ({ ..._, id: 'id_' + index, key: _.name })));
    
    const optionsByCategoryMap = groupByKey(draggableOptions, 'category');
    
    const isValid = areStatusOptionsValid(draggableOptions);
    useDidMountEffect(() => props.onChange(isValid), [isValid, draggableOptions]);

    useImperativeHandle(ref, (): ISettingsEditComponent => ({
        save: (fieldInfo) => {
            fieldInfo.type = FieldType.Text;
            fieldInfo.settings = {
                ...fieldInfo.settings,
                editControl: "ColorStatusDropdown",
                options: buildOptions(draggableOptions, buildCategoryStatusOption)
            };
        },
        update: (fieldInfo) => ({
            ...fieldInfo,
            optionsUpdate: buildOptionsUpdate(settings.options, draggableOptions, buildCategoryStatusOption)
        })
    }));

    const onCategoryOptionsChange = (category: StatusCategory, editedCategoryOptions: DraggableOption<CategoryStatusOption>[]) => {
        const categoryIndex = draggableOptions.findIndex(_ => _.category === category);
        const options = [...draggableOptions].filter(_ => _.category !== category);
        options.splice(categoryIndex, 0, ...editedCategoryOptions);
        setDraggableOptions(options);
    }

    return (
        <>
            <LearnMoreMessage entityType={entityType} />
            {orderedCategories.map((_) => (
                <StatusCategorySettingsEdit
                    key={_}
                    category={_}
                    entityType={entityType}
                    options={optionsByCategoryMap[_]}
                    isUnique={() => areStatusOptionsUnique(draggableOptions)}
                    onChange={(categoryOptions) => onCategoryOptionsChange(_, categoryOptions)}
                />
            ))}
        </>
    );
})

export default StatusSelectSettingsEdit;

const statusOptionsSetSelector = (option: DraggableOption<CategoryStatusOption>) => option.name.toLowerCase();

export function areStatusOptionsValid(options: DraggableOption<CategoryStatusOption>[]) {
    return areOptionsValid(options, statusOptionsSetSelector);
}

export function areStatusOptionsUnique(options: DraggableOption<CategoryStatusOption>[]) {
    return areOptionsUnique(options, statusOptionsSetSelector);
}

const LearnMoreMessage = ({ entityType } : { entityType: EntityType }) => {
    const withAutoCalculation = entityTypesWithAutoCalculationFeature.includes(entityType);
    return (
        <div>
            Define statuses for each status category. Drag the preferred status to
            the top to make it default{withAutoCalculation ? " for the automatic status calculation." : <>.<br/></>} <Link
                target="_blank" 
                href="https://help.ppm.express/89502-ppm-express-how-to-articles/how-to-customize-status-categories-for-projectsprogramsportfoliostasksetc">
                    Learn more
                </Link>
        </div>
    );
}

const buildCategoryStatusOption = (draggableOption: DraggableOption<CategoryStatusOption>): CategoryStatusOption => ({
    category: draggableOption.category,
    name: draggableOption.name,
    color: draggableOption.color
});
