import { Field } from "./Metadata";
import { StatusCategory, statusCategoryMap } from "./common";

export type IStatusDropDownSettings = {
    options: CategoryStatusOption[];
}

export type IWithStatusCategory = {
    category: StatusCategory;
};

export type CategoryStatusOption = IWithStatusCategory & {
    name: string;
    color: string;
};

export default class StatusDescriptor {
    private readonly _statusField: Field;

    constructor(statusField: Field) {
        this._statusField = statusField;
    }

    public getCategoryDefaultOption(category: StatusCategory): CategoryStatusOption {
        const options = this.getOptions();
        return options?.find(_ => _.category === category)!;
    }

    public getCategoryDefaultStatusValue(category: StatusCategory): string {
        return this.getCategoryDefaultOption(category).name;
    }

    public getCategoryOrDefault(statusValue: string, defaultCategory: StatusCategory): StatusCategory {
        return this.getCategory(statusValue) ?? defaultCategory;
    }

    public getColorOrDefault(statusValue: string, defaultColorCategory: StatusCategory): string {
        return this.getColor(statusValue) ?? statusCategoryMap[defaultColorCategory].color;
    }

    public isInCategory(statusValue: string, category: StatusCategory): boolean {
        return this.isInOneOfCategories(statusValue, category);
    }

    public isInOneOfCategories(statusValue: string, ...categories: StatusCategory[]): boolean {
        const option = this.getOption(statusValue);
        return option !== undefined && categories.includes(option.category);
    }

    public getOptionOrDefault(statusValue: string, defaultOptionCategory: StatusCategory): CategoryStatusOption {
        return this.getOption(statusValue) ?? this.getCategoryDefaultOption(defaultOptionCategory)!;
    }

    public getOption(statusValue: string): CategoryStatusOption | undefined {
        const options = this.getOptions();
        return options?.find(_ => _.name === statusValue);
    }

    public getOptions(): CategoryStatusOption[] {
        const statusSettings = this._statusField.settings as IStatusDropDownSettings;
        return statusSettings.options;
    }

    public getCategoryOptions(category: StatusCategory): CategoryStatusOption[] {
        return this.getOptions().filter(_ => _.category === category);
    }

    private getCategory(statusValue: string): StatusCategory | undefined {
        const option = this.getOption(statusValue);
        return option?.category;
    }

    private getColor(statusValue: string): string | undefined {
        const option = this.getOption(statusValue);
        return option?.color;
    }
}
