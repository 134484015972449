
export type PAT = {
    id: string;
    createdDate: string;
    scopes: Scope[];
    name: string;
    expirationDate: string;

    data?: string;
}

export type PATWithJwt = {
    pat: PAT;
    jwt: string;
}

export enum Scope {
    ResourceWrite = "resource:write",
    ResourceRead = "resource:read",
    ProjectWrite = "project:write",
    ProjectRead = "project:read",
    ChallengeRead = "challenge:read",
    ChallengeWrite = "challenge:write",
    IdeaRead = "idea:read",
    IdeaWrite = "idea:write",
    TaskRead = "task:read",
    TaskWrite = "task:write",
    KeyDateRead = "keydate:read",
    KeyDateWrite = "keydate:write",
    UserRead = "user:read",
    MPPFileIntegration = "integration:mppfile",
    ProgramRead = "program:read",
    ProgramWrite = "program:write",
    PortfolioRead = "portfolio:read",
    PortfolioWrite = "portfolio:write"

}

const namesMap = {
    [Scope.ResourceRead]: "Resources Read",
    [Scope.ResourceWrite]: "Resources Write",
    [Scope.ProjectRead]: "Projects Read",
    [Scope.ProjectWrite]: "Projects Write",
    [Scope.ChallengeRead]: "Challenges Read",
    [Scope.ChallengeWrite]: "Challenges Write",
    [Scope.IdeaRead]: "Ideas Read",
    [Scope.IdeaWrite]: "Ideas Write",
    [Scope.TaskRead]: "Tasks Read",
    [Scope.TaskWrite]: "Tasks Write",
    [Scope.KeyDateRead]: "Key Dates Read",
    [Scope.KeyDateWrite]: "Key Dates Write",
    [Scope.UserRead]: "User Read",
    [Scope.MPPFileIntegration]: "Project Desktop",
}

export function getScopeName(scope: Scope) {
    return namesMap[scope] || scope;
}

export const PATApiPath = '/api/user/pat';