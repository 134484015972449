import * as React from 'react';
import { StatusCalculationTypes } from '../../store/Tenant';
import { Toggle, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { contains } from '../../store/permissions';
import Link from '../common/Link';

type Props = { hasML: boolean; statusCalculation: StatusCalculationTypes, onChange?: (statusCalculation: StatusCalculationTypes) => void }

export class StatusAutoCalculationSettings extends React.Component<Props> {
    public render() {
        const { hasML, statusCalculation, onChange } = this.props;
        const disabled = !onChange;
        const isAuto = contains(statusCalculation, StatusCalculationTypes.Auto);
        const isManual = contains(statusCalculation, StatusCalculationTypes.Manual);
        return <>
            <div className="status-calculation-toggle align-center">
                <span className="label">Calculate statuses automatically</span>
                <Toggle className="toggle"
                    disabled={disabled}
                    checked={isAuto}
                    onChange={(e, checked: boolean) =>
                        this.props.onChange!(checked
                            ? (statusCalculation | StatusCalculationTypes.Auto)
                            : StatusCalculationTypes.Manual)}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
            <div className="status-calculation-toggle align-center">
                <span className="label">Define statuses manually</span>
                <Toggle className="toggle"
                    disabled={disabled || !isAuto}
                    checked={isManual}
                    onChange={(e, checked: boolean) =>
                        this.props.onChange!(checked
                            ? (statusCalculation | StatusCalculationTypes.Manual)
                            : StatusCalculationTypes.Auto)}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
            {!hasML && <MessageBar messageBarType={MessageBarType.warning}>
                Automated status calculation feature is not available in your PPM Express plan. Please
                <Link className='contact-us-link' href="https://ppm.express/contact-us" target="_blank">contact us</Link> to request a demo or get trial.
            </MessageBar>}
        </>;
    }
}