export enum TextStyles {
    None,
    Brief,
    Expanded,
    Bullets
}

export interface DigestAdjustOptions {
    textStyle: TextStyles;
}

export const TextStylesMap = {
    [TextStyles.None]: 'none',
    [TextStyles.Brief]: 'brief',
    [TextStyles.Expanded]: 'expanded',
    [TextStyles.Bullets]: 'bullets'
}

export type AiDigest = {
    prompt?: string;
    digest: string;
    html?: string;
    errorMessage?: string; 
}

export type Settings = {
    digestDataItems: DigestDataItem[];
    textStyle: TextStyles;
}

export type DigestDataItem = {
    key: string;
    name: string;
    description: string;
    isSelected: boolean;
}

export const projectDigestItems: DigestDataItem[] = [
    {
        key: "ProjectAttributes",
        name: "Project Attributes",
        description: "Primary project details (Project Name, Dates, Manager, etc.)",
        isSelected: false
    },
    {
        key: "Statuses",
        name: "Statuses",
        description: "All Statuses and their descriptions",
        isSelected: false
    },
    {
        key: "Milestones",
        name: "Milestones",
        description: "Completed Milestones (past 30 days), Upcoming Milestones (next 30 days), their status and dates, Overdue Milestones",
        isSelected: false
    },
    {
        key: "ScheduleInformation",
        name: "Schedule Information",
        description: "Roll-up values from PPM Express schedule, Overdue Tasks",
        isSelected: false
    },
    {
        key: "BudgetInformation",
        name: "Budget Information",
        description: "Budget Summary: Totals for budget, forecast, actual, and baseline",
        isSelected: false
    },
    {
        key: "Risks",
        name: "Risks",
        description: "Active Risks with a high impact",
        isSelected: false
    },
    {
        key: "Issues",
        name: "Issues",
        description: "Active Issues with a high priority",
        isSelected: false
    },
    {
        key: "ActionItems",
        name: "Action Items",
        description: "Active Action Items",
        isSelected: false
    },
    {
        key: "ChangeRequests",
        name: "Change Requests",
        description: "Pending Change Requests",
        isSelected: false
    }
];