import * as React from "react";
import { TextField, Link, MessageBar, MessageBarType, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { ExtensionInfo, ExtensionType, SaveExtensionInfo } from "../../../store/ExtensionStore";
import { UserState } from "../../../store/User";
import { Subscription } from "../../../store/Tenant";
import { Validator } from "../../../validation";
import { ItemCreation } from "../../common/ItemCreation";
import LabellableComponent from "../../common/LabellableComponent";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { distinct } from "../../utils/common";

type OwnProps = {
    extension?: ExtensionInfo;
    onSave: (entity: SaveExtensionInfo) => void;
    onDismiss: () => void;
}
type StateProps = { 
    user: UserState;
    subscription: Subscription;
    extensionTypes: ExtensionType[];
};
type Props = OwnProps & StateProps;

const validator = Validator.new().required().build();
const urlValidator = Validator.new().required().url().build();

export const defaultExtensionType = ExtensionType.QuickStart;
export const options: IDropdownOption[] = [
    {
        key: defaultExtensionType,
        text: "Quick Start",
    },
    {
        key: ExtensionType.Task,
        text: "Tasks section"
    }
];

const ExtensionEdit = (props: Props) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [extension, setExtension] = React.useState<SaveExtensionInfo>(props.extension
        ? { ...props.extension }
        : {
            name: "",
            url: "",
            type: props.extensionTypes.includes(ExtensionType.QuickStart) ? ExtensionType.Task : ExtensionType.QuickStart
        });
    const canManage = true;

    const getOptions = () => {
        if (extension?.type === defaultExtensionType || !props.extensionTypes.includes(defaultExtensionType)) {
            return options;
        }

        return options.filter(_ => _.key !== defaultExtensionType);
    };

    return <ItemCreation
        onDismiss={props.onDismiss}
        header={{
            text: extension.id ? "Edit Extension" : "New Extension",
            secondaryText: <MessageBar isMultiline messageBarType={MessageBarType.warning}>
                To configure extensions, please<Link href="https://ppm.express/contact-us" target="_blank">contact us</Link> to request a demo and learn more.
            </MessageBar>,
            nameEditorLabel: "Extension Name",
            value: extension.name,
            onChanged: _ => { 
                setIsDirty(true);
                setExtension({ ... extension, name: _ });
            },
            validator: validator
        }}
        isDirty={isDirty}
        commands={[
            {
                primary: true,
                disabled: !canManage 
                    || !validator.isValid(extension.name) 
                    || !urlValidator.isValid(extension.url),
                text: extension.id ? "Save Extension" : "Create Extension",
                onClick: () => {
                    props.onSave(extension);
                    props.onDismiss();
                },
            },
            {
                text: "Cancel",
                onClick: props.onDismiss,
            }
        ]}>
        <div className="panel-area">
            <div className="grid-item">
                <LabellableComponent
                    className="field-container"
                    label="URL"
                    required
                    description="Enter the URL of the page containing the content to be displayed on the relevant PPM Express page where the extension should appear."
                >
                    <TextField
                        value={extension.url}
                        onGetErrorMessage={_ => urlValidator.getErrorMessage(_)}
                        onChange={(e, _) => { 
                            setIsDirty(true);
                            setExtension({ ...extension, url: _ || "" });
                        }}
                        disabled={!canManage}
                    />
                </LabellableComponent>
            </div>
            <div className="grid-item">
                <LabellableComponent
                    className="field-container"
                    label="Type"
                >
                    <Dropdown
                        selectedKey={extension.type}
                        options={getOptions()}
                        disabled={!canManage}
                        onChange={(e, _) => setExtension({ ...extension, type: _!.key as ExtensionType })}
                    />
                </LabellableComponent>
            </div>
        </div>
    </ItemCreation>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        extensionTypes: state.extensions.entities.map(_ => _.type).filter(distinct),
    };
}

export default connect(mapStateToProps)(ExtensionEdit);
