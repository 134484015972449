import * as React from 'react';
import DatePickerInput, { DatePickerInputProps } from '../inputs/DatePickerInput';
import { IRangeFilterProps, IRangeFilterValue } from '../interfaces/IRangeFilter';
import TextInput from '../inputs/TextInput';
import InputErrorMessage from '../inputs/inputErrorMessage';
import { toDate } from '../../utils/common';

export type DateFilterValue = IRangeFilterValue<string>;

export default class DateFilter extends React.Component<IRangeFilterProps<DateFilterValue> & { disableAutoFocus?: boolean }> {
    public render() {
        const { value = {}, onEditComplete, validator, disableAutoFocus } = this.props;
        return <div className="date-filter">
            <DateFilterInput
                inputProps={{ placeholder: "From" }}
                value={value.from}
                disableAutoFocus={disableAutoFocus}
                onEditComplete={_ => {
                    const from = _ || undefined;
                    const filterToDate = value.to && !isNaN(Date.parse(value.to)) ? toDate(value.to) : undefined;
                    const filterFromDate = !!from && !isNaN(Date.parse(from)) ? toDate(from) : undefined;
                    onEditComplete({
                        from,
                        to: filterFromDate
                            ? filterToDate && filterToDate < filterFromDate ? from : value.to
                            : value.to
                    });
                }}
                validator={validator}
            />
            <DateFilterInput
                inputProps={{ placeholder: "To" }}
                value={value.to}
                disableAutoFocus={disableAutoFocus}
                onEditComplete={_ => {
                    const to = _ || undefined;
                    const filterFromDate = value.from && !isNaN(Date.parse(value.from)) ? toDate(value.from) : undefined;
                    const filterToDate = !!to && !isNaN(Date.parse(to)) ? toDate(to) : undefined;
                    onEditComplete({
                        from: filterToDate
                            ? filterFromDate && filterToDate < filterFromDate ? to : value.from
                            : value.from,
                        to
                    });
                }}
                validator={validator}
            />
        </div>;
    }
}
const numberPattern = `((\\+|\\-)( +)?([1-9][0-9]{0,2}|1000)d)`;
const macrosPattern = `^@today( +)?${numberPattern}?$`;
export function convertToDate(value?: string): Date | undefined {
    if (!value) {
        return undefined;
    }

    if (!isNaN(Date.parse(value))) {
        return toDate(value);
    }

    value = value.toLowerCase();
    const isMacros = new RegExp(macrosPattern).test(value);
    if (!isMacros) {
        return undefined;
    }

    const today = new Date();
    if (value == "@today") {
        return today;
    }
    const daysShiftStr: string | undefined = new RegExp(numberPattern).exec(value)?.[0];
    if (daysShiftStr && daysShiftStr.endsWith("d")) {
        const daysShift: number = Number.parseInt(daysShiftStr.substring(0, daysShiftStr.length - 1))
        return Number.isNaN(daysShift) ? today : today.addDays(daysShift);
    }
    return today;
}

type State = { isMacros?: boolean, textValue?: string, error?: string };

class DateFilterInput extends React.Component<DatePickerInputProps, State> {
    private _datepicker: any;
    constructor(props: DatePickerInputProps) {
        super(props);

        this.state = this._buildState(props.value);
    }
    
    componentDidUpdate(prevProps: DatePickerInputProps, prevState: State, snapshot: any) {
        if (prevState.isMacros && !this.state.isMacros) {
            this._datepicker?.focusDatepicker();
        }
    }

    componentWillReceiveProps(nextProps: DatePickerInputProps) {
        if (this.props.value !== nextProps.value) {
            this.setState(this._buildState(nextProps.value));
        }
    }

    render() {
        return (
            this.state.isMacros
                ? <div>
                    <TextInput
                        inputProps={this.props.inputProps}
                        value={this.state.textValue}
                        onChanged={(value: string) => this.setState(this._buildState(value))}
                        onEditComplete={this._onEditComplete} />
                    <InputErrorMessage text={this.state.error} />
                  </div>
                : <DatePickerInput {...this.props}
                    ref={_ => this._datepicker = _}
                    value={this.state.textValue}
                    disableAutoFocus={this.props.disableAutoFocus ?? true}
                    onParseDateFromString={(value: string) => this.setState(this._buildState(value))}
                    onEditComplete={this._onEditComplete} />
        );
    }

    private _buildState = (value?: string): State => {
        return {
            isMacros: value?.startsWith("@"),
            error: value && new RegExp(macrosPattern).test(value.toLowerCase()) ? undefined : "Invalid macros",
            textValue: value
        }
    }

    private _onEditComplete = (value: string | null) => {
        this.setState({}, () => this.props.onEditComplete?.((this.state.isMacros ? this.state.textValue : value) || null))
    }
}