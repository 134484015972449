import * as React from 'react';
import { get, remove } from '../../../fetch-interceptor';
import { IBasePicker, IPickerItemProps, IBasePickerSuggestionsProps, ISuggestionItemProps, IconButton, getId, arraysEqual, Autofill } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps';
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import PPMXPicker from './PPMXPicker';
import { Field } from '../../../entities/Metadata';
import TagLink from '../TagLink';
import InputErrorMessage from './inputErrorMessage';
import { EntityType } from '../../../entities/common';

type Tag = {
    value: string;
    isNew?: boolean;
}

type Props = IFormInputProps<string[], Autofill> & {
    field: Field;
    entityType?: EntityType;
    onEditComplete: (result?: string[]) => void;
    autoExpand?: boolean;
};

type State = {
    value?: string[];
    tagsPromise?: Promise<Tag[]>;
    tags?: Tag[];
}

export default class TagPicker extends React.Component<Props, State> implements IFormInputComponent {
    private _picker = React.createRef<IBasePicker<Tag>>();

    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
        this.props.inputRef && this.props.inputRef(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!this.props.value || !nextProps.value || !arraysEqual(this.props.value, nextProps.value)) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    focus(): void {
        this._picker.current!.focusInput();
    }

    public render() {
        let selectedItems: Tag[] = this.state.value?.map(_ => ({ value: _ } as Tag)) ?? [];

        return <>
            <div className="tag-picker">
                <ItemPicker
                    {...this.props}
                    onRenderItem={this._onRenderItem}
                    onChange={this._onChange}
                    onRemoveSuggestion={this._onRemoveSuggestion}
                    onResolveSuggestions={this._onResolveSuggestions}
                    selectedItems={selectedItems}
                    componentRef={this._picker}
                />
            </div>
            <InputErrorMessage text={this._getErrorMessage(this.state.value)} />
        </>;
    }

    private _onResolveSuggestions = (filter: string, selectedItems?: Tag[]) => {
        let array = this.state.tags
            ? Promise.resolve(this.state.tags)
            : this.state.tagsPromise;
        if (!array) {
            let tagsPromise = get<Tag[]>(`api/metadata/tag/${this.props.field.id}/values`);
            tagsPromise.then(_ => this.setState({ tags: _ }));
            this.setState({ tagsPromise });
            array = tagsPromise;
        }

        if (!!filter) {
            let tagFilter = filter.startsWith("#") ? filter : "#" + filter;
            array = array.then(_ => _.filter(__ => __.value.startsWith(tagFilter)));
            array = array.then(_ => _.findIndex(__ => __.value == tagFilter) == -1 ? [{ value: tagFilter, isNew: true } as Tag].concat(_) : _);
        }

        if (selectedItems && selectedItems.length) {
            array = array.then(_ => _.filter(__ => !selectedItems.some(selected => selected.value == __.value)));
        }

        return array;
    }

    private _onChange = (items?: Tag[]) => {
        let item = items && items.length && items[items.length - 1];

        if (item && item.isNew) {
            this.setState({ tags: this.state.tags?.concat(item).sort((a, b) => a.value > b.value ? 1 : -1) });
        }
        const value = items?.map(_ => _.value) || undefined;
        this.setState({ value });
        if (this.props.onChanged) {
            this.props.onChanged(value);
        }
        if (this.props.onEditComplete && this._validate(value)) {
            this.props.onEditComplete(value || null);
        }
    }

    private _validate = (value?: string[]) => {
        return this.props.validator == undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value?: string[]) => {
        return this.props.validator && this.props.validator.getErrorMessage(value);
    }

    private _onRemoveSuggestion = (item: Tag) => {
        remove(`api/metadata/tag/${this.props.field.id}`, { value: item.value });
        this.state.tags?.splice(this.state.tags.indexOf(item), 1);
        this.setState({ tags: this.state.tags });
    }

    private _onRenderItem = (itemProps: IPickerItemProps<Tag>) => {
        const { item, onRemoveItem, index } = itemProps;

        const itemId = getId();
        return (
            <div key={index} className="picker-item"
                role={'listitem'}
                aria-labelledby={'selectedItemOption-' + itemId}>
                <TagLink value={item.value} entityType={this.props.entityType} />
                {!this.props.readOnly && <IconButton
                    onClick={onRemoveItem}
                    iconProps={{ iconName: 'Cancel', style: { fontSize: '12px' } }}
                />}
            </div>
        );
    }
}

class ItemPicker extends PPMXPicker<Tag> {
    public static defaultProps = {
        onRenderSuggestionsItem: (props: Tag, itemProps: ISuggestionItemProps<Tag>) => <div className="picker-suggestion-item">{props.value}</div>,
        pickerSuggestionsProps: { noResultsFoundText: "No tags found", loadingText: 'Loading', showRemoveButtons: true } as IBasePickerSuggestionsProps,
        getTextFromItem: (item: Tag) => item.value
    };
}