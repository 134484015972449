import * as React from 'react';
import * as analytics from '../../analytics';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, IconButton, PrimaryButton } from 'office-ui-fabric-react';
import {
    Objective, KeyResult, MaxValueLimit, OKRValueType, OKRState, OKRValueTypeOptions, actionCreators, OKRDirection,
    OKRDirectionOptions, OKRCalculationTypeOptions, ObjectiveCalculationType
} from "../../store/ObjectivesListStore";
import { Validator } from '../../validation';
import LabellableComponent from '../common/LabellableComponent';
import NumberInput from '../common/inputs/NumberInput';
import { formatsMap, OkrProgressFormatter, propsOKRFlagLabels } from '../common/formatters/OkrFormatters';
import TextInput from '../common/inputs/TextInput';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/User';
import { KeyResultContributingProjects } from './KeyResultContributingProjects';
import { post } from '../../fetch-interceptor';
import { defaultCatch } from '../../store/utils';
import { ItemCreation } from '../common/ItemCreation';
import { DirtyFormMessageBar } from '../common/DirtyFormMessageBar';
import { EntityInfo } from '../common/inputs/EntityPicker';
import ColorStatusDropdownInput from '../common/inputs/ColorStatusDropdownInput';
import { StatusCategory, EntityType, IWarning } from '../../entities/common';
import { NumberFlagInput } from '../common/inputs/NumberFlagInput';
import { FormatType } from '../../entities/Metadata';
import { HUNDRED_PCT, notUndefined } from '../utils/common';

type OwnProps = {
    parent: Objective,
    keyResult: KeyResult | null,
    onDismiss: () => void,
    onDelete?: (id: string) => void
}

export type StoreProps = {
    user: UserState;
}

type ActionProps = {
    objectiveActions: typeof actionCreators;
};

type State = {
    model: KeyResult;
    isProjectsLoading: boolean;
    projects: EntityInfo[];
    contributedValue?: number;
    isDirty?: boolean;
    showHasChangesWarning?: boolean;
}

type KeyResultCreateProps = OwnProps & StoreProps & ActionProps;

const validators: { [key: string]: Validator } = {
    name: Validator.new().required().build(),
    targetValue: Validator.new().required().decimal().min(-MaxValueLimit).max(MaxValueLimit).build(),
    startValue: Validator.new().required().decimal().min(-MaxValueLimit).max(MaxValueLimit).build(),
    currentValue: Validator.new().required().decimal().min(-MaxValueLimit).max(MaxValueLimit).build(),
    progress: Validator.new().required().decimal().min(0).max(MaxValueLimit).build(),
}

export const CalculationTypeDescription = `Calculation type defines the method of the Key Result progress calculation:

- Calculated – progress (%) is calculated based on the following formulas that depend on the Start and Target Values, and the Key Result Direction:
    
    If Key Result Direction is set to “Growth” or Reduction: (Current Value - Start Value) / (Target Value - Start Value) * 100
    If Key Result Direction is set to “Above Threshold”: Current Value >= Target Value = 100%, Current Value < Target Value = 0%
    If Key Result Direction is set to “Below Threshold”: Current Value <= Target Value = 100%, Current Value > Target Value = 0%)
    Note: If Flag Value Type is selected, Progress is changed to 100% when Current Value is set to Yes.

- Manual – progress is entered manually`;

export const DirectionDescription =
    `Direction defines the direction of the efforts for reaching the Target Value. Key Result progress calculation formula will be applied based on the selected option:

- Growth – for the key results where the Current Value aims to grow to be equal/above the Target Value.

- Reduction – for the key results where the Current Value aims to decrease to be equal/below the Target Value.

- Above Threshold – for the key results where the Current Value aims to stay equal/above the Target Value.

- Below Threshold – for the key results where the Current Value aims to stay equal/below the Target Value.`;

class KeyResultEdit extends React.Component<KeyResultCreateProps, State> {
    constructor(props: KeyResultCreateProps) {
        super(props);
        const valueType = props.keyResult?.valueType ?? props.parent.attributes.ValueType;
        const direction = props.keyResult?.direction ?? props.parent.attributes.Direction;
        this.state = {
            isProjectsLoading: false,
            projects: [],
            model: {
                name: props.keyResult ? props.keyResult.name : "",
                valueType,
                direction,
                calculationType: props.keyResult?.calculationType ?? ObjectiveCalculationType.Calculated,
                targetValue: props.keyResult
                    ? props.keyResult.targetValue
                    : (valueType === OKRValueType.Percent ? HUNDRED_PCT : (valueType === OKRValueType.Flag ? 1 : 0)),
                startValue: props.keyResult ? props.keyResult.startValue : 0,
                currentValue: props.keyResult ? props.keyResult.currentValue : 0,
                progress: props.keyResult ? props.keyResult.progress : 0,
                description: props.keyResult ? props.keyResult.description : null,
                status: props.keyResult ? props.keyResult.status : StatusCategory.NA,
                projectIds: props.keyResult ? props.keyResult.projectIds : []
            } as KeyResult
        };
    }

    componentDidMount() {
        const { keyResult } = this.props;
        if (keyResult && keyResult.projectIds.length > 0) {
            this.setState({ isProjectsLoading: true });
            post<{ projects: EntityInfo[]; contributedValue: number }>(`api/project/find/okr/${keyResult.id}`, {})
                .then(data => {
                    this.setState({
                        isDirty: false,
                        projects: data.projects,
                        isProjectsLoading: false,
                        contributedValue: data.contributedValue
                    });
                })
                .catch(defaultCatch());
        }
    }

    public render() {
        const { model, projects, contributedValue } = this.state;
        const { parent, keyResult, onDelete } = this.props;
        const readOnly = !parent.isEditable
            || keyResult?.state == OKRState.Closed
            || keyResult?.state == OKRState.Archived;
        const isNew = keyResult == null;

        const warnings: IWarning[] | undefined = !isNew && keyResult!.projectIds.length && contributedValue !== undefined && contributedValue !== model.currentValue
            ? [{ type: "ObjectiveKeyResultWarning_ContributedCurrentValue", text: "Current Value is not equal to the Contributed Value" }]
            : undefined;

        const progressWarnings: IWarning[] = [
            model.calculationType === ObjectiveCalculationType.Calculated && model.direction === OKRDirection.Growth && model.startValue > model.targetValue
                ? {
                    type: "ObjectiveKeyResultWarning_GrowthStartValue",
                    text: "For correct progress calculation Target Value should be more than Start Value if Growth direction is selected."
                } : undefined,
            model.calculationType === ObjectiveCalculationType.Calculated && model.direction === OKRDirection.Reduction && model.startValue < model.targetValue
                ? {
                    type: "ObjectiveKeyResultWarning_ReductionStartValue",
                    text: "For correct progress calculation Start Value should be more than Target Value if Reduction direction is selected."
                } : undefined
        ].filter(notUndefined);

        const primaryCommandText = readOnly ? undefined : this.props.keyResult ? "Save Key Result" : "Create Key Result";
        const cancelCommandText = readOnly ? 'Close' : 'Cancel';
        return <ItemCreation onDismiss={this._onDismiss}
            header={{
                text: `${keyResult ? (readOnly ? "View" : "Edit") : "New"} Key Result`,
                secondaryText: readOnly
                    ? `Check fields below to view the Key Result`
                    : `Fill in the fields below to ${keyResult ? "edit the" : "create new"} Key Result`,
                nameEditorLabel: `Key Result Name`,
                disableNameEditor: readOnly,
                onChanged: (_) => this.updateModel({ name: _ }),
                validator: validators.name,
                value: model.name
            }}
            className="status-panel">
            <div className="panel-area">
                <div className="grid-item">
                    <LabellableComponent
                        label="Calculation Type"
                        description={CalculationTypeDescription}
                        className="field-container">
                        <div className="field-value">
                            {readOnly || keyResult?.projectIds.length
                                ? <TextInput disabled value={OKRCalculationTypeOptions.filter(_ => _.key === model.calculationType)[0].text} />
                                : <Dropdown
                                    options={OKRCalculationTypeOptions}
                                    defaultSelectedKey={model.calculationType}
                                    onChange={(e, item) => item && this.updateModel({ calculationType: item.key as ObjectiveCalculationType })} />
                            }
                        </div>
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        label="Value Type"
                        className="field-container">
                        <div className="field-value">
                            {readOnly || keyResult?.projectIds.length
                                ? < TextInput disabled value={OKRValueTypeOptions.filter(_ => _.key === model.valueType)[0].text} />
                                : <Dropdown
                                    options={OKRValueTypeOptions}
                                    defaultSelectedKey={model.valueType}
                                    onChange={(e, item) => item && this.updateModel({
                                        valueType: item.key as OKRValueType,
                                        ...(item.key as OKRValueType == OKRValueType.Percent
                                            && !this.state.model.startValue
                                            && !this.state.model.currentValue
                                            && !this.state.model.targetValue ? { startValue: 0, currentValue: 0, targetValue: HUNDRED_PCT } : {}),
                                        ...(item.key as OKRValueType == OKRValueType.Flag
                                            ? { startValue: 0, 
                                                targetValue: 1,
                                                currentValue: this.state.model.progress >= HUNDRED_PCT ? 1 : 0,
                                                direction: OKRDirection.Growth
                                            } : {})
                                    })} />
                            }
                        </div>
                    </LabellableComponent>

                </div>
                {model.valueType !== OKRValueType.Flag && model.calculationType === ObjectiveCalculationType.Calculated && <div className="grid-item">
                    <LabellableComponent
                        label="Direction"
                        description={DirectionDescription}
                        className="field-container">
                        <div className="field-value">
                            {readOnly || keyResult?.projectIds.length
                                ? <TextInput disabled value={OKRDirectionOptions.filter(_ => _.key === model.direction)[0].text} />
                                : <Dropdown
                                    options={OKRDirectionOptions}
                                    defaultSelectedKey={model.direction}
                                    onChange={(e, item) => item && this.updateModel({ direction: item.key as OKRDirection })} />
                            }
                        </div>
                    </LabellableComponent>
                </div>}
                {model.valueType != OKRValueType.Flag && <div className="grid-item">
                    <LabellableComponent label="Start Value"
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            <NumberInput
                                readOnly={readOnly}
                                value={model.startValue}
                                format={formatsMap[model.valueType]}
                                validator={validators.startValue}
                                onEditComplete={_ => this.updateModel({ startValue: _! })} />
                        </div>
                    </LabellableComponent>
                </div>}
                {model.valueType != OKRValueType.Flag && <div className="grid-item">
                    <LabellableComponent label="Target Value"
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            <NumberInput
                                readOnly={readOnly}
                                value={model.targetValue}
                                format={formatsMap[model.valueType]}
                                validator={validators.targetValue}
                                onEditComplete={_ => this.updateModel({ targetValue: _! })} />
                        </div>
                    </LabellableComponent>
                </div>}
                <div className="grid-item">
                    <LabellableComponent label="Current Value"
                        warnings={warnings}
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            {model.valueType !== OKRValueType.Flag && <NumberInput
                                readOnly={readOnly}
                                value={model.currentValue}
                                format={formatsMap[model.valueType]}
                                validator={validators.currentValue}
                                onEditComplete={_ => this.updateModel({ currentValue: _! })} />}
                            {model.valueType === OKRValueType.Flag && <NumberFlagInput
                                {...propsOKRFlagLabels}
                                readOnly={readOnly}
                                value={model.currentValue}
                                onEditComplete={_ => this.updateModel({ currentValue: _ })} />}
                        </div>
                    </LabellableComponent>
                </div>
                {!isNew && <div className="grid-item full-width">
                    <LabellableComponent label="Contributed Value from Projects"
                        description="Total of all Contributing Projects Current Values"
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            {model.valueType === OKRValueType.Flag
                                ? <NumberFlagInput {...propsOKRFlagLabels} readOnly value={contributedValue} />
                                : <NumberInput disabled value={contributedValue} format={formatsMap[model.valueType]} />}
                        </div>
                    </LabellableComponent>
                </div>}
                <div className="grid-item">
                    <LabellableComponent label="Progress"
                        warnings={progressWarnings}
                        className="field-container">
                        {model.calculationType === ObjectiveCalculationType.Manual
                            ? <NumberInput
                                readOnly={readOnly}
                                value={model.progress}
                                format={FormatType.Percent}
                                validator={validators.progress}
                                onEditComplete={_ => this.updateModel({ progress: _! })} />
                            : <OkrProgressFormatter
                                direction={this.state.model.direction}
                                startValue={this.state.model.startValue}
                                targetValue={this.state.model.targetValue}
                                currentValue={this.state.model.currentValue} />}
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        label="Status"
                        description="Defines current status of the Key Result."
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            <ColorStatusDropdownInput
                                disabled={readOnly}
                                value={model.status}
                                onEditComplete={_ => this.updateModel({ status: _! })} />
                        </div>
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label="Description"
                        className={`field-container ${readOnly ? "readonly" : ""}`}>
                        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
                            <TextInput
                                inputProps={{ multiline: true }}
                                readOnly={readOnly}
                                value={model.description}
                                onEditComplete={_ => this.updateModel({ description: _! })} />
                        </div>
                    </LabellableComponent>
                </div>
            </div>
            {this.state.showHasChangesWarning && <DirtyFormMessageBar primaryCommandLabel={primaryCommandText} cancelCommandLabel={cancelCommandText} />}
            <div className="commands justify">
                <div>
                    {!readOnly && <PrimaryButton
                        disabled={!this._isFormValid()}
                        text={primaryCommandText}
                        onClick={this._save} />}
                    <Button text={cancelCommandText} onClick={this.props.onDismiss} />
                </div>
                {!readOnly && keyResult && onDelete && <IconButton
                    iconProps={{ iconName: "Delete" }}
                    title="Delete"
                    style={{ color: 'red' }}
                    onClick={() => onDelete(keyResult.id)} />}
            </div>
            {!!keyResult && <KeyResultContributingProjects
                entity={keyResult}
                projects={projects}
                isLoading={this.state.isProjectsLoading}
                valueType={this.state.model.valueType} />}
            {this.props.children}
        </ItemCreation>;
    }

    private _onDismiss = (ev: React.SyntheticEvent<HTMLElement>) => {
        if (this.state.isDirty && ev?.currentTarget?.classList?.contains("ms-Overlay")) {
            this.setState({ showHasChangesWarning: true });
            ev.preventDefault();
            return;
        }
        this.props.onDismiss();
    }

    private _isFormValid(): boolean {
        return Validator.isValid(validators, this.state.model);
    }

    private _save = () => {
        const model = this.state.model;
        this.props.keyResult && (model.id = this.props.keyResult.id);
        this.props.objectiveActions.saveKeyResult(this.props.parent.id, model);
        if (!model.id) {
            analytics.trackCreate(this.props.user, {
                itemTitle: model.name,
                itemType: EntityType.KeyResult,
                parentType: EntityType.Objective
            })
        }

        this.props.onDismiss();
    }

    private updateModel(state: any): void {
        this.setState({ isDirty: true, model: { ...this.state.model, ...state } });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    return {
        user: state.user
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        objectiveActions: bindActionCreators(actionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(KeyResultEdit)