import React from 'react';
import { Icon, IconButton } from 'office-ui-fabric-react';
import { DraggableOption } from '../../SelectSettingsEdit/OptionEdit';
import { CategoryStatusOption } from '../../../../entities/StatusDescriptor';

type Props = {
    index: number;
    option: DraggableOption<CategoryStatusOption>;
    iconName: string;
    onEditClick?: () => void;
    onRemove?: (optionId: string) => void;
};

const ColorStatusOption = (props: Props) => {
    const { index, option, iconName, onEditClick, onRemove } = props;
    const { name, color } = option;

    return (
        <div className="status-option align-center">
            <div className="align-center color-status" style={{ backgroundColor: color }} title={''}>
                <Icon iconName={iconName} className="status-icon" />
            </div>
            <div className="title">
                <div className="title-text overflow-text" title={name}>{name}</div>
                {index === 0 && <div className='title-default'>(Default)</div>}
            </div>
            <div className="align-center option-actions">
                {onEditClick && (
                    <IconButton
                        menuIconProps={{ iconName: 'Edit' }}
                        title='Edit'
                        className='menu'
                        onClick={onEditClick} />
                )}
                {onRemove && (
                    <IconButton
                        menuIconProps={{ iconName: 'Delete' }}
                        title='Remove'
                        className='menu'
                        onClick={() => onRemove(option.id)} />
                )}
            </div>
        </div>
    );
};

export default ColorStatusOption;
