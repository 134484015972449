import * as React from 'react';

type SectionProps = {
    isOpen: boolean;
    className: string;
    onExpand?: () => void;
    onCollapse?: () => void;
    onRenderHeader?: () => JSX.Element;
    onRenderBody: () => JSX.Element;
    onKeyDown?: React.KeyboardEventHandler<any>;
}

export default class AccordionSection extends React.Component<SectionProps> {
    public render() {
        return (
            <div className={`${this.props.className} ${this.props.isOpen ? 'open' : ''}`} onKeyDown={this.props.onKeyDown}>
                {this.props.onRenderHeader && <div className="section-head">
                    {this.props.onRenderHeader()}
                </div>}
                {this.props.isOpen &&
                    <div className="section-body">
                        <div className="section-body-container">
                            {this.props.onRenderBody()}
                        </div>
                    </div>
                }
            </div>
        );
    }

    public expand = () => {
        if (this.props.onExpand) {
            this.props.onExpand();
        }
    }

    public collapse = () => {
        if (this.props.onCollapse) {
            this.props.onCollapse();
        }
    }
};