import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Field, FieldType } from '../../../../../entities/Metadata';
import { ITask } from '../../../../../entities/Subentities';
import { FlagFormatter } from '../../../../common/formatters/FlagFormatter';

export const IsSummaryFakeField: Field = {
    id: "240a6b3c-405a-492b-85a6-b8ee3fa0f9a3",
    label: "Is Summary",
    name: "IsSummary",
    type: FieldType.Flag,
    isNative: false,
    isCustom: false,
    isReadonly: true,
    isSystem: false,
    isFake: true,
    settings: {
        views: { list: { componentPath: "task/IsSummary", maxWidth: 150 } }
    }
};

export default class IsSummary extends React.Component<IListViewFieldColumn<ITask>> {
    render() {
        return <FlagFormatter value={this.props.entity.hierarchy.isParent}/>;
    }
}
