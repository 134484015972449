import * as React from 'react';
import * as analytics from '../../../../analytics';
import { bindActionCreators } from 'redux';
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import { Selection, arraysEqual, DefaultButton, CheckboxVisibility } from 'office-ui-fabric-react';
import { IListProps } from '../../extensibleEntity/EntityDetailsList';
import * as ResourcesListStore from '../../../../store/ResourcesListStore';
import * as PortfoliosListStore from '../../../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../../../store/ProgramsListStore';
import * as ProjectsListStore from '../../../../store/ProjectsListStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import * as Metadata from '../../../../entities/Metadata';
import AddEntitiesDialog from "./projectsControl/AddEntitiesDialog";
import {
    Dictionary, EntityType, IExtensibleEntity, ITimeframe, IWithResourcePlan, IWithWarnings, SortDirection, updateCheckboxOptions, entityTypeLabelMap
} from "../../../../entities/common";
import { ControlSpiner } from '../../Spinner';
import { isInReadonlyMode, UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { TotalUsageCell, allocationWarning, hasOutsideAllocation } from '../../../resource/usage/ResourceUsageCell';
import { SectionControlPlaceholder } from '../SectionPlaceholder';
import RemoveDialog from '../../RemoveDialog';
import ResourceCreationInResourcePlanControl from '../../../resource/ResourceCreationInResourcePlanControl';
import PersonPickerInput, { PersonInfo } from '../../inputs/PersonPickerInput';
import { notUndefined, toDate } from '../../../utils/common';
import {
    defaultTimeframe, ViewType, IMaybeTotal, totalRow, PersistResourceUsageGrid,
    ResourceUsageGridSettings
} from '../../../resource/usage/ResourceUsageGrid';
import { nameof } from '../../../../store/services/metadataService';
import { ITimelineProps } from '../../extensibleEntity/EntityTimelineList';
import { IRow } from '../../timeline/TimelineList';
import { PPMFeatures, Subscription } from '../../../../store/Tenant';
import ReplaceResourceDialog from '../../../resource/ReplaceResourceDialog';
import { ViewService } from '../../../../services/ViewService';

export interface IDataContext {
    entityType: EntityType;
}

export interface IActions {
    addResources: (resourceIds: string[]) => void;
    replaceResources: (prevId: string, nexId: string, keepRate: boolean, sumUpPlanHours: boolean, sumUpActualHours: boolean) => void;
    removeResources: (resourceIds: string[]) => void;
    createResource: (name: string, layoutId: string, auto?: boolean, startDate?: Date, finishDate?: Date) => void;
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;
}

interface ISettings {
    displayFields: string[];
    orderBy: string[];
    timeline: {
        displayFields: string[];
    };
}

type ControlSettings = { viewType: ViewType } & ResourceUsageGridSettings;
export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>
type OwnProps = ISectionUIControlProps<IActions, ISettings, IExtensibleEntity & IWithResourcePlan, {}, ControlSettings, IDataContext>;

type StoreProps = {
    resources: ResourcesListStore.ResourcesState,
    fields: Metadata.Field[];
    fakeFields: Metadata.Field[];
    isFieldsLoading: boolean;
    user: UserState;
    canConfigureSelection: boolean;
    hasPortfolioManagement: boolean;
    hasProjectManagement: boolean;
}

type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    resourceActions: typeof ResourcesListStore.actionCreators;
}

type Props = OwnProps & StoreProps & ActionProps;

interface State {
    resources: ResourcesListStore.Resource[];
    selectedResourceIds: string[];
    isAdding: boolean;
    isCreating: boolean;
    isReplacing: boolean;
    isRemoving: boolean;
    canReplace: boolean;

    timeframe: ITimeframe;
}

class ResourcePlanControl extends React.Component<Props, State> {
    private _rowSelection: Selection;

    constructor(props: Props) {
        super(props);

        this._rowSelection = new Selection({
            onSelectionChanged: () => { this.setState({ selectedResourceIds: this._rowSelection.getSelection().map(_ => (_ as ResourcesListStore.Resource).id) }); },
            getKey: (item) => (item as IExtensibleEntity).id
        });

        const { entity } = props;

        entity.resourceIds.length && props.resourceActions.loadResourcesByIds(entity.resourceIds);
        const resources = entity.resourceIds.map(_ => this.props.resources.byId[_]).filter(notUndefined);

        this.state = {
            resources,
            selectedResourceIds: [],
            isAdding: false,
            isCreating: false,
            isReplacing: false,
            isRemoving: false,
            canReplace: false,
            timeframe: defaultTimeframe,
        };
    }

    componentWillMount() {
        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosActions.requestPortfolios();
            this.props.programsActions.requestPrograms();
        }
        if (this.props.hasProjectManagement) {
            this.props.projectsActions.requestProjects();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const nextEntity = nextProps.entity;
        const entity = this.props.entity;

        if (!arraysEqual(entity.resourceIds, nextEntity.resourceIds)) {
            const notLoaded = nextEntity.resourceIds.filter(_ => !~entity.resourceIds.indexOf(_));
            notLoaded.length && this.props.resourceActions.loadResourcesByIds(notLoaded);
        }

        const resources = nextEntity.resourceIds.map(_ => nextProps.resources.byId[_]).filter(notUndefined);
        if (!arraysEqual(this.state.resources, resources)) {
            this.setState({ resources });
        }
    }

    public render() {
        const { user, entity, fields, settings, fakeFields, isFieldsLoading, canConfigureSelection, controlSettings, onSaveSettings } = this.props;
        const { isAdding, isCreating, isRemoving, isReplacing, resources, timeframe, selectedResourceIds } = this.state;
        const { entityType } = this.props.datacontext;

        if (isFieldsLoading || (this.props.resources.isListLoading && !resources.length)) {
            return <ControlSpiner isLoading={true} />;
        }

        const readOnly = !entity.isEditable || !contains(user.permissions.common, CommonOperations.ResourceManage);
        const autoallocationTimeframe = entity.attributes.StartDate && entity.attributes.FinishDate
            ? { start: toDate(entity.attributes.StartDate)!, end: toDate(entity.attributes.FinishDate)! }
            : timeframe;
            
        const selectionModeCommands = this._getSelectionModeCommands(readOnly);
        const listProps: IListProps & Partial<ITimelineProps> = {
            buildTree: true,
            showTreeExpandColumn: false,
            selection: this._rowSelection,
            entities: [totalRow],
            entityType: EntityType.Resource,
            fields: fields.concat(fakeFields),
            isFieldFake: field => this.props.fakeFields.some(_ => _.id == field.id),
            displayFields: contains(user.permissions.common, CommonOperations.ResourceView)
                ? settings.timeline.displayFields
                : [nameof<ResourcesListStore.ResourceAttrs>("Name")],
            onItemRender: (_entity: ResourcesListStore.Resource & IMaybeTotal, _, __, defaultRender) => {
                if (_entity.isTotal) {
                    return <></>;
                }
                return defaultRender();
            },
            expandedEntitiesIds: [totalRow.id],
            primaryOutlineLevel: 1,
            buildRow: _ => buildResourcesGroup(_, entity, resources, entityType),
            isArchived: entity.isArchived,
            ...updateCheckboxOptions({ checkboxVisibility: readOnly ? CheckboxVisibility.hidden : undefined }, selectionModeCommands)
        };

        return <>
            {!entity.resourceIds.length
                ? <SectionControlPlaceholder
                    iconName="PPMXSectionResourcePlan"
                    title="Resource plan section is empty"
                    description={`Resources have not been added to the ${entityTypeLabelMap[entityType].singular} yet`}>
                    {
                        !readOnly &&
                        <>
                            <DefaultButton
                                text="Add Resources"
                                onClick={() => this.setState({ isAdding: true })} />
                            <DefaultButton
                                text="Create Resource"
                                onClick={() => this.setState({ isCreating: true })} />
                        </>
                    }
                </SectionControlPlaceholder>
                : <PersistResourceUsageGrid
                    entitiesMap={{ [entity.id]: entity }}
                    entityType={entityType}
                    commitDisabled={entityType === EntityType.Idea || entity.isPrivate}
                    actualTimeDisabled={entityType === EntityType.Idea}
                    onTimeframeChanged={_ => this.setState({ timeframe: _ })}
                    menuProps={{
                        fields: listProps.fields,
                        type: "Columns",
                        entityType: entityType,
                        mandatoryViewFields: ViewService.getViewMandatoryFields(entityType),
                        displayFields: listProps.displayFields,
                        canViewConfigureColumns: canConfigureSelection,
                        onDisplayFieldsChange: canConfigureSelection ? this._onTimelineDisplayFieldsChange : undefined,
                        commands: readOnly ? [] : [{
                                key: 'addRow',
                                name: 'Add Resource',
                                iconProps: { iconName: 'Add' },
                                onClick: () => this.setState({ isAdding: true })
                            }, {
                                key: 'createRow',
                                name: 'Create Resource',
                                iconProps: { iconName: 'Add' },
                                onClick: () => this.setState({ isCreating: true })
                            }],
                        selectionMode: {
                            enabled: selectedResourceIds.length > 0,
                            items: selectionModeCommands,
                            onCancel: () => this._rowSelection.setAllSelected(false)
                        }
                    }}
                    listProps={listProps}
                    selectedEntityIds={selectedResourceIds}
                    isLoading={this.props.resources.isListLoading || this.props.resources.isLoading || this.props.resources.isLoadingUsage}
                    entitiesIds={entity.resourceIds}
                    extractRowIds={(_: IExtensibleEntity & IMaybeTotal) => ({
                        entityIds: [entity.id], resourceIds: _.isTotal ? entity.resourceIds : [_.id], isTotalRow: _.isTotal
                    })}
                    extractCellIds={cell => ({ entityId: entity.id, resourceId: cell.entityId })}
                    viewType={this.props.controlSettings.viewType}
                    renderTotalSegmentContent={(_, segment, timeType) =>
                        <TotalUsageCell resources={resources} entityIds={[entity.id]} startDate={segment.startDate} finishDate={segment.finishDate}
                            timeType={timeType} viewType={this.props.controlSettings.viewType ?? ViewType.Hours} entityType={entityType} />
                    }
                    cellTooltipProps={{ showPerEntityWork: true }}
                    isEditable={readOnly ? undefined : (item: IExtensibleEntity & IMaybeTotal) => !item.isTotal}
                    buildRowMenuItems={(item: IExtensibleEntity & IMaybeTotal) => item.isTotal || readOnly ? [] : [
                        selectedResourceIds.length === 1 ? {
                            key: 'replace',
                            name: 'Replace Resource',
                            iconProps: { iconName: 'PPMXSwitchUser' },
                            onClick: () => this.setState({ isReplacing: true })
                        } : undefined,
                        {
                            key: 'deleteRow',
                            name: `Remove from ${entityTypeLabelMap[entityType].singular}`,
                            style: { color: 'red' },
                            iconProps: { iconName: "Delete", style: { color: 'red' } },
                            onClick: () => this.setState({ isRemoving: true })
                        }
                    ].filter(notUndefined)}
                    controlSettings={controlSettings}
                    onSaveSettings={onSaveSettings}
                    defaultSort={settings.orderBy?.length
                        ? { fieldName: settings.orderBy[0], direction: SortDirection.ASC }
                        : undefined}
                />}
            {isAdding && <AddEntitiesDialog
                confirmButtonText="Add Resources"
                dialogContentProps={{
                    title: 'Add Resources',
                    subText: `Select resources to be added to the current ${entityTypeLabelMap[entityType].singular}`
                }}
                onDismiss={() => this.setState({ isAdding: false })}
                onComplete={_ => this._addResources(_)}
                renderEntitySelector={(onChanged) =>
                    <PersonPickerInput
                        multichoice
                        onChanged={(value) => value && onChanged((value as PersonInfo[]).map(_ => _.id))}
                        searchUrl="api/resource/find"
                        filter={{ exceptIds: entity.resourceIds }}
                        inputProps={{ placeholder: "Type to search", autoFocus: true }}
                    />}
            />}
            {isCreating && <ResourceCreationInResourcePlanControl
                onDismiss={() => this.setState({ isCreating: false })}
                entityType={entityType}
                timeframe={autoallocationTimeframe}
                onCreate={this.props.actions.createResource}
            />}
            {isReplacing && <ReplaceResourceDialog
                entityid={entity.id}
                entityType={entityType}
                resource={(this._rowSelection.getSelection() as ResourcesListStore.Resource[])[0]}
                resourceIds={entity.resourceIds}
                onDismiss={() => this.setState({ isReplacing: false })}
                onReplaceResource={this._replaceResource}
            />}
            {isRemoving && <RemoveDialog dialogContentProps={{ title: `Remove Resource${this._rowSelection.getSelectedCount() > 1 ? 's' : ''}`, subText: this._getDeletionMessage() }}
                onClose={() => this.setState({ isRemoving: false })} onComplete={this._onRemove} />}
        </>;
    }

    private _onTimelineDisplayFieldsChange = (fields: string[]) => {
        const { sectionId, id } = this.props;
        this.props.actions.updateUIControl(sectionId, id, { timeline: { displayFields: fields } });
    }

    private _getDeletionMessage = () => {
        const { entityType } = this.props.datacontext;
        const selection = this._rowSelection.getSelection();
        if (selection.length === 1) {
            return `Are you sure you want to remove resource "${(selection[0] as ResourcesListStore.Resource).attributes.Name}"\nfrom the ${entityTypeLabelMap[entityType].singular}?`;
        }

        return `Are you sure you want to remove selected resources\nfrom the ${entityTypeLabelMap[entityType].singular}?`;
    }

    private _onRemove = () => {
        let selectedItems = this._rowSelection.getSelection().map(_ => (_ as ResourcesListStore.Resource).id);
        if (selectedItems.length) {
            this.props.actions.removeResources(selectedItems);
        }
    }

    private _addResources = (resourceIds: string[] | undefined) => {
        if (resourceIds) {
            this.props.actions.addResources(resourceIds);
            analytics.trackEvent("Add resource in resource plan", this.props.user, { count: resourceIds.length });
        }

        this.setState({ isAdding: false });
    }

    private _replaceResource = (newResourceIds: string[], keepRateOnReplace: boolean, sumUpPlanHours: boolean, sumUpActualHours: boolean) => {
        const prevResourceId = (this._rowSelection.getSelection() as ResourcesListStore.Resource[])[0]?.id;
        prevResourceId && newResourceIds?.[0] && this.props.actions.replaceResources(
            prevResourceId, newResourceIds[0], keepRateOnReplace, sumUpPlanHours, sumUpActualHours);

        analytics.trackEvent("Replace resource in resource plan", this.props.user, { keepRateOnReplace, sumUpPlanHours, sumUpActualHours });
    }

    private _getSelectionModeCommands(readOnly: boolean) {
        const { entityType } = this.props.datacontext;
        return readOnly ? [] : [{
            key: 'deleteRow',
            name: `Remove from ${entityTypeLabelMap[entityType].singular}`,
            iconProps: { iconName: 'Delete' },
            onClick: () => this.setState({ isRemoving: true })
        }];
    }
}

function mapStateToProps(state: ApplicationState, ownProps: Props): StoreProps {
    const fields = state.fields[EntityType.Resource];

    const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant, ownProps.entity);
    return {
        resources: state.resources,
        fields: fields.allIds.map(_ => fields.byId[_]),
        fakeFields: state.views[EntityType.Resource].list.fakeFields,
        isFieldsLoading: fields.isLoading,
        user: state.user,
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        hasProjectManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.ProjectManagement),
        canConfigureSelection: contains(state.user.permissions.common, CommonOperations.ResourceView)
            && (ownProps.isViewSelected
                ? !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
                : isReadOnlyMode || !!ownProps.entity.canConfigure),
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        resourceActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ResourcePlanControl);

export const buildResourcesGroup = (groupingEntity: IExtensibleEntity, entity: IExtensibleEntity, resources: ResourcesListStore.Resource[], entityType: EntityType,
    subitemBuilder?: (resource: ResourcesListStore.Resource, entity: IExtensibleEntity) => IRow): IRow => ({
        key: groupingEntity.id,
        entity: groupingEntity,
        subItems: resources.map<IRow>(resource => {
            const warnings: IWithWarnings | undefined = hasOutsideAllocation(resource.usage, entity.attributes.StartDate, entity.attributes.FinishDate, entity.id)
                ? { warnings: [allocationWarning(entityType)] }
                : undefined;

            resource = warnings ? { ...resource, ...warnings } : resource;

            return subitemBuilder?.(resource, entity) ?? {
                key: resource.id,
                entity: resource,
                markers: [],
                segments: []
            }
        }),
        markers: [],
        segments: []
    })