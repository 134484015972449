import * as React from 'react';
import { DetailsSpinner } from '../common/Spinner';
import { SectionsContainer } from '../common/sectionsControl/SectionsContainer';
import { EntityType, Dictionary } from '../../entities/common';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { InsightsData, actionCreators, Insights } from '../../store/InsightsStore';
import { ProjectFilterValue } from '../../store/project/filters';
import { CounterFilter } from './common';
import * as InsightsSummaryControl from '../common/sectionsControl/uiControls/InsightsSummaryControl';
import * as InsightsProjectsControl from '../common/sectionsControl/uiControls/InsightsProjectsControl';
import * as InsightsActivitiesControl from '../common/sectionsControl/uiControls/InsightsActivitiesControl';
import * as InsightsAttentionRequired from '../common/sectionsControl/uiControls/InsightsAttentionRequired';
import { nameof } from '../../store/services/metadataService';
import { LayoutService } from '../utils/LayoutService';
import { isInReadonlyMode, UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import { notUndefined, toDictionary } from '../utils/common';
import { Portfolio, actionCreators as portfoliosActionCreators } from '../../store/PortfoliosListStore';
import { actionCreators as programsActionCreators, Program } from '../../store/ProgramsListStore';
import { ProjectInfo, ProjectsListState, StatusNames, defaultActionCreators as projectsActionCreators, ProjectAttrs } from '../../store/ProjectsListStore';
import { Field, IFilter, IUpdateSectionInfo, Section } from '../../entities/Metadata';
import { ViewTypeViews, buildViewTypeSelect } from '../common/ViewTypeSelect';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { PPMFeatures, Subscription } from '../../store/Tenant';
import InsightsHeader from './InsightsHeader';
import * as InsightsSettingsStore from '../../store/InsightsSettingsStore';
import { IWithActiveFilter } from '../../store/services/viewSaver';
import { onlyMyPrefilterId } from '../project/ProjectsFilter';
import { mergeDefault } from '../../store/utils';

export type ControlSettings = IWithActiveFilter;
type StateProps = {
    user: UserState;
    projects: ProjectsListState;
    portfolios: Portfolio[];
    programs: Program[];
    sections: Section[];
    fields: Field[];
    filters: IFilter<ProjectFilterValue>[];
    insights: InsightsData[];
    isHandling: boolean;
    isUpdatingSections: boolean;
    isReadonlyMode: boolean;
    hasPortfolioManagement: boolean;
    controlSettings: ControlSettings;
}
type ActionProps = {
    actions: typeof actionCreators;
    projectsActions: typeof projectsActionCreators;
    programsActions: typeof programsActionCreators;
    portfoliosActions: typeof portfoliosActionCreators;
    insightsSettingsActions: typeof InsightsSettingsStore.actionCreators;
}
type Props = StateProps & ActionProps;

type State = {
    counterFilter?: CounterFilter;
    insights: Insights[];
    filtered: Insights[];
    projects: ProjectInfo[];
    isCofigurationPanelOpen: boolean;
    fields: Field[];
}

const defaultControlSettings: ControlSettings = {
    activeFilter: {
        preFilterId: onlyMyPrefilterId
    }
}

const PPM_INSIGHTS_ID = 'd2548ff0-61c5-4940-9f3e-8f7dbf0e3455'

class PPMInsights extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            insights: [],
            filtered: [],
            projects: [],
            fields: [],
            isCofigurationPanelOpen: false
        }
    }

    componentWillMount() {
        this.props.insightsSettingsActions.loadSettings();
        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosActions.requestPortfolios();
            this.props.programsActions.requestPrograms();
        }
        this.props.projectsActions.requestProjects();
        this.props.actions.load();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.isHandling && !nextProps.isHandling) {
            const byId = nextProps.projects.byId;
            const insights = nextProps.insights.map(_ => ({ ..._, project: byId[_.id] })).filter(_ => _.project);
            const map = toDictionary(insights);
            this.setState({
                insights: insights,
                filtered: this.state.filtered.map(_ => map[_.id]).filter(notUndefined),
                projects: insights.map(_ => _.project)
            });
        }

        if (this.props.fields !== nextProps.fields) {
            this.setState({
                fields: nextProps.fields.map(_ => {
                    if (StatusNames.indexOf(_.name) !== -1) {
                        return {
                            ..._,
                            settings: {
                                ..._.settings,
                                views: {
                                    list: {
                                        componentPath: "ColorStatusWithHistory"
                                    }
                                }
                            }
                        }
                    }

                    if (_.name === nameof<ProjectAttrs>("Name")) {
                        return {
                            ..._,
                            settings: {
                                ..._.settings,
                                views: {
                                    ..._.settings?.views,
                                    list: {
                                        ..._.settings?.views?.list,
                                        openNewPage: true,
                                        showLinkedSystems: true
                                    }
                                }
                            }
                        }
                    }

                    return _;
                })
            });
        }
    }

    public render() {

        const { sections, isHandling, isUpdatingSections } = this.props;
        const { filtered } = this.state;
        const entity = { id: PPM_INSIGHTS_ID, insights: filtered } as any;
        return <DetailsSpinner isLoading={isHandling}>
            <InsightsHeader
                entity={entity}
                controlSettings={this.props.controlSettings}
                datacontext={{
                    filters: this.props.filters,
                    counterFilter: this.state.counterFilter,
                    sections: this.props.sections,
                    projectFields: this.state.fields,
                    projects: this.state.projects,
                    programs: this.props.programs,
                    portfolios: this.props.portfolios
                }}
                actions={{
                    updateSections: this.updateSections,
                    onFilterChange: projects => {
                        const ids = new Set(projects.map(_ => _.id));
                        this.setState({ filtered: this.state.insights.filter(_ => ids.has(_.id)) })
                    },
                    onCounterFilterChange: _ => this.setState({ counterFilter: _ }),
                    onSaveSettings: this.props.insightsSettingsActions.saveSettings
                }}
            ></InsightsHeader>
            <SectionsContainer
                key='sections'
                entity={entity}
                entityType={EntityType.Insights}
                getSections={() => sections}
                isUpdatingSections={isUpdatingSections}
                controlsConfig={this._buildControlsConfigurations()}
            />
        </DetailsSpinner>;
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const canManageConfiguration = contains(this.props.user.permissions.common, CommonOperations.ConfigurationManage);
        return {
            ['InsightsSummaryControl']: {
                datacontext: {
                    projectFields: this.state.fields,
                },
                actions: {
                    onCounterFilterChange: _ => this.setState({ counterFilter: _ })
                }
            },
            ['InsightsProjectsControl']: {
                ...buildViewTypeSelect(ViewTypeViews.Insights),
                datacontext: {
                    counterFilter: this.state.counterFilter,
                    fields: this.state.fields,
                    canManageConfiguration,
                    isReadonly: this.props.isReadonlyMode
                },
                actions: {
                    updateUIControl: this.updateUIControl,
                    requestUpdate: this.props.actions.requestUpdate
                }
            },
            ['InsightsAttentionRequired']: {
                datacontext: {
                    counterFilter: this.state.counterFilter,
                    canManageConfiguration,
                    isReadonly: this.props.isReadonlyMode
                },
                actions: {
                    updateUIControl: this.updateUIControl,
                    requestUpdate: this.props.actions.requestUpdate,
                    prioritize: this.props.actions.prioritizeAlert
                }
            },
            ['InsightsActivitiesControl']: {
                datacontext: {
                    counterFilter: this.state.counterFilter,
                    canManageConfiguration,
                    isReadonly: this.props.isReadonlyMode
                },
                actions: {
                    updateUIControl: this.updateUIControl,
                    requestUpdate: this.props.actions.requestUpdate,
                }
            }
        }
    }

    private updateUIControl = (sectionId: string, uiControlId: string, settings: Dictionary<any>) => {
        return this.props.isReadonlyMode
            ? this.props.actions.updateUIControlOnClient(sectionId, uiControlId, settings)
            : this.props.actions.updateUIControl(sectionId, uiControlId, settings);
    }

    private updateSections = (updates: Dictionary<IUpdateSectionInfo>) => {
        return this.props.isReadonlyMode
            ? this.props.actions.updateSectionsOnClient(updates)
            : this.props.actions.updateSections(updates);
    }
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    InsightsSummaryControl: InsightsSummaryControl.IConfiguration;
    InsightsAttentionRequired: InsightsAttentionRequired.IConfiguration;
    InsightsProjectsControl: InsightsProjectsControl.IConfiguration;
    InsightsActivitiesControl: InsightsActivitiesControl.IConfiguration;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const fields = state.fields[EntityType.Project];
    const filters = state.filters[EntityType.Project];
    const insights = state.insights;
    return {
        user: state.user,
        projects: state.projectsList,
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        insights: insights.allIds.map(_ => insights.byId[_]),
        fields: fields.allIds.map(_ => fields.byId[_]),
        filters: filters.allIds.map(_ => filters.byId[_]),
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        sections: LayoutService.getDefault(state.layouts[EntityType.Insights]).sections,
        isHandling: insights.isHandling
            || state.projectsList.isListLoading
            || state.portfolios.isListLoading
            || state.insightsSettings.isLoading,
        isUpdatingSections: insights.isUpdatingSections,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant),
        controlSettings: mergeDefault(defaultControlSettings, state.insightsSettings.settings) 
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        projectsActions: bindActionCreators(projectsActionCreators, dispatch),
        programsActions: bindActionCreators(programsActionCreators, dispatch),
        portfoliosActions: bindActionCreators(portfoliosActionCreators, dispatch),
        actions: bindActionCreators(actionCreators, dispatch),
        insightsSettingsActions: bindActionCreators(InsightsSettingsStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(PPMInsights);