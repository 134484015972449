import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import {
    CheckboxVisibility, IColumn, DetailsListLayoutMode, Selection,
    IObjectWithKey, IDetailsGroupDividerProps, IDetailsRowProps, CollapseAllVisibility,
    IGroup, IDetailsHeaderProps, IDragDropEvents, IDragDropContext, DefaultButton, arraysEqual, IStyle, IDetailsListProps, SelectionMode
} from 'office-ui-fabric-react';
import DetailsListWrapper, { getOrderBy } from '../../common/DetailsListWrapper';
import {
    IExtensibleEntity, Dictionary, EntityType, SortDirection, IWithSortBy, IWithSubViews, ComparerBuilder,
    ISortingProps
} from '../../../entities/common';
import { ViewService } from "../../../services/ViewService";
import { SortService } from "../../../services/SortService";
import { IOrderBy } from '../../../store/views';
import { EntityGroup } from "./EntityGroupHeader";
import DragWarningDialog from '../DragWarningDialog';
import { notEmpty } from '../../utils/common';
import { ResultsNotFoundPlaceholder } from '../sectionsControl/SectionPlaceholder';
import { IUserSettings, ListUserSettings, useColumnResize, useListSaver, useSorting } from '../../../store/services/viewSaver';
import { HierarchyProps, chainMerger, THierarchyEntity, useHierarchy } from '../../utils/SectionHierarchyManager';
import { RowMenuColumn } from './RowMenuColumn';
import { getRowIndentation } from '../timeline/THead';

export const LIST_ROW_HEIGHT = 55;
export const CHECKBOX_COLUMN_WIDTH = 28;

export type OnItemRender<T,> = (entity: T, index: number, field: Metadata.Field, defaultRender: () => JSX.Element) => JSX.Element;

export interface IDraggableEvents<T> {
    onDragStart?: (entity: T) => void
    onDrag?: (entities: string[], groupId?: string, insertBeforeId?: string) => void;
    isDragDisabled?: (entity: T) => boolean
}

export interface IListProps<T extends IExtensibleEntity = IExtensibleEntity,> {
    entities: T[];
    entityType: EntityType;
    selection?: Selection;
    checkboxVisibility?: CheckboxVisibility;
    displayFields: string[];
    fields: Metadata.Field[];
    isFieldFake?: (field: Metadata.Field) => boolean;
    sorting?: ISortingProps;
    fieldValueExtractor?: (item: T, field: Metadata.Field) => any;
    groups?: EntityGroup[];
    listGrouping?: ListGroupingProps;
    draggableEvents?: IDraggableEvents<T>;
    showMore?: {
        title: string;
        step: number;
        extraCount?: number;
        sliceFunc?: (entities: any[], showCount: number, getValue: (value: any) => T) => any[];
    };
    onItemRender?: OnItemRender<T>;
    onItemMenuRender?: (item: T) => JSX.Element | null;
    extraColumns?: IColumn[];
    /**
    * IMPORTANT: getKey of DetailsList and Selection must be synced to avoid selection discard on list rerender
    * sample: Bug 6429: PPMX Tasks List View - 3 dots menu doesn't work for the second time
    */
    getKey?: (entity: T) => string;
    columns?: Metadata.ISubViewColumn[];
    onColumnResize?: (fieldId: string, newWidth: number) => void;
    isVirtualizationDisabled?: boolean;
    isFilterApplied?: boolean;
    disableNavigation?: boolean;
    isArchived?: boolean;
}

export type ListGroupingProps = {
    getGroupKey: (entity: IExtensibleEntity) => string;
    renderGroupHeader: (
        group: EntityGroup,
        isCollapsed: boolean,
        isSelected: boolean | undefined,
        onToggleCollapse: (e: any) => void,
        onToggleSelectGroup?: (e: any) => void) => JSX.Element | null;
    renderGroupFooter?: (group: EntityGroup) => JSX.Element | null;
}

type GroupDetails = {
    entitiesByGroupMap: Dictionary<IExtensibleEntity[]>;
    groups: EntityGroup[];
}

export interface IDetailsProps {
    fabricListProps?: Partial<IDetailsListProps>;
};

type Props<T extends IExtensibleEntity = IExtensibleEntity> = IListProps<T> & IDetailsProps;

interface IState {
    fieldsMap: Dictionary<Metadata.Field>;
    items: IExtensibleEntity[];
    groupDetails?: GroupDetails;
    orderBy?: IOrderBy | IOrderBy[];
    isDragNDropAlertOpen?: boolean;
    totalCount: number;
    showCount?: number;
    fabricListGroupingProps?: Partial<IDetailsListProps>;
}

export type EntityWithKey = IExtensibleEntity & IObjectWithKey;

export default class EntityDetailsList extends React.Component<Props, IState> {
    private _draggedItem: IExtensibleEntity | undefined;
    private _draggedIndex: number = -1;

    constructor(props: Props) {
        super(props);
        const fieldsMap = Metadata.toMap(props.fields, props.isFieldFake);
        const groupDetails = this._buildGroupDetails(props.entities, props.groups, props.listGrouping);
        this.state = {
            fieldsMap,
            groupDetails,
            items: this._getItems(props, fieldsMap, props.sorting?.orderBy, groupDetails, props.showMore?.step),
            orderBy: this.props.sorting?.orderBy,
            totalCount: props.entities.length,
            showCount: props.showMore?.step,
            fabricListGroupingProps: this._buildFabricListGroupingProps(props, groupDetails)
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        let reorder = false;
        let fieldsMap = this.state.fieldsMap;
        if (!arraysEqual(this.props.fields, nextProps.fields)) {
            fieldsMap = Metadata.toMap(nextProps.fields, nextProps.isFieldFake)
            reorder = true;
            this.setState({ fieldsMap });
        }

        let orderBy = this.state.orderBy;
        if (!SortService.areSortsEqual(this.props.sorting?.orderBy, nextProps.sorting?.orderBy)
            && !SortService.areSortsEqual(this.state.orderBy, nextProps.sorting?.orderBy)) {
            orderBy = nextProps.sorting?.orderBy;
            reorder = true;
            this.setState({ orderBy });
        }

        if (reorder
            || !arraysEqual(this.props.entities, nextProps.entities)
            || this.props.groups !== nextProps.groups
            || this.props.isVirtualizationDisabled !== nextProps.isVirtualizationDisabled) {
            const groupDetails = this._buildGroupDetails(nextProps.entities, nextProps.groups, nextProps.listGrouping);
            const showCount = !nextProps.showMore
                ? undefined
                : this.state.showCount == null ? nextProps.showMore?.step : this.state.showCount;
            this.setState({
                items: this._getItems(nextProps, fieldsMap, orderBy, groupDetails, showCount),
                showCount,
                totalCount: nextProps.entities.length,
                groupDetails,
                fabricListGroupingProps: this._buildFabricListGroupingProps(nextProps, groupDetails)
            });
        }
    }

    private _getItems(
        props: Props,
        fields: Dictionary<Metadata.Field>,
        orderBy?: IOrderBy | IOrderBy[],
        groupDetails?: GroupDetails,
        showCount?: number
    ) {
        const { entities, isFieldFake, showMore, sorting, getKey, fieldValueExtractor } = props;
        let sortedEntities: IExtensibleEntity[] = [];
        if (sorting?.external) {
            sortedEntities = groupDetails
                ? groupDetails.groups.reduce(
                    (p, c) => p.concat(groupDetails.entitiesByGroupMap[c.key] || []),
                    Array.of<IExtensibleEntity>())
                : entities;
        } else if (!groupDetails) {
            sortedEntities = this._sortItems(entities, fields, orderBy, isFieldFake, fieldValueExtractor);
        } else {
            groupDetails.groups.forEach(_ => {
                const toSort = groupDetails.entitiesByGroupMap[_.key] || [];
                const sorted = this._sortItems(toSort, fields, orderBy, isFieldFake, fieldValueExtractor);
                sortedEntities = [...sortedEntities, ...sorted];
            });
        }

        if (!getKey) {
            for (const entity of sortedEntities) {
                (entity as IObjectWithKey).key = entity.id;
            }
        }

        const count = showCount && (showCount + (showMore?.extraCount || 0));
        return !groupDetails && count
            ? !orderBy && showMore && showMore.sliceFunc
                ? showMore.sliceFunc(sortedEntities, count, _ => _)
                : sortedEntities.slice(0, count)
            : sortedEntities;
    }

    private _sortItems(
        entities: IExtensibleEntity[],
        fields: Dictionary<Metadata.Field>,
        orderBy?: IOrderBy | IOrderBy[],
        isFieldFake?: (field: Metadata.Field) => boolean,
        extractor?: (item: IExtensibleEntity, field: Metadata.Field) => any
    ): IExtensibleEntity[] {
        return orderBy
            ? entities.sort(SortService.getComparer(fields, orderBy, isFieldFake, extractor))
            : entities;
    }

    public render() {
        const { showMore, getKey, isVirtualizationDisabled, selection, checkboxVisibility, draggableEvents, fabricListProps,
            isFilterApplied } = this.props;
        const { items, showCount, totalCount, groupDetails, fabricListGroupingProps } = this.state;
        if (!items.length && isFilterApplied !== false) {
            this.props.selection?.setAllSelected(false);
            return <ResultsNotFoundPlaceholder />;
        }
        return <>
            <DetailsListWrapper
                setKey='set'
                getKey={getKey}
                items={items}
                columns={this._buildColumns()}
                checkboxVisibility={checkboxVisibility}
                selectionMode={checkboxVisibility === CheckboxVisibility.hidden ? SelectionMode.none : fabricListProps?.selectionMode}
                selection={selection}
                layoutMode={DetailsListLayoutMode.justified}
                onColumnHeaderClick={this._onColumnHeaderClick}
                onColumnResize={this._onColumnResize}
                dragDropEvents={draggableEvents && this._getDragDropEvents()}
                onShouldVirtualize={() => !isVirtualizationDisabled}
                {...fabricListGroupingProps}
                {...fabricListProps} />
            {
                !groupDetails && showMore && showCount && totalCount > showCount && <DefaultButton
                    iconProps={{ iconName: "PPMXArrowDown", className: "icon-small" }}
                    className="details-list-show-more"
                    text={showMore.title}
                    onClick={() => this._showMore(showCount + showMore.step)} />
            }
            {
                this.state.isDragNDropAlertOpen && <DragWarningDialog
                    onDismiss={() => this.setState({ isDragNDropAlertOpen: false })}
                    onEnable={this._enableDragNDrop} />
            }
        </>;
    }

    private _onColumnResize = (column?: IColumn, newWidth?: number) => {
        column && newWidth && this.props.onColumnResize?.(column.key, Math.round(newWidth));
    }

    private _buildColumns = (): IColumn[] => {
        const { fieldsMap, orderBy } = this.state;
        const { onItemMenuRender, entityType, onItemRender, fabricListProps, extraColumns } = this.props;
        const onRenderItemColumn = fabricListProps?.onRenderItemColumn;

        const fieldColumns = this.props.displayFields
            .map<IColumn | null>((_, index) => {
                const column = ViewService.buildColumn(_, fieldsMap, orderBy, false, entityType, this.props.columns, this.props.disableNavigation, this.props.isArchived);

                if (!column) {
                    return column;
                }

                return {
                    ...column,
                    onRender: (item, i, c) =>
                        <RowMenuColumn
                            onItemMenuRender={onItemMenuRender && ViewService.isMenuColumn(entityType, _, index) ? () => onItemMenuRender(item) : undefined}>
                            {onItemRender
                                ? onItemRender(item, i!, fieldsMap[ViewService.getFieldName(_)],
                                    () => onRenderItemColumn
                                        ? onRenderItemColumn(item, i, c)
                                        : column.onRender?.(item, i, c))
                                : column.onRender?.(item, i, c)
                            }
                        </RowMenuColumn>
                };
            });

        return [...fieldColumns, ...extraColumns || []].filter(notEmpty);
    }

    private _onColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (!column || column.fieldName === undefined) return;
        const field = this.state.fieldsMap[column.fieldName];
        if (this.props.sorting?.disabled || !field || !SortService.isSortable(field, this.props.isFieldFake)) return;

        const orderBy: IOrderBy = getOrderBy(field.name, column);
        if (!this.props.sorting?.external) {
            const { fieldsMap, groupDetails, showCount } = this.state;
            this.setState({
                items: this._getItems(this.props, fieldsMap, orderBy, groupDetails, showCount),
                orderBy
            }, () => this.props.sorting?.onChange?.(orderBy));
        }
        else {
            this.props.sorting?.onChange?.(orderBy);
        }
    }

    private _buildGroupDetails(entities: IExtensibleEntity[], groups?: EntityGroup[], listGrouping?: ListGroupingProps): GroupDetails | undefined {
        if (!listGrouping || !groups) {
            return undefined;
        }

        const groupMap: Dictionary<IExtensibleEntity[]> = {};
        entities.forEach(_ => {
            const groupKey = listGrouping.getGroupKey(_);
            const array = groupMap[groupKey] || [];
            array.push(_);
            groupMap[groupKey] = array;
        });
        return { entitiesByGroupMap: groupMap, groups };
    }

    private _buildFabricListGroupingProps = (props: Props, groupDetails: GroupDetails | undefined): Partial<IDetailsListProps> | undefined => {
        const { listGrouping, groups, checkboxVisibility } = props;
        if (!groupDetails || !listGrouping) {
            return undefined;
        }

        const fabricListProps: Partial<IDetailsListProps> = {
            className: "groupped",
            selectionPreservedOnEmptyClick: true,
            groupProps: {
                onRenderHeader: this._renderGroupHeader,
                onRenderFooter: listGrouping.renderGroupFooter ? this._renderGroupFooter : undefined,
                collapseAllVisibility: CollapseAllVisibility.hidden,
                showEmptyGroups: true
            },
            onRenderDetailsHeader: (props?: IDetailsHeaderProps, defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null): JSX.Element | null => {
                if (!props || !defaultRender) {
                    return null;
                }
                if (props.columns.length) {
                    //nail: fix empty space on the right of the grid. caused by rows indent if grouping is on and collapseAllVisibility is hidden
                    const GROUP_EXPAND_WIDTH = 36;
                    const SCROLL_WIDTH = 17;
                    const delta = GROUP_EXPAND_WIDTH + SCROLL_WIDTH;
                    const lastCol = props.columns[props.columns.length - 1];
                    if (lastCol.calculatedWidth !== undefined && (lastCol.currentWidth === undefined || lastCol.calculatedWidth !== (lastCol.currentWidth + delta))) {
                        lastCol.calculatedWidth = lastCol.calculatedWidth + delta;
                    }
                }
                
                return defaultRender({ ...props, styles: { ...props.styles, root: this._buildRowIndentationStyles() } });
            },
            onRenderRow: (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null => {
                if (!props || !defaultRender) {
                    return null;
                }
                const groupKey = listGrouping.getGroupKey(props.item);
                const color: string | undefined = groups?.find(_ => _.key === groupKey)?.color;

                return (
                    <div className={`with-group-marker align-center ${checkboxVisibility === CheckboxVisibility.hidden ? "no-checkbox" : ""}`}>
                        <div className="group-marker" style={{ backgroundColor: color }} />
                        {defaultRender({ ...props, styles: { ...props.styles, fields: this._buildRowIndentationStyles() } })}
                    </div>
                );
            },
            getGroupHeight: (group: IGroup, groupIndex: number) => LIST_ROW_HEIGHT * (1 +
                (group.isCollapsed
                    ? 0
                    : ((groupDetails.entitiesByGroupMap[group.key]?.length ?? 0) + (this.props.listGrouping?.renderGroupFooter ? 1 : 0))
                ))
        };

        const fabricGroups: IGroup[] = [];
        groupDetails.groups.forEach(_ => {
            const startIndex = fabricGroups.map(_ => _.count).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            const count = (groupDetails.entitiesByGroupMap[_.key]?.length) || 0;
            const isCollapsed = this.state?.fabricListGroupingProps?.groups?.find(__ => __.key === _.key)?.isCollapsed;
            fabricGroups.push({ key: _.key, name: _.name, startIndex, count, data: _, isCollapsed });
        });

        fabricListProps.groups = fabricGroups;

        return fabricListProps;
    }

    private _renderGroupHeader = (props: IDetailsGroupDividerProps): JSX.Element | null => {
        const group: IGroup = props.group!;

        return (
            <div className={`group-row align-center ${!this.props.checkboxVisibility ? 'visible-onhover' : ''}`}>
                {this.props.listGrouping!.renderGroupHeader(
                    group.data as EntityGroup,
                    !!group.isCollapsed,
                    props.selected,
                    () => props.onToggleCollapse!(group),
                    () => props.onToggleSelectGroup!(group))}
            </div>
        );
    }

    private _renderGroupFooter = (props: IDetailsGroupDividerProps): JSX.Element | null => {
        const group: IGroup = props.group!;
        return group.isCollapsed
            ? null
            : this.props.listGrouping!.renderGroupFooter?.(group.data) ?? null;
    }

    private _getDragDropEvents(): IDragDropEvents {
        return {
            canDragGroups: true,
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                return "dragged_entity";
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            //first call: item - target entity
            //second call: item - target group
            onDrop: (item: any, event?: DragEvent) => {
                if (!item) {
                    return;
                }
                //item - entity or group
                const itemKey = item.key ?? this.props.getKey?.(item);
                const entity = this.props.entities.find(_ => ((_ as IObjectWithKey).key ?? this.props.getKey?.(_)) == itemKey);
                if (entity) {
                    const groupKey = this.props.listGrouping!.getGroupKey(entity);
                    const group = this.state.groupDetails?.groups.find(_ => _.key == groupKey)!;
                    this._dragEntity(group, entity);
                    event?.stopPropagation();
                    return;
                }
                const group = this.state.groupDetails?.groups.find(_ => _.key == item.key && _ == item.data);
                if (group) {
                    this._dragEntity(group);
                }
            },
            //first call: item - target entity
            //second call: item - target group
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                if (this.state.orderBy) {
                    this.setState({ isDragNDropAlertOpen: true })
                    return;
                }

                const itemKey = item.key ?? this.props.getKey?.(item);
                if (this.props.entities.find(_ => ((_ as IObjectWithKey).key ?? this.props.getKey?.(_)) == itemKey)) {
                    this._draggedItem = item;
                    this._draggedIndex = itemIndex!;
                    this.props.draggableEvents?.onDragStart?.(item);
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                this._draggedItem = undefined;
                this._draggedIndex = -1;
            }
        };
    }

    private _dragEntity(group: EntityGroup, targetItem?: IExtensibleEntity): void {
        const { selection, draggableEvents } = this.props;

        const draggedItems = selection && selection.isIndexSelected(this._draggedIndex)
            ? (selection.getSelection() as IExtensibleEntity[])
            : [this._draggedItem!];

        draggableEvents?.onDrag?.(draggedItems.map(_ => _.id), group.key, targetItem?.id)
    }

    private _enableDragNDrop = () => {
        const { entities, groups, listGrouping } = this.props;
        const { fieldsMap, showCount } = this.state;
        const groupDetails = this._buildGroupDetails(entities, groups, listGrouping);
        const orderBy = undefined;
        this.setState({
            isDragNDropAlertOpen: false,
            orderBy,
            items: this._getItems(this.props, fieldsMap, orderBy, groupDetails, showCount),
            groupDetails
        }, () => this.props.sorting?.onChange?.(orderBy));
    }

    private _showMore = (showCount: number) => {
        const { fieldsMap, orderBy, groupDetails } = this.state;
        this.setState({
            items: this._getItems(this.props, fieldsMap, orderBy, groupDetails, showCount),
            showCount: showCount
        });
    }

    private _buildRowIndentationStyles = (): IStyle => ({ marginLeft: getRowIndentation(this.props.checkboxVisibility) });
}

export type HierarchDetailsListProps<T extends THierarchyEntity> = Omit<Props<T>, "entities" | 'type'> & ListUserSettings & HierarchyProps<T>;

export const HierarchyDetailsList = <T extends THierarchyEntity>(props: HierarchDetailsListProps<T>) => {
    const listProps = chainMerger(props,
        _ => useListSaver(_),
        _ => useHierarchy(_.hierarchy, _.sorting!, props.comparerBuilder, _.onItemRender));
    return <EntityDetailsList {...listProps} />;
}

export const PersistDetailsList = (props: Props & ListUserSettings) => {
    const saverProps = useListSaver(props);
    return <EntityDetailsList {...props} {...saverProps} />;
}

export type SimpleListProps<T> = IDetailsListProps & IUserSettings<IWithSortBy & IWithSubViews> & {
    defaulSortBy: IOrderBy;
    comparerBuilder: ComparerBuilder<T>;
}
export const PersistSimpleList = <T,>(props: SimpleListProps<T>) => {
    const resize = useColumnResize(props.userSettings, props.onSaveSettings);
    const { orderBy, onChange: onOrderChange } = useSorting(props.userSettings, props.onSaveSettings, props.defaulSortBy);

    const onColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column?: IColumn) => {
        if (!column || column.fieldName === undefined) return;
        onOrderChange(getOrderBy(column.fieldName, column));
    }

    const columns = React.useMemo<IColumn[]>(
        () => mergeColumns(props.columns || [], orderBy!, resize.columns),
        [props.columns, orderBy, resize.columns]);

    const items = React.useMemo(
        () => [...props.items].sort(props.comparerBuilder(orderBy!)),
        [orderBy, props.comparerBuilder, props.items]);

    return <DetailsListWrapper
        {...props}
        items={items}
        columns={columns}
        onColumnHeaderClick={onColumnHeaderClick}
        onColumnResize={(column, width) => column?.key && resize.onColumnResize?.(column?.key, width || 0)}
    />;
}

const mergeColumns = (columns: IColumn[], orderBy: IOrderBy, resizeColumns: Metadata.ISubViewColumn[]) => columns.map(column => {
    const origin = resizeColumns.find(_ => _.id === column.key);
    return ({
        ...column,
        maxWidth: origin?.width ?? column.maxWidth,
        minWidth: origin?.width ?? column.minWidth,
        isSorted: column.fieldName == orderBy.fieldName,
        isSortedDescending: column.fieldName == orderBy.fieldName && orderBy.direction == SortDirection.DESC
    })
});