import * as React from 'react';
import { IListViewFieldColumn } from '../../../../../services/ViewService';
import MyWorkStatus from '../../../../mywork/MyWorkStatus';
import { Status_, MyWork } from '../../../../../store/MyWorkStore';

export default class Name extends React.Component<IListViewFieldColumn<MyWork>> {
    public render() {
        return <MyWorkStatus work={this.props.entity} />
    }
    
    public static getValue(entity: MyWork) {
        return Status_.getOrder(entity.attributes.Status);
    }
}