import * as React from 'react';
import { buildRoadmapItemDependencyIconName, DependencyType, IRoadmapItem, IRoadmapItemDependency } from '../../entities/Subentities';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react';
import RoadmapItemDependency from './RoadmapItemDependency';
import RoadmapItemDependencyCreateBox from './RoadmapItemDependencyCreateBox';
import { SectionControlPlaceholder } from '../common/sectionsControl/SectionPlaceholder';
import { toDictionary } from '../utils/common';

export const DependencyTypeMetadata = {
    [DependencyType.DependsOn]: {
        label: 'Depends On',
        iconName: buildRoadmapItemDependencyIconName(DependencyType.DependsOn)
    },
    [DependencyType.DeliversTo]: {
        label: 'Delivers To',
        iconName: buildRoadmapItemDependencyIconName(DependencyType.DeliversTo)
    }
}

interface RoadmapItemDependenciesProps {
    readonly: boolean;
    roadmapItemId?: string;
    roadmapItems: IRoadmapItem[];
    dependencies: IRoadmapItemDependency[];
    add: (item: IRoadmapItemDependency) => void;
    remove: (item: IRoadmapItemDependency) => void;
}

const RoadmapItemDependenciesEmptyState = (props: { readonly: boolean, onNewDependencyClick: () => void }) => <SectionControlPlaceholder
    iconName="PPMXSectionDependencies"
    title="No Dependencies defined">
    {!props.readonly && <DefaultButton text={`New Dependency`} onClick={props.onNewDependencyClick} />}
</SectionControlPlaceholder>;

const RoadmapItemDependencies = (props: RoadmapItemDependenciesProps) => {
    const [dependencyType, setDependencyType] = React.useState<DependencyType>();
    const onNewDependencyClick = () => setDependencyType(DependencyType.DependsOn);
    if (dependencyType === undefined && props.dependencies.length === 0) {
        return <RoadmapItemDependenciesEmptyState onNewDependencyClick={onNewDependencyClick} readonly={props.readonly} />
    }
    const { roadmapItemId, readonly } = props;
    const roadmapItemsMap = toDictionary(props.roadmapItems);

    return <>
        {props.dependencies.map((_, index) => <RoadmapItemDependency
            key={index}
            dependencyItem={roadmapItemsMap[_.sourceId === roadmapItemId ? _.targetId : _.sourceId]}
            dependencyType={_.sourceId === roadmapItemId ? DependencyType.DeliversTo : DependencyType.DependsOn}
            readonly={readonly}
            onRemove={() => props.remove(_)} />)}
        {!readonly && dependencyType === undefined && <ActionButton iconProps={{ iconName: "Add" }} onClick={onNewDependencyClick}>Add</ActionButton>}
        {!readonly && dependencyType !== undefined && <RoadmapItemDependencyCreateBox {...props} dependencyType={dependencyType}
            onCreate={(type: DependencyType, id: string) => {
                props.add(type === DependencyType.DeliversTo
                    ? { sourceId: roadmapItemId, targetId: id } as IRoadmapItemDependency
                    : { sourceId: id, targetId: roadmapItemId } as IRoadmapItemDependency);
                setDependencyType(undefined);
            }} />}
    </>;
}

export default RoadmapItemDependencies;