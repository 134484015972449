import { EntityWarningsTypeMap as EntityWarningsMap, ServerWarningTypeMap, SectionWarningsTypeMap, EntityWarningsTypeMap, IControlWarningsTypeMap } from "../warningsTypeMapsStore";
import { Dictionary, IWarning } from "../../entities/common";
import { Section } from "../../entities/Metadata";

type TypeConfig = { section: string, uiControl: string, item: string };

export class warningService {
    public static convert(serverMap: ServerWarningTypeMap): EntityWarningsMap {
        const targetedMap: Dictionary<SectionWarningsTypeMap> = {};
        const serverMapTargeted = serverMap.targeted;

        Object.keys(serverMapTargeted).forEach(sectionName => {
            const sectionMap: SectionWarningsTypeMap = {};
            Object.keys(serverMapTargeted[sectionName]).forEach(controlName => {
                const controlWarningsMap = serverMapTargeted[sectionName][controlName];
                let all: string[] = [];
                for (const prop in controlWarningsMap) {
                    all = all.concat(controlWarningsMap[prop]);
                }

                sectionMap[controlName] = {
                    byKey: { ...controlWarningsMap },
                    all
                }
            });

            targetedMap[sectionName] = sectionMap;
        });
        return { common: [...serverMap.common], targeted: targetedMap, dependencies: serverMap.dependencies };
    }

    public static getWarningsByKey(key: string, warnings: IWarning[], warningsTypeMap?: IControlWarningsTypeMap): IWarning[] | undefined {
        const warningTypes: string[] | undefined = warningsTypeMap && warningsTypeMap.byKey[key];
        return warningTypes && warnings.filter(_ => warningTypes.find(__ => __ === _.type));
    }

    //Copy of this filter exist in WarningsExtensions FilterBySections
    //after change it please made same change in server filter
    public static filterByEntityConfiguration(allWarnings: IWarning[], warningsTypeMap: EntityWarningsTypeMap, sections: Section[])
        : { warnings: IWarning[], sectionWarningsTypeMap: Dictionary<string[]> } {
        const dic: Dictionary<TypeConfig[]> = warningService.transposeWarningsTypeMap(warningsTypeMap.targeted);

        //exclude warnings for hidden sections and fields
        const detailsSection = sections.find(_ => _.isSelected && _.name === "Details");
        const fieldsArea = detailsSection && detailsSection.uiControls.find(_ => _.type === "FieldsArea");
        const fieldNames: string[] = (fieldsArea && fieldsArea.settings["fields"]) || [];

        const statusesSection = sections.find(_ => _.isSelected && _.name === "Statuses");
        const statusesControl = statusesSection && statusesSection.uiControls.find(_ => _.type === "StatusesControl");
        const statuses: string[] = ((statusesControl && statusesControl.settings["statuses"]) || []).map((_: string) => `${_}Status`);

        const hiddenSections: string[] = sections.filter(_ => _.isSelected === false).map(_ => _.name);

        const filteredWarningsTypes: string[] = [];
        const sectionWarningsTypeMap: Dictionary<string[]> = {};

        for (const type in dic) {
            if (!dic.hasOwnProperty(type)) {
                continue;
            }

            const visibleItems = dic[type].filter(_ =>
            ((!hiddenSections.find(__ => __ === _.section) && _.section !== "Details" && _.section !== "Statuses") ||
                (
                    (_.section === "Details" && _.uiControl === "FieldsArea" && fieldNames.find(__ => __ === _.item)) ||
                    (_.section === "Statuses" && _.uiControl === "StatusesControl" && statuses.find(__ => __ === _.item))
                )));

            const dependencies = warningsTypeMap.dependencies[type];

            let hasAllDependencies: boolean = true;
            dependencies.forEach(item => {
                hasAllDependencies = hasAllDependencies && (!!visibleItems.find(_ => _.item === item));
            });

            if (hasAllDependencies) {
                filteredWarningsTypes.push(type);

                visibleItems.forEach(_ => {
                    if (!!Object.keys(sectionWarningsTypeMap).find(__ => __ === _.section)) {
                        if (!sectionWarningsTypeMap[_.section].find(__ => __ === type)) {
                            sectionWarningsTypeMap[_.section].push(type);
                        }
                    } else {
                        sectionWarningsTypeMap[_.section] = [type];
                    }
                });
            }
        }

        const warnings = allWarnings.filter(_ => filteredWarningsTypes.find(__ => __ === _.type) || warningsTypeMap.common.find(__ => __ === _.type));
        return { warnings, sectionWarningsTypeMap };
    }

    private static transposeWarningsTypeMap(map: Dictionary<SectionWarningsTypeMap>): Dictionary<TypeConfig[]> {
        const dic: Dictionary<TypeConfig[]> = {};
        for (const sectionName in map) {
            if (!map.hasOwnProperty(sectionName)) {
                continue;
            }

            const sectionConfig = map[sectionName];
            for (const uiControlName in sectionConfig) {
                if (!sectionConfig.hasOwnProperty(uiControlName)) {
                    continue;
                }

                const uiControlConfig = sectionConfig[uiControlName].byKey;
                for (const itemName in uiControlConfig) {
                    if (!uiControlConfig.hasOwnProperty(itemName)) {
                        continue;
                    }

                    const warningTypes = uiControlConfig[itemName];
                    warningTypes.forEach(_ => {
                        const config = { section: sectionName, uiControl: uiControlName, item: itemName };
                        if (Object.keys(dic).find(__ => __ === _)) {
                            dic[_] = [...dic[_], config];
                        } else {
                            dic[_] = [config];
                        }
                    });
                }
            }
        }
        return dic;
    }
}