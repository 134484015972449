import React, { useMemo } from 'react';
import { IContextualMenuItem, IContextualMenuProps, Selection } from 'office-ui-fabric-react';
import { actionCreators as PngExportActionCreators } from '../../store/PngExporterStore';
import { buildExportToPngMenuItem, PngExportConfig } from './PngExporter';
import { UserState } from "../../store/User";
import { canCreate, contains, CommonOperations, LicenseType } from "../../store/permissions";
import { EntityType, IEditableExtensibleEntity, entityTypeLabelMap } from '../../entities/common';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { PPMFeatures, RoutesAvailability, Subscription, TenantState, ToRouteAvailabilitySettings } from '../../store/Tenant';
import ReportsPlaceholder from '../reporting/ReportsPlaceholder';
import { ActiveView, EntitiesScreenRouter, IHeaderProps, IEntitiesScreenView } from './EntitiesScreenHeader';
import { toDictionary, notUndefined } from "../utils/common";
import { Field, ISubView } from '../../entities/Metadata';
import { getActiveSubViewColumnIds } from './EntitiesScreen';
import { MenuTitleBuilder } from '../MenuTitleBuilder';

type ActionMenuItemBuildProps = {
    entityIds: string[];
    importEntityTypes: EntityType[];
    currentEntityType: EntityType;
    readonly: boolean | undefined;
    user: UserState;
    projectIntegrations: SourceType[];
    resourceIntegrations: SourceType[];
    pngExporterActions: typeof PngExportActionCreators;
    getPngExportConfig: (() => PngExportConfig) | undefined;
    onCsvImport: (() => void) | undefined;
    onCreate: (entityType?: EntityType) => void;
};

type PlanningAndUtilizationMenuItemBuildProps = {
    user: UserState;
    subscription: Subscription,
    headerProps: IHeaderProps,
    router: EntitiesScreenRouter,
};

type ReportedTimeMenuItemBuildProps = {
    user: UserState;
    subscription: Subscription,
    headerProps: IHeaderProps,
    router: EntitiesScreenRouter,
    tenant: TenantState
};

type ExportToCsvMenuItemBuildProps = {
    fields: Field[];
    fakeFields: Field[] | undefined;
    views: IEntitiesScreenView<IEditableExtensibleEntity>[];
    selectedItemIds: string[]
    entityType: EntityType;
    activeSubView: ISubView;
    selection: Selection;
    onExportEntitiesToFile: (pluralEntityTypeLabel: string, fields: string[], ids: string[]) => void;
};

const calloutProps = { className: "header-callout" };
const dropdownButtonClassName = "dropdown-button";

export interface IReportsProps {
    reportsButtonAdditionalOptions?: IContextualMenuItem[];
}

export const csvImportExportIconProps = { iconName: getFileTypeIconProps({ extension: "csv", size: 16, imageFileType: "svg" }).iconName };

export function buildActionsMenuItem(buildProps: ActionMenuItemBuildProps): IContextualMenuItem | undefined {
    const { entityIds, projectIntegrations, resourceIntegrations, pngExporterActions, readonly, getPngExportConfig, onCsvImport, onCreate } = buildProps;

    const disabled = buildProps.user.license !== LicenseType.Regular && !getPngExportConfig;
    const items = getActionsButtonOptions();
    const actionsMenuItem = buildActionsMenuItemWithItems(items);
    
    if (items.length === 0) {
        return undefined;
    }

    return {
        ...actionsMenuItem,
        disabled,
        className: `dropdown-button ${disabled ? 'disabled-button' : ''}`,
    };

    function getActionsButtonOptions(): IContextualMenuItem[] {
        const { permissions } = buildProps.user;
        const { importEntityTypes, currentEntityType } = buildProps;
        const actionsButtonItems: IContextualMenuItem[] = [];

        const entityTypesWithProjectImport: EntityType[] = [
            EntityType.Portfolio,
            EntityType.Program,
            EntityType.Project
        ];

        if (importEntityTypes.includes(EntityType.Project) && projectIntegrations.length && entityTypesWithProjectImport.includes(currentEntityType)) {
            actionsButtonItems.push({
                key: 'import-projects',
                name: 'Import Projects',
                iconProps: { iconName: "PPMXImport" },
                disabled: !canCreate(permissions.project),
                onClick: () => onCreate(EntityType.Project)
            });
        }

        if (importEntityTypes.includes(EntityType.Resource) && resourceIntegrations.length && currentEntityType === EntityType.Resource) {
            actionsButtonItems.push(
                {
                    key: 'import-resources',
                    name: 'Import Resources',
                    iconProps: { iconName: "PPMXImport" },
                    disabled: !contains(permissions.common, CommonOperations.ResourceManage),
                    onClick: () => onCreate(EntityType.Resource)
                }
            );
        }

        if (!readonly && onCsvImport) {
            actionsButtonItems.push(buildImportFromCsvMenuItem());
        }

        if (getPngExportConfig) {
            actionsButtonItems.push(buildExportToPngMenuItem(getPngExportConfig, pngExporterActions, !entityIds?.length));
        }

        return actionsButtonItems;
    }

    function buildImportFromCsvMenuItem(): IContextualMenuItem {
        return {
            key: 'importRows',
            name: 'Import from CSV',
            iconProps: csvImportExportIconProps,
            title: MenuTitleBuilder.importFromCSVTitle(entityTypeLabelMap[buildProps.currentEntityType].plural),
            onClick: onCsvImport!
        };
    }
}

export function buildActionsMenuItemWithItems(items: IContextualMenuItem[]): IContextualMenuItem {
    return {
        key: 'actions-command-item',
        text: 'Actions',
        title: 'Actions',
        iconProps: { iconName: "PPMXActions" },
        subMenuProps: {
            items,
            gapSpace: 10,
            useTargetAsMinWidth: true,
            calloutProps
        }
    }
};

export function buildReportsMenuItem(reportsProps: IReportsProps | undefined, tenant: TenantState): IContextualMenuItem | undefined {
    if (!reportsProps) {
        return undefined;
    }
    
    const { reportsButtonAdditionalOptions } = reportsProps;
    
    if (reportsButtonAdditionalOptions?.length) {
        return {
            key: 'reports',
            className: dropdownButtonClassName,
            text: "Reports",
            title: tenant.reporting.error,
            disabled: !reportsButtonAdditionalOptions || !reportsButtonAdditionalOptions.length,
            iconProps: { iconName: "FabricReportLibrary" },
            subMenuProps: {
                items: reportsButtonAdditionalOptions,
                gapSpace: 10,
                useTargetAsMinWidth: true,
                calloutProps
            }
        };
    }

    return {
        key: 'reports',
        className: dropdownButtonClassName,
        text: "Reports",
        iconProps: { iconName: "FabricReportLibrary" },
        subMenuProps: {
            items: [{ key: "" }],
            onRenderMenuList: () => <ReportsPlaceholder />
        }
    };
}


export function buildSortByMenuItem(active: ActiveView<IEditableExtensibleEntity>): IContextualMenuItem | undefined {
    if (!active.view.sortBy) {
        return undefined
    }
    
    const text = `Sort by ${active.view.sortBy.active.label}`;
    return {
        key: 'sort-by',
        className: dropdownButtonClassName,
        iconProps: { iconName: 'PPMXSort' },
        text: text,
        title: text,
        subMenuProps: buildSortMenuProps(active)
    };
}

export function buildPlanningAndUtilizationMenuItem(buildProps: PlanningAndUtilizationMenuItemBuildProps): IContextualMenuItem | undefined {
    const { user, subscription, headerProps, router } = buildProps;
    const text = 'Planning and Utilization';
    return RoutesAvailability.ResourcePlan(subscription, user)
        && Subscription.contains(subscription, PPMFeatures.ResourceManagement)
        && headerProps.entityType === EntityType.Resource
            ? {
                key: 'plan-usage',
                text: text,
                title: text,
                iconProps: { iconName: "PPMXSectionResourcePlan" },
                onClick: () => router.history.push(`/resources/plan`),
            } : undefined;
}

export function buildReportedTimeMenuItem(buildProps: ReportedTimeMenuItemBuildProps): IContextualMenuItem | undefined {
    const { user, subscription, headerProps, router, tenant } = buildProps;
    const text = 'Reported Time';
    return RoutesAvailability.ReportedTime(subscription, user, ToRouteAvailabilitySettings(tenant))
        && headerProps.entityType === EntityType.Resource
            ? {
                key: 'reported-time',
                text: text,
                title: text,
                iconProps: { iconName: "Clock" },
                onClick: () => router.history.push(`/resources/reported-time`),
            } : undefined;
}

export const buildExportToCsvMenuItem = (buildProps: ExportToCsvMenuItemBuildProps): IContextualMenuItem => {
    const getDisplayedFieldNames = () => {
        const { views, fields, fakeFields, activeSubView } = buildProps;
        const columnIds = getActiveSubViewColumnIds(views, activeSubView);
        const fieldsMap = toDictionary(fields.concat(fakeFields ?? []));
        return columnIds.map(_ => fieldsMap[_]?.name)?.filter(notUndefined);
    };

    const onCsvExport = () => {
        buildProps.onExportEntitiesToFile(
            entityTypeLabelMap[buildProps.entityType].plural,
            getDisplayedFieldNames(),
            buildProps.selectedItemIds);

        buildProps.selection.setAllSelected(false);
    };

    const text = 'Export to CSV';
    return {
        key: 'export-to-csv',
        text,
        title: MenuTitleBuilder.exportSelectedToCSVTitle(buildProps.entityType),
        iconProps: csvImportExportIconProps,
        onClick: onCsvExport,
    };
};

function buildSortMenuProps(active: ActiveView<IEditableExtensibleEntity>): IContextualMenuProps {
    const { sortBy, onSortChange } = active.view;

    const items = sortBy
        ? sortBy.options.map<IContextualMenuItem>(_ => ({
            key: _.label,
            name: _.label,
            iconProps: { iconName: _.iconName },
            onClick: () => onSortChange && onSortChange(_)
        }))
        : [];

    return {
        items: items,
        className: "views-list",
        gapSpace: 10,
        useTargetAsMinWidth: true,
        calloutProps
    };
}