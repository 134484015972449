import './ExtensionPanel.css'
import * as React from 'react';
import { connect } from 'react-redux';
import { PanelType } from 'office-ui-fabric-react';
import ExpandablePanel from '../ExpandablePanel';
import { Dictionary, EXTRA_LARGE_PANEL_WIDTH, EntityType } from '../../../entities/common';
import { ExtensionInfo } from '../../../store/ExtensionStore';
import { TenantState } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { EmbeddedContent } from '../EmbeddedContent';

type ExtensionViewerProps = {
    entityType: EntityType,
    info: ExtensionInfo,
    context: Dictionary<any>,
    ///will be removed
    urlParams?: Dictionary<string>,
}

const ExtensionViewer = (props: ExtensionViewerProps) => {
    const urlStr = props.info.url;
    if (!urlStr) {
        return <div className="error-message">Extension is not configured well for {props.entityType}</div>;
    }
    const url = new URL(urlStr);
    if (props.urlParams) {
        for (const key in props.urlParams) {
            if (props.urlParams.hasOwnProperty(key)) {
                url.searchParams.set(key, props.urlParams[key])
            }
        }
    }

    return <EmbeddedContent url={url} context={props.context} />
}

type OwnProps = { entityType: EntityType, info: ExtensionInfo, onDismiss: () => void, context: Dictionary<any>, urlParams?: Dictionary<string> }
type StateProps = { tenant: TenantState }

const Header = (props: {name: string}) => (
    <div className="ms-Panel-header align-center">
        <p className="ms-Panel-headerText">{props.name}</p>
    </div>
);
const ExtensionPanel = (props: OwnProps & StateProps) => {
    const urlParams = props.urlParams ? { ...(props.urlParams || {}), tenantId: props.tenant.id } : undefined;
    
    return <ExpandablePanel
        className="extension-panel"
        isOpen
        type={PanelType.custom}
        customWidth={EXTRA_LARGE_PANEL_WIDTH}
        onDismiss={props.onDismiss}
        onRenderHeader={() => <Header name={props.info.name}/>}
    >
        <ExtensionViewer context={props.context} urlParams={urlParams} entityType={props.entityType} info={props.info} />
    </ExpandablePanel>;
}
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(ExtensionPanel);