import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import SettingsPage, { Section } from '../SettingsPage';
import { CommonOperations, contains } from '../../../store/permissions';
import OrganizationAuthenticationSettings from './OrganizationAuthenticationSettings';
import OrganizationAuthenticationSettingsTooltip from './OrganizationAuthenticationSettings/OrganizationAuthenticationSettingsTooltip';
import GlobalSettings from './GlobalSettings';
import SyncSettings from './SyncSettings';
import NotificationSettings from './NotificationSettings';
import AzureADSyncSettings from './AzureADSyncSettings';
import AzureADUsersSyncSettings from './AzureADUsersSyncSettings';
import ExtensionsSettings from './ExtensionsSettings';
import { PPMFeatures, ProductType, Subscription, isAzureAdAvailable } from '../../../store/Tenant';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/User';
import ResourcePlanningSettings from './ResourcePlanningSettings';
import DefaultProjectSettings from './DefaultProjectSettings';
import {StyleSettings, StyleSettingsTooltip} from './StyleSettings';
import {FeaturesSettings, FeaturesSettingsTooltip} from './FeaturesSettings';

type Props = {
    subscription: Subscription;
    user: UserState;
    productType: ProductType;
}

const TenantSettings = (props: Props) => {
    const { subscription, user, productType } = props;

    const sections: Section[] = [];
    
    if (Subscription.containsAny({features: subscription.allFeatures}, PPMFeatures.TimeTracking | PPMFeatures.PrivateProjects)) {
        sections.push({
            key: "featuresAndModules",
            name: "Features and Modules",
            icon: "",
            onRenderBody: () => <FeaturesSettings />,
            tooltip: <FeaturesSettingsTooltip/>
        });
    }

    sections.push(...[
        {
            key: 'styleSettings',
            name: 'Style',
            icon: '',
            onRenderBody: () => <StyleSettings />,
            tooltip: <StyleSettingsTooltip />
        },
        {
            key: "organizationAuthenticationSettings",
            name: "Organization Authentication",
            icon: "",
            onRenderBody: () => <OrganizationAuthenticationSettings />,
            tooltip: <OrganizationAuthenticationSettingsTooltip />
        },
        {
            key: "globalSettings",
            name: "Global Settings",
            icon: "",
            onRenderBody: () => <GlobalSettings />,
            tooltip: <div>
                Define your Tenant timezone. Update tasks' and key dates' statuses imported from external systems will occur daily at Midnight according to the selected timezone.
                <br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
            </div>
        }]
    );

    if (contains(user.permissions.common, CommonOperations.ScheduleManage) && Subscription.hasSyncableIntegration(subscription)) {
        sections.push({
            key: "dataSynchronizationSettings",
            name: "Data Synchronization",
            icon: "",
            onRenderBody: () => <SyncSettings />,
            tooltip: <div>
                Define the exact day-to-day time when the sync on projects connected with external systems will take place.
                <br />
                Select the recipients to send the 'data synchronization failed' email, if needed.
                <br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
            </div>
        });
    }

    sections.push({
        key: "notifications",
        name: "Notifications",
        icon: "",
        onRenderBody: () => <NotificationSettings />,
        tooltip: <div>
            Set up daily and weekly summary notifications to Microsoft Teams or by email.
            Enable or disable email notifications on PPM Express actions/events.
            <br />
            Please refer to the <Link href="https://help.ppm.express/89245-getting-started-with-ppm-express/529387" target="_blank">article</Link> for more details.
        </div>
    });   

    if (Subscription.contains(subscription, PPMFeatures.ResourceManagement) &&
        Subscription.contains(subscription, PPMFeatures.PortfolioManagement)) {
        sections.push({
            key: "resourcePlanningSettings",
            name: "Resource Planning",
            icon: "",
            onRenderBody: () => <ResourcePlanningSettings />,
            tooltip: <div>
                Define the level on which Resource Planning will be performed.
                Please note that Resource Planning will be available only on the selected level and will be disabled on other levels.
                <br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
            </div>
        });
    }

    sections.push({
        key: "defaultProjectSettings.",
        name: "Default Project Settings",
        icon: "",
        onRenderBody: () => <DefaultProjectSettings />
    });

    if (isAzureAdAvailable(productType)) {
        sections.push(...[
            {
                key: "azureADSyncSettings",
                name: "Azure Active Directory Resource Synchronization",
                icon: "",
                onRenderBody: () => <AzureADSyncSettings />,
                tooltip: <div>
                    Configure fields mapping between Azure Active Directory and PPM Express Resources.
                    <br />
                    Please refer to the <Link href="https://help.ppm.express/111183-resource-management/1391517" target="_blank">article</Link> for more details.
                </div>
            },
            {
                key: "azureADUsersSyncSettings",
                name: "Azure Active Directory User Synchronization",
                icon: "",
                onRenderBody: () => <AzureADUsersSyncSettings />,
                tooltip: <div>
                    Configure sync between Azure Active Directory and PPM Express Users.
                    <br />
                    Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
                </div>
            },
        ])
    }

    return <SettingsPage title="Tenant Settings" requiredPermission={CommonOperations.ScheduleView} sections={sections.concat([
        {
            key: "extensionsSettings",
            name: "Extensions (Beta)",
            icon: "",
            onRenderBody: () => <ExtensionsSettings />,
            tooltip: <div>Configure extension to extend PPM Express functionality.</div>
        }])}>
    </SettingsPage>;
}

const mapStateToProps = (_: ApplicationState) => ({
    subscription: _.tenant.subscription,
    user: _.user,
    productType: _.tenant.subscription.productType
});
export default connect(mapStateToProps)(TenantSettings);