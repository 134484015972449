import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dictionary, UserPreferencesSettingsUpdate } from '../../../../entities/common';
import { ApplicationState } from '../../../../store';
import { actionCreators as UserActionCreators, UserState } from '../../../../store/User';
import { BaseSectionsContainerProps, BaseSectionsContainer } from './BaseSectionsContainer';
import { actionCreators as SectionsActionCreators } from '../../../../store/Sections';
import { useDidMount } from '../../../utils/effects';
import { actionCreators as UserInterfaceActionCreators, UserInterfaceState } from '../../../../store/UserInterfaceStore';

export interface StateProps {
    user: UserState;
    isLoading: boolean;
    sectionsSettings?: Dictionary<any>;
    userInterface: UserInterfaceState;
}

export type ActionProps = {
    userActions: typeof UserActionCreators;
    sectionsActions: typeof SectionsActionCreators;
    userInterfaceActions: typeof UserInterfaceActionCreators;
}

export type SectionsContainerProps = Omit<BaseSectionsContainerProps, keyof (ActionProps & StateProps)>
type Props = SectionsContainerProps & ActionProps & StateProps & RouteComponentProps<{}>;

const SectionsContainer = (props: Props) => {
    const didMount = useDidMount();

    React.useEffect(() => {
        props.sectionsActions.loadSettings(props.entity.id);
    }, [props.entity.id])

    const onSaveSetting = React.useCallback((update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => {
        const updateWithEntityId: UserPreferencesSettingsUpdate = {
            parentSettingsPathKeys: [props.entity.id, ...update.parentSettingsPathKeys],
            valueBySettingNameMap: update.valueBySettingNameMap
        };
        
        props.sectionsActions.saveSettings(updateWithEntityId, sendToServer ?? true);
    }, [props.entity.id]);

    return <BaseSectionsContainer
        {...props}
        onSaveSettings={(props.isLoading || !didMount) ? undefined : onSaveSetting}
        sectionsSettings={props.sectionsSettings?.[props.entity.id]} />
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(UserActionCreators, dispatch),
        sectionsActions: bindActionCreators(SectionsActionCreators, dispatch),
        userInterfaceActions: bindActionCreators(UserInterfaceActionCreators, dispatch)
    };
}

function mapStateToProps(state: ApplicationState, ownProps?: SectionsContainerProps): StateProps {
    return {
        user: state.user,
        isLoading: state.sections.isLoading,
        sectionsSettings: state.sections.settings,
        userInterface: state.userInterface
    }
}

export default withRouter<SectionsContainerProps>(connect(mapStateToProps, mergeActionCreators)(SectionsContainer));