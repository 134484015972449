import * as React from 'react';
import { connect } from 'react-redux';
import { FormatDate, formatFieldValue } from "../utils/common";
import { ProjectInfo, ProjectAttrs } from "../../store/ProjectsListStore";
import ProjectLogo from './ProjectLogo';
import LinkedSystemIcons from "../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons";
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import { FormatType, Field, getLabel } from '../../entities/Metadata';
import { nameof } from '../../store/services/metadataService';
import { ApplicationState } from '../../store';
import { EntityType, StatusCategory, ppmxTaskConnectionId } from '../../entities/common';
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { UserState } from '../../store/User';
import Stage from '../views/list/columns/project/Stage';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import TasksIcon from '../common/sectionsControl/uiControls/projectSummaryControl/TasksIcon';
import { getTasksMetrics } from '../../store/project/utils';
import PrivateProjectIcon from '../common/PrivateProjectIcon';

type OwnProps = { project: ProjectInfo };
type StoreProps = { fields: Field[]; user: UserState };
type Props = OwnProps & StoreProps;

const ProjectTooltipContent = (props: Props) => {
    const { project } = props;

    const startDate = project.attributes.StartDate
        ? FormatDate(project.attributes.StartDate)
        : "-";
    const finishDate = project.attributes.FinishDate
        ? FormatDate(project.attributes.FinishDate)
        : "-";
    const manager = project.attributes.Manager;
    const projectOverallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Project, props.fields)!;
    const statusOption = projectOverallStatusDescriptor.getOptionOrDefault(project.attributes.OverallStatus, StatusCategory.NA);

    return (
        <div className="timeline-tooltip">
            <div className="header">
                <ProjectLogo imageId={project.imageId} />
                <div className="title"><div className="overflow-text">
                    {project.isPrivate && <PrivateProjectIcon /> }
                    {project.attributes.Name}</div>
                </div>
                <CategoryColorStatusView
                    statusOption={statusOption}
                    trend={project.insights.statuses[nameof<ProjectAttrs>("OverallStatus")]?.trend}
                />
            </div>

            <div className="content">
                {renderField("StartDate", <div>{startDate}</div>)}
                {renderField("FinishDate", <div>{finishDate}</div>)}
                {renderField("Budget", <div>{formatFieldValue(project.attributes.Budget, FormatType.Cost, props.user)}</div>)}                    
                {renderField("Progress", 
                    <div className="align-center">
                        <div className="connect-buttons">
                            {!!getTasksMetrics(project.sourceInfos, project.calculation.taskProgresses, ppmxTaskConnectionId)?.total && <TasksIcon entity={project} />}
                            <LinkedSystemIcons
                                sourceInfos={project.sourceInfos}
                                taskProgresses={project.calculation.taskProgresses}
                                showTitle
                                showMetrics
                                excludeCollaborative
                                entityType={EntityType.Project} />
                        </div>
                    </div>)}
                {renderField("Stage",
                    project.attributes.Stage != undefined
                        ? field => <Stage entity={project} field={field} />
                        : <div>-</div>)}
                {renderField("Manager",
                    manager.length > 0
                        ? <div className="align-center">
                            <ResourceFormatter resource={manager} withNavigation={false} />
                        </div>
                        : <div className="align-center">
                            <span>(Manager is not assigned yet)</span>
                        </div>)}
            </div>
        </div>
    );

    function renderField(name: keyof ProjectAttrs, value: ((field: Field) => JSX.Element) | JSX.Element): JSX.Element | null {
        const field = props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {typeof value === 'function' ? value(field): value}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Project];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        user: state.user
    };
}

export default connect(mapStateToProps)(ProjectTooltipContent);